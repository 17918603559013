import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import productsApi from "shared/logic/api/productsApi";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";
import PartneredProductsQuery from "shared/logic/types/PartneredProducts/PartneredProductsQuery";

export interface GetPartneredProductsReturn {
  allPartneredProducts: PartneredProduct[];
  filteredPartneredProducts: PartneredProduct[];
  filteredPartneredProductsMap: Record<string, PartneredProduct>;
}

export async function getPartneredProducts(
  partneredProductsQuery: PartneredProductsQuery,
): Promise<GetPartneredProductsReturn> {
  const partneredProductsResponse = await productsApi.getPartneredProducts({
    ...partneredProductsQuery,
    approvalStatus: undefined,
  });
  const { partneredProducts: allPartneredProducts } = partneredProductsResponse.data;

  let filteredPartneredProducts: PartneredProduct[];
  if (partneredProductsQuery.approvalStatus) {
    filteredPartneredProducts = allPartneredProducts.filter(
      (partneredProduct) =>
        partneredProduct.partnerApprovalStatus === partneredProductsQuery.approvalStatus,
    );
  } else {
    filteredPartneredProducts = [...allPartneredProducts];
  }

  const filteredPartneredProductsMap = parseArrayToIdsEntityPair(filteredPartneredProducts);

  return {
    allPartneredProducts,
    filteredPartneredProducts,
    filteredPartneredProductsMap,
  };
}
