import { useState } from "react";

export const useOrganization = () => {
  const [isOrganizationDrawerOpen, setOrganizationDrawerOpen] = useState(false);
  const toggleOrganizationDrawer = () => {
    setOrganizationDrawerOpen((prev) => !prev);
  };
  const onOrganizationEditClick = () => {
    toggleOrganizationDrawer();
  };
  return {
    isOrganizationDrawerOpen,
    toggleOrganizationDrawer,
    onOrganizationEditClick,
  };
};
