import { styled, Button, css } from "@mui/material";

export const StyledButton = styled(Button)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);
