import { FC } from "react";
import { ButtonProps } from "@mui/material";
import { Button, BackIconWrapper, BackIcon, BackText } from "./BackButton.styled";

export const BackButton: FC<ButtonProps> = ({ ...materialProps }) => (
  <Button {...materialProps}>
    <BackIconWrapper>
      <BackIcon />
    </BackIconWrapper>
    <BackText content="navigation.back" />
  </Button>
);
