import { styled, Box, IconButton, Popover, css, Button } from "@mui/material";

export const StyledButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const StyledMoreButton = styled(IconButton)`
  align-self: center;
  :hover {
    background-color: transparent;
  }
`;

export const StyledPopover = styled(Popover)(
  ({ theme }) => css`
    & .MuiPopover-paper {
      border-radius: 0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px;
      box-shadow: ${theme.spacing(0, 0.5, 2)} rgba(0, 0, 0, 0.16);
      border: 1px solid ${theme.palette.border};
    },
  `,
);

export const RequestCommissionBtn = styled(Button)`
  width: 350px;
  height: 72px;
  :hover {
    background-color: transparent;
  }
`;

export const StyledRequestButton = styled(Button)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    :hover {
      background-color: transparent;
    }

    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);
