import BrandView from "views/brandViews";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import React from "react";
import AdminViews from "views/adminViews";
import { ExploreProducts } from "views/partnerViews/Discover/ExploreProducts";
import { Logout } from "views/Logout";
import { useAuthenticatedRouting } from "views/Routing/AuthenticatedRouting/useAuthenticatedRouting";
import { InviteToPlatform } from "views/InviteToPlatform";

interface AuthenticatedRoutingProps {
  isSetUpNotCompleted: boolean;
}

export const AuthenticatedRouting: React.FC<AuthenticatedRoutingProps> = ({
  isSetUpNotCompleted,
}) => {
  const {
    productsPathComponent,
    setupPathComponent,
    partnershipsPathComponent,
    dashboardPathComponent,
    discoverPathComponent,
    isBrandUser,
    isAdmin,
    isPartnerUser,
    settingsPathComponent,
  } = useAuthenticatedRouting(isSetUpNotCompleted);

  return (
    <Switch>
      <Route path={RouteEnum.DASHBOARD} component={dashboardPathComponent} />
      <Route path={RouteEnum.PARTNERSHIPS} component={partnershipsPathComponent} />
      <Route path={RouteEnum.PRODUCTS} component={productsPathComponent} />
      <Route path={RouteEnum.INVITE_TO_PLATFORM} component={InviteToPlatform} />
      <Route path={RouteEnum.SETTINGS} component={settingsPathComponent} />
      {isBrandUser && [
        <Route
          path={RouteEnum.ASSIGN_PRODUCT}
          key={RouteEnum.ASSIGN_PRODUCT}
          component={BrandView.AssignProduct}
        />,
        <Route
          path={RouteEnum.ASSIGN_PARTNER}
          key={RouteEnum.ASSIGN_PARTNER}
          component={BrandView.AssignPartner}
        />,
      ]}
      {isAdmin && <Route path={RouteEnum.ADD_ACCOUNT} component={AdminViews.AddAccount} />}
      {isPartnerUser && (
        <Route exact path={`${RouteEnum.DISCOVER}/:id`} component={ExploreProducts} />
      )}
      <Route path={RouteEnum.DISCOVER} component={discoverPathComponent} />
      <Route path={RouteEnum.SETUP} component={setupPathComponent} />
      <Route path={RouteEnum.LOGOUT} component={Logout} />
      <Route path="*">
        <Redirect to={RouteEnum.DASHBOARD} />
      </Route>
    </Switch>
  );
};
