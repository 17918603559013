import {
  performTransaction,
  PerformTransactionArgs,
} from "shared/logic/store/features/user/signUp/utils/performTransaction";
import { UserPoliciesDTO } from "shared/logic/types/User/UserPolicy";
import userApi from "shared/logic/api/userApi";

interface CreatePolicyTransactionArgs
  extends Pick<PerformTransactionArgs<UserPoliciesDTO>, "onError" | "rollback"> {
  data: UserPoliciesDTO;
}

export async function createPolicyTransaction({
  data,
  onError,
  rollback,
}: CreatePolicyTransactionArgs): Promise<UserPoliciesDTO> {
  async function action(): Promise<UserPoliciesDTO> {
    const newPolicyResponse = await userApi.putUserPolicy(data);
    return newPolicyResponse.data;
  }

  return performTransaction({ action, onError, rollback });
}
