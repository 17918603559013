import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";

export const useLayoutVariantButtons = () => {
  const [searchParams] = useSearchParams();
  const isGridViewDisabled = searchParams?.status === ApprovalStatus.PENDING; // Temporarily disable grid on pending items view due to lack of designs

  return {
    isGridViewDisabled,
  };
};
