import { FC, ReactNode } from "react";
import { ListItemText } from "@mui/material";
import { TextBase, TextContentPath } from "shared/components/base/TextBase/TextBase";
import {
  StyledListButton,
  StyledListIcon,
  FlexBox,
  StyledBadge,
  StyledTitle,
  BadgeWrapper,
} from "views/NavigationSidebar/NavigationItem/NavigationItem.styled";
import { SvgIconComponent } from "@mui/icons-material";

export interface NavigationItemProps {
  localizedText: TextContentPath;
  MaterialIcon: SvgIconComponent;
  onClick: () => void;
  isSelected?: boolean;
  badgeContent?: ReactNode;
  title?: TextContentPath;
}

export const NavigationItem: FC<NavigationItemProps> = ({
  MaterialIcon,
  localizedText,
  onClick,
  isSelected,
  badgeContent,
  title,
}) => (
  <>
    {title && <StyledTitle variant="caption" color="primary.disabled" content={title} />}
    <StyledListButton selected={isSelected} onClick={onClick}>
      <FlexBox>
        <StyledListIcon>
          <MaterialIcon />
        </StyledListIcon>
        <ListItemText>
          <TextBase content={localizedText} variant="subtitle1" />
        </ListItemText>
      </FlexBox>
      <BadgeWrapper>
        <StyledBadge color="secondary" badgeContent={badgeContent} />
      </BadgeWrapper>
    </StyledListButton>
  </>
);
