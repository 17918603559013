import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import brandUser from "../../viewsSlices/brandUser";
import partnerUser from "../../viewsSlices/partnerUser";

interface PartneredProductsState {
  partneredProducts: IdAndEntityPair<PartneredProduct>;
}

export const initialState: PartneredProductsState = {
  partneredProducts: {},
};

const partneredProductsSlice = createSlice({
  name: storePrefixes.resourceSlices.partneredProduct,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(brandUser.assignView.revokeAccess.fulfilled, (state, action) => {
        delete state.partneredProducts[action.payload.partneredProductId];
      })
      .addMatcher(
        isAnyOf(
          brandUser.assignView.fetchResources.fulfilled,
          brandUser.assignView.makeProductAvailable.fulfilled,
          brandUser.dashboardView.fetchResources.fulfilled,
          brandUser.partnershipsView.fetchResources.fulfilled,
          partnerUser.partnershipsView.fetchResources.fulfilled,
          partnerUser.productsView.fetchResources.fulfilled,
          partnerUser.dashboardView.fetchResources.fulfilled,
        ),
        (state, action) => {
          state.partneredProducts = {
            ...state.partneredProducts,
            ...action.payload.partneredProducts,
          };
        },
      );
  },
});

export default partneredProductsSlice.reducer;
