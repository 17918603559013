import { matchPath, useLocation } from "react-router-dom";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { UserRole } from "shared/components/auth/AuthContext";
import { useSelector } from "react-redux";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";

export const useFiltersContainer = () => {
  const { pathname } = useLocation();
  const currentPolicy = useSelector(getUserPolicy);
  const shouldDisplayProductTabs =
    currentPolicy?.entityType === UserRole.PARTNER && matchPath(pathname, RouteEnum.PRODUCTS);

  return {
    shouldDisplayProductTabs,
  };
};
