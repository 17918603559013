import createTheme from "@mui/material/styles/createTheme";

const defaultBodyStyling = {
  styleOverrides: {
    body: {
      margin: 0,
      padding: 0,
    },
  },
};

// theme proto
// design rules will go here
export const createFermatTheme = () =>
  createTheme({
    palette: {
      primary: {
        main: "#0B132B",
        light: "#333954",
        dark: "#000",
        contrastText: "#FFF",
        disabled: "rgba(255, 255, 255, 0.38)",
      },
      secondary: {
        main: "#E98074",
        light: "#FFB1A3",
        dark: "#B35148",
        contrastText: "rgba(255, 255, 255, 0.74)",
      },
      success: {
        main: "#4CAF50",
        light: "rgba(76, 175, 80, 0.12)",
      },
      warning: {
        main: "#FBC02D",
        light: "rgb(255,247,230)",
      },
      error: {
        main: "#B00020",
        light: "rgba(176, 0, 32, 0.12)",
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.74)",
        disabled: "rgba(0, 0, 0, 0.38)",
      },
      tertiary: {
        main: "#5A4FDB",
        light: "#917CFF",
        dark: "#0F25A8",
        contrastText: "#FFF",
        dim: "rgba(90, 79, 219, 0.08)",
      },
      background: {
        onSurface: "rgb(245, 245, 245)",
        onSurfaceHover: "rgb(230, 230, 230)",
      },
      border: "rgba(0, 0, 0, 0.12)",
      action: {
        // TODO -- do something with opacity
      },
    },
    typography: {
      fontFamily: ["Poppins", "serif"].join(","),
      subtitle1: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: "148%",
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "148%",
      },
      caption: {
        fontSize: 11,
        fontWeight: 500,
        lineHeight: "14px",
      },
      h2: {
        lineHeight: "120%",
        fontWeight: 600,
        fontSize: 33,
      },
      h3: {
        lineHeight: "120%",
        fontWeight: 600,
        fontSize: 28,
      },
      h4: {
        fontSize: 23,
        lineHeight: "120%",
        fontWeight: 600,
      },
      h5: {
        fontSize: 19.2,
        lineHeight: "120%",
        fontWeight: 600,
      },
      tableLabel: {
        fontSize: 13,
        lineHeight: "19px",
        fontWeight: 500,
      },
      caption2: {
        fontSize: 11,
        lineHeight: "148%",
        fontWeight: 400,
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiCssBaseline: defaultBodyStyling,
      MuiBadge: {
        styleOverrides: {
          badge: {
            fontSize: 11,
            lineHeight: "14px",
          },
        },
      },
      MuiListItemButton: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        variants: [
          {
            props: { size: "medium" },
            // TODO - get values from spacing
            style: { padding: "12px 16px" },
          },
          {
            props: { size: "small" },
            // TODO - get values from spacing
            style: { padding: "6px 8px" },
          },
          {
            props: { size: "icon" },
            // TODO - get values from spacing
            style: { padding: "12px", minWidth: 0 },
          },
        ],
      },
      MuiIconButton: {
        variants: [
          {
            props: { size: "small" },
            style: { padding: "6px" },
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            fontSize: 16,
          },
        },
      },
    },
  });

// default theme injected at load time
export const defaultTheme = createFermatTheme();
