export const isObjectEmpty = (object?: object) => {
  if (!object) {
    return true;
  }
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const i in object) {
    if (Object.prototype.hasOwnProperty.call(object, i)) return false;
  }
  return true;
};
