import { Box, Button, css, InputAdornment, styled } from "@mui/material";
import { LocalizedInput } from "shared/components/base/LocalizedInput/LocalizedInput";

export const StyledBox = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    max-width: 362px;
    margin: 0 auto;
    justify-content: center;

    & > * + * {
      margin-top: ${theme.spacing(5)};
    }
  `,
);

export const StyledForm = styled("form")(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(5.5)};
    }
  `,
);

export const NameInputContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;

    & > * + * {
      margin-left: ${theme.spacing(2)};
    }
  `,
);

export const StyledInput = styled(LocalizedInput)(
  ({ theme }) => css`
    input {
      font-size: ${theme.typography.subtitle1.fontSize}px;
      line-height: ${theme.typography.subtitle1.lineHeight};
      font-weight: ${theme.typography.subtitle1.fontWeight};
    }
  `,
);

export const TitleWrapper = styled(Box)(
  ({ theme }) => css`
    text-align: center;

    & > * + * {
      margin-top: ${theme.spacing(0.5)};
    }
  `,
);

export const StyledInputAdornment = styled(InputAdornment)(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
  `,
);

export const ClickableStyledInputAdornment = styled(StyledInputAdornment)`
  cursor: pointer;
`;

export const SubmitBtn = styled(Button)``;
