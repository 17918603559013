import { FC, Fragment } from "react";
import { TableBody } from "@mui/material";
import { ViewHeadline } from "shared/components/ViewHeadline";
import { FiltersContainer } from "shared/components/TableComponents/FiltersContainer";
import { TableWrapper } from "shared/components/TableComponents/TableWrapper";
import { useDiscoverBrands } from "views/partnerViews/Discover/useDiscoverBrands";
import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";
import { SpaceBetweenWrapper } from "../../brandViews/Partnerships/Partnerships.styled";
import { DiscoverBrandsTableHeader } from "./DiscoverBrandsTableHeader";
import { DiscoverBrandsItem } from "./DiscoverBrandsItem";
import { RequestNewBrandDrawer } from "./RequestNewBrandDrawer";

export const DiscoverBrands: FC = () => {
  const { brands, isLoading, isEmpty, onSearchHandler } = useDiscoverBrands();
  return (
    <StyledViewSpace multiplier={3}>
      <ViewHeadline
        localizedText="discoverBrands.title"
        buttonToDisplay={<RequestNewBrandDrawer />}
      />
      <StyledViewSpace multiplier={1}>
        <FiltersContainer onSearchHandler={onSearchHandler} />
        <SpaceBetweenWrapper>
          <TableWrapper isLoading={isLoading} isEmpty={isEmpty}>
            <DiscoverBrandsTableHeader />
            <TableBody>
              {brands &&
                brands.map((brand) => (
                  <Fragment key={brand.id}>
                    <DiscoverBrandsItem
                      brandId={brand.id}
                      commissionPercent={brand.defaultPartnershipCommissionPercent}
                      website={brand.website}
                      imageUrl={brand.logoUrl}
                      name={brand.name}
                      flatFeeValue={5} // TODO - remove mocked value
                    />
                  </Fragment>
                ))}
            </TableBody>
          </TableWrapper>
        </SpaceBetweenWrapper>
      </StyledViewSpace>
    </StyledViewSpace>
  );
};
