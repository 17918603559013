import { AuthContextState } from "shared/components/auth/AuthContext";

export type AuthTokenDependencies = Pick<
  AuthContextState,
  "isAuthenticated" | "getAccessTokenSilently" | "authUser"
>;

export enum TokenStatus {
  APPLIED = "APPLIED",
  APPLYING = "APPLYING",
  NOT_USED = "NOT_USED",
}

export type OnAccessingTokenError = (e: unknown) => void;
