import { useDispatch, useSelector } from "react-redux";
import {
  closeSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { getSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/selectors";
import { Info, ContentCopy, Done, CodeOffRounded } from "@mui/icons-material";

const useSnackbar = () => {
  const { open, icon, message, i18nInterpolationValues } = useSelector(getSnackbar);
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeSnackbar());

  const IconElement = () => {
    switch (icon) {
      case SnackbarIcon.COPY:
        return <ContentCopy color="secondary" />;
      case SnackbarIcon.INFO:
        return <Info color="secondary" />;
      case SnackbarIcon.DONE:
        return <Done color="secondary" />;
      case SnackbarIcon.CODE:
        return <CodeOffRounded color="secondary" />;
      default:
        return <Info color="secondary" />;
    }
  };

  return {
    onClose,
    IconElement,
    open,
    message,
    i18nInterpolationValues,
  };
};

export default useSnackbar;
