import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { useSettings } from "views/brandViews/Settings/useSettings";
import { SettingsWrapper } from "shared/components/SettingsComponents/SettingsWrapper";
import { Organization } from "views/brandViews/Settings/Organization";
import { Integration } from "views/brandViews/Settings/Integration";

export const Settings = () => {
  const { brandInfo, shopifyName, isSetupCompleted } = useSettings();
  if (!brandInfo) {
    return <FullScreenLoader />;
  }
  return (
    <SettingsWrapper>
      <Organization brandInfo={brandInfo} isSetupCompleted={isSetupCompleted} />
      <Integration
        brandInfo={brandInfo}
        shopifyName={shopifyName}
        isSetupCompleted={isSetupCompleted}
      />
    </SettingsWrapper>
  );
};
