import { styled, Box, css, Divider } from "@mui/material";

export const StyledWhiteBox = styled(Box)(
  ({ theme }) => css`
    background-color: white;
    border-radius: ${theme.shape.borderRadius}px;
    padding: ${theme.spacing(3)};
    height: 100%;
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${theme.spacing(1)};
    }
  `,
);

export const StyledGridWrapper = styled(Box)(
  ({ theme }) => css`
    display: grid;
    grid-template: 150px 1px 1fr / 1fr 1fr;
    overflow: hidden;
    grid-row-gap: ${theme.spacing(3)};
    grid-column-gap: ${theme.spacing(2)};
    height: 100%;
  `,
);

export const StyledViewWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: ${theme.spacing(3)};

    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);

export const StyledDivider = styled(Divider)`
  grid-row: 2;
  grid-column: 1 / -1;
`;
