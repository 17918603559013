import { styled, css, tooltipClasses } from "@mui/material";
import { LocalizedTooltip, LocalizedTooltipProps } from "shared/components/base/LocalizedTooltip";

export const StyledTooltip = styled(({ className, children, ...props }: LocalizedTooltipProps) => (
  <LocalizedTooltip {...props} classes={{ popper: className }}>
    {children}
  </LocalizedTooltip>
))(
  ({ theme }) => css`
    & .${tooltipClasses.tooltip} {
      background-color: ${theme.palette.primary.main};
      font-size: ${theme.typography.caption.fontSize}px;
      line-height: ${theme.typography.caption.lineHeight};
      font-weight: ${theme.typography.caption.fontWeight};
      padding: ${theme.spacing(1, 1.5)};
    }
  `,
);
