import { useState, useMemo, useCallback, ChangeEvent } from "react";
import { useTranslationWithMarkdown } from "shared/logic/hooks/translations/useTranslationWithMarkdown";
import { getTextWidth, materialFontToString } from "shared/logic/utils/theme/fontHelpers";
import { defaultTheme } from "shared/logic/utils/theme/createFermatTheme";
import { debounce } from "lodash-es";
import { SearchFiltersProps } from "shared/components/TableComponents/FiltersContainer/SearchFilters/index";

export enum SearchAnimation {
  BLUR = "blur",
  FOCUS = "focus",
}

export type UseSearchFiltersProps = SearchFiltersProps;

export const useSearchFilters = ({ onSearchHandler }: UseSearchFiltersProps) => {
  const [searchState, setSearchState] = useState<SearchAnimation>(SearchAnimation.BLUR);
  const [isSearchEmpty, setSearchEmpty] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const { t } = useTranslationWithMarkdown();
  const searchPlaceholder = t("utility.searchPlaceholder");
  const searchAnimationVariants = useMemo(
    () => ({
      [SearchAnimation.BLUR]: {
        // initial search bar width based on calculated placeholder width
        width:
          getTextWidth(
            searchPlaceholder,
            materialFontToString(defaultTheme.typography.subtitle1),
          ) ?? 0,
      },
      [SearchAnimation.FOCUS]: { width: "unset" },
    }),
    [searchPlaceholder],
  );
  const closeAnimation = () => {
    if (isSearchEmpty) {
      setSearchState(SearchAnimation.BLUR);
    }
  };
  const openAnimation = () => {
    setSearchState(SearchAnimation.FOCUS);
  };

  const onSearch = useCallback(
    debounce((input: string) => {
      onSearchHandler(input);
      setIsSearching(false);
    }, 500),
    [onSearchHandler],
  );

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchQuery = e.target.value;
    setIsSearching(true);
    setSearchEmpty(!searchQuery);
    onSearch(searchQuery);
  };
  return {
    searchState,
    isSearching,
    searchAnimationVariants,
    searchPlaceholder,
    closeAnimation,
    openAnimation,
    onInputChange,
  };
};
