import { CompleteInput } from "shared/components/CompleteInput";
import { styled, Box } from "@mui/material";
import gap from "shared/components/Styled/Gap";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";

export const DetailsForm = styled("form")`
  ${gap.top(3)};
`;

export const Content = styled(Box)`
  width: 570px;
  ${gap.top(4)};
`;

export const Title = (props: TextBaseProps) => <TextBase variant="h3" {...props} />;

export const CommissionInput = CompleteInput;
export const AccountNameInput = CompleteInput;
export const DescriptionInput = CompleteInput;
