import { TokenStatus } from "shared/components/auth/AuthGuard/hooks/useToken/types";

interface IsTokenLoadingArgs {
  status: TokenStatus;
  canTokenBeEstablished: boolean;
}

export function isTokenLoading({ status, canTokenBeEstablished }: IsTokenLoadingArgs): boolean {
  // TOKEN IS CURRENTLY APPLYING - IT WILL TAKE COUPLE MS
  if (status === TokenStatus.APPLYING) {
    return true;
  }

  // CHECK IF THE EFFECT HAS BEEN RUN
  if (canTokenBeEstablished) {
    switch (status) {
      case TokenStatus.APPLIED:
        return false;
      case TokenStatus.NOT_USED:
      default:
        return true;
    }
  }

  switch (status) {
    case TokenStatus.APPLIED:
      return true;
    case TokenStatus.NOT_USED:
    default:
      return false;
  }
}
