import { FormControlLabelProps, FormControlLabel } from "@mui/material";
import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import { memo, FC } from "react";
import { useTranslationWithMarkdown } from "shared/logic/hooks/translations/useTranslationWithMarkdown";

export interface LocalizedFormControlLabelProps extends Omit<FormControlLabelProps, "label"> {
  label?: TextContentPath;
}

const LocalizedFormControlLabelComponent: FC<LocalizedFormControlLabelProps> = ({
  label,
  ...materialProps
}) => {
  const { t } = useTranslationWithMarkdown();
  return <FormControlLabel label={label ? t(label) : null} {...materialProps} />;
};

export const LocalizedFormControlLabel = memo(LocalizedFormControlLabelComponent);
