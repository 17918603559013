import { Predicate } from "shared/logic/store/filters/types";
import { Argument, PlainObject } from "shared/logic/types/Filters";
import { matchArgument } from "shared/logic/store/filters/matchArgument";

type Obj = PlainObject;

interface CreatePredicateArgs {
  value: any;
}

export function createPredicate({ value }: CreatePredicateArgs): Predicate<Argument<Obj, any>> {
  return function predicate(argument) {
    try {
      return matchArgument({
        value,
        argument,
      });
    } catch {
      // DO NOT MATCH - STH WENT WRONG
      // Its better to omit the given argument from filtering than to throw an error to the parent in this case
      return false;
    }
  };
}
