import {
  AuthTokenDependencies,
  OnAccessingTokenError,
} from "shared/components/auth/AuthGuard/hooks/useToken/types";
import { AxiosRequestConfig } from "axios";

interface CreateAuthInterceptorArgs {
  getAccessTokenSilently: AuthTokenDependencies["getAccessTokenSilently"];
  onError: OnAccessingTokenError;
}

type OnFulfilled<V = AxiosRequestConfig> = (config: V) => V | Promise<V>;

export function createAuthInterceptorOnFulfilled({
  getAccessTokenSilently,
  onError,
}: CreateAuthInterceptorArgs): OnFulfilled {
  return async function onFulfilled(config) {
    try {
      const token = await getAccessTokenSilently();

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    } catch (e) {
      onError(e);
      throw e;
    }
  };
}
