import {
  IntegrationDetails,
  Websites,
  IntegrationSetup,
} from "shared/components/SettingsComponents/Organization/Organization.styled";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { VisitWebsite } from "shared/components/VisitWebsite";
import {
  ShopifyIntegration,
  ShopifyIntegrationLabel,
  StyledCheckedIcon,
  ShopifyIntegrationWrapper,
} from "views/brandViews/Settings/Settings.styled";
import { Shopify } from "shared/components/Svg/Shopify";
import Brand from "shared/logic/types/Brands/Brand";
import { FC } from "react";
import { MissingInfoAlert } from "shared/components/SettingsComponents/MissingInfoAlert";
import { RemoveCircleOutline } from "@mui/icons-material";
import { useIntegration } from "views/brandViews/Settings/Integration/useIntegration";
import {
  TitleWrapper,
  Title,
  Label,
} from "shared/components/SettingsComponents/TitleBox/TitleBox.styled";
import { IntegrationBtn, IntegrationIcon } from "./Integration.styled";

interface IntegrationProps {
  brandInfo: Brand;
  shopifyName: string;
  isSetupCompleted: boolean;
}

export const Integration: FC<IntegrationProps> = ({ brandInfo, shopifyName, isSetupCompleted }) => {
  const { onIntegrationStartClick } = useIntegration();
  return (
    <IntegrationSetup>
      <TitleWrapper>
        <Title content="settings.organization.integrationSetup" />
        <IntegrationBtn onClick={onIntegrationStartClick} disabled={isSetupCompleted}>
          <IntegrationIcon $disabled={isSetupCompleted} />
          <Label content="settings.organization.startIntegration" />
        </IntegrationBtn>
      </TitleWrapper>
      {!isSetupCompleted && (
        <MissingInfoAlert content="settings.organization.missingIntegrationInfo.brand" />
      )}
      <IntegrationDetails>
        <Websites>
          <TextBase variant="subtitle1">{shopifyName}</TextBase>
          <VisitWebsite
            website={brandInfo.website}
            iconPosition="start"
            missingWebsiteLabel="settings.organization.brandUrl"
          />
          <VisitWebsite
            website={brandInfo.privacyPolicyUrl}
            iconPosition="start"
            label="settings.organization.privacyPolicy"
          />
        </Websites>
        <ShopifyIntegrationWrapper $disabled={!isSetupCompleted}>
          <ShopifyIntegration>
            <Shopify />
            <ShopifyIntegrationLabel content="settings.organization.shopifyIntegration" />
          </ShopifyIntegration>
          {isSetupCompleted ? <StyledCheckedIcon /> : <RemoveCircleOutline />}
        </ShopifyIntegrationWrapper>
      </IntegrationDetails>
    </IntegrationSetup>
  );
};
