import { FC } from "react";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import {
  StyledContentWrapper,
  StyledInputBox,
  ContinueButton,
  StyledButtonBox,
  StyledCheckIcon,
} from "views/brandViews/Setup/SharedStyles.styled";
import { isObjectEmpty } from "shared/logic/utils/isObjectEmpty";
import { urlRegex } from "shared/logic/utils/url/urlRegex";
import { Alert } from "shared/components/Alert";
import { useFinaliseIntegration } from "./useFinaliseIntegration";
import { PrivacyPolicyInput } from "./FinaliseIntegration.styled";

export const FinaliseIntegration: FC = () => {
  const { register, handleSubmit, onSubmit, errors } = useFinaliseIntegration();
  return (
    <StyledContentWrapper>
      <Alert iconComponent={<StyledCheckIcon color="success" />}>
        <TextBase
          variant="subtitle1"
          color="text.secondary"
          content="setup.finaliseIntegration.successAlert"
        />
      </Alert>
      <StyledInputBox>
        <TextBase variant="body1" content="setup.finaliseIntegration.policyLabel" />
        <form onSubmit={handleSubmit(onSubmit)} id="privacy-policy-form">
          <PrivacyPolicyInput
            label="setup.finaliseIntegration.policyInputLabel"
            variant="outlined"
            fullWidth
            required
            placeholder="navigation.https"
            color="tertiary"
            {...register("privacyPolicyUrl", {
              required: true,
              pattern: {
                value: urlRegex,
                message: "Provide proper URL",
              },
            })}
            error={!isObjectEmpty(errors)}
            helperText={errors.privacyPolicyUrl?.message}
          />
        </form>
      </StyledInputBox>
      <StyledButtonBox>
        <ContinueButton
          variant="contained"
          color="tertiary"
          type="submit"
          form="privacy-policy-form"
        >
          <TextBase
            variant="subtitle1"
            color="tertiary.contrastText"
            content="setup.finaliseIntegration.saveIntegration"
          />
        </ContinueButton>
      </StyledButtonBox>
    </StyledContentWrapper>
  );
};
