import { createSlice } from "@reduxjs/toolkit";
import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { EcommerceAccount } from "shared/logic/types/EcommerceAccount/EcommerceAccount";
import brandUser from "shared/logic/store/features/viewsSlices/brandUser";

interface EcommerceAccountsState {
  ecommerceAccounts: IdAndEntityPair<EcommerceAccount>;
}

export const initialState: EcommerceAccountsState = {
  ecommerceAccounts: {},
};

const ecommerceAccountsSlice = createSlice({
  name: storePrefixes.resourceSlices.ecommerceAccounts,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(brandUser.setupView.fetchResources.fulfilled, (state, action) => {
      state.ecommerceAccounts = action.payload.ecommerceAccounts;
    });
  },
});

export default ecommerceAccountsSlice.reducer;
