import { styled, Box, Link, css } from "@mui/material";
import gap from "shared/components/Styled/Gap";

type IconPositionProps = {
  $iconPosition: "start" | "end";
  $disabled?: boolean;
};

export const StyledLinkWrapper = styled(Box)<IconPositionProps>(
  ({ $iconPosition, $disabled }) => css`
    display: flex;
    justify-content: ${$iconPosition === "start" ? "flex-start" : "flex-end"};
    ${$disabled && "filter: grayscale(1) opacity(0.38)"};
    ${gap.left(1)};
  `,
);

export const StyledLink = styled(Link)(
  ({ theme }) => css`
    text-decoration: none;
    display: flex;
    align-items: center;
    color: ${theme.palette.tertiary.main};
    ${gap.left(1)};
  `,
);
