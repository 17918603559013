import { CodeOffRounded } from "@mui/icons-material";
import { ItemStatus, ItemStatusSize } from "shared/components/ItemStatus";
import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";
import { FC } from "react";
import {
  Brand,
  CommissionInfo,
  CopyButton,
  VariantCount,
  CommissionRate,
  CommissionDescription,
} from "views/partnerViews/Products/ProductCards/ProductCards.styled";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";
import { ProductPartProps } from "shared/components/ProductCards";
import { useDispatch } from "react-redux";
import { openGetTagDialog } from "shared/logic/store/features/viewsSlices/partnerUser/products/productsViewSlice";

export const ImageOverlayButton: FC<ProductPartProps<ProductAggregated>> = ({ product }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(openGetTagDialog(product));
  };
  return (
    <CopyButton onClick={onClick}>
      <CodeOffRounded />
    </CopyButton>
  );
};

export const ProductCardDetails: FC<ProductPartProps<ProductAggregated>> = ({ product }) => (
  <>
    <VariantCount>
      {product.productVariants.length} Variant{product.productVariants.length > 1 && "s"}
    </VariantCount>
    <Brand>{product.brand.name}</Brand>
    <ItemStatus
      size={ItemStatusSize.SMALL}
      hideIcon
      status={product.partnerApprovalStatus as ApprovalStatus}
    />
  </>
);

export const ProductCardFooter: FC<ProductPartProps<ProductAggregated>> = ({ product }) => (
  <CommissionInfo>
    <CommissionDescription content="products.commissionDescription" />
    <CommissionRate>
      {product.commissionPercent ?? product.partnership.commissionPercent}%
    </CommissionRate>
  </CommissionInfo>
);
