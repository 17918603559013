import { styled, css } from "@mui/material";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";

export const Description = styled((props: TextBaseProps) => (
  <TextBase variant="caption" color="text.secondary" {...props} />
))(
  ({ theme }) => css`
    display: inline-block;
    padding: ${theme.spacing(0.5, 2)};
  `,
);

export const Adornment = (props: TextBaseProps) => (
  <TextBase variant="subtitle1" color="text.primary" {...props} />
);
