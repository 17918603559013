import { useFirstLogin } from "views/partnerViews/FirstLogin/useFirstLogin";
import { isObjectEmpty } from "shared/logic/utils/isObjectEmpty";
import { percentValueRegex } from "shared/logic/utils/percentValueRegex";
import {
  Wrapper,
  Form,
  CommissionRateInput,
  DescriptionInput,
  Logo,
  TitleSection,
  Subtitle,
  DescriptionLabel,
  ButtonLabel,
  CommissionRateLabel,
  LinkLabel,
  NavigationInfo,
  AgreeLabel,
  NavigationWrapper,
  CommissionRateDescription,
  CheckboxWrapper,
  Content,
  Title,
  CommissionInputWrapper,
  StyledLink,
  ContinueButton,
  Checkbox,
} from "./FirstLogin.styled";

export const FirstLogin = () => {
  const {
    handleSubmit,
    register,
    onSubmit,
    isFormValid,
    creatorTermsAndConditionsUrl,
    errors,
    partnerName,
  } = useFirstLogin();
  return (
    <Wrapper>
      <Logo />
      <Content>
        <TitleSection>
          <Title content="firstLogin.title" tParams={{ name: partnerName }} />
          <Subtitle content="firstLogin.subTitle" />
        </TitleSection>
        <Form onSubmit={handleSubmit(onSubmit)} id="brand-first-login">
          <CommissionRateLabel content="firstLogin.partner.commissionRateLabel" />
          <CommissionInputWrapper>
            <CommissionRateInput
              required
              type="number"
              placeholder="firstLogin.partner.commissionRatePlaceholder"
              label="firstLogin.partner.commissionRateInputLabel"
              error={!isObjectEmpty(errors)}
              {...register("commissionRate", {
                required: true,
                pattern: { value: percentValueRegex, message: "Incorrect value" },
              })}
            />
            <CommissionRateDescription content="firstLogin.partner.commissionRateDescription" />
          </CommissionInputWrapper>
          <DescriptionLabel content="firstLogin.descriptionLabel" />
          <DescriptionInput
            placeholder="firstLogin.descriptionPlaceholder"
            {...register("description", { required: true })}
          />
          <CheckboxWrapper>
            <Checkbox required {...register("termsAndConditionsAgreement", { required: true })} />
            <AgreeLabel content="firstLogin.agreement" />
            <StyledLink href={creatorTermsAndConditionsUrl}>
              <LinkLabel content="firstLogin.terms" />
            </StyledLink>
          </CheckboxWrapper>
        </Form>
        <NavigationWrapper>
          <NavigationInfo content="firstLogin.accept" />
          <ContinueButton type="submit" form="brand-first-login" disabled={!isFormValid}>
            <ButtonLabel content="navigation.continue" />
          </ContinueButton>
        </NavigationWrapper>
      </Content>
    </Wrapper>
  );
};
