import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ComponentProps } from "react";
import { RejectItemDialog } from "shared/components/Dialogs/RejectItemDialog";
import { RejectionAcceptDialog } from "shared/components/Dialogs/RejectionAcceptDialog";
import { ProductRevocationDialog } from "shared/components/Dialogs/ProductRevocationDialog";
import { RejectionMessageDialog } from "shared/components/Dialogs/RejectionMessageDialog";
import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import { RequestCommissionDialog } from "shared/components/Dialogs/RequestCommissionDialog";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

export enum ChosenDialogEnum {
  ITEM_REJECTION = "itemRejection",
  REJECTION_ACCEPTANCE = "rejectionAcceptance",
  PRODUCT_REVOCATION = "productRevocation",
  REJECTION_MESSAGE = "rejectionMessage",
  REQUEST_COMMISSION = "requestCommission",
}

type ChosenDialogType = ChosenDialogEnum | undefined;

type DialogPropsType<T extends ChosenDialogType> = T extends ChosenDialogEnum.ITEM_REJECTION
  ? ComponentProps<typeof RejectItemDialog>
  : T extends ChosenDialogEnum.REJECTION_ACCEPTANCE
  ? ComponentProps<typeof RejectionAcceptDialog>
  : T extends ChosenDialogEnum.PRODUCT_REVOCATION
  ? ComponentProps<typeof ProductRevocationDialog>
  : T extends ChosenDialogEnum.REJECTION_MESSAGE
  ? ComponentProps<typeof RejectionMessageDialog>
  : T extends ChosenDialogEnum.REQUEST_COMMISSION
  ? ComponentProps<typeof RequestCommissionDialog>
  : never;

export interface DialogState<T extends ChosenDialogType> {
  open: boolean;
  title?: TextContentPath;
  chosenDialog?: T;
  dialogProps?: DialogPropsType<T>;
}

export const initialState: DialogState<ChosenDialogType> = {
  open: false,
  title: undefined,
  chosenDialog: undefined,
  dialogProps: undefined,
};

interface SetDialogPayload<T extends ChosenDialogType> extends DialogState<T> {}

const DialogSlice = createSlice({
  name: storePrefixes.resourceSlices.dialog,
  initialState,
  reducers: {
    setDialog<T extends ChosenDialogType>(
      state: DialogState<T>,
      action: PayloadAction<SetDialogPayload<T>>,
    ) {
      const { open, chosenDialog, title, dialogProps } = action.payload;
      state.open = open;
      state.title = title;
      state.dialogProps = dialogProps;
      state.chosenDialog = chosenDialog;
    },
    closeDialog(state) {
      state.open = false;
    },
  },
});

export const { setDialog, closeDialog } = DialogSlice.actions;

export default DialogSlice.reducer;
