import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { useForm, useFieldArray } from "react-hook-form";
import {
  AddAccountFormEnum,
  AddAccountStepEnum,
  UsersListUpdateParams,
} from "views/adminViews/AddAccount/useAddAccount";
import { AddUsersProps } from "views/adminViews/AddAccount/AddUsers/index";

export type UseAddUsersProps = AddUsersProps;

export const useAddUsers = ({ usersListUpdate, savedUsersList }: UseAddUsersProps) => {
  const [, setSearchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      [AddAccountFormEnum.USERS_TO_ADD]: savedUsersList?.length
        ? savedUsersList
        : [{ email: undefined, lastName: undefined, firstName: undefined }],
    },
    mode: "onChange",
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: AddAccountFormEnum.USERS_TO_ADD,
  });
  const onBackClick = () => {
    setSearchParams({ step: AddAccountStepEnum.ACCOUNT_DETAILS });
  };
  const onSubmit = ({ usersToAdd }: UsersListUpdateParams) => {
    usersListUpdate({ usersToAdd });
    setSearchParams({ step: AddAccountStepEnum.CONFIRM_DETAILS });
  };
  const addUserInput = () => {
    append({ email: undefined, lastName: undefined, firstName: undefined });
  };
  const removeUserInput = (index: number) => {
    remove(index);
  };
  return {
    handleSubmit,
    register,
    isValid,
    fields,
    onBackClick,
    onSubmit,
    addUserInput,
    removeUserInput,
  };
};
