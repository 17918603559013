import { createAsyncThunk } from "@reduxjs/toolkit";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import productsApi from "shared/logic/api/productsApi";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";

export interface FetchProductsForDiscoveredBrandPayload {
  brandId: string;
}

export const fetchProductsForDiscoveredBrand = createAsyncThunk(
  storePrefixes.partnerUser.discoverSlice.fetchProducts,
  async ({ brandId }: FetchProductsForDiscoveredBrandPayload, { dispatch }) => {
    try {
      const productsResponse = await productsApi.getProductsByBrandId(brandId);

      return {
        // RESOURCE SLICE
        products: parseArrayToIdsEntityPair(productsResponse.data.products),
        // VIEW SLICE
        brandId,
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
