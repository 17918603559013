import { styled, Box, css } from "@mui/material";

export const StyledContentWrapper = styled(Box)(
  ({ theme }) => css`
    max-width: 552px;
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);
