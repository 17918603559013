import { useSelector } from "react-redux";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { UserRole } from "shared/components/auth/AuthContext";
import { PolicyRole } from "shared/logic/types/User/UserPolicy";

export const useUserRole = () => {
  const currentPolicy = useSelector(getUserPolicy);
  const isBrandUser = currentPolicy?.entityType === UserRole.BRAND;
  const isPartnerUser = currentPolicy?.entityType === UserRole.PARTNER;
  const isAdmin = currentPolicy?.role === PolicyRole.FERMAT_ADMINISTRATOR;
  const policyId = currentPolicy?.entityId;

  return {
    isBrandUser,
    isPartnerUser,
    isAdmin,
    currentPolicy,
    policyId,
  };
};
