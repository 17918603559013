import { TextBase } from "shared/components/base/TextBase/TextBase";
import { FC } from "react";
import { AddCircle } from "@mui/icons-material";
import { StyledButton } from "./AddIntegrationButton.styled";

interface AddIntegrationButtonProps {
  onClick: () => void;
  disabled: boolean;
}

export const AddIntegrationButton: FC<AddIntegrationButtonProps> = ({ onClick, disabled }) => (
  <StyledButton variant="contained" color="tertiary" onClick={onClick} disabled={disabled}>
    <TextBase variant="subtitle1" content="setup.addIntegration" />
    <AddCircle />
  </StyledButton>
);
