import { Filters, PlainObject } from "shared/logic/types/Filters";
import { filter } from "shared/logic/store/filters/utils/filter";
import { FilterPropertyOrUndefined } from "shared/logic/store/filters/types";
import { Tuple } from "shared/logic/store/filters/matchFilters/types";
import { createPredicate } from "./createPredicate";

type Obj = PlainObject;

interface MatchFiltersArgs<O extends Obj> {
  object: O;
  filters: Filters<O>;
}

type MatchFiltersReturn = boolean;

export function matchFilters<O extends Obj>({
  object,
  filters,
}: MatchFiltersArgs<O>): MatchFiltersReturn {
  // DO NOT MATCH - THERE IS NO OBJECT
  // Its better to omit the given object from filtering than to throw an error to the parent in this case
  if (!object) {
    return false;
  }

  try {
    return filter<Tuple<O>>({
      booleanFunction: filters.function,
      entries: Object.entries<FilterPropertyOrUndefined<O>>(filters.properties),
      predicate: createPredicate({ object, matchFilters }),
    });
  } catch {
    // DO NOT MATCH - STH WENT WRONG
    // Its better to omit the given object from filtering than to throw an error to the parent in this case
    return false;
  }
}
