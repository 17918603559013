import { FC, ReactElement } from "react";
import { TextBase, TextContentPath } from "shared/components/base/TextBase/TextBase";
import { StyledHeadline } from "shared/components/ViewHeadline/ViewHeadline.styled";
import { Typography } from "@mui/material";

export interface ViewHeadlineProps {
  localizedText?: TextContentPath;
  text?: string;
  buttonToDisplay?: ReactElement;
}

export const ViewHeadline: FC<ViewHeadlineProps> = ({ text, localizedText, buttonToDisplay }) => (
  <StyledHeadline>
    {localizedText && <TextBase content={localizedText} variant="h2" />}
    {text && <Typography variant="h2">{text}</Typography>}
    {buttonToDisplay}
  </StyledHeadline>
);
