import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { getIsFirstPartnerLoginLoading } from "shared/logic/store/features/resourceSlices/partners/selectors";
import { storeAPI } from "views/partnerViews/storeAPI";

type FormValues = {
  commissionRate: number;
  termsAndConditionsAgreement: boolean;
  description: string;
};

export const useFirstLogin = () => {
  const {
    handleSubmit,
    register,
    formState: { isValid: isFormValid, errors },
  } = useForm<FormValues>({ mode: "onChange" });
  const policy = useSelector(getUserPolicy);
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsFirstPartnerLoginLoading);
  const creatorTermsAndConditionsUrl = process.env.REACT_APP_CREATOR_TERMS_AND_CONDITIONS_URL;
  const partnerName = policy?.partner?.name ?? "";

  const onSubmit = async ({
    description,
    commissionRate,
    termsAndConditionsAgreement,
  }: FormValues) => {
    dispatch(
      storeAPI.firstLoginSetupView.firstLoginPartnerSetup({
        partnerId: policy?.entityId,
        commissionRate,
        termsAndConditionsAgreement,
        description,
      }),
    );
  };

  return {
    handleSubmit,
    register,
    isFormValid,
    creatorTermsAndConditionsUrl,
    onSubmit,
    isLoading,
    errors,
    partnerName,
  };
};
