import { fetchResourcesForDiscoverView } from "./fetchResourcesForDiscoverView";
import { fetchProductsForDiscoveredBrand } from "./fetchProductsForDiscoveredBrand";
import { requestPartnershipWithBrand } from "./requestPartnershipWithBrand";
import discoverViewSlice from "./discoverViewSlice";
import selectors from "./selectors";

export default {
  ...selectors,
  fetchResources: fetchResourcesForDiscoverView,
  requestPartnershipWithBrand,
  exploreProductsView: {
    fetchResources: fetchProductsForDiscoveredBrand,
  },
  slice: discoverViewSlice,
};
