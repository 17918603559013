import { useHistory } from "react-router-dom";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { Product } from "shared/logic/types/Products/Product";

export const useProductsItem = (product: Product) => {
  const history = useHistory();
  const onShareClick = () => {
    history.push({
      pathname: RouteEnum.ASSIGN_PRODUCT,
      search: product.id,
    });
  };
  return {
    onShareClick,
  };
};
