import { datadogRum } from "@datadog/browser-rum";
import { ENVIRONMENT, getEnvironment } from "shared/logic/utils/getEnvironment";

export const dataDogInit = () => {
  datadogRum.init({
    applicationId: "86d031b5-0a05-4119-aa4e-cfacbeca2811",
    clientToken: "pub87872063ba18754322fce299a7eec8ac",
    site: "datadoghq.com",
    service: "fermat-dashboard",
    sampleRate: 100,
    env: getEnvironment() === ENVIRONMENT.PRODUCTION ? "prod" : "dev",
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });
  datadogRum.startSessionReplayRecording();
};
