import { createAsyncThunk } from "@reduxjs/toolkit";
import productsApi from "shared/logic/api/productsApi";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { getBaseDataForBrandUser } from "shared/logic/store/features/viewsSlices/brandUser/getBaseDataForBrandUser";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

interface AssignParams {
  brandId: string;
}

interface AssignProductsParams extends AssignParams {
  partnerId: string;
  productId?: never;
}
interface AssignPartnersParams extends AssignParams {
  partnerId?: never;
  productId: string;
}

export const fetchResourcesForAssignView = createAsyncThunk(
  storePrefixes.brandUser.assignSlice.fetch,
  async (
    { brandId, productId, partnerId }: AssignProductsParams | AssignPartnersParams,
    { dispatch },
  ) => {
    try {
      // PRODUCT
      const productsPromise = productsApi.getProductsDetailedInfo({
        "brand-id": brandId,
        include: [productId ?? ""],
      });

      // BASE DATA
      const baseDataPromise = getBaseDataForBrandUser({
        partnershipsQuery: { brandId, partnerId },
        partneredProductsQuery: { brandId },
      });

      const [productsResponse, baseDataResponse] = await Promise.all([
        productsPromise,
        baseDataPromise,
      ]);

      const {
        // PARTNERSHIPS
        partnershipsMap,
        // PARTNERS
        partnersMap,
        partners,
        // PARTNERED PRODUCTS
        filteredPartneredProductsMap,
      } = baseDataResponse;

      const { products } = productsResponse.data;

      return {
        // RESOURCE SLICES DATA
        products: parseArrayToIdsEntityPair(products), // creating this to have consistent return for product resource slice
        partnerships: partnershipsMap,
        partneredProducts: filteredPartneredProductsMap,
        partners: partnersMap,

        // VIEW SLICE DATA
        productId,
        partnerId,
        partnersIds: partners.map((partner) => partner.id),
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
