import { createSlice } from "@reduxjs/toolkit";
import { fetchResourcesForProductsView } from "shared/logic/store/features/viewsSlices/brandUser/products/fetchResourcesForProducstView";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";

interface ProductsViewState {
  productsViewSliceStatus: SliceStatus;
  isDataInvalid: boolean;
  productsIds: string[];
}

export const initialState: ProductsViewState = {
  productsViewSliceStatus: SliceStatus.IDLE,
  isDataInvalid: false,
  productsIds: [],
};

const productsViewSliceForBrandUser = createSlice({
  name: storePrefixes.brandUser.productsSlice.this,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourcesForProductsView.rejected, (state) => {
        state.productsViewSliceStatus = SliceStatus.REJECTED;
      })
      .addCase(fetchResourcesForProductsView.pending, (state) => {
        state.productsViewSliceStatus = SliceStatus.PENDING;
      })
      .addCase(fetchResourcesForProductsView.fulfilled, (state, action) => {
        const { productsIds } = action.payload;
        state.productsViewSliceStatus = SliceStatus.FULFILLED;
        state.productsIds = productsIds;
      });
  },
});

export default productsViewSliceForBrandUser.reducer;
