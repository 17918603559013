import { ThemeProvider } from "@mui/material/styles";
import "shared/styles/global.css";
import { defaultTheme } from "shared/logic/utils/theme/createFermatTheme";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { Layout } from "views/Layout";
import { AuthProvider } from "shared/components/auth";
import store from "shared/logic/store";
import { Provider } from "react-redux";
import { DialogWrapper } from "shared/components/Dialogs/DialogWrapper";
import Snackbar from "shared/components/Snackbar";
import ReactQueryProvider from "shared/components/ReactQueryProvider";
import { Routing } from "views/Routing";
import { AuthGuard } from "shared/components/auth/AuthGuard";
import { UserProvider } from "shared/components/auth/UserProvider";

export const App = () => (
  <Provider store={store}>
    <ReactQueryProvider>
      <Router>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <AuthProvider>
            <AuthGuard>
              <UserProvider>
                <Layout>
                  <Routing />
                </Layout>
              </UserProvider>
            </AuthGuard>
            <Snackbar />
            <DialogWrapper />
          </AuthProvider>
        </ThemeProvider>
      </Router>
    </ReactQueryProvider>
  </Provider>
);
