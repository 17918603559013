import { Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import useDialogWrapper from "shared/components/Dialogs/DialogWrapper/useDialogWrapper";
import {
  StyledDialogHeaderWrapper,
  StyledCloseButton,
  StyledContent,
} from "shared/components/Dialogs/DialogWrapper/DialogWrapper.styled";
import { TextBase } from "shared/components/base/TextBase/TextBase";

export const DialogWrapper = () => {
  const { open, onDialogClose, title, Content } = useDialogWrapper();
  return (
    <Dialog open={open} onClose={onDialogClose}>
      <StyledContent>
        <StyledDialogHeaderWrapper>
          <TextBase variant="h4" content={title} />
          <StyledCloseButton onClick={onDialogClose}>
            <Close />
          </StyledCloseButton>
        </StyledDialogHeaderWrapper>
        <Content />
      </StyledContent>
    </Dialog>
  );
};
