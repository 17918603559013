import { CreateNewPartnerDTO, Partner } from "shared/logic/types/Partners/Partner";
import partnersApi from "shared/logic/api/partnersApi";
import {
  performTransaction,
  PerformTransactionArgs,
} from "shared/logic/store/features/user/signUp/utils/performTransaction";
import { UpdateBrandLogoProps } from "shared/logic/api/brandsApi";

interface CreatePartnerTransactionArgs
  extends Pick<PerformTransactionArgs<Partner>, "onError" | "rollback"> {
  data: Partial<CreateNewPartnerDTO>;
  logo?: UpdateBrandLogoProps["logo"];
}

export async function createPartnerTransaction({
  data,
  logo,
  onError,
  rollback,
}: CreatePartnerTransactionArgs): Promise<Partner> {
  async function action(): Promise<Partner> {
    const newPartnerResponse = await partnersApi.postPartner(data);
    if (!logo) {
      return newPartnerResponse.data;
    }
    const newPartnerWithLogoResponse = await partnersApi.updatePartnerLogo({
      partnerId: newPartnerResponse.data.id,
      logo,
    });
    return newPartnerWithLogoResponse.data;
  }

  return performTransaction({ action, onError, rollback });
}
