import { OverflowColumnFlex } from "shared/components/OverflowColumnFlex";
import { styled, ButtonProps, Button } from "@mui/material";
import gap from "shared/components/Styled/Gap";
import { TextBase, TextBaseProps } from "shared/components/base/TextBase/TextBase";
import {
  InputBaseProps,
  LocalizedInput,
} from "shared/components/base/LocalizedInput/LocalizedInput";
import { forwardRef } from "react";

export const Wrapper = styled(OverflowColumnFlex)`
  ${gap.top(4)};
`;

export const StyledForm = styled("form")`
  max-width: 552px;
  ${gap.top(4)};
  display: flex;
  flex-direction: column;
`;

export const PasswordChangeBtn = styled((props: ButtonProps) => (
  <Button color="tertiary" size="small" {...props} />
))`
  align-self: flex-start;
`;

export const SaveChangesBtn = styled((props: ButtonProps) => (
  <Button color="tertiary" variant="contained" {...props} />
))`
  align-self: flex-end;
`;

export const Title = (props: TextBaseProps) => <TextBase variant="h4" {...props} />;

const StyledInput = forwardRef<HTMLInputElement, InputBaseProps>((props, ref) => (
  <LocalizedInput color="tertiary" variant="outlined" fullWidth ref={ref} {...props} />
));

export const Label = (props: TextBaseProps) => <TextBase variant="subtitle1" {...props} />;

export const NameInput = StyledInput;
export const EmailInput = StyledInput;
