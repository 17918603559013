import { FC } from "react";
import { TableContainer, Table } from "@mui/material";
import { MissingContent } from "shared/components/TableComponents/MissingContent";
import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import { OverflowColumnFlex } from "shared/components/OverflowColumnFlex";
import { FullScreenLoader } from "shared/components/FullScreenLoader";

export interface TableWrapperProps {
  isLoading?: boolean;
  isEmpty?: boolean;
  missingContentTitle?: TextContentPath;
  missingContentDescription?: TextContentPath;
}

export const TableWrapper: FC<TableWrapperProps> = ({
  isLoading,
  isEmpty,
  missingContentTitle,
  missingContentDescription,
  children,
}) => (
  <OverflowColumnFlex>
    {!isLoading && !isEmpty && (
      <TableContainer>
        <Table stickyHeader>{children}</Table>
      </TableContainer>
    )}
    {!isLoading && isEmpty && missingContentTitle && missingContentDescription && (
      <MissingContent title={missingContentTitle} description={missingContentDescription} />
    )}
    {isLoading && <FullScreenLoader />}
  </OverflowColumnFlex>
);
