import { styled, Box, Button, css } from "@mui/material";

export const StyledInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const StyledShareButton = styled(Button)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);
