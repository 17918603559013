import { useDispatch } from "react-redux";
import { useCallback } from "react";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";

export interface UseCopyToClipboardProps {
  textToCopy: string;
  itemName: string;
  icon?: SnackbarIcon;
}

export const useCopyToClipboard = () => {
  const dispatch = useDispatch();
  return useCallback(
    async ({
      textToCopy,
      itemName,
      icon = SnackbarIcon.COPY,
    }: UseCopyToClipboardProps): Promise<void> => {
      try {
        await navigator.clipboard.writeText(textToCopy);
        dispatch(
          setSnackbar({
            icon,
            message: "snackbar.copySuccess",
            i18nInterpolationValues: { itemName },
          }),
        );
      } catch (error) {
        dispatch(
          setSnackbar({
            icon,
            message: "snackbar.copyError",
            i18nInterpolationValues: { itemName },
          }),
        );
      }
    },
    [],
  );
};
