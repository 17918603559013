import { TitleBox } from "shared/components/SettingsComponents/TitleBox";
import {
  IntegrationDetails,
  Websites,
  StyledChevronRight,
  IntegrationSetup,
} from "shared/components/SettingsComponents/Organization/Organization.styled";
import { VisitWebsite } from "shared/components/VisitWebsite";
import {
  GetSiteTagButton,
  GetSiteTag,
  StyledBolt,
  GetSiteTagLabel,
} from "views/partnerViews/Settings/Settings.styled";
import { Partner } from "shared/logic/types/Partners/Partner";
import { FC } from "react";
import { MissingInfoAlert } from "shared/components/SettingsComponents/MissingInfoAlert";
import { useIntegration } from "views/partnerViews/Settings/Integration/useIntegration";

interface IntegrationProps {
  partnerInfo: Partner;
  isSetupCompleted: boolean;
}

export const Integration: FC<IntegrationProps> = ({ partnerInfo, isSetupCompleted }) => {
  const { onIntegrationEditClick, onGetTagClick } = useIntegration();
  return (
    <IntegrationSetup>
      <TitleBox
        title="settings.organization.integrationSetup"
        onEditClick={onIntegrationEditClick}
      />
      {!isSetupCompleted && (
        <MissingInfoAlert content="settings.organization.missingIntegrationInfo.partner" />
      )}
      <IntegrationDetails>
        <Websites>
          <VisitWebsite
            website={partnerInfo.website}
            iconPosition="start"
            missingWebsiteLabel="settings.organization.partnerUrl"
          />
          <VisitWebsite
            website={partnerInfo.privacyPolicyUrl}
            iconPosition="start"
            label="settings.organization.privacyPolicy"
          />
        </Websites>
        <GetSiteTagButton onClick={onGetTagClick} disabled={!isSetupCompleted}>
          <GetSiteTag>
            <StyledBolt disabled={!isSetupCompleted} />
            <GetSiteTagLabel content="settings.organization.getSiteTag" />
          </GetSiteTag>
          <StyledChevronRight />
        </GetSiteTagButton>
      </IntegrationDetails>
    </IntegrationSetup>
  );
};
