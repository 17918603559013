import { styled, Box, css } from "@mui/material";

export const StyledWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const StyledBottomWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;

    & > * + * {
      margin-left: ${theme.spacing(2)};
    }
  `,
);
