import { useEffect, useState } from "react";
import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { SetupStep } from "views/partnerViews/Setup/StepDrawer";
import { useDispatch, useSelector } from "react-redux";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { storeAPI } from "views/partnerViews/storeAPI";

const useSetup = () => {
  const [isStepDrawerOpen, setStepDrawerOpen] = useState(false);
  const partner = useSelector(storeAPI.setupView.getPartnerForSetupView);
  const isDataLoading = useSelector(storeAPI.setupView.getIsSetupViewDataFetching);
  const [, setSearchParams] = useSearchParams();
  const policy = useSelector(getUserPolicy);
  const dispatch = useDispatch();
  const toggleStepDrawer = () => {
    setStepDrawerOpen((prev) => !prev);
  };
  const closeDrawer = () => {
    setStepDrawerOpen(false);
  };
  const onAddIntegrationClick = () => {
    setSearchParams({ step: SetupStep.DOMAIN_DETAILS });
    setStepDrawerOpen(true);
  };

  useEffect(() => {
    dispatch(storeAPI.setupView.fetchResources({ partnerId: policy!.entityId }));
  }, [policy?.entityId]);

  return {
    isStepDrawerOpen,
    isDataLoading,
    toggleStepDrawer,
    closeDrawer,
    onAddIntegrationClick,
    partner,
  };
};

export default useSetup;
