import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import Brand from "shared/logic/types/Brands/Brand";
import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import brandUser from "../../viewsSlices/brandUser";
import partnerUser from "../../viewsSlices/partnerUser";

interface BrandsState {
  brands: IdAndEntityPair<Brand>;
  isFirstBrandLoginLoading: boolean;
}

export const initialState: BrandsState = {
  brands: {},
  isFirstBrandLoginLoading: false,
};

const brandsSlice = createSlice({
  name: storePrefixes.resourceSlices.brands,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(brandUser.firstLoginSetupView.firstLoginSetup.pending, (state) => {
        state.isFirstBrandLoginLoading = true;
      })
      .addCase(brandUser.firstLoginSetupView.firstLoginSetup.fulfilled, (state, action) => {
        const { patchedBrand, brandId } = action.payload;
        state.isFirstBrandLoginLoading = false;
        state.brands[brandId] = {
          ...state.brands[brandId],
          ...patchedBrand,
        };
      })
      .addCase(brandUser.firstLoginSetupView.firstLoginSetup.rejected, (state) => {
        state.isFirstBrandLoginLoading = false;
      })
      // SET BRANDS
      .addCase(partnerUser.discoverView.fetchResources.fulfilled, (state, action) => {
        const { brands } = action.payload;
        if (brands) {
          // SAFE ASSIGNMENT - ALL BRANDS ANYWAY
          state.brands = brands;
        }
      })
      .addMatcher(
        isAnyOf(
          brandUser.setupView.saveIntegration.fulfilled,
          brandUser.settingsView.updateBrandDetails.fulfilled,
        ),
        (state, action) => {
          const { updatedBrand, brandId } = action.payload;
          state.brands[brandId] = {
            ...state.brands[brandId],
            ...updatedBrand,
          };
        },
      )
      // UPDATE BRANDS
      .addMatcher(
        isAnyOf(
          partnerUser.productsView.fetchResources.fulfilled,
          partnerUser.dashboardView.fetchResources.fulfilled,
          partnerUser.partnershipsView.fetchResources.fulfilled,
          brandUser.setupView.fetchResources.fulfilled,
        ),
        (state, action) => {
          state.brands = {
            ...state.brands,
            ...action.payload.brands,
          };
        },
      );
  },
});

export default brandsSlice.reducer;
