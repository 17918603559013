import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "shared/logic/store/rootReducer";

export const getBrandsSlice = (state: RootState) => state.brandsSlice;

export const getIsFirstBrandLoginSetupSuccessful = createSelector(
  [getBrandsSlice],
  ({ isFirstBrandLoginLoading }) => isFirstBrandLoginLoading,
);

export const getBrands = createSelector([getBrandsSlice], ({ brands }) => brands);
