import { styled, Box, css } from "@mui/material";

export const SwitchCellWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    & > * + * {
      margin-left: ${theme.spacing(1.5)};
    }
  `,
);

export const StatusCellWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);
