import { styled, css, Theme } from "@mui/material";
import { ReactComponent as Logo } from "assets/logo.svg";
import { SVGProps } from "react";

export enum LogoVariant {
  WHITE = "white",
  TERTIARY = "tertiary",
}

export enum LogoSize {
  DEFAULT = "default",
  BIG = "big",
}

interface LogoProps extends SVGProps<any> {
  variant: LogoVariant;
  size?: LogoSize;
}

interface VariantToPropParams {
  variant: LogoVariant;
  theme: Theme;
}

const propToVariant = ({ variant, theme }: VariantToPropParams) => {
  switch (variant) {
    case "tertiary":
      return theme.palette.tertiary.main;
    case "white":
      return theme.palette.common.white;
    default:
      return theme.palette.tertiary.main;
  }
};

export const StyledAppLogo = styled(Logo)<LogoProps>(
  ({ theme, variant, size }) => css`
    height: ${size === LogoSize.BIG ? "56px" : "32px"};
    width: auto;

    path {
      fill: ${propToVariant({ variant, theme })};
    }
  `,
);
