import { FC } from "react";
import { ViewHeadline } from "shared/components/ViewHeadline";
import { Inventory, SupervisedUserCircle } from "@mui/icons-material";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { CountInfo } from "shared/components/CountInfo";
import { DashboardList } from "shared/components/DashboardList";
import { ItemTypeEnum } from "shared/components/DashboardList/useDashboardList";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import useDashboardForBrandUser from "./useDashboardForBrandUser";
import { StyledDivider, StyledGridWrapper, StyledViewWrapper } from "./Dashboard.styled";

export const DashboardForBrandUser: FC = () => {
  const {
    partnershipsAggregated,
    partneredProductsWithDetails,
    history,
    isDashboardDataFetched,
    mostSharedPartneredProductsWithDetails,
    policy,
  } = useDashboardForBrandUser();

  if (!isDashboardDataFetched || !policy?.brand) {
    return <FullScreenLoader />;
  }

  return (
    <StyledViewWrapper>
      <ViewHeadline text={`Welcome ${policy.brand.name}!`} />
      <StyledGridWrapper>
        <CountInfo
          Icon={SupervisedUserCircle}
          count={partnershipsAggregated.length}
          heading="dashboard.brandUserView.activePartnerships"
          onBrowseClick={() => history.push(RouteEnum.PARTNERSHIPS)}
        />
        <CountInfo
          Icon={Inventory}
          count={Object.values(partneredProductsWithDetails).length}
          heading="dashboard.brandUserView.uploadedProducts"
          onBrowseClick={() => history.push(RouteEnum.PRODUCTS)}
        />
        <StyledDivider />
        <DashboardList
          itemType={ItemTypeEnum.BRAND_USER_LATEST_PARTNERSHIPS}
          header="dashboard.brandUserView.activePartnerships"
          items={partnershipsAggregated}
          missingItemsDescription="dashboard.brandUserView.noPartnershipsDescription"
          missingItemsHeader="dashboard.brandUserView.noPartnerships"
        />
        <DashboardList
          itemType={ItemTypeEnum.BRAND_USER_MOST_SHARED_PRODUCTS}
          header="dashboard.brandUserView.mostSharedProducts"
          items={mostSharedPartneredProductsWithDetails}
          missingItemsDescription="dashboard.brandUserView.noProductsDescription"
          missingItemsHeader="dashboard.brandUserView.noProducts"
        />
      </StyledGridWrapper>
    </StyledViewWrapper>
  );
};
