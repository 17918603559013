import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { SetupStep } from "views/partnerViews/Setup/StepDrawer";
import { IntegrationItemProps } from "views/partnerViews/Setup/IntegrationItem/index";

type UseIntegrationItemProps = Pick<IntegrationItemProps, "toggleDrawer">;

export const useIntegrationItem = ({ toggleDrawer }: UseIntegrationItemProps) => {
  const [, setSearchParams] = useSearchParams();
  const onEditClick = () => {
    setSearchParams({
      step: SetupStep.DOMAIN_DETAILS,
      edit: true,
    });
    toggleDrawer();
  };
  const onSettingsClick = () => {
    setSearchParams({
      step: SetupStep.SITE_TAG,
      readOnly: true,
    });
    toggleDrawer();
  };
  return {
    onEditClick,
    onSettingsClick,
  };
};
