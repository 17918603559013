import Partnership from "shared/logic/types/Partnerships/Partnership";
import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import Brand from "shared/logic/types/Brands/Brand";
import { RootState } from "shared/logic/store/rootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import { getPartnerships } from "shared/logic/store/features/resourceSlices/partnerships/selectors";
import { getBrands } from "shared/logic/store/features/resourceSlices/brands/selectors";
import { aggregatePartnershipsHOF } from "shared/logic/store/features/viewsSlices/partnerUser/aggregatePartnershipsHOF";
import { getPartneredProductsAggregatedByPartnershipId } from "shared/logic/store/features/resourceSlices/partneredProducts/selectors";

export interface PartnershipAggregated extends Partnership {
  partneredProducts: PartneredProduct[];
  brand: Brand;
}

const getPartnershipsViewSlice = (state: RootState) => state.partner.partnershipViewSlice;

const getPartnershipsAggregated = createSelector(
  [getPartnerships, getBrands, getPartneredProductsAggregatedByPartnershipId],
  (partnershipsMap, brandsMap, partneredProductsAggregatedByPartnershipId) =>
    Object.values(partnershipsMap).map(
      aggregatePartnershipsHOF(brandsMap, partneredProductsAggregatedByPartnershipId),
    ),
);

const getPartnershipsViewSliceStatus = createSelector(
  [getPartnershipsViewSlice],
  ({ partnershipsViewSliceStatus }) => partnershipsViewSliceStatus,
);

const getIsPartnershipsLoading = createSelector(
  [getPartnershipsViewSliceStatus],
  (partnershipsViewSliceStatus) => partnershipsViewSliceStatus === SliceStatus.PENDING,
);

export default {
  getPartnershipsViewSlice,
  getPartnershipsAggregated,
  getPartnershipsViewSliceStatus,
  getIsPartnershipsLoading,
};
