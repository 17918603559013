import { FC } from "react";
import { ViewHeadline } from "shared/components/ViewHeadline";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";
import { Controller } from "react-hook-form";
import { Radio, RadioGroup } from "@mui/material";
import { useInviteToPlatform } from "views/InviteToPlatform/useInviteToPlatform";
import { UserRole } from "shared/components/auth/AuthContext";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import {
  AccountTypeSection,
  AccountTypeText,
  AddDetailsText,
  ButtonsWrapper,
  ContinueButton,
  EmailInput,
  EmailInviteText,
  StyledForm,
  StyledFormControlLabel,
  StyledWrapper,
} from "./InviteToPlatform.styled";

export const InviteToPlatform: FC = () => {
  const { handleSubmit, isValid, onSubmitForm, control, register, isLoading } =
    useInviteToPlatform();
  if (isLoading) {
    return <FullScreenLoader />;
  }
  return (
    <StyledViewSpace multiplier={6}>
      <ViewHeadline localizedText="inviteToPlatform.title" />
      <StyledWrapper>
        <AddDetailsText content="inviteToPlatform.addDetails" />
        <StyledForm onSubmit={handleSubmit(onSubmitForm)} id="account-details-form">
          <AccountTypeSection>
            <AccountTypeText content="inviteToPlatform.accountType" />
            <Controller
              rules={{ required: true }}
              control={control}
              name="roleType"
              render={({ field }) => (
                <RadioGroup {...field}>
                  <StyledFormControlLabel
                    control={<Radio color="tertiary" />}
                    label="utility.brand.title"
                    value={UserRole.BRAND}
                  />
                  <StyledFormControlLabel
                    control={<Radio color="tertiary" />}
                    label="utility.partner.title"
                    value={UserRole.PARTNER}
                  />
                </RadioGroup>
              )}
            />
          </AccountTypeSection>
          <EmailInviteText content="inviteToPlatform.emailAddress" />
          <EmailInput
            {...register("email", { required: true })}
            label="inviteToPlatform.emailLabel"
            type="email"
            required
          />
        </StyledForm>
        <ButtonsWrapper>
          <ContinueButton
            variant="contained"
            color="tertiary"
            form="account-details-form"
            type="submit"
            disabled={!isValid}
          >
            <TextBase variant="subtitle1" content="inviteToPlatform.sendBtn" />
          </ContinueButton>
        </ButtonsWrapper>
      </StyledWrapper>
    </StyledViewSpace>
  );
};
