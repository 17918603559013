import axios from "axios";
import pagination from "shared/logic/configs/pagination";

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

axiosInstance.interceptors.request.use((config) => ({
  ...config,
  params: {
    ...config.params,
    ...pagination,
  },
}));

export default axiosInstance;
