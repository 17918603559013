import { styled, Box, css } from "@mui/material";
import { borderRadiusHelper } from "shared/logic/utils/theme/borderRadiusHelper";
import { CARD_SIZE } from "views/partnerViews/Products/ProductCards/ProductCards.styled";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";

export const StyledDetailsWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    color: ${theme.palette.text.disabled};
    align-items: center;

    & > * + *::before {
      content: "·";
      align-self: center;
      margin: ${theme.spacing(0, 1)};
    }
  `,
);

export const InfoWrapper = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)};
    & > * + * {
      margin-top: ${theme.spacing(2)};
    }
  `,
);

export const ImageWrapper = styled(Box)(
  ({ theme }) => css`
    position: relative;
    width: ${CARD_SIZE}px;
    height: ${CARD_SIZE}px;
    background: ${theme.palette.background.onSurface};
    border-radius: ${borderRadiusHelper(theme.shape.borderRadius, 2)}px;
    transition: 0.2s border-radius;
    overflow: hidden;
  `,
);

export const Image = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ProductCard = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: ${borderRadiusHelper(theme.shape.borderRadius, 2)}px;
    box-sizing: content-box;
    transition: 0.2s box-shadow;
    overflow: hidden;

    &:hover {
      cursor: pointer;
      box-shadow: 0 ${theme.spacing(1)} ${theme.spacing(3)} ${theme.palette.border};
      outline: 1px solid ${theme.palette.border};
      & > :first-child {
        border-radius: 0;
      }
    }
  `,
);

export const CardsWrapper = styled(Box)(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, ${CARD_SIZE}px);
    grid-auto-rows: min-content;
    gap: ${theme.spacing(6, 2)};
    overflow: auto;
    justify-items: center;
    justify-content: center;
    /*
    * CSS Trick: We need to apply a negative margin to the grid wrapper
    * and compensate it with a padding, so items' box-shadow could 
    * overcome clipping caused by overflow property (which is needed for scrolling)
    * and the padding of the "Layout" view wrapper combined
    */
    margin: ${theme.spacing(0, -4)};
    padding: ${theme.spacing(2, 4)};
  `,
);

export const Name = styled((props: TextBaseProps) => <TextBase variant="subtitle1" {...props} />)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ProductInfo = styled(Box)``;
