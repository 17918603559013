import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "shared/logic/store/rootReducer";
import aggregateEntitiesIntoArrayByAKey from "shared/logic/utils/aggregators/aggregateEntitiesIntoArrayByAKey";
import Partnership from "shared/logic/types/Partnerships/Partnership";

export const getPartnershipsSlice = (state: RootState) => state.partnershipsSlice;

export const getPartnerships = createSelector(
  [getPartnershipsSlice],
  ({ partnerships }) => partnerships,
);

export const getPartnershipsAggregatedByPartnerId = createSelector(
  [getPartnerships],
  (partnerships) =>
    aggregateEntitiesIntoArrayByAKey<Partnership>(Object.values(partnerships), "partnerId"),
);
