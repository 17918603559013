import { useForm } from "react-hook-form";
import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { Brush, ShoppingBasket } from "@mui/icons-material";
import React from "react";
import { FormDetailsProps } from "views/SelfSignup/SelfSignUpFirstStep/index";
import { UserAccountFormEnum, UserDetailsUpdateParams, UserLoginStepEnum } from "../useSelfSignUp";
import { StyledIcon } from "./SelfSignUpFirstStep.styled";

export type UseFormDetailsProps = FormDetailsProps;

export const useSelfSignUpFirstStep = ({
  savedUserName,
  savedUserType,
  userDetailsUpdate,
}: UseFormDetailsProps) => {
  const [, setSearchParams] = useSearchParams();
  const {
    handleSubmit,
    register,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      [UserAccountFormEnum.USER_NAME]: savedUserName ?? "",
      [UserAccountFormEnum.USER_TYPE]: savedUserType ?? "",
    },
    mode: "onChange",
  });

  const BrandIcon = (
    <StyledIcon>
      <ShoppingBasket color="tertiary" />
    </StyledIcon>
  );

  const PartnerIcon = (
    <StyledIcon>
      <Brush color="tertiary" />
    </StyledIcon>
  );

  const onSubmit = ({ userName, userType }: UserDetailsUpdateParams) => {
    userDetailsUpdate({ userName, userType });
    setSearchParams({ step: UserLoginStepEnum.USER_INFORMATION });
  };
  return {
    onSubmit,
    handleSubmit,
    register,
    control,
    isValidForm: isValid,
    BrandIcon,
    PartnerIcon,
  };
};
