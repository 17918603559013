import { RootState } from "shared/logic/store/rootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import { getProducts } from "shared/logic/store/features/resourceSlices/products/selectors";

const getProductsViewSlice = (state: RootState) => state.brand.productsViewSlice;

const getProductsViewSliceStatus = createSelector(
  [getProductsViewSlice],
  ({ productsViewSliceStatus }) => productsViewSliceStatus,
);

const getProductsIds = createSelector([getProductsViewSlice], ({ productsIds }) => productsIds);

const getProductsForProductsView = createSelector(
  [getProductsIds, getProducts],
  (productsIds, products) => productsIds.map((productId) => products[productId]),
);

const getIsProductsLoading = createSelector(
  [getProductsViewSliceStatus],
  (productsViewSliceStatus) => productsViewSliceStatus === SliceStatus.PENDING,
);

export default {
  getProductsViewSlice,
  getProductsViewSliceStatus,
  getProductsIds,
  getProductsForProductsView,
  getIsProductsLoading,
};
