import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { getIsFirstBrandLoginSetupSuccessful } from "shared/logic/store/features/resourceSlices/brands/selectors";
import { storeAPI } from "views/brandViews/storeAPI";

interface FormInputs {
  termsAndConditionsAgreement: boolean;
  image?: File;
  description: string;
}

export const useFirstLogin = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm<FormInputs>({ mode: "onChange" });
  const dispatch = useDispatch();
  const policy = useSelector(getUserPolicy);
  const isLoading = useSelector(getIsFirstBrandLoginSetupSuccessful);
  const brandTermsAndConditionsUrl = process.env.REACT_APP_BRAND_TERMS_AND_CONDITIONS_URL;
  const brandName = policy?.brand?.name ?? "";

  const onSubmit: SubmitHandler<FormInputs> = async ({
    description,
    image,
    termsAndConditionsAgreement,
  }: FormInputs) => {
    dispatch(
      storeAPI.firstLoginSetupView.firstLoginSetup({
        brandId: policy?.entityId,
        logo: image,
        termsAndConditionsAgreement,
        description,
      }),
    );
  };

  const imageOnUpload = (image: File) => {
    setValue("image", image);
  };

  return {
    register,
    handleSubmit,
    isFormValid: isValid,
    brandTermsAndConditionsUrl,
    onSubmit,
    imageOnUpload,
    isLoading,
    brandName,
  };
};
