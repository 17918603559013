import { FC } from "react";
import { StyledItemRow, ImageCell, Image } from "shared/components/TableComponents/Styles";
import { TableCell, Typography, IconButton, Badge } from "@mui/material";
import IOSSwitch from "shared/components/Styled/StyledSwitch";
import { usePartnerItem } from "views/brandViews/AssignProduct/PartnerItem/usePartnerItem";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import {
  SwitchCellWrapper,
  StatusCellWrapper,
} from "views/brandViews/AssignProduct/PartnerItem/PartnerItem.styled";
import { ItemStatus } from "shared/components/ItemStatus";
import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";
import { Message } from "@mui/icons-material";
import { StyledTooltip } from "shared/components/Styled/StyledTooltip";
import { Product } from "shared/logic/types/Products/Product";
import { PartnerAggregated } from "shared/logic/store/features/viewsSlices/brandUser/assign/selectors";

interface PartnerItemProps {
  partner: PartnerAggregated;
  productToAccess: Product;
}

const PartnerItem: FC<PartnerItemProps> = ({ partner, productToAccess }) => {
  const {
    onAvailableChange,
    isAvailable,
    onMessageClick,
    productStatus,
    commissionPercentToDisplay,
  } = usePartnerItem({
    product: productToAccess,
    partner,
  });
  return (
    <StyledItemRow>
      <ImageCell>
        <Image variant="rounded" src={partner.creatorPhotoUrl} />
      </ImageCell>
      <TableCell padding="none">
        <Typography variant="subtitle1">{partner.name}</Typography>
      </TableCell>
      <TableCell align="right">
        {commissionPercentToDisplay && (
          <Typography variant="body1">{commissionPercentToDisplay}%</Typography>
        )}
      </TableCell>
      <TableCell align="right" padding="none">
        {productStatus && (
          <StatusCellWrapper>
            <ItemStatus status={productStatus as ApprovalStatus} />
            {productStatus === ApprovalStatus.REJECTED && (
              <StyledTooltip title="tooltip.seeMessage">
                <IconButton disableRipple onClick={onMessageClick}>
                  <Badge color="secondary" variant="dot" invisible={false}>
                    <Message color="tertiary" />
                  </Badge>
                </IconButton>
              </StyledTooltip>
            )}
          </StatusCellWrapper>
        )}
      </TableCell>
      <TableCell align="right">
        <SwitchCellWrapper>
          <TextBase variant="tableLabel" content="products.productAvailable" />
          <IOSSwitch checked={isAvailable} onChange={onAvailableChange} />
        </SwitchCellWrapper>
      </TableCell>
    </StyledItemRow>
  );
};

export default PartnerItem;
