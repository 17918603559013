import { FC } from "react";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { TableHead, TableCell } from "@mui/material";
import { StyledHeaderRow } from "shared/components/TableComponents/Styles";

export const PartnershipsListHeader: FC = () => (
  <TableHead>
    <StyledHeaderRow>
      <TableCell colSpan={2}>
        <TextBase content="partnerships.brandUserView.partner" variant="tableLabel" />
      </TableCell>
      <TableCell align="right">
        <TextBase content="partnerships.brandUserView.creatorWebsite" variant="tableLabel" />
      </TableCell>
      <TableCell align="right">
        <TextBase content="partnerships.brandUserView.availableProducts" variant="tableLabel" />
      </TableCell>
      <TableCell align="right">
        <TextBase content="partnerships.brandUserView.commissionPercent" variant="tableLabel" />
      </TableCell>
      <TableCell align="right" />
    </StyledHeaderRow>
  </TableHead>
);
