import { Badge, Button, css, styled } from "@mui/material";

interface TabButtonProps {
  $active?: boolean;
}

export const TabButton = styled(Button)<TabButtonProps>(
  ({ theme, $active }) => css`
    color: ${theme.palette.text.primary};
    background-color: ${$active && theme.palette.tertiary.dim};
    display: flex;
    justify-content: space-between;
    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledBadge = styled(Badge)(
  ({ theme }) => css`
    width: 20px;
    .MuiBadge-badge:not(.MuiBadge-invisible) {
      color: ${theme.palette.text.primary};
      margin-right: ${theme.spacing(0.5)};
    }
  `,
);
