import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  RequestPartnershipDTO,
  RequestPartnershipType,
} from "shared/logic/types/Partnerships/Partnership";
import partnershipsApi from "shared/logic/api/partnershipsApi";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import {
  getUserId,
  getUserPolicy,
} from "shared/logic/store/features/resourceSlices/user/selectors";
import { getPartners } from "shared/logic/store/features/resourceSlices/partners/selectors";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import { ThunkConfigRootState } from "shared/logic/store/rootReducer";

export type RequestPartnershipReturned = void;

export interface RequestPartnershipPayload {
  partnerId: string;
}

export const requestPartnershipWithPartner = createAsyncThunk<
  RequestPartnershipReturned,
  RequestPartnershipPayload,
  ThunkConfigRootState
>(
  storePrefixes.brandUser.discoverSlice.requestPartnership,
  async ({ partnerId }, { dispatch, getState }) => {
    try {
      const userId = getUserId(getState());
      const currentPolicy = getUserPolicy(getState());

      const partner = getPartners(getState())[partnerId];
      const brand = currentPolicy?.brand;

      if (!brand || !partner) {
        throw new Error("Missing brand/creator entity in memory");
      }

      const { name: partnerName, defaultCommissionPercent } = partner;
      const { defaultPartnershipNonRecurringCharge } = brand;

      const requestBody: RequestPartnershipDTO = {
        recipient_org_id: partnerId,
        request_type: RequestPartnershipType.BRAND_TO_CREATOR,
        nonRecurringCharge: defaultPartnershipNonRecurringCharge ?? 0,
        commissionPercent: defaultCommissionPercent,
        requesting_user_id: userId,
      };

      await partnershipsApi.requestPartnership(currentPolicy.entityId, requestBody);

      dispatch(
        setSnackbar({
          message: "snackbar.partnershipRequestSuccess",
          icon: SnackbarIcon.DONE,
          i18nInterpolationValues: { name: partnerName },
        }),
      );
    } catch (e) {
      ConsoleLog(e);
      dispatch(
        setSnackbar({
          message: "snackbar.partnershipError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
