import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import Partnership from "shared/logic/types/Partnerships/Partnership";
import Brand from "shared/logic/types/Brands/Brand";
import { Product } from "shared/logic/types/Products/Product";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";
import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";

interface AggregateProductsArgs {
  partneredProducts: PartneredProduct[];
  partnershipsMap: Record<string, Partnership>;
  brandsMap: Record<string, Brand>;
  productsMap: Record<string, Product>;
}

export interface AggregatePartneredProductsReturnType {
  idAndPartneredProductEnhancedPair: IdAndEntityPair<ProductAggregated>;
  partneredProductsEnhancedArray: ProductAggregated[];
}

export const aggregatePartneredProducts = ({
  partneredProducts,
  brandsMap,
  partnershipsMap,
  productsMap,
}: AggregateProductsArgs): AggregatePartneredProductsReturnType => {
  const idAndPartneredProductEnhancedPair: IdAndEntityPair<ProductAggregated> = {};
  const partneredProductsEnhancedArray: ProductAggregated[] = [];

  partneredProducts.forEach((partneredProduct) => {
    const partnership = partnershipsMap[partneredProduct.partnershipId];
    const partneredProductEnhanced = {
      ...productsMap[partneredProduct.productId],
      ...partneredProduct, // partneredProductId as primary id
      brand: brandsMap[partnership.brandId],
      partnership,
    };

    idAndPartneredProductEnhancedPair[partneredProduct.id] = partneredProductEnhanced;
    partneredProductsEnhancedArray.push(partneredProductEnhanced);
  });

  return {
    idAndPartneredProductEnhancedPair,
    partneredProductsEnhancedArray,
  };
};
