import { createAsyncThunk } from "@reduxjs/toolkit";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { getPartnerships } from "shared/logic/store/features/viewsSlices/utils/getPartnerships";
import brandsApi from "shared/logic/api/brandsApi";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";
import { filterBrandIdsByEnv } from "shared/logic/store/features/viewsSlices/partnerUser/discover/utils/filterBrandIdsByEnv";
import { filterBrandIdsByPartnerships } from "shared/logic/store/features/viewsSlices/partnerUser/discover/utils/filterBrandIdsByPartnerships";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

export const fetchResourcesForDiscoverView = createAsyncThunk(
  storePrefixes.partnerUser.discoverSlice.fetch,
  async (partnerId: string, { dispatch }) => {
    try {
      // ############################################################
      // PARTNERSHIPS
      const partnershipsPromise = getPartnerships({ partnerId });
      // ############################################################
      // BRANDS
      const brandsPromise = brandsApi.getBrandsByQuery();

      const [partnershipsResponse, brandsResponse] = await Promise.all([
        partnershipsPromise,
        brandsPromise,
      ]);
      const { brands } = brandsResponse.data;
      const { partnerships, partnershipsMap } = partnershipsResponse;
      const brandsMap = parseArrayToIdsEntityPair(brands);

      // ############################################################
      // FILTER
      const brandIds = brands.map((brand) => brand.id);
      const brandsThatNotAppearInPartnerships = filterBrandIdsByPartnerships(
        brandIds,
        partnerships,
      );

      return {
        brandIds: filterBrandIdsByEnv(brandsThatNotAppearInPartnerships, brandsMap), // ENVIRONMENT SPECIFIC EXCLUSION
        brands: brandsMap,
        partnerships: partnershipsMap,
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
    }

    return {};
  },
);
