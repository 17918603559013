import { TextBase, TextContentPath } from "shared/components/base/TextBase/TextBase";
import { Upload } from "@mui/icons-material";
import {
  StyledAvatar,
  StyledImageInfo,
  StyledImageUpload,
  StyledInputBox,
  StyledUploadButton,
} from "./ImageUpload.styled";
import { useImageUpload } from "./useImageUpload";

interface ImageUploadProps {
  onUpload: (file: File) => void;
  customLabel?: TextContentPath;
  uploadLabel?: TextContentPath;
  previousImageUrl?: string;
}

export const ImageUpload = ({
  onUpload,
  customLabel = "utility.logoPreview",
  uploadLabel,
  previousImageUrl,
}: ImageUploadProps) => {
  const { uploadedImage, imageRef, onFileUpload, onUploadClick } = useImageUpload({
    onUpload,
    previousImageUrl,
  });
  return (
    <StyledInputBox>
      <TextBase content={uploadLabel} variant="body1" />
      <StyledImageUpload>
        <StyledImageInfo>
          <StyledAvatar variant="rounded" src={uploadedImage} />
          <TextBase variant="subtitle1" color="text.secondary" content={customLabel} />
        </StyledImageInfo>
        <input
          accept="image/png"
          hidden
          id="raised-button-file"
          type="file"
          ref={imageRef}
          onChange={onFileUpload}
        />
        <StyledUploadButton variant="outlined" onClick={onUploadClick}>
          <TextBase color="text.primary" variant="subtitle1" content="navigation.upload" />
          <Upload color="tertiary" />
        </StyledUploadButton>
      </StyledImageUpload>
    </StyledInputBox>
  );
};
