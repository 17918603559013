export const getCookie = (name: string) => {
  const nonceCookie = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (nonceCookie) {
    return nonceCookie[2];
  }
  return null;
};

export const setCookie = (key: string, name: string | string[]) => {
  document.cookie = typeof name === "string" ? `${key}=${name}` : `${key}=${name.join(",")}`;
};

export const removeCookie = (key: string) => {
  setCookie(key, `;expires=${new Date(0).toUTCString()}`);
};
