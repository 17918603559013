import { ProductInfo } from "shared/components/ProductInfo";
import { FiltersContainer } from "shared/components/TableComponents/FiltersContainer";
import { TableWrapper } from "shared/components/TableComponents/TableWrapper";
import { FC } from "react";
import { TableBody } from "@mui/material";
import AssignProductTableHeader from "views/brandViews/AssignProduct/AssignProductTableHeader";
import PartnerItem from "views/brandViews/AssignProduct/PartnerItem";
import { isObjectEmpty } from "shared/logic/utils/isObjectEmpty";
import { useAssignProduct } from "views/brandViews/AssignProduct/useAssignProduct";
import { SpaceBetweenWrapper } from "views/brandViews/AssignProduct/AssignProduct.styled";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";

export const AssignProduct: FC = () => {
  const { product, partners, isLoading, isEmpty } = useAssignProduct();
  // TODO - loader on isLoading and redirect on error
  if (!product || isObjectEmpty(product) || isLoading) {
    return <FullScreenLoader />;
  }
  return (
    <StyledViewSpace multiplier={3}>
      <ProductInfo
        name={product.name}
        imageUrl={product.primaryImageUrl}
        variantCount={product.productVariants.length}
      />
      <StyledViewSpace multiplier={1}>
        <FiltersContainer />
        <SpaceBetweenWrapper>
          <TableWrapper
            isLoading={isLoading}
            isEmpty={isEmpty}
            missingContentDescription="partners.noPartnersDescription"
            missingContentTitle="partners.noPartners"
          >
            <AssignProductTableHeader />
            <TableBody>
              {!isLoading &&
                !isEmpty &&
                partners &&
                partners.map((partner) => (
                  <PartnerItem key={partner.id} partner={partner} productToAccess={product} />
                ))}
            </TableBody>
          </TableWrapper>
        </SpaceBetweenWrapper>
      </StyledViewSpace>
    </StyledViewSpace>
  );
};
