import { AssignProduct } from "views/brandViews/AssignProduct";
import { AssignPartner } from "views/brandViews/AssignPartner";
import { Products } from "views/brandViews/Products";
import { DashboardForBrandUser as Dashboard } from "views/brandViews/Dashboard";
import { PartnershipsForBrandUser as Partnerships } from "views/brandViews/Partnerships";
import { Setup } from "views/brandViews/Setup";
import { DiscoverPartner } from "./DiscoverPartner";
import { SelfSignUp } from "../SelfSignup";
import { FirstLogin } from "./FirstLogin";
import { Settings } from "./Settings";

export default {
  AssignProduct,
  AssignPartner,
  Products,
  Dashboard,
  Setup,
  Partnerships,
  SelfSignUp,
  FirstLogin,
  DiscoverPartner,
  Settings,
};
