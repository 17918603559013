import { Box, Button, css, FormControl, styled } from "@mui/material";
import { forwardRef } from "react";
import {
  InputBaseProps,
  LocalizedInput,
} from "shared/components/base/LocalizedInput/LocalizedInput";
import { LocalizedFormControlLabel } from "shared/components/base/LocalizedFormControlLabel";
import { OverflowColumnFlex } from "shared/components/OverflowColumnFlex";
import { TextBase, TextBaseProps } from "shared/components/base/TextBase/TextBase";

export const AccountTypeSection = styled(FormControl)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(1)};
    }
  `,
);

export const StyledForm = styled("form")(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const StyledFormControlLabel = styled(LocalizedFormControlLabel)(
  ({ theme }) => css`
    span {
      font-size: ${theme.typography.subtitle1.fontSize}px;
      font-weight: ${theme.typography.subtitle1.fontWeight};
    }
  `,
);

export const ButtonsWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const StyledWrapper = styled(OverflowColumnFlex)(
  ({ theme }) => css`
    max-width: 552px;

    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);

export const EmailInput = forwardRef<HTMLInputElement, InputBaseProps>((props, ref) => (
  <LocalizedInput ref={ref} {...props} fullWidth color="tertiary" />
));

export const AddDetailsText = (props: TextBaseProps) => <TextBase {...props} variant="h3" />;

export const AccountTypeText = (props: TextBaseProps) => (
  <TextBase {...props} variant="body1" color="text.secondary" />
);

export const EmailInviteText = (props: TextBaseProps) => (
  <TextBase {...props} color="text.secondary" variant="body1" />
);

export const ContinueButton = styled(Button)``;
