import { styled, Box, css, ButtonBase, IconButton } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { motion } from "framer-motion";

type OpenAnimationProps = {
  $isOpen: boolean;
};

export const StyledCodeBoxContent = styled(Box)<OpenAnimationProps>(
  ({ theme, $isOpen }) => css`
    background: ${theme.palette.background.onSurface};
    padding: ${theme.spacing(2)};
    overflow: hidden;
    flex: 1;

    code {
      color: ${theme.palette.tertiary.main};
      display: block;
    }

    &::before {
      ${$isOpen && "display: none"}
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(360deg, ${theme.palette.common.white} 0%, rgba(0, 0, 0, 0) 100%);
      width: 100%;
      height: 100%;
    }
  `,
);

export const StyledCodeBox = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: ${theme.shape.borderRadius}px;
    border: 1px solid ${theme.palette.border};
    overflow: hidden;
  `,
);

export const ShowAllButton = styled(ButtonBase)(
  ({ theme }) => css`
    border-top: 1px solid ${theme.palette.border};
    justify-content: center;
    align-items: center;
    padding: ${theme.spacing(0.5)};
  `,
);

export const StyledCopyButton = styled(IconButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
    z-index: 1;
    background: ${theme.palette.tertiary.main};
    border-radius: ${theme.shape.borderRadius}px;
    color: white;

    &:focus,
    &:hover {
      background: ${theme.palette.tertiary.dark};
    }
  `,
);

export const StyledArrowDown = styled(KeyboardArrowDown)`
  width: 20px;
  height: 20px;
`;

export const AnimatedArrow = motion(StyledArrowDown);
export const AnimatedCodeBox = motion(StyledCodeBox);
