import { styled, css, Box, Checkbox, Button, Link } from "@mui/material";
import { forwardRef } from "react";
import {
  InputBaseProps,
  LocalizedInput,
} from "shared/components/base/LocalizedInput/LocalizedInput";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";

export const StyledBox = styled(Box)`
  height: 100%;
`;

export const StyledFormBox = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 552px;
    margin: 0 auto;
    justify-content: center;

    & > * + * {
      margin-top: ${theme.spacing(5)};
    }
  `,
);

export const TitleWrapper = styled(Box)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(0.5)};
    }
  `,
);

export const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCheckboxContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledLink = styled(Link)(
  ({ theme }) => css`
    text-decoration: none;

    color: ${theme.palette.tertiary.main};
  `,
);

export const StyledCheckbox = styled(Checkbox)`
  padding-left: 0;
`;

export const StyledForm = styled("form")(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const DescriptionInput = forwardRef<HTMLInputElement, InputBaseProps>((props, ref) => (
  <LocalizedInput ref={ref} {...props} fullWidth color="tertiary" rows={5} multiline />
));

export const DescriptionLabel = (props: TextBaseProps) => <TextBase {...props} variant="body1" />;

export const ContinueButton = styled(Button)``;
