import { css, IconButton, styled } from "@mui/material";

export const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${theme.palette.tertiary.main};
    color: ${theme.palette.common.white};

    :hover {
      background-color: ${theme.palette.tertiary.dark};
    }
  `,
);
