import { styled, Box } from "@mui/material";

export const Wrapper = styled(Box)`
  display: flex;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
