import Brand from "shared/logic/types/Brands/Brand";
import { Partner } from "shared/logic/types/Partners/Partner";
import { UserRole } from "shared/components/auth/AuthContext";

export enum PolicyRole {
  FERMAT_ADMINISTRATOR = "FERMAT_ADMINISTRATOR",
  ADMINISTRATOR = "ADMINISTRATOR",
}

// TODO - conditional type
export interface UserPolicy {
  entityType: UserRole;
  role: PolicyRole;
  entityId: string;
  brand?: Brand;
  partner?: Partner;
}

export type PolicyEntity = Omit<UserPolicy, "brand" | "partner">;

export interface UserPoliciesDTO {
  permissioned_entities: PolicyEntity[];
  user_id: string;
  policy_id: string;
}
