export enum ENVIRONMENT {
  DEVELOPMENT = "DEVELOPMENT",
  PRODUCTION = "PRODUCTION",
  NOT_SPECIFIED = "NOT_SPECIFIED",
}

export function getEnvironment(): ENVIRONMENT {
  switch (process.env.REACT_APP_ENVIRONMENT_NAME) {
    case "DEVELOPMENT":
      return ENVIRONMENT.DEVELOPMENT;
    case "PRODUCTION":
      return ENVIRONMENT.PRODUCTION;
    default:
      return ENVIRONMENT.NOT_SPECIFIED;
  }
}
