import React, { FC, useEffect } from "react";
import { useAuth } from "shared/logic/hooks/auth/useAuth";
import { FullScreenLoader } from "shared/components/FullScreenLoader";

export const Logout: FC = () => {
  const { logout } = useAuth();

  useEffect(() => {
    logout();
  }, []);

  return <FullScreenLoader />;
};
