import { FC } from "react";
import { TableCell, Typography } from "@mui/material";
import { Partner } from "shared/logic/types/Partners/Partner";
import { PartnershipInteractions } from "shared/components/PartnershipInteractions";
import { Image, ImageCell, StyledItemRow } from "shared/components/TableComponents/Styles";
import { VisitWebsite } from "shared/components/VisitWebsite";
import { StyledInfoWrapper, StyledDescription } from "./DiscoverPartnerItem.styled";

interface PartnerItemProps {
  partner: Partner;
}

export const DiscoverPartnerItem: FC<PartnerItemProps> = ({ partner }) => (
  <StyledItemRow>
    <ImageCell>
      <Image variant="rounded" src={partner.creatorPhotoUrl} />
    </ImageCell>
    <TableCell padding="none">
      <StyledInfoWrapper>
        <Typography variant="subtitle1">{partner.name}</Typography>
        <StyledDescription>
          <Typography variant="caption" color="text.disabled">
            {partner.description}
          </Typography>
        </StyledDescription>
      </StyledInfoWrapper>
    </TableCell>
    <TableCell align="right">
      {partner.website && <VisitWebsite website={partner.website} label="utility.visitWebsite" />}
    </TableCell>
    <TableCell align="right">
      <Typography variant="body1">{partner.defaultCommissionPercent}%</Typography>
    </TableCell>
    <TableCell align="right">
      <PartnershipInteractions entityIdToPartnerWith={partner.id} />
    </TableCell>
  </StyledItemRow>
);
