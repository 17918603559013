import { styled, Box, css } from "@mui/material";

export const StyledSnackbarContent = styled(Box)(
  ({ theme }) => css`
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    height: 40px;
    padding: ${theme.spacing(0, 2)};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.shape.borderRadius}px;

    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledMessageWrapper = styled(Box)`
  display: flex;
`;
