import { styled, Button, Box, css, Typography } from "@mui/material";

export const StyledContentWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${theme.spacing(2)};
    }
  `,
);

export const StyledMessage = styled(Typography)`
  text-transform: none;
  white-space: break-spaces;
`;

export const StyledBottomWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AcceptButton = styled(Button)``;
