import React from "react";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { useAuth } from "shared/logic/hooks/auth/useAuth";
import { useToken } from "shared/components/auth/AuthGuard/hooks/useToken";

export const AuthGuard: React.FunctionComponent = ({ children }) => {
  const {
    isLoading: isAuthLoading,
    logout,
    error,
    authUser,
    getAccessTokenSilently,
    isAuthenticated,
  } = useAuth();

  const { isLoading: isTokenLoading } = useToken({
    isAuthenticated,
    getAccessTokenSilently,
    authUser,
    onError: () => {
      logout();
    },
  });

  if (error) {
    // TODO DISPATCH SNACKBAR
    logout();
    // TODO FALLBACK NEEDED - SNACKBAR WILL DISAPPEAR SOON AFTER REDIRECT
    return null;
  }

  if (isAuthLoading || isTokenLoading) {
    return <FullScreenLoader />;
  }

  return <>{children}</>;
};
