import { useDispatch } from "react-redux";
import { closeDialog } from "shared/logic/store/features/resourceSlices/dialog/dialogSlice";

const useCommissionDialog = () => {
  const dispatch = useDispatch();
  const closeDialogAction = () => dispatch(closeDialog());
  return {
    closeDialogAction,
  };
};

export default useCommissionDialog;
