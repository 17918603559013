import CopyToClipboardButton from "shared/components/CopyToClipboard";
import { ContentCopy } from "@mui/icons-material";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import React, { FC } from "react";
import { useCodeBox, CodeBoxState } from "shared/components/CodeBox/useCodeBox";
import {
  AnimatedCodeBox,
  StyledCodeBoxContent,
  StyledCopyButton,
  ShowAllButton,
  AnimatedArrow,
} from "shared/components/CodeBox/CodeBox.styled";

export interface CodeBoxProps {
  code: string;
}

export const CodeBox: FC<CodeBoxProps> = ({ code }) => {
  const { codeBoxAnimation, toggleCodeBoxState, codeBoxVariants, isOpen } = useCodeBox();
  return (
    <AnimatedCodeBox
      layout
      variants={codeBoxVariants}
      initial={CodeBoxState.CLOSE}
      animate={codeBoxAnimation}
      transition={{ ease: "easeInOut" }}
    >
      <CopyToClipboardButton textToCopy={code} itemName="Site Tag">
        <StyledCopyButton>
          <ContentCopy />
        </StyledCopyButton>
      </CopyToClipboardButton>
      <StyledCodeBoxContent $isOpen={isOpen}>
        <code>{code}</code>
      </StyledCodeBoxContent>
      <ShowAllButton onClick={toggleCodeBoxState}>
        <TextBase variant="caption" content="utility.showAll" />
        <AnimatedArrow animate={{ rotate: isOpen ? -180 : 0 }} />
      </ShowAllButton>
    </AnimatedCodeBox>
  );
};
