import { createAsyncThunk } from "@reduxjs/toolkit";
import partnersApi from "shared/logic/api/partnersApi";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { getPartnerships } from "shared/logic/store/features/viewsSlices/utils/getPartnerships";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { filterPartnerIdsByPartnerships } from "shared/logic/store/features/viewsSlices/brandUser/discoverNewPartners/utils/filterPartnerIdsByPartnerships";
import { filterPartnerIdsByEnv } from "shared/logic/store/features/viewsSlices/brandUser/discoverNewPartners/utils/filterPartnerIdsByEnv";

export const fetchResourcesForDiscoverNewPartnersView = createAsyncThunk(
  storePrefixes.brandUser.discoverSlice.fetch,
  async ({ brandId }: { brandId?: string }, { dispatch }) => {
    try {
      // ############################################################
      // PARTNERSHIPS
      const partnershipsPromise = getPartnerships({ brandId });
      // ############################################################
      // PARTNERS
      const partnersPromise = partnersApi.getPartners();

      const [partnershipsResponse, partnersResponse] = await Promise.all([
        partnershipsPromise,
        partnersPromise,
      ]);

      const { partners } = partnersResponse.data;
      const { partnerships, partnershipsMap } = partnershipsResponse;
      const partnersMap = parseArrayToIdsEntityPair(partners);

      // ############################################################
      // FILTER
      const partnerIds = partners.map((partner) => partner.id);
      const partnersThatNotAppearInPartnerships = filterPartnerIdsByPartnerships(
        partnerIds,
        partnerships,
      );

      return {
        // RESOURCE SLICES DATA
        partnerships: partnershipsMap,
        partners: partnersMap,
        // VIEW SLICES DATA
        partnerIds: filterPartnerIdsByEnv(partnersThatNotAppearInPartnerships, partnersMap), // ENVIRONMENT SPECIFIC EXCLUSION
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
