import { createSelector } from "@reduxjs/toolkit";
import { getPartnerSlices } from "shared/logic/store/features/viewsSlices/partnerUser/selectors";
import { getBrands } from "shared/logic/store/features/resourceSlices/brands/selectors";
import Brand from "shared/logic/types/Brands/Brand";
import {
  getProductIdsForBrands,
  getProducts,
} from "shared/logic/store/features/resourceSlices/products/selectors";

const getDiscoverSlice = createSelector([getPartnerSlices], (partner) => partner.discoverViewSlice);

const getDiscoverBrandIds = createSelector([getDiscoverSlice], ({ brandIds }) => brandIds);

const getDiscoverBrands = createSelector([getDiscoverBrandIds, getBrands], (brandIds, brands) => {
  const results: Brand[] = [];

  brandIds.forEach((id) => {
    if (brands[id]) {
      results.push(brands[id]);
    } else {
      // SHOULD NOT HAPPEN
      // TODO LOG ERROR TO DATADOG
    }
  });

  return results;
});

const getCurrentlyOpenedBrandId = createSelector(
  [getDiscoverSlice],
  ({ currentlyOpenedBrandId }) => currentlyOpenedBrandId,
);

const getCurrentlyOpenedBrand = createSelector(
  [getCurrentlyOpenedBrandId, getBrands],
  (currentlyOpenedBrandId, brands) => {
    if (currentlyOpenedBrandId) {
      return brands[currentlyOpenedBrandId];
    }
    return null;
  },
);

const getBrandsInventory = createSelector(
  [getCurrentlyOpenedBrandId, getProductIdsForBrands, getProducts],
  (currentlyOpenedBrandId, productIdsForBrands, products) => {
    if (currentlyOpenedBrandId && productIdsForBrands[currentlyOpenedBrandId]) {
      return productIdsForBrands[currentlyOpenedBrandId].map((productId) => products[productId]);
    }
    return null;
  },
);

const getDiscoverBrandIsLoading = createSelector(
  [getDiscoverSlice],
  (discoverSlice) => discoverSlice.isBrandsLoading,
);
const getDiscoverBrandProductsIsLoading = createSelector(
  [getDiscoverSlice],
  (discoverSlice) => discoverSlice.areProductsLoading,
);

export default {
  getDiscoverSlice,
  getDiscoverBrandIds,
  getDiscoverBrands,
  getCurrentlyOpenedBrandId,
  getCurrentlyOpenedBrand,
  getBrandsInventory,
  getDiscoverBrandIsLoading,
  getDiscoverBrandProductsIsLoading,
};
