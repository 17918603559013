import { createAsyncThunk } from "@reduxjs/toolkit";
import ecommerceAccountsApi from "shared/logic/api/ecommerceAccountsApi";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { SetupStepEnum } from "views/brandViews/Setup/useSetup";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";

interface BillingPermissionsParams {
  brandId?: string;
  shopName?: string;
  authorizationCode?: string;
}

export const setBillingPermissions = createAsyncThunk(
  "oauthBillingPermissions",
  async ({ brandId, shopName, authorizationCode }: BillingPermissionsParams, { dispatch }) => {
    try {
      if (!brandId || !shopName || !authorizationCode) {
        return;
      }
      const newEcommerceAccountResponse = await ecommerceAccountsApi.newAccount({
        brandId,
        shopName,
        authorizationCode,
      });
      const ecommerceAccountId = newEcommerceAccountResponse.data.id;
      const urlToRedirect = `${window.location.protocol}//${window.location.host}${RouteEnum.SETUP}?step=${SetupStepEnum.FINALISE_INTEGRATION}`;
      const setupBillingResponse = await ecommerceAccountsApi.setupBilling({
        ecommerceAccountId,
        urlToRedirect,
      });
      window.location.href = setupBillingResponse.data.confirmationUrl;
    } catch (e) {
      ConsoleLog(e);
      dispatch(
        setSnackbar({
          open: true,
          message: "snackbar.shopifyBillingError",
        }),
      );
    }
  },
);
