import { AddAccountFormEnum, AddAccountStepEnum } from "views/adminViews/AddAccount/useAddAccount";
import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { ConfirmDetailsProps } from "views/adminViews/AddAccount/ConfirmDetails/index";
import { UserRole } from "shared/components/auth/AuthContext";
import { getCreateNewUsersRequest } from "shared/logic/store/features/resourceSlices/user/selectors";
import { useHistory } from "react-router-dom";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { usePrevious } from "shared/logic/hooks/generalPurpose/usePreviousValue";
import { storeAPI } from "views/adminViews/storeAPI";

export type UseConfirmDetailsProps = ConfirmDetailsProps;

export const useConfirmDetails = ({ formData }: UseConfirmDetailsProps) => {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { inProgress, fulfilled } = useSelector(getCreateNewUsersRequest);
  const history = useHistory();
  const prevFulfilled = usePrevious(fulfilled);

  const {
    [AddAccountFormEnum.ACCOUNT_NAME]: accountName,
    [AddAccountFormEnum.ACCOUNT_TYPE]: accountType,
    [AddAccountFormEnum.USERS_TO_ADD]: usersToAdd,
    [AddAccountFormEnum.COMMISSION_RATE]: commissionRate,
  } = formData;
  const isFormIncomplete = !accountType || !accountName || !usersToAdd || !commissionRate;
  const accountTypeLabel = accountType === UserRole.PARTNER ? "Creator" : accountType;

  const onBackClick = () => {
    setSearchParams({ step: AddAccountStepEnum.ADD_USERS });
  };

  const sendForm = () => {
    if (isFormIncomplete) {
      return;
    }
    const parsedUsers = usersToAdd.map((user) => ({
      emailAddress: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    }));
    const isBrand = accountType === UserRole.BRAND;
    const isPartner = accountType === UserRole.PARTNER;
    if (isBrand) {
      dispatch(
        storeAPI.addAccountView.createNewUsers({
          newUsersData: parsedUsers,
          newBrandData: { name: accountName, defaultPartnershipCommissionPercent: commissionRate },
        }),
      );
    } else if (isPartner) {
      dispatch(
        storeAPI.addAccountView.createNewUsers({
          newUsersData: parsedUsers,
          newPartnerData: { name: accountName, defaultCommissionPercent: commissionRate },
        }),
      );
    }
  };

  useEffect(() => {
    if (isFormIncomplete) {
      setSearchParams({ step: AddAccountStepEnum.ACCOUNT_DETAILS });
      dispatch(setSnackbar({ open: true, message: "snackbar.genericError" }));
    }
  }, [isFormIncomplete]);

  useEffect(() => {
    if (prevFulfilled === false && fulfilled) {
      history.push({ pathname: RouteEnum.ADD_ACCOUNT, search: undefined });
    }
  }, [fulfilled]);

  return {
    onBackClick,
    sendForm,
    isFormIncomplete,
    accountName,
    accountTypeLabel,
    usersToAdd,
    inProgress,
  };
};
