import { createAsyncThunk } from "@reduxjs/toolkit";
import { SignUpQuery } from "shared/logic/types/User/SignUpQuery";
import Brand, { CreateBrandDTO } from "shared/logic/types/Brands/Brand";
import { CreateNewPartnerDTO, Partner } from "shared/logic/types/Partners/Partner";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { createCompanyTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createCompanyTransaction";
import companiesApi from "shared/logic/api/companiesApi";
import userApi from "shared/logic/api/userApi";
import { createBrandTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createBrandTransaction";
import { createPolicyTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createPolicyTransaction";
import { PolicyRole } from "shared/logic/types/User/UserPolicy";
import { UserRole } from "shared/components/auth/AuthContext";
import {
  setSnackbarError,
  createOrReturnExistingUser,
} from "shared/logic/store/features/viewsSlices/adminUser/utils";
import { createPartnerTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createPartnerTransaction";

export type NewUserData = Pick<SignUpQuery, "emailAddress" | "firstName" | "lastName">;

interface CreateNewUser {
  newUsersData: NewUserData[];
}

export interface CreateNewBrandUser extends CreateNewUser {
  newPartnerData?: never;
  newBrandData: Pick<CreateBrandDTO, "name" | "defaultPartnershipCommissionPercent">;
}

export interface CreateNewPartnerUser extends CreateNewUser {
  newPartnerData: Pick<CreateNewPartnerDTO, "name" | "defaultCommissionPercent">;
  newBrandData?: never;
}

type CreateNewUserActionPayload = CreateNewBrandUser | CreateNewPartnerUser;

export const createNewUsers = createAsyncThunk(
  "createNewUsers",
  async (
    { newUsersData, newBrandData, newPartnerData }: CreateNewUserActionPayload,
    { dispatch },
  ) => {
    const showError = () => setSnackbarError(dispatch);

    const company = await createCompanyTransaction({
      name: newBrandData?.name ?? newPartnerData?.name ?? newUsersData[0].emailAddress,
      onError: showError,
    });

    const users = await Promise.all(
      newUsersData.map(async (user) =>
        createOrReturnExistingUser({
          user,
          onError: showError,
          newCompanyId: company.id,
        }),
      ),
    );

    let roleEntity: (Brand | Partner) & { entityType: UserRole };

    if (newBrandData) {
      const brand = await createBrandTransaction({
        data: { ...newBrandData, companyId: company.id },
        onError: showError,
        rollback: async () => {
          await companiesApi.deleteCompany(company.id);
        },
      });
      roleEntity = {
        ...brand,
        entityType: UserRole.BRAND,
      };
    } else if (newPartnerData) {
      const partner = await createPartnerTransaction({
        data: { ...newPartnerData, companyId: company.id },
        onError: showError,
        rollback: async () => {
          await companiesApi.deleteCompany(company.id);
        },
      });
      roleEntity = {
        ...partner,
        entityType: UserRole.PARTNER,
      };
    } else {
      await companiesApi.deleteCompany(company.id);
      showError();
      throw new Error("No policy created");
    }

    const policies = await Promise.all(
      users.map(async (user) => {
        try {
          const prevPoliciesResponse = await userApi.getUserPolicies(user.id);
          const newPolicy = {
            role: PolicyRole.ADMINISTRATOR,
            entityType: roleEntity.entityType,
            entityId: roleEntity.id,
          };
          return await createPolicyTransaction({
            data: {
              user_id: user.id,
              policy_id: user.id,
              permissioned_entities: [
                ...prevPoliciesResponse.data.permissioned_entities,
                newPolicy,
              ],
            },
            onError: showError,
            rollback: async () => {
              await companiesApi.deleteCompany(company.id);
            },
          });
        } catch {
          throw new Error(`Error on creating new policy for ${user.emailAddress}`);
        }
      }),
    );

    const policiesAggregated = policies.map((p) => ({
      ...p,
      roleEntity,
    }));

    dispatch(
      setSnackbar({
        message: "snackbar.usersCreateSuccess",
        icon: SnackbarIcon.DONE,
      }),
    );

    return {
      company,
      users,
      policies,
      policiesAggregated,
      roleEntity,
    };
  },
);
