export const LinkShop = () => (
  <svg
    width="136"
    height="102"
    viewBox="0 0 136 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3404_4890)">
      <rect width="136" height="102" rx="8" fill="black" fillOpacity="0.04" />
      <rect
        x="12"
        y="22"
        width="35.0968"
        height="3.09677"
        rx="1.54839"
        fill="black"
        fillOpacity="0.38"
      />
      <rect
        x="12"
        y="26.8172"
        width="48"
        height="3.09677"
        rx="1.54839"
        fill="black"
        fillOpacity="0.12"
      />
      <rect
        x="12"
        y="77.7288"
        width="42.0571"
        height="2.43764"
        rx="1.21882"
        fill="black"
        fillOpacity="0.12"
      />
      <rect
        x="12"
        y="72.041"
        width="16.0508"
        height="2.43764"
        rx="1.21882"
        fill="black"
        fillOpacity="0.12"
      />
      <rect x="30.0508" y="72.041" width="22.9587" height="2.43764" rx="1.21882" fill="#5A4FDB" />
      <rect
        x="55.0095"
        y="72.041"
        width="11.1746"
        height="2.43764"
        rx="1.21882"
        fill="black"
        fillOpacity="0.12"
      />
      <rect
        x="12"
        y="66.3531"
        width="59.1238"
        height="2.43764"
        rx="1.21882"
        fill="black"
        fillOpacity="0.12"
      />
      <rect
        x="12"
        y="60.6653"
        width="53.0286"
        height="2.43764"
        rx="1.21882"
        fill="black"
        fillOpacity="0.12"
      />
      <rect
        x="12"
        y="54.9775"
        width="15.8476"
        height="2.43764"
        rx="1.21882"
        fill="black"
        fillOpacity="0.12"
      />
      <rect
        x="30.2856"
        y="54.9775"
        width="25.6"
        height="2.43764"
        rx="1.21882"
        fill="black"
        fillOpacity="0.12"
      />
      <rect
        x="58.3237"
        y="54.9775"
        width="17.2698"
        height="2.43764"
        rx="1.21882"
        fill="black"
        fillOpacity="0.12"
      />
      <rect
        x="12"
        y="49.2897"
        width="59.1238"
        height="2.43764"
        rx="1.21882"
        fill="black"
        fillOpacity="0.12"
      />
      <rect
        x="12"
        y="43.6018"
        width="26.819"
        height="2.43764"
        rx="1.21882"
        fill="black"
        fillOpacity="0.12"
      />
      <rect
        x="41.2571"
        y="43.6018"
        width="34.7429"
        height="2.43764"
        rx="1.21882"
        fill="black"
        fillOpacity="0.12"
      />
      <rect
        x="12"
        y="37.914"
        width="54.8571"
        height="2.43764"
        rx="1.21882"
        fill="black"
        fillOpacity="0.12"
      />
      <rect width="45.3333" height="102" transform="translate(91)" fill="white" />
      <rect x="97" y="18" width="33" height="33" rx="4" fill="black" fillOpacity="0.12" />
      <rect
        x="97"
        y="59"
        width="24.129"
        height="3.09677"
        rx="1.54839"
        fill="black"
        fillOpacity="0.12"
      />
      <rect
        x="97"
        y="63.8172"
        width="33"
        height="3.09677"
        rx="1.54839"
        fill="black"
        fillOpacity="0.12"
      />
      <rect x="97" y="74.914" width="33" height="6.61" rx="2" fill="black" fillOpacity="0.38" />
    </g>
    <path
      d="M8 1H128V-1H8V1ZM135 8V94H137V8H135ZM128 101H8V103H128V101ZM1 94V8H-1V94H1ZM8 101C4.13401 101 1 97.866 1 94H-1C-1 98.9706 3.02944 103 8 103V101ZM135 94C135 97.866 131.866 101 128 101V103C132.971 103 137 98.9706 137 94H135ZM128 1C131.866 1 135 4.13401 135 8H137C137 3.02944 132.971 -1 128 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z"
      fill="black"
      fillOpacity="0.12"
    />
    <defs>
      <clipPath id="clip0_3404_4890">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H128C132.418 0 136 3.58172 136 8V94C136 98.4183 132.418 102 128 102H8C3.58172 102 0 98.4183 0 94V8Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
