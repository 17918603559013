import { TextFieldProps, TextField } from "@mui/material";
import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import { memo, FC, forwardRef } from "react";
import { useTranslationWithMarkdown } from "shared/logic/hooks/translations/useTranslationWithMarkdown";

export interface InputBaseProps extends Omit<TextFieldProps, "label" | "placeholder"> {
  label?: TextContentPath;
  placeholder?: TextContentPath;
}

const LocalizedInputComponent: FC<InputBaseProps> = forwardRef(
  ({ label, placeholder, ...materialProps }: InputBaseProps, ref) => {
    const { t } = useTranslationWithMarkdown();
    return (
      <TextField
        label={label ? t(label) : null}
        placeholder={placeholder ? (t(placeholder) as string) : ""}
        {...materialProps}
        ref={ref}
      />
    );
  },
);

export const LocalizedInput = memo(LocalizedInputComponent);
