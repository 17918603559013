import { styled, IconButton, css, Box } from "@mui/material";

export const StyledCloseButton = styled(IconButton)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    border: 1px solid ${theme.palette.border};
  `,
);

export const StyledDialogHeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledContent = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(4)};
    width: 600px; // TODO - change to not be fixed
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);
