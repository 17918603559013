import { styled, TableRow, css, TableCell, Avatar } from "@mui/material";

export const StyledHeaderRow = styled(TableRow)(
  ({ theme }) => css`
    height: 48px;
    > * {
      background-color: ${theme.palette.background.onSurface};
      color: ${theme.palette.text.disabled};
      border: unset;
      padding-top: 0;
      padding-bottom: 0;
      :first-child {
        border-radius: ${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px;
      }
      :last-child {
        border-radius: 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0;
      }
    }
  `,
);

export const ImageCell = styled(TableCell)`
  border: 0;
  width: 48px;
`;

export const Image = styled(Avatar)`
  height: 48px;
  width: 48px;
  img {
    object-fit: contain;
  }
`;

export const StyledItemRow = styled(TableRow)(
  ({ theme }) => css`
    > * {
      border-color: ${theme.palette.background.onSurface};
    }
  `,
);
