export interface PerformTransactionArgs<R> {
  action: () => Promise<R>;
  onError?: () => void;
  rollback?: () => Promise<void>;
}

export async function performTransaction<R>({
  action,
  onError,
  rollback,
}: PerformTransactionArgs<R>): Promise<R> {
  try {
    return await action();
  } catch (e) {
    // ROLLBACK
    if (rollback) {
      await rollback();
    }
    // ON ERROR
    if (onError) {
      onError();
    }
    // THROW ERROR BACK
    throw e;
  }
}
