import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";
import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { useDispatch, useSelector } from "react-redux";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";
import { DisplayVariant } from "shared/components/TableComponents/FiltersContainer/LayoutVariantButtons";
import { useFilter } from "shared/logic/hooks/generalPurpose/useFilter";
import { AppDispatch } from "shared/logic/store";
import { storeAPI } from "views/partnerViews/storeAPI";

const unfilteredProductsMock: ProductAggregated[] = [];

const useProducts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const policy = useSelector(getUserPolicy);
  const { pathname } = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [status, setStatus] = useState(
    (searchParams?.status as ApprovalStatus) ?? ApprovalStatus.APPROVED,
  );
  const [displayVariant, setDisplayVariant] = useState(DisplayVariant.GRID);
  const unfilteredProducts = useSelector(storeAPI.productsView.getProducts);
  const isProductsLoading = useSelector(storeAPI.productsView.getIsProductsLoading);
  const partner = useSelector(storeAPI.productsView.getPartner);

  const { onSearchHandler, items: products } = useFilter<ProductAggregated>({
    unfilteredItems: unfilteredProducts ?? unfilteredProductsMock,
    propertiesToFilterBy: ["name", "description"],
  });
  const isEmpty = products && products.length === 0;

  useEffect(() => {
    const fetchResources = dispatch(
      storeAPI.productsView.fetchResources({ partnerId: policy?.entityId, approvalStatus: status }),
    );
    return () => {
      fetchResources.abort();
    };
  }, [status, policy?.entityId]);

  // Temporarily disable grid on pending items view due to lack of designs
  useEffect(() => {
    if (status === ApprovalStatus.PENDING) {
      setDisplayVariant(DisplayVariant.LIST);
    }
    if (status === ApprovalStatus.APPROVED) {
      setDisplayVariant(DisplayVariant.GRID);
    }
  }, [status]);

  useEffect(() => {
    if (!searchParams?.status) {
      setSearchParams({ status: ApprovalStatus.APPROVED });
    }
  }, [pathname, searchParams]);

  useEffect(() => {
    const queryStatus = searchParams?.status;
    if (!queryStatus) {
      return;
    }
    setStatus(queryStatus as ApprovalStatus);
  }, [searchParams?.status]);

  return {
    products,
    isLoading: isProductsLoading,
    isEmpty,
    partner,
    displayVariant,
    setDisplayVariant,
    onSearchHandler,
  };
};

export default useProducts;
