import { Box, Button, css, Drawer, IconButton, styled } from "@mui/material";
import { TextBase, TextBaseProps } from "shared/components/base/TextBase/TextBase";

export const StyledContent = styled(Box)(
  ({ theme }) => css`
    max-width: 414px;
    padding: ${theme.spacing(8, 4, 3, 4)};
    display: flex;
    flex-direction: column;
    flex: 1;

    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);

export const TitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseButton = styled(IconButton)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    border: 1px solid ${theme.palette.border};
  `,
);

export const StyledContentDrawer = styled(Box)`
  display: flex;
  flex: 1;
`;

export const Title = (props: TextBaseProps) => (
  <TextBase {...props} variant="h4" fontWeight="semibold" />
);

export const ClearAllLabel = (props: TextBaseProps) => (
  <TextBase {...props} variant="subtitle1" fontWeight="medium" color="tertiary.main" />
);

export const Navigation = styled(Box)``;
export const ClearAllBtn = styled(Button)``;
export const StyledDrawer = styled(Drawer)``;
