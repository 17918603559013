import selectors from "./selectors";
import ViewSlice from "./settingsViewSlice";
import { updateBrandDetails } from "./updateBrandDetails";

export default {
  ...selectors,
  // fetchResources: fetchResourcesFor,
  slice: ViewSlice,
  updateBrandDetails,
};
