import { FC } from "react";
import { useDispatch } from "react-redux";
import {
  StyledWrapper,
  StyledTagButton,
  StyledTagIcon,
  GetTagLabel,
} from "views/partnerViews/Products/ProductsItem/GetProductTag/GetProductTag.styled";
import { openGetTagDialog } from "shared/logic/store/features/viewsSlices/partnerUser/products/productsViewSlice";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";

export interface GetProductTagProps {
  product: ProductAggregated;
}

export const GetProductTag: FC<GetProductTagProps> = ({ product }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(openGetTagDialog(product));
  };
  return (
    <StyledWrapper>
      <StyledTagButton onClick={onClick}>
        <GetTagLabel content="products.getItemTag" />
        <StyledTagIcon />
      </StyledTagButton>
    </StyledWrapper>
  );
};
