export enum RouteEnum {
  HOME = "/",
  LOGIN = "/login",
  REGISTER = "/register",
  DASHBOARD = "/dashboard",
  PARTNERSHIPS = "/partnerships",
  PRODUCTS = "/products",
  ASSIGN_PRODUCT = "/assign-product",
  ASSIGN_PARTNER = "/assign-partner",
  SETUP = "/setup",
  ACCOUNT = "/account",
  LOGOUT = "/logout",
  RESET_PASSWORD = "/reset-password",
  NEW_PASSWORD = "/new-password",
  ADD_ACCOUNT = "/admin/add-account",
  FIRST_LOGIN = "/first-login",
  DISCOVER = "/discover",
  FORBIDDEN = "/403",
  INTERNAL_ERROR = "/500",
  INVITE_TO_PLATFORM = "/invite",
  SETTINGS = "/settings",
}
