import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import { LogoVariant } from "shared/components/Styled/StyledAppLogo";
import { FC } from "react";
import {
  Wrapper,
  Logo,
  Content,
  ErrorNumber,
  Description,
  DescriptionSection,
  SignOutButton,
  ButtonText,
  ErrorTitle,
} from "./ErrorPage.styled";

export interface ErrorPageProps {
  errorNumber: number;
  errorTitle: TextContentPath;
  errorDescription: TextContentPath;
  errorButtonLabel: TextContentPath;
  errorButtonAction: () => void;
}

export const ErrorPage: FC<ErrorPageProps> = ({
  errorTitle,
  errorButtonAction,
  errorButtonLabel,
  errorNumber,
  errorDescription,
}) => (
  <Wrapper>
    <Logo variant={LogoVariant.TERTIARY} />
    <Content>
      <ErrorTitle content={errorTitle} />
      <ErrorNumber>{errorNumber}</ErrorNumber>
      <DescriptionSection>
        <Description content={errorDescription} />
        <SignOutButton onClick={errorButtonAction}>
          <ButtonText content={errorButtonLabel} />
        </SignOutButton>
      </DescriptionSection>
    </Content>
  </Wrapper>
);
