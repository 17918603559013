import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "locales/en/translation.json";

// translation resources
export const resources = {
  en: {
    translation: translationEN,
  },
} as const;

// single namespace
// extend translation namespaces if needed here
export const defaultNS = "translation";

// i18n init
i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  supportedLngs: ["en"],
  defaultNS,
});

export default i18n;
