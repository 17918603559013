import Brand from "shared/logic/types/Brands/Brand";
import { ENVIRONMENT, getEnvironment } from "shared/logic/utils/getEnvironment";
import { isProductionBrand } from "shared/logic/utils/brands/isProductionBrand";

export function filterBrandIdsByEnv(
  brandIds: string[],
  brandsMap: Record<string, Brand>,
): string[] {
  switch (getEnvironment()) {
    case ENVIRONMENT.PRODUCTION:
      return brandIds.filter((id) => {
        const brand = brandsMap[id];
        return brand && isProductionBrand(brand);
      });
    case ENVIRONMENT.DEVELOPMENT:
    default:
      return brandIds;
  }
}
