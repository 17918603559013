import Partnership from "shared/logic/types/Partnerships/Partnership";
import uniq from "lodash-es/uniq";
import difference from "lodash-es/difference";

export function filterBrandIdsByPartnerships(
  brandIds: string[],
  partnerships: Partnership[],
): string[] {
  const brandsToOmit = uniq(partnerships.map((partnership) => partnership.brandId));
  return difference(brandIds, brandsToOmit);
}
