import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "shared/logic/store/rootReducer";
import { UserRole } from "shared/components/auth/AuthContext";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import { UserSetupStatus, UserStatus } from "shared/logic/types/User/UserStatus";
import { PolicyRole } from "shared/logic/types/User/UserPolicy";

export const getUserSlice = (state: RootState) => state.userSlice;

// ############################################################
// apiCallForUserInfo
const getUserInfoRequestError = createSelector(
  [getUserSlice],
  ({ userInfoRequest }) => userInfoRequest.error,
);
const getUserInfoRequestStatus = createSelector(
  [getUserSlice],
  ({ userInfoRequest }) => userInfoRequest.status,
);
export const getUserInfoRequest = createSelector(
  [getUserInfoRequestError, getUserInfoRequestStatus],
  (error, status) => ({
    error,
    status,
    inProgress: status === SliceStatus.PENDING,
  }),
);

// ############################################################
// signUp
const getSignUpRequestError = createSelector(
  [getUserSlice],
  ({ signUpRequest }) => signUpRequest.error,
);
const getSignUpRequestStatus = createSelector(
  [getUserSlice],
  ({ signUpRequest }) => signUpRequest.status,
);
export const getSignUpRequest = createSelector(
  [getSignUpRequestError, getSignUpRequestStatus],
  // TODO CREATE AN UTIL
  // eslint-disable-next-line sonarjs/no-identical-functions
  (error, status) => ({
    error,
    status,
    inProgress: status === SliceStatus.PENDING,
  }),
);

// ############################################################
// createNewUsers
const getCreateNewUsersRequestError = createSelector(
  [getUserSlice],
  ({ createNewUsersRequest }) => createNewUsersRequest.error,
);
const getCreateNewUsersRequestStatus = createSelector(
  [getUserSlice],
  ({ createNewUsersRequest }) => createNewUsersRequest.status,
);

export const getCreateNewUsersRequest = createSelector(
  [getCreateNewUsersRequestError, getCreateNewUsersRequestStatus],
  // TODO CREATE AN UTIL
  // eslint-disable-next-line sonarjs/no-identical-functions
  (error, status) => ({
    error,
    status,
    inProgress: status === SliceStatus.PENDING,
    fulfilled: status === SliceStatus.FULFILLED,
  }),
);

export const getUserInfo = createSelector([getUserSlice], ({ userInfo }) => userInfo);
export const getUserStatus = createSelector(
  [getUserInfo],
  ({ status }) => status ?? UserStatus.NOT_SPECIFIED,
);
export const getUserId = createSelector([getUserInfo], ({ user }) => user?.id);

// ############################################################
// POLICY

export const getCurrentUserPolicyId = (state: RootState) => state.userSlice.currentPolicyId;
export const getUserPolicies = (state: RootState) => state.userSlice.userInfo.policies;
export const getUserPolicy = (state: RootState) => {
  if (state.userSlice.userInfo.policies && state.userSlice.currentPolicyId) {
    return state.userSlice.userInfo.policies[state.userSlice.currentPolicyId];
  }
  return undefined;
};

export const getUserSetupStatus = createSelector(
  [getUserPolicy],
  (currentPolicy): UserSetupStatus => {
    if (!currentPolicy) {
      return UserSetupStatus.NO_NEED;
    }

    const isFermatAdministrator = currentPolicy.role === PolicyRole.FERMAT_ADMINISTRATOR;

    if (isFermatAdministrator) {
      return UserSetupStatus.NO_NEED;
    }

    const isPartnerUser = currentPolicy.entityType === UserRole.PARTNER;
    const isPartnerFirstLogin =
      !currentPolicy.partner?.description || !currentPolicy.partner?.defaultCommissionPercent;

    if (isPartnerUser && isPartnerFirstLogin) {
      return UserSetupStatus.PARTNER_FIRST_LOGIN;
    }

    const isBrandUser = currentPolicy.entityType === UserRole.BRAND;
    const isBrandFirstLogin = !currentPolicy.brand?.description;

    if (isBrandUser && isBrandFirstLogin) {
      return UserSetupStatus.BRAND_FIRST_LOGIN;
    }

    const isPartnerSetUp = !!currentPolicy.partner?.website;

    if (isPartnerUser && !isPartnerSetUp) {
      return UserSetupStatus.PARTNER_SETUP;
    }

    const isBrandSetUp = !!currentPolicy.brand?.privacyPolicyUrl;

    if (isBrandUser && !isBrandSetUp) {
      return UserSetupStatus.BRAND_SETUP;
    }

    return UserSetupStatus.NO_NEED;
  },
);

export const getIsCurrentUserSetUpCompletely = createSelector([getUserPolicy], (userPolicy) => {
  if (!userPolicy) {
    return undefined;
  }

  const { entityType, brand, partner } = userPolicy;

  if (entityType === UserRole.BRAND) {
    return !!brand?.privacyPolicyUrl;
  }

  if (entityType === UserRole.PARTNER) {
    return !!partner?.website;
  }

  return undefined;
});
