import { styled, Box, Button, ButtonProps } from "@mui/material";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";
import gap from "shared/components/Styled/Gap";

export const TitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleBoxButton = styled((props: ButtonProps) => <Button size="small" {...props} />)`
  ${gap.leftNoSpan(1)};
`;

export const EditButton = TitleBoxButton;
export const Title = (props: TextBaseProps) => <TextBase variant="h4" {...props} />;
export const Label = (props: TextBaseProps) => <TextBase variant="subtitle1" {...props} />;
