import { styled, Box, css, IconButton } from "@mui/material";

export const StyledDrawerContent = styled(Box)(
  ({ theme }) => css`
    max-width: 720px;
    padding: ${theme.spacing(4, 8, 3, 8)};
    display: flex;
    flex-direction: column;
    flex: 1;

    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);

export const StyledCloseButton = styled(IconButton)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    border: 1px solid ${theme.palette.border};
  `,
);

export const StyledDrawerStep = styled(Box)`
  display: flex;
  flex: 1;
`;

export const TitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SpaceBetweenWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const NavigationContent = styled(Box)(
  ({ theme }) => css`
    border-top: 1px solid ${theme.palette.border};
    height: 72px;
    padding-top: ${theme.spacing(3)};
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const ContentSection = styled(Box)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);

export const Content = styled(Box)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(2)};
    }
  `,
);

export const StepDetails = styled(Box)``;
