import { styled } from "@mui/material";
import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";
import { TextBase, TextBaseProps } from "shared/components/base/TextBase/TextBase";
import {
  TagStyleButtonProps,
  TagStyleButton,
} from "views/partnerViews/Products/TagDrawer/TagStyleButton";
import { LinkShop } from "shared/components/Svg/LinkShop";
import { EmbeddedShop } from "shared/components/Svg/EmbeddedShop";

export const Content = styled((props) => <StyledViewSpace {...props} multiplier={4} />)`
  width: 570px;
`;

export const ItemTitle = (props: TextBaseProps) => <TextBase variant="h3" {...props} />;
export const ChooseTagTitle = (props: TextBaseProps) => <TextBase variant="h5" {...props} />;

export const LinkShopTag = (props: TagStyleButtonProps) => (
  <TagStyleButton {...props} IconComponent={LinkShop} />
);

export const EmbeddedShopTag = (props: TagStyleButtonProps) => (
  <TagStyleButton {...props} IconComponent={EmbeddedShop} />
);
