import { useUserSettings } from "shared/components/SettingsComponents/UserSettings/useUserSettings";
import {
  Wrapper,
  Title,
  NameInput,
  EmailInput,
  PasswordChangeBtn,
  Label,
  SaveChangesBtn,
  StyledForm,
} from "shared/components/SettingsComponents/UserSettings/User.styled";

export const UserSettings = () => {
  const { register, handleSubmit, onSubmit } = useUserSettings();
  return (
    <Wrapper>
      <Title content="settings.userProfile.title" />
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <NameInput label="settings.userProfile.fullName" {...register("fullName")} />
        <EmailInput label="settings.userProfile.emailAddress" {...register("email")} disabled />
        <PasswordChangeBtn>
          <Label content="settings.userProfile.requestPasswordChange" />
        </PasswordChangeBtn>
        <SaveChangesBtn type="submit">
          <Label content="settings.userProfile.submit" />
        </SaveChangesBtn>
      </StyledForm>
    </Wrapper>
  );
};
