import Brand from "shared/logic/types/Brands/Brand";
import Partnership from "shared/logic/types/Partnerships/Partnership";
import brandsApi from "shared/logic/api/brandsApi";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";

export interface GetBrandsByPartnershipsReturn {
  brands: Brand[];
  brandsMap: Record<string, Brand>;
}

export async function getBrandsByPartnerships(
  partnerships: Partnership[],
): Promise<GetBrandsByPartnershipsReturn> {
  const brandsIds = partnerships.map((p) => p.brandId);
  const brandsResponse = await brandsApi.getBrandsByQuery({ include: brandsIds });
  const { brands } = brandsResponse.data;
  const brandsMap = parseArrayToIdsEntityPair(brands);

  return {
    brands,
    brandsMap,
  };
}
