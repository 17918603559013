import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { SetupStep } from "views/partnerViews/Setup/StepDrawer/index";
import { useForm } from "react-hook-form";
import { domainRegex } from "shared/logic/utils/url/urlRegex";
import { useTranslationWithMarkdown } from "shared/logic/hooks/translations/useTranslationWithMarkdown";

export enum DomainForm {
  DOMAIN = "domain",
  CONFIRM_DOMAIN = "confirm-domain",
}

interface FormData {
  [DomainForm.DOMAIN]: string;
  [DomainForm.CONFIRM_DOMAIN]: string;
}

export const useDomainDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslationWithMarkdown();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm();
  const isEditMode = !!searchParams.edit;
  const domainInputValue = watch(DomainForm.DOMAIN);
  const domainInputErrorMessage = t("setup.partner.domainInputError");
  const handleConfirmDomainValidation = (site: string) => {
    const isSiteValid = domainRegex.test(site);
    const areSitesEqual = site === domainInputValue;
    return isSiteValid && areSitesEqual;
  };
  const onSubmit = (formData: FormData) => {
    setSearchParams(
      searchParams.edit
        ? { step: SetupStep.DOMAIN_EDITED, domain: formData[DomainForm.DOMAIN] }
        : {
            step: SetupStep.SITE_TAG,
            domain: formData[DomainForm.DOMAIN],
          },
    );
  };
  return {
    onSubmit,
    handleSubmit,
    register,
    errors,
    isEditMode,
    handleConfirmDomainValidation,
    domainInputErrorMessage,
  };
};
