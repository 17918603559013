import axiosInstance from "shared/logic/api/axiosInstance";
import { BrandResponseDTO, ResponseDTO } from "shared/logic/types/ResponseDTO";
import BrandsQuery from "shared/logic/types/Brands/BrandsQuery";
import Brand, { CreateBrandDTO, PatchBrandDTO } from "shared/logic/types/Brands/Brand";
import serializeArrayQueryParam from "shared/logic/utils/serializeArrayQueryParam";
import { ENVIRONMENT, getEnvironment } from "shared/logic/utils/getEnvironment";
import { AccountType } from "shared/logic/types/AccountType";

function getBrandsByQuery(brandsQuery?: BrandsQuery): Promise<ResponseDTO<Brand[]>> {
  return axiosInstance.get("/brands", {
    params: {
      ...brandsQuery,
      include: serializeArrayQueryParam(brandsQuery?.include),
    },
  });
}

const patchBrand = (
  brandId: string,
  newBrandData: Partial<PatchBrandDTO>,
): Promise<BrandResponseDTO> => axiosInstance.patch(`/brands/${brandId}`, newBrandData);

export interface UpdateBrandLogoProps {
  logo: File; // image/png
  brandId: string;
}

const updateBrandLogo = ({ logo, brandId }: UpdateBrandLogoProps): Promise<BrandResponseDTO> =>
  axiosInstance.patch(`/brands/${brandId}/logo`, logo, { headers: { "Content-Type": logo.type } });

const postNewBrand = (newBrandData: Partial<CreateBrandDTO>): Promise<{ data: Brand }> =>
  axiosInstance.post(`/brands`, {
    description: "",
    ecommercePlatform: "SHOPIFY",
    accountType:
      getEnvironment() === ENVIRONMENT.PRODUCTION ? AccountType.PRODUCTION : AccountType.TEST,
    ...newBrandData,
  });

const deleteBrand = (brandId: string): Promise<Brand> => axiosInstance.delete(`/brands/${brandId}`);

export default {
  getBrandsByQuery,
  patchBrand,
  updateBrandLogo,
  postNewBrand,
  deleteBrand,
};
