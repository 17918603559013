import {
  Accordion,
  accordionClasses,
  AccordionSummary,
  accordionSummaryClasses,
  Box,
  Button,
  ButtonProps,
  css,
  styled,
  SvgIconProps,
} from "@mui/material";
import { ChevronRight, FilterAlt } from "@mui/icons-material";
import { TextBase, TextBaseProps } from "shared/components/base/TextBase/TextBase";

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ShowItemsButton = styled((props: ButtonProps) => (
  <Button variant="contained" color="tertiary" {...props} fullWidth />
))(
  ({ theme }) => css`
    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const FilterButton = styled((props: ButtonProps) => <Button size="small" {...props} />)(
  // eslint-disable-next-line sonarjs/no-identical-functions
  ({ theme }) => css`
    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledDividerWrapper = styled(Box)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(3)};
  `,
);

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;

  & .${accordionClasses.expanded} {
    min-height: 0;
  }
`;

export const StyledSummary = styled(AccordionSummary)`
  padding: 0;

  & .${accordionSummaryClasses.expanded} {
    margin: 12px 0;
  }
`;

export const FilterLabel = (props: TextBaseProps) => <TextBase {...props} variant="subtitle1" />;

export const FilterIcon = (props: SvgIconProps) => <FilterAlt {...props} color="tertiary" />;

export const ShowMoreIcon = (props: SvgIconProps) => <ChevronRight {...props} color="tertiary" />;

export const FilterLabelName = (props: TextBaseProps) => (
  <TextBase {...props} variant="subtitle1" />
);

export const StyledForm = styled("form")``;
export const ButtonWrapper = styled(Box)``;
