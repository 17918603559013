import { RootState } from "shared/logic/store/rootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import { aggregatePartnershipsHOF } from "shared/logic/store/features/viewsSlices/partnerUser/aggregatePartnershipsHOF";
import { getPartnerships } from "shared/logic/store/features/resourceSlices/partnerships/selectors";
import { getBrands } from "shared/logic/store/features/resourceSlices/brands/selectors";
import { getPartneredProductsAggregatedByPartnershipId } from "shared/logic/store/features/resourceSlices/partneredProducts/selectors";
import { getPartners } from "shared/logic/store/features/resourceSlices/partners/selectors";

const getDashboardSlice = (state: RootState) => state.partner.dashboardViewSlice;

const getDashboardSliceStatus = createSelector(
  [getDashboardSlice],
  ({ dashboardSliceStatus }) => dashboardSliceStatus,
);

const getPartnerId = createSelector([getDashboardSlice], ({ partnerId }) => partnerId);

const getPartner = createSelector([getPartners, getPartnerId], (partners, partnerId) =>
  partnerId ? partners[partnerId] : null,
);

const getIsDashboardDataFetched = createSelector(
  [getDashboardSliceStatus],
  (dashboardSliceStatus) => dashboardSliceStatus === SliceStatus.FULFILLED,
);

const getPartnershipsAggregated = createSelector(
  [getPartnerships, getBrands, getPartneredProductsAggregatedByPartnershipId],
  (partnershipsMap, brandsMap, partneredProductsAggregatedByPartnershipId) =>
    Object.values(partnershipsMap).map(
      aggregatePartnershipsHOF(brandsMap, partneredProductsAggregatedByPartnershipId),
    ),
);

export default {
  getDashboardSlice,
  getDashboardSliceStatus,
  getPartnerId,
  getPartner,
  getIsDashboardDataFetched,
  getPartnershipsAggregated,
};
