import { FC } from "react";
import { ListItem, ListItemProps } from "shared/components/DashboardList/ListContent/ListItem";
import { ItemInterfaces } from "shared/components/DashboardList/useDashboardList";
import { StyledListWrapper } from "./ListContent.styled";

interface ListContentProps {
  items: ItemInterfaces[];
  parseProps: (item: ItemInterfaces) => ListItemProps;
}

export const ListContent: FC<ListContentProps> = ({ items, parseProps }) => (
  <StyledListWrapper>
    {items.map((item) => (
      <ListItem key={item.id} {...parseProps(item)} />
    ))}
  </StyledListWrapper>
);
