import { createAsyncThunk } from "@reduxjs/toolkit";
import PartnershipsQuery from "shared/logic/types/Partnerships/PartnershipsQuery";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { getBaseDataForPartnerUser } from "shared/logic/store/features/viewsSlices/partnerUser/getBaseDataForPartnerUser";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

export const fetchResourcesForPartnershipsView = createAsyncThunk(
  storePrefixes.partnerUser.partnershipsSlice.fetch,
  async (partnershipQuery: PartnershipsQuery, { dispatch }) => {
    try {
      const { partnershipsMap, brandsMap, filteredPartneredProductsMap } =
        await getBaseDataForPartnerUser({
          partnershipsQuery: partnershipQuery,
          partneredProductsQuery: {
            partnerId: partnershipQuery.partnerId,
          },
        });

      return {
        brands: brandsMap,
        partnerships: partnershipsMap,
        partneredProducts: filteredPartneredProductsMap,
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
    }
    return {
      partnershipsAggregated: [],
      allProductsAggregated: [],
      productsAggregated: [],
    };
  },
);
