import { Filters } from "shared/logic/types/Filters";
import isEmpty from "lodash-es/isEmpty";
import { matchFilters } from "shared/logic/store/filters/matchFilters";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";

export function filterProductsAggregated(
  products: ProductAggregated[],
  filters?: Filters<ProductAggregated>,
): ProductAggregated[] {
  if (!filters || isEmpty(filters)) {
    return products;
  }

  return products.filter((product) => matchFilters({ object: product, filters }));
}
