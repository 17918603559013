import { useCallback } from "react";
import { useAuth } from "shared/logic/hooks/auth/useAuth";
import { InternalLogin } from "shared/components/auth/AuthContext";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import { useDispatch } from "react-redux";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";

export const useLoginPage = () => {
  const dispatch = useDispatch();
  const { login } = useAuth();

  const onLogin = useCallback(async () => {
    try {
      await (login as InternalLogin)();
    } catch (e) {
      ConsoleLog(e);
      dispatch(setSnackbar({ open: true, message: "snackbar.signInError" }));
    }
  }, [login]);

  return {
    onLogin,
  };
};
