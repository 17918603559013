import { styled, Box, css, Button } from "@mui/material";
import { OverflowColumnFlex } from "shared/components/OverflowColumnFlex";

export const StepTitleSection = styled(Box)``;

export const StyledWrapper = styled(OverflowColumnFlex)(
  ({ theme }) => css`
    max-width: 552px;

    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);

export const ButtonsWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-end;

    & > * + * {
      margin-left: ${theme.spacing(2)};
    }
  `,
);

export const BackButton = styled(Button)(
  ({ theme }) => css`
    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const ContinueButton = styled(Button)``;
