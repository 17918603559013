import { Image } from "shared/components/TableComponents/Styles";
import { Typography } from "@mui/material";
import { FC } from "react";
import {
  StyledInfoWrapper,
  StyledWrapper,
  StyledDetailsWrapper,
} from "shared/components/ProductInfo/ProductInfo.styled";

export interface ProductInfoProps {
  name: string;
  imageUrl: string;
  variantCount?: number;
  brandName?: string;
  commissionPercent?: number;
}

export const ProductInfo: FC<ProductInfoProps> = ({
  name,
  imageUrl,
  brandName,
  variantCount,
  commissionPercent,
}) => (
  <StyledWrapper>
    <Image src={imageUrl} variant="rounded" />
    <StyledInfoWrapper>
      <Typography variant="subtitle1">{name}</Typography>
      <StyledDetailsWrapper>
        {variantCount && (
          <Typography variant="caption">
            {variantCount} Variant{variantCount > 1 && "s"}
          </Typography>
        )}
        {brandName && <Typography variant="caption">{brandName}</Typography>}
        {commissionPercent && (
          <Typography variant="caption">{commissionPercent}% Commission</Typography>
        )}
      </StyledDetailsWrapper>
    </StyledInfoWrapper>
  </StyledWrapper>
);
