import { Typography, TypographyProps, styled } from "@mui/material";
import { memo, FC } from "react";
import { TFunction } from "react-i18next";
import { TOptions } from "i18next";
import { useTranslationWithMarkdown } from "shared/logic/hooks/translations/useTranslationWithMarkdown";
// i18n guard
export type TextContentPath = Parameters<TFunction<"translation">>[0];
export type TFunctionArgs = Parameters<TFunction<"translation">>;

export interface TextBaseProps extends TypographyProps {
  content?: TextContentPath;
  tParams?: TOptions;
  component?: string;
}

export const createInnerHTML = (val: string) => ({
  __html: val,
});

const TextBaseComponent: FC<TextBaseProps> = ({ content, tParams, children, ...materialProps }) => {
  const { t } = useTranslationWithMarkdown();

  return content ? (
    <Typography
      {...materialProps}
      dangerouslySetInnerHTML={createInnerHTML(t(content, "", tParams))}
    />
  ) : (
    <Typography {...materialProps}>{children}</Typography>
  );
};

const TextBaseMemoized = memo(TextBaseComponent) as typeof TextBaseComponent;
export const TextBase = styled(TextBaseMemoized)`
  text-transform: none;
`;
