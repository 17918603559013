import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import { Product } from "shared/logic/types/Products/Product";
import Brand from "shared/logic/types/Brands/Brand";
import Partnership from "shared/logic/types/Partnerships/Partnership";
import { RootState } from "shared/logic/store/rootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import { getPartners } from "shared/logic/store/features/resourceSlices/partners/selectors";
import { getProductsAggregatedMap } from "shared/logic/store/features/resourceSlices/partneredProducts/selectors";
import isEmpty from "lodash-es/isEmpty";
import { filterProductsAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/utils/filterProductsAggregated";

// TODO MOVE IT TO TYPES
export interface ProductAggregated extends PartneredProduct, Product {
  brand: Brand;
  partnership: Partnership;
}

const getProductsViewSlice = (state: RootState) => state.partner.productsViewSlice;

const getProductsAggregatedIds = createSelector(
  [getProductsViewSlice],
  ({ productsAggregatedIds }) => productsAggregatedIds,
);

const getPartnerId = createSelector([getProductsViewSlice], ({ partnerId }) => partnerId);

const getProducts = createSelector(
  [getProductsAggregatedIds, getProductsAggregatedMap],
  (productsIds, products) => (products ? productsIds.map((id) => products[id]) : null),
);

const getProductsViewSliceStatus = createSelector(
  [getProductsViewSlice],
  ({ productsViewSliceStatus }) => productsViewSliceStatus,
);

const getIsProductsLoading = createSelector(
  [getProductsViewSliceStatus],
  (productsViewSliceStatus) => productsViewSliceStatus === SliceStatus.PENDING,
);

const getPartner = createSelector([getPartners, getPartnerId], (partners, partnerId) =>
  partnerId ? partners[partnerId] : null,
);

const getGetTagProduct = createSelector(
  [getProductsViewSlice],
  ({ getTagProduct }) => getTagProduct,
);

const getTagDrawerOpen = createSelector(
  [getProductsViewSlice],
  ({ isGetTagDrawerOpen }) => isGetTagDrawerOpen,
);

const getFilters = createSelector([getProductsViewSlice], ({ filters }) => filters);
const getSearch = createSelector([getProductsViewSlice], ({ search }) => search);

const getPartnerProducts = createSelector(
  [getProducts, getSearch, getFilters],
  (productsAggregated, search, filters): ProductAggregated[] => {
    if (!productsAggregated || isEmpty(productsAggregated)) {
      return [];
    }

    const filteredBySearch = filterProductsAggregated(productsAggregated, search);
    return filterProductsAggregated(filteredBySearch, filters);
  },
);

export default {
  getProductsViewSlice,
  getProductsAggregatedIds,
  getPartnerId,
  getProducts,
  getProductsViewSliceStatus,
  getIsProductsLoading,
  getPartner,
  getGetTagProduct,
  getTagDrawerOpen,
  getPartnerProducts,
};
