import { ProductInfo } from "shared/components/ProductInfo";
import { FC } from "react";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { useProductRevocationDialog } from "shared/components/Dialogs/ProductRevocationDialog/useProductRevocationDialog";
import {
  ConfirmButton,
  StyledContentWrapper,
  SpaceBetweenWrapper,
} from "shared/components/Dialogs/ProductRevocationDialog/ProductRevokeDialog.styled";
import { Product } from "shared/logic/types/Products/Product";
import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import { closeDialog } from "shared/logic/store/features/resourceSlices/dialog/dialogSlice";

export interface ProductRevocationDialogProps {
  product: Product;
  partneredProduct: PartneredProduct;
}

export const ProductRevocationDialog: FC<ProductRevocationDialogProps> = ({
  product,
  partneredProduct,
}) => {
  const { dispatch, onConfirmClick } = useProductRevocationDialog({ partneredProduct });
  if (!product) {
    dispatch(closeDialog());
    return <></>;
  }
  return (
    <StyledContentWrapper>
      <ProductInfo
        name={product.name}
        imageUrl={product.primaryImageUrl}
        variantCount={product.productVariants.length}
      />
      <SpaceBetweenWrapper>
        <TextBase variant="tableLabel" color="text.disabled" content="products.revokeAccess" />
        <ConfirmButton variant="contained" color="tertiary" onClick={onConfirmClick}>
          <TextBase variant="subtitle1" content="navigation.confirm" />
        </ConfirmButton>
      </SpaceBetweenWrapper>
    </StyledContentWrapper>
  );
};
