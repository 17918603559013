import { Avatar, Box, css, styled } from "@mui/material";

export const StyledIntegrationBox = styled(Box)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.border};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: ${theme.shape.borderRadius}px;
    padding: ${theme.spacing(2)};
  `,
);

export const NameAndLogoWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > * + * {
      margin-left: ${theme.spacing(2)};
      justify-content: center;
    }
  `,
);

export const StyledLogo = styled(Avatar)`
  height: 48px;
  width: 48px;
`;

export const StyledNameBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledIntegrationsWrapper = styled(Box)(
  ({ theme }) => css`
    max-width: 552px;
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);
