import { RootState } from "shared/logic/store/rootReducer";
import { createSelector } from "@reduxjs/toolkit";

const getSettingsViewSlice = (state: RootState) => state.brand.settingsViewSlice;

const getSettingsUpdateStatus = createSelector(
  [getSettingsViewSlice],
  ({ settingsViewSliceEditThunkStatus }) => settingsViewSliceEditThunkStatus,
);

const getSettingsViewSliceStatus = createSelector(
  [getSettingsViewSlice],
  ({ settingsViewSliceStatus }) => settingsViewSliceStatus,
);

export default {
  getSettingsViewSlice,
  getSettingsUpdateStatus,
  getSettingsViewSliceStatus,
};
