import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import {
  InputBaseProps,
  LocalizedInput,
} from "shared/components/base/LocalizedInput/LocalizedInput";
import { FC, forwardRef } from "react";
import { Description, Adornment } from "shared/components/CompleteInput/CompleteInput.styled";
import { Box, InputAdornment } from "@mui/material";

export interface CompleteInputProps extends InputBaseProps {
  description?: TextContentPath;
  startAdornment?: string;
}

export const CompleteInput: FC<CompleteInputProps> = forwardRef(
  ({ description, label, placeholder, startAdornment, ...materialProps }, ref) => (
    <Box>
      <LocalizedInput
        ref={ref}
        placeholder={placeholder}
        label={label}
        variant="outlined"
        color="tertiary"
        fullWidth
        InputProps={
          startAdornment
            ? {
                startAdornment: (
                  <InputAdornment position="start">
                    <Adornment>{startAdornment}</Adornment>
                  </InputAdornment>
                ),
              }
            : undefined
        }
        {...materialProps}
      />
      {description && <Description content={description} />}
    </Box>
  ),
);
