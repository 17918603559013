import { FC } from "react";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { UserSettings } from "shared/components/SettingsComponents/UserSettings";
import { SettingsTabs } from "shared/components/SettingsComponents/SettingsWrapper/useSettingsWrapper";

export interface TabContentProps {
  tabToDisplay: SettingsTabs;
}

export const TabContent: FC<TabContentProps> = ({ tabToDisplay, children }) => {
  switch (tabToDisplay) {
    case SettingsTabs.ORGANIZATION:
      return <>{children}</>;
    case SettingsTabs.USER_PROFILE:
      return <UserSettings />;
    default:
      return <FullScreenLoader />;
  }
};
