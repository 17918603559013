import { styled, Box, css } from "@mui/material";

export const StyledDetailsWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    color: ${theme.palette.text.disabled};
    align-items: center;

    & > * + *::before {
      content: "·";
      margin: ${theme.spacing(0, 1)};
    }
  `,
);

export const StyledRow = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    min-height: 80px;
    height: 80px;

    & > * + * {
      margin-left: ${theme.spacing(2)};
    }
  `,
);

export const StyledInfoWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid ${theme.palette.border};
    white-space: nowrap;
  `,
);
