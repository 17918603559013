import { FilterAlt } from "@mui/icons-material";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { Divider } from "@mui/material";
import { FC } from "react";
import { FiltersComponentProps } from "shared/components/TableComponents/FiltersContainer/types";
import {
  ButtonWrapper,
  FilterButton,
  FilterIcon,
  FilterLabel,
  ShowItemsButton,
  StyledBox,
  StyledDividerWrapper,
  StyledForm,
} from "./FilterByCategory.styled";
import { FilterDrawerWrapper } from "../FilterDrawer";
import { useFilterByCategory } from "./useFilterByCategory";

export interface FilterByCategoryProps {
  FiltersComponent: FC<FiltersComponentProps>; // TODO -- change to proper type during integration
}

export const FilterByCategory: FC<FilterByCategoryProps> = ({ FiltersComponent }) => {
  const { toggleOpenDrawer, isOpenDrawer, handleSubmit, register, onSubmitFilters } =
    useFilterByCategory();
  return (
    <>
      <FilterButton onClick={toggleOpenDrawer}>
        <FilterIcon />
        <FilterLabel content="filterDrawer.title" />
      </FilterButton>
      <FilterDrawerWrapper
        isOpen={isOpenDrawer}
        toggleOpen={toggleOpenDrawer}
        title="filterDrawer.title"
      >
        <StyledBox>
          <StyledForm onSubmit={handleSubmit(onSubmitFilters)} id="filter-details-form">
            <FiltersComponent register={register} />
          </StyledForm>
          <ButtonWrapper>
            <StyledDividerWrapper>
              <Divider />
            </StyledDividerWrapper>
            <ShowItemsButton form="filter-details-form" type="submit">
              <TextBase content="filterDrawer.showItem" />
              <FilterAlt />
            </ShowItemsButton>
          </ButtonWrapper>
        </StyledBox>
      </FilterDrawerWrapper>
    </>
  );
};
