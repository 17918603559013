import { FC } from "react";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import {
  StyledBottomWrapper,
  StyledContentWrapper,
  AcceptButton,
} from "shared/components/Dialogs/RequestCommissionDialog/RequestCommissionDialog.styled";
import useCommissionDialog from "./useCommisiionDialog";

export const RequestCommissionDialog: FC = () => {
  const { closeDialogAction } = useCommissionDialog();
  return (
    <StyledContentWrapper>
      <StyledBottomWrapper>
        <TextBase variant="body1" content="discoverPartners.requestCommissionView.title" />
        <AcceptButton color="tertiary" variant="contained" onClick={closeDialogAction}>
          <TextBase
            variant="subtitle1"
            content="discoverPartners.requestCommissionView.acceptButton"
          />
        </AcceptButton>
      </StyledBottomWrapper>
    </StyledContentWrapper>
  );
};
