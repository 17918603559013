import { Partner } from "shared/logic/types/Partners/Partner";
import { useDomainActions } from "views/partnerViews/Setup/StepDrawer/useDomainActions";

export const useDomainEdited = (partner?: Partner) => {
  const { onSaveClick, domainToSave, onBackClick } = useDomainActions({
    partner,
    isEditMode: true,
  });
  return {
    onSaveClick,
    onBackClick,
    domainToSave,
  };
};
