export default interface Partnership {
  id: string;
  brandId: string;
  partnerId: string;
  commissionPercent: number;
  customerEmailSharingEnabled: boolean;
}

export interface CreatePartnershipDTO {
  brandId?: string;
  partnerId?: string;
  commissionPercent: number;
}

export enum RequestPartnershipType {
  BRAND_TO_CREATOR = "BRANDTOCREATOR",
  CREATOR_TO_BRAND = "CREATORTOBRAND",
}

export interface RequestPartnershipDTO {
  recipient_org_id: string;
  request_type: RequestPartnershipType;
  commissionPercent: number;
  nonRecurringCharge: number;
  requesting_user_id?: string;
}
