import Partnership from "shared/logic/types/Partnerships/Partnership";
import uniq from "lodash-es/uniq";
import difference from "lodash-es/difference";

export function filterPartnerIdsByPartnerships(
  partnerIds: string[],
  partnerships: Partnership[],
): string[] {
  const partnersToOmit = uniq(partnerships.map((partnership) => partnership.partnerId));
  return difference(partnerIds, partnersToOmit);
}
