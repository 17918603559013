import { FC } from "react";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { useIntegrationDetails } from "views/brandViews/Setup/IntegrationDetails/useIntegrationDetails";
import {
  StyledContentWrapper,
  StyledListBox,
  StyledOrderedList,
  StyledButtonBox,
  ContinueButton,
  StyledInputBox,
} from "views/brandViews/Setup/SharedStyles.styled";
import { isObjectEmpty } from "shared/logic/utils/isObjectEmpty";
import { BrandNameInput } from "views/brandViews/Setup/IntegrationDetails/IntegrationDetails.styled";

export const IntegrationDetails: FC = () => {
  const { handleSubmit, onSubmit, register, errors } = useIntegrationDetails();
  return (
    <StyledContentWrapper>
      <StyledInputBox>
        <TextBase variant="body1" content="setup.integrationDetails.shopifyLabel" />
        <form onSubmit={handleSubmit(onSubmit)} id="brand-name-form">
          <BrandNameInput
            label="setup.integrationDetails.shopifyInputLabel"
            variant="outlined"
            fullWidth
            required
            color="tertiary"
            {...register("brandName", {
              required: true,
              pattern: {
                message: "Your name should consist of alphanumeric characters",
                value: /^[a-z0-9][a-z0-9-]*$/i,
              },
            })}
            error={!isObjectEmpty(errors)}
            helperText={errors.brandName?.message}
          />
        </form>
      </StyledInputBox>
      <StyledListBox>
        <TextBase
          variant="subtitle1"
          color="text.secondary"
          content="setup.integrationDetails.listTitle"
        />
        <StyledOrderedList>
          <li>
            <TextBase
              color="text.secondary"
              variant="body1"
              content="setup.integrationDetails.listSignInStep"
            />
          </li>
          <li>
            <TextBase
              color="text.secondary"
              variant="body1"
              content="setup.integrationDetails.listApproveStep"
            />
          </li>
        </StyledOrderedList>
      </StyledListBox>
      <StyledButtonBox>
        <ContinueButton variant="contained" color="tertiary" form="brand-name-form" type="submit">
          <TextBase variant="subtitle1" content="setup.integrationDetails.shopifyRedirect" />
        </ContinueButton>
      </StyledButtonBox>
    </StyledContentWrapper>
  );
};
