import { fetchResourcesForSetupView } from "./fetchResourcesForSetupView";
import { editPartnerSetup } from "./editPartnerSetup";
import selectors from "./selectors";
import setupViewSlice from "./setupViewSlice";

export default {
  ...selectors,
  fetchResources: fetchResourcesForSetupView,
  editPartnerSetup,
  slice: setupViewSlice,
};
