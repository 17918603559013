import { css, IconButton, styled, TableCell } from "@mui/material";
import { Edit } from "@mui/icons-material";

export const StyledIconButtonGray = styled(IconButton)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${theme.palette.background.onSurface};
    color: ${theme.palette.tertiary};

    &:hover {
      background-color: ${theme.palette.background.onSurfaceHover};
    }
  `,
);

export const IconButtonsCell = styled(TableCell)(
  ({ theme }) => css`
    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledEditIcon = styled(Edit)(
  ({ theme }) => css`
    color: ${theme.palette.tertiary};
  `,
);
