import { styled, Box, css, AvatarProps } from "@mui/material";
import { OverflowColumnFlex } from "shared/components/OverflowColumnFlex";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";
import { Image } from "shared/components/TableComponents/Styles";

export const PolicyDetails = styled(Box)(
  ({ theme }) => css`
    display: flex;
    & > * + * {
      margin-left: ${theme.spacing(2)};
    }
  `,
);

export const Wrapper = styled(Box)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    display: flex;
    padding: ${theme.spacing(2)};
    background-color: ${theme.palette.background.onSurface};
    justify-content: space-between;
    align-items: center;
  `,
);

export const InfoWrapper = styled(OverflowColumnFlex)`
  justify-content: center;
`;

export const Details = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    & > * + *::before {
      content: "·";
      margin: ${theme.spacing(0, 1)};
    }
  `,
);

export const Name = (props: TextBaseProps) => <TextBase variant="subtitle1" {...props} />;
export const Role = (props: TextBaseProps) => <TextBase variant="subtitle1" {...props} />;
export const Commission = (props: TextBaseProps) => <TextBase variant="subtitle1" {...props} />;
export const MissingCommission = (props: TextBaseProps) => <TextBase variant="body1" {...props} />;
export const Avatar = (props: AvatarProps) => <Image variant="rounded" {...props} />;

export const Description = (props: TextBaseProps) => (
  <TextBase variant="caption" color="text.disabled" {...props} />
);
