import { Partner } from "shared/logic/types/Partners/Partner";
import { useMemo } from "react";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { useDispatch } from "react-redux";
import { SetupStep } from "views/partnerViews/Setup/StepDrawer/index";
import { storeAPI } from "views/partnerViews/storeAPI";

interface UseDomainActionProps {
  partner?: Partner;
  isEditMode?: boolean;
}

export const useDomainActions = ({ partner, isEditMode }: UseDomainActionProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const domainToSave = useMemo((): string => {
    if (typeof searchParams.domain === "string") {
      return searchParams.domain;
    }
    return partner?.website ?? "";
  }, [searchParams.domain, partner?.website]);
  const onBackClick = () => {
    setSearchParams({ step: SetupStep.DOMAIN_DETAILS, edit: isEditMode });
  };
  const onSaveClick = () => {
    if (!partner) {
      dispatch(setSnackbar({ open: true, message: "snackbar.genericError" }));
      return;
    }
    dispatch(
      storeAPI.setupView.editPartnerSetup({
        partnerId: partner.id,
        newSetupData: { ...partner, website: domainToSave },
      }),
    );
    setSearchParams({});
  };
  return {
    onSaveClick,
    onBackClick,
    searchParams,
    domainToSave,
  };
};
