import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import { createSlice } from "@reduxjs/toolkit";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { inviteToPlatform } from "shared/logic/store/features/viewsSlices/shared/inviteToPlatform/inviteToPlatform";

interface InviteToPlatformState {
  sliceStatus: SliceStatus;
}

const initialState: InviteToPlatformState = {
  sliceStatus: SliceStatus.IDLE,
};

const inviteViewSlice = createSlice({
  name: storePrefixes.shared.inviteToPlatformSlice.this,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(inviteToPlatform.pending, (state) => {
        state.sliceStatus = SliceStatus.PENDING;
      })
      .addCase(inviteToPlatform.fulfilled, (state) => {
        state.sliceStatus = SliceStatus.FULFILLED;
      })
      .addCase(inviteToPlatform.rejected, (state) => {
        state.sliceStatus = SliceStatus.REJECTED;
      });
  },
});

export default inviteViewSlice.reducer;
