import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import { Product } from "shared/logic/types/Products/Product";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import brandUser from "shared/logic/store/features/viewsSlices/brandUser";
import partnerUser from "../../viewsSlices/partnerUser";

interface ProductsState {
  products: IdAndEntityPair<Product>;
  productIdsForBrands: IdAndEntityPair<string[]>;
}

export const initialState: ProductsState = {
  products: {},
  productIdsForBrands: {},
};

const productsSlice = createSlice({
  name: storePrefixes.resourceSlices.products,
  initialState,
  reducers: {
    // TODO ADD RESET ACTION
  },
  extraReducers: (builder) => {
    builder
      // DISCOVER BRAND - BRAND INVENTORY VIEW
      .addCase(
        partnerUser.discoverView.exploreProductsView.fetchResources.fulfilled,
        (state, action) => {
          state.productIdsForBrands = {
            ...state.productIdsForBrands,
            [action.payload.brandId]: Object.keys(action.payload.products),
          };
          state.products = {
            ...state.products,
            ...action.payload.products,
          };
        },
      )
      // UPDATE PRODUCTS
      .addMatcher(
        isAnyOf(
          partnerUser.productsView.fetchResources.fulfilled,
          partnerUser.dashboardView.fetchResources.fulfilled,
          partnerUser.discoverView.exploreProductsView.fetchResources.fulfilled,
          brandUser.assignView.fetchResources.fulfilled,
          brandUser.dashboardView.fetchResources.fulfilled,
          brandUser.productsView.fetchResources.fulfilled,
        ),
        (state, action) => {
          state.products = {
            ...state.products,
            ...action.payload.products,
          };
        },
      );
  },
});

export default productsSlice.reducer;
