import { matchPath, useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { useSelector } from "react-redux";
import { getUserPolicies } from "shared/logic/store/features/resourceSlices/user/selectors";

export const useLayout = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const userPolicies = useSelector(getUserPolicies);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const noNavigationRoutes = [
    RouteEnum.HOME,
    RouteEnum.LOGIN,
    RouteEnum.REGISTER,
    RouteEnum.RESET_PASSWORD,
    RouteEnum.LOGOUT,
    RouteEnum.NEW_PASSWORD,
    RouteEnum.FIRST_LOGIN,
  ];
  const backButtonRoutes = [
    { origin: RouteEnum.PARTNERSHIPS, destination: RouteEnum.DASHBOARD },
    { origin: RouteEnum.PRODUCTS, destination: RouteEnum.DASHBOARD },
    { origin: RouteEnum.DISCOVER, destination: RouteEnum.DASHBOARD },
    {
      origin: `${RouteEnum.DISCOVER}/:id`,
      destination: RouteEnum.DISCOVER,
    },
    { origin: RouteEnum.SETUP, destination: RouteEnum.DASHBOARD },
    { origin: RouteEnum.ASSIGN_PRODUCT, destination: RouteEnum.PRODUCTS },
    { origin: RouteEnum.ADD_ACCOUNT, destination: RouteEnum.DASHBOARD },
    { origin: RouteEnum.ASSIGN_PARTNER, destination: RouteEnum.PARTNERSHIPS },
    { origin: RouteEnum.INVITE_TO_PLATFORM, destination: RouteEnum.DASHBOARD },
    { origin: RouteEnum.SETTINGS, destination: RouteEnum.DASHBOARD },
  ];

  const shouldDisplayNavigation =
    userPolicies &&
    !matchPath(pathname, {
      path: noNavigationRoutes,
      exact: true,
    });
  const shouldDisplaySidebar = shouldDisplayNavigation && isSidebarOpen;
  const shouldDisplayBackBtn =
    shouldDisplayNavigation &&
    matchPath(pathname, {
      path: backButtonRoutes.map((route) => route.origin),
      exact: true,
    });
  const isBackgroundColor = pathname === RouteEnum.DASHBOARD;

  const onBackButtonClick = () => {
    backButtonRoutes.forEach((route) => {
      if (matchPath(pathname, { path: route.origin, exact: true })) {
        history.push(route.destination);
      }
    });
  };

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  return {
    toggleSidebar,
    isBackgroundColor,
    shouldDisplayNavigation,
    shouldDisplayBackBtn,
    shouldDisplaySidebar,
    isMobileView,
    setSidebarOpen,
    onBackButtonClick,
  };
};
