import {
  SpaceBetweenWrapper,
  StepDetails,
  Content,
  NavigationContent,
  ContentSection,
} from "views/partnerViews/Setup/StepDrawer/StepDrawer.styled";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { LocalizedInput } from "shared/components/base/LocalizedInput/LocalizedInput";
import { domainRegex } from "shared/logic/utils/url/urlRegex";
import { isObjectEmpty } from "shared/logic/utils/isObjectEmpty";
import { InputAdornment } from "@mui/material";
import { StyledForm, DomainInput, InputDescription, ContinueButton } from "./DomainDetails.styled";
import { useDomainDetails, DomainForm } from "./useDomainDetails";

export const DomainDetails = () => {
  const {
    onSubmit,
    handleSubmit,
    register,
    errors,
    isEditMode,
    handleConfirmDomainValidation,
    domainInputErrorMessage,
  } = useDomainDetails();
  return (
    <SpaceBetweenWrapper>
      <ContentSection>
        <StepDetails>
          {!isEditMode && (
            <TextBase
              variant="caption"
              color="text.disabled"
              content="utility.step"
              tParams={{ current: "1", max: "2" }}
            />
          )}
          <TextBase variant="h3" content="setup.partner.domainDetails" />
        </StepDetails>
        <Content>
          <StyledForm id="domain-details-form" onSubmit={handleSubmit(onSubmit)}>
            <TextBase variant="body1" content="setup.partner.domainTitle" />
            <DomainInput>
              <LocalizedInput
                label="setup.partner.tableHeader"
                placeholder="utility.exampleDomain"
                variant="outlined"
                fullWidth
                required
                color="tertiary"
                {...register(DomainForm.DOMAIN, {
                  required: true,
                  pattern: {
                    value: domainRegex,
                    message: domainInputErrorMessage,
                  },
                })}
                error={!isObjectEmpty(errors)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TextBase
                        variant="subtitle1"
                        color="text.secondary"
                        content="navigation.https"
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <InputDescription variant="caption2" content="setup.partner.domainHelperText" />
            </DomainInput>
            <LocalizedInput
              label="setup.partner.confirmDomain"
              placeholder="utility.exampleDomain"
              variant="outlined"
              fullWidth
              required
              color="tertiary"
              {...register(DomainForm.CONFIRM_DOMAIN, {
                required: true,
                validate: handleConfirmDomainValidation,
              })}
              error={!isObjectEmpty(errors)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TextBase
                      variant="subtitle1"
                      color="text.secondary"
                      content="navigation.https"
                    />
                  </InputAdornment>
                ),
              }}
            />
          </StyledForm>
        </Content>
      </ContentSection>
      <NavigationContent>
        <TextBase
          variant="tableLabel"
          color="text.disabled"
          content="setup.partner.saveAndContinue"
        />
        <ContinueButton
          color="tertiary"
          variant="contained"
          type="submit"
          form="domain-details-form"
        >
          <TextBase variant="subtitle1" content="navigation.continue" />
        </ContinueButton>
      </NavigationContent>
    </SpaceBetweenWrapper>
  );
};
