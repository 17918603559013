import { FC } from "react";
import { ProductInfo } from "shared/components/ProductInfo";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import useRejectionAcceptDialog from "shared/components/Dialogs/RejectionAcceptDialog/useRejectionAcceptDialog";
import {
  StyledBottomWrapper,
  StyledWrapper,
} from "shared/components/Dialogs/RejectionAcceptDialog/RejectionAcceptDialog.styled";
import { Button } from "@mui/material";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";

export interface RejectionAcceptDialogProps {
  product?: ProductAggregated;
  rejectionMessage?: string;
}

export const RejectionAcceptDialog: FC<RejectionAcceptDialogProps> = ({
  product,
  rejectionMessage,
}) => {
  const { onCancelClick, onConfirmClick, closeDialogAction } = useRejectionAcceptDialog({
    product,
    rejectionMessage,
  });
  if (!product) {
    closeDialogAction();
    return <></>;
  }
  return (
    <StyledWrapper>
      <ProductInfo
        name={product.name}
        imageUrl={product.primaryImageUrl}
        brandName={product.brand.name}
        variantCount={product.productVariants.length}
        commissionPercent={product.commissionPercent ?? product.partnership.commissionPercent}
      />
      <StyledBottomWrapper>
        <Button color="tertiary" variant="outlined" fullWidth onClick={onCancelClick}>
          <TextBase variant="subtitle1" content="navigation.cancel" />
        </Button>
        <Button color="tertiary" variant="contained" fullWidth onClick={onConfirmClick}>
          <TextBase variant="subtitle1" content="navigation.confirm" />
        </Button>
      </StyledBottomWrapper>
    </StyledWrapper>
  );
};
