import { createSlice } from "@reduxjs/toolkit";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import { updatePartnerDetails } from "shared/logic/store/features/viewsSlices/partnerUser/settings/updatePartnerDetails";
import { editPartnerDomainSettings } from "shared/logic/store/features/viewsSlices/partnerUser/settings/editPartnerDomainSettings";

interface SettingsViewState {
  settingsViewSliceEditThunkStatus: SliceStatus;
  settingsViewSliceEditDomainThunkStatus: SliceStatus;
  settingsViewSliceStatus: SliceStatus;
}

export const initialState: SettingsViewState = {
  settingsViewSliceEditThunkStatus: SliceStatus.IDLE,
  settingsViewSliceEditDomainThunkStatus: SliceStatus.IDLE,
  settingsViewSliceStatus: SliceStatus.IDLE,
};

const settingsViewSlice = createSlice({
  name: storePrefixes.partnerUser.settingsSlice.this,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(editPartnerDomainSettings.pending, (state) => {
        state.settingsViewSliceEditDomainThunkStatus = SliceStatus.PENDING;
      })
      .addCase(editPartnerDomainSettings.fulfilled, (state) => {
        state.settingsViewSliceEditDomainThunkStatus = SliceStatus.FULFILLED;
      })
      .addCase(updatePartnerDetails.pending, (state) => {
        state.settingsViewSliceEditThunkStatus = SliceStatus.PENDING;
      })
      .addCase(updatePartnerDetails.fulfilled, (state) => {
        state.settingsViewSliceEditThunkStatus = SliceStatus.FULFILLED;
      });
  },
});

export default settingsViewSlice.reducer;
