import { styled, Box, css, Divider } from "@mui/material";
import { TextBase } from "shared/components/base/TextBase/TextBase";

export const AccountInfo = styled(Box)``;

export const UserListItem = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(1.5, 0)};

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  `,
);

export const StyledGrayBox = styled(Box)(
  ({ theme }) => css`
    background: ${theme.palette.background.onSurface};
    border-radius: ${theme.shape.borderRadius}px;
    padding: ${theme.spacing(2)};
  `,
);

export const StyledUsersList = styled(StyledGrayBox)``;

export const CapitalizedText = styled(TextBase)`
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const StyledDivider = styled(Divider)`
  height: auto;
`;

export const AccountDetailsSection = styled(StyledGrayBox)(
  ({ theme }) => css`
    display: flex;

    & > * + * {
      margin-left: ${theme.spacing(4)};
    }
  `,
);

export const StyledWrapper = styled(Box)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);
