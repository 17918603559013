import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { storeAPI } from "views/brandViews/storeAPI";

interface OnFormSubmitParams {
  brandName: string;
}

export const useIntegrationDetails = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const onSubmit = async ({ brandName }: OnFormSubmitParams) => {
    dispatch(storeAPI.setupView.oAuthBrandIntegration({ brandName }));
  };
  return {
    handleSubmit,
    register,
    onSubmit,
    errors,
  };
};
