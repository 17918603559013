import { useHistory } from "react-router-dom";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { usdCurrencyString } from "shared/logic/utils/usdCurrencyString";
import { BrandInfoProps } from "../BrandInfo";

export type UseBrandInfoProps = Pick<BrandInfoProps, "flatFeeValue" | "brandId">;

export const useDiscoverBrandItem = ({ flatFeeValue, brandId }: UseBrandInfoProps) => {
  const formattedFeeValue = usdCurrencyString(flatFeeValue ?? 0);
  const history = useHistory();
  const onExploreClick = () => {
    history.push(`${RouteEnum.DISCOVER}/${brandId}`);
  };
  return { formattedFeeValue, onExploreClick };
};
