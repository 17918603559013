import { ViewHeadline } from "shared/components/ViewHeadline";
import { NoIntegrations } from "views/partnerViews/Setup/NoIntegrations";
import { TableBody } from "@mui/material";
import { TableWrapper } from "shared/components/TableComponents/TableWrapper";
import { SetupTableHeader } from "views/partnerViews/Setup/SetupTableHeader";
import { IntegrationItem } from "views/partnerViews/Setup/IntegrationItem";
import { StepDrawer } from "views/partnerViews/Setup/StepDrawer";
import { AddIntegrationButton } from "views/partnerViews/Setup/AddIntegrationButton";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";
import { SpaceBetweenWrapper } from "./Setup.styled";
import useSetup from "./useSetup";

export const Setup = () => {
  const {
    toggleStepDrawer,
    closeDrawer,
    isStepDrawerOpen,
    isDataLoading,
    onAddIntegrationClick,
    partner,
  } = useSetup();

  const content = () => {
    if (isDataLoading) {
      return <FullScreenLoader />;
    }
    if (!partner?.website) {
      return <NoIntegrations onClick={onAddIntegrationClick} />;
    }
    return (
      <SpaceBetweenWrapper>
        <TableWrapper>
          <SetupTableHeader />
          <TableBody>
            <IntegrationItem key={partner.id} partner={partner} toggleDrawer={toggleStepDrawer} />
          </TableBody>
        </TableWrapper>
      </SpaceBetweenWrapper>
    );
  };
  return (
    <StyledViewSpace multiplier={3}>
      <ViewHeadline
        localizedText="setup.title"
        buttonToDisplay={
          <AddIntegrationButton
            onClick={onAddIntegrationClick}
            disabled={!partner || (partner && !!partner?.website)}
          />
        }
      />
      {content()}
      <StepDrawer
        partner={partner}
        isOpen={isStepDrawerOpen}
        toggleOpen={toggleStepDrawer}
        closeDrawer={closeDrawer}
      />
    </StyledViewSpace>
  );
};
