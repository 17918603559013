import { TextContentPath, TextBase } from "shared/components/base/TextBase/TextBase";
import { FC } from "react";
import { WarningAmberRounded } from "@mui/icons-material";
import { Alert } from "shared/components/Alert";

interface MissingInfoAlertProps {
  content: TextContentPath;
}
export const MissingInfoAlert: FC<MissingInfoAlertProps> = ({ content }) => (
  <Alert
    severity="warning"
    sideBorder="left"
    iconComponent={<WarningAmberRounded color="warning" />}
  >
    <TextBase variant="body1" content={content} />
  </Alert>
);
