import { IconButton } from "@mui/material";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { FC } from "react";
import {
  AddAccountFormEnum,
  UsersListUpdateParams,
  NewUserInterface,
} from "views/adminViews/AddAccount/useAddAccount";
import { LocalizedInput } from "shared/components/base/LocalizedInput/LocalizedInput";
import { AddCircle, KeyboardArrowLeft, Close } from "@mui/icons-material";
import {
  ContinueButton,
  ButtonsWrapper,
  BackButton,
} from "views/adminViews/AddAccount/AddAccount.styled";
import { useAddUsers } from "./useAddUsers";
import {
  StyledForm,
  InputsWrapper,
  UserInputWrapper,
  ButtonSpace,
  FormNavigationWrapper,
  AddAnotherUserButton,
  NameInputWrapper,
  StyledDivider,
  UserInputSection,
} from "./AddUsers.styled";

export interface AddUsersProps {
  usersListUpdate: ({ usersToAdd }: UsersListUpdateParams) => void;
  savedUsersList?: NewUserInterface[];
}

export const AddUsers: FC<AddUsersProps> = ({ usersListUpdate, savedUsersList }) => {
  const {
    addUserInput,
    removeUserInput,
    onBackClick,
    fields,
    register,
    handleSubmit,
    onSubmit,
    isValid,
  } = useAddUsers({ usersListUpdate, savedUsersList });
  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)} id="add-users-form">
        <TextBase
          color="text.secondary"
          variant="body1"
          content="addAccount.addUsers.description"
        />
        <InputsWrapper>
          {fields.map((field, index) => (
            <UserInputSection key={field.id}>
              <UserInputWrapper>
                <NameInputWrapper>
                  <LocalizedInput
                    label="addAccount.addUsers.firstNameInputLabel"
                    type="name"
                    fullWidth
                    color="tertiary"
                    required
                    {...register(`${AddAccountFormEnum.USERS_TO_ADD}.${index}.firstName`, {
                      required: true,
                    })}
                  />
                  <LocalizedInput
                    label="addAccount.addUsers.lastNameInputLabel"
                    type="name"
                    fullWidth
                    color="tertiary"
                    required
                    {...register(`${AddAccountFormEnum.USERS_TO_ADD}.${index}.lastName`, {
                      required: true,
                    })}
                  />
                </NameInputWrapper>
                <NameInputWrapper>
                  <LocalizedInput
                    label="addAccount.addUsers.emailInputLabel"
                    type="email"
                    fullWidth
                    color="tertiary"
                    required
                    {...register(`${AddAccountFormEnum.USERS_TO_ADD}.${index}.email`, {
                      required: true,
                    })}
                  />
                </NameInputWrapper>
                {fields.length > 1 && index !== fields.length - 1 && <StyledDivider />}
              </UserInputWrapper>
              <ButtonSpace>
                {fields.length > 1 && (
                  <IconButton color="secondary" onClick={() => removeUserInput(index)}>
                    <Close />
                  </IconButton>
                )}
              </ButtonSpace>
            </UserInputSection>
          ))}
        </InputsWrapper>
        <FormNavigationWrapper>
          <AddAnotherUserButton disabled={!isValid} onClick={addUserInput}>
            <AddCircle color="tertiary" />
            <TextBase variant="subtitle1" content="addAccount.addUsers.addUser" />
          </AddAnotherUserButton>
        </FormNavigationWrapper>
        <ButtonsWrapper>
          <BackButton variant="outlined" color="tertiary" onClick={onBackClick}>
            <KeyboardArrowLeft color="tertiary" />
            <TextBase variant="subtitle1" color="text.secondary" content="navigation.back" />
          </BackButton>
          <ContinueButton
            type="submit"
            variant="contained"
            color="tertiary"
            form="add-users-form"
            disabled={!isValid}
          >
            <TextBase variant="subtitle1" content="navigation.continue" />
          </ContinueButton>
        </ButtonsWrapper>
      </StyledForm>
    </>
  );
};
