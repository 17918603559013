import { FilterPropertyOrUndefined } from "shared/logic/store/filters/types";
import {
  MatchFiltersArgs,
  MatchFiltersReturn,
} from "shared/logic/store/filters/matchFilters/types";
import { PlainObject } from "shared/logic/types/Filters";
import { isArgument } from "shared/logic/store/filters/utils/isArgument";
import { isPrimitiveFilter } from "shared/logic/store/filters/utils/isPrimitiveFilter";
import { isFilters } from "shared/logic/store/filters/utils/isFilters";
import isPlainObject from "lodash-es/isPlainObject";
import { matchArgument } from "shared/logic/store/filters/matchArgument";
import { matchPrimitiveFilter } from "shared/logic/store/filters/matchPrimitiveFilter";

type Obj = PlainObject;

export interface MatchFilterPropertyArgs<O extends Obj> {
  object: O;
  matchFilters: <P extends Obj>(args: MatchFiltersArgs<P>) => MatchFiltersReturn;
  property: string;
  filterProperty: FilterPropertyOrUndefined<O>;
}

type MatchFilterPropertyReturn = boolean;

export function matchFilterProperty<O extends Obj>({
  object,
  matchFilters,
  property,
  filterProperty,
}: MatchFilterPropertyArgs<O>): MatchFilterPropertyReturn {
  // NO FILTER - SKIP - NO NEED TO FILTER BY THIS PROPERTY
  if (!filterProperty) {
    return true;
  }

  // INVALID FILTER - PROPERTY DOES NOT EXIST - DO NOT MATCH
  // falsy property or property not in object
  if (!property || !(property in object)) {
    throw new Error("PROPERTY DOES NOT EXIST");
  }

  const value = object[property];

  // IF ARGUMENT
  if (isArgument<O>(filterProperty)) {
    return matchArgument({ value, argument: filterProperty });
  }

  // IF PRIMITIVE
  if (isPrimitiveFilter<O>(filterProperty)) {
    return matchPrimitiveFilter({ value, primitiveFilter: filterProperty });
  }

  // IF COMPLEX AGAIN
  if (isFilters<O>(filterProperty) && isPlainObject(value)) {
    return matchFilters({ object: value, filters: filterProperty });
  }

  throw new Error("PROPERTY DOES NOT EXIST");
}
