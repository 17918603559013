import { styled, Drawer, css, Box, Divider, IconButton, Toolbar } from "@mui/material";

export const NAVIGATION_WIDTH_DESKTOP = 256;
export const NAVIGATION_WIDTH_MOBILE = 320;

export const StyledNavigation = styled(Drawer)(
  ({ theme }) => css`
    width: ${NAVIGATION_WIDTH_DESKTOP}px;
    flex-shrink: 0;

    & .MuiDrawer-paper {
      width: ${NAVIGATION_WIDTH_DESKTOP}px;
      background-color: ${theme.palette.primary.main};
      box-sizing: border-box;
    }

    ${theme.breakpoints.down("sm")} {
      width: ${NAVIGATION_WIDTH_MOBILE}px;

      & .MuiDrawer-paper {
        width: ${NAVIGATION_WIDTH_MOBILE}px;
      }
    }
  `,
);

export const StyledDivider = styled(Divider)(
  ({ theme }) => css`
    background-color: rgba(255, 255, 255, 0.08);
    margin: ${theme.spacing(1, 0)};
  `,
);

export const NavigationContent = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    & > :last-child {
      flex: 0;
    }
  `,
);

export const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    color: ${theme.palette.common.white};
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: ${theme.shape.borderRadius}px;
    padding: ${theme.spacing(0.5)};
  `,
);

export const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
`;

export const UserAccountWrapper = styled(Box)`
  position: relative;
`;
