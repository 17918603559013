import { styled, Box, css } from "@mui/material";

export const StyledBox = styled(Box)`
  height: 100%;
`;

export const StyledFlexbox = styled(Box)`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

interface ContentBackgroundProps {
  $isBackgroundColor?: boolean;
}

export const StyledContent = styled(Box)<ContentBackgroundProps>(
  ({ theme, $isBackgroundColor }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: ${theme.spacing(3, 4)};
    background-color: ${$isBackgroundColor && theme.palette.background.onSurface};

    & > * + * {
      margin-top: ${theme.spacing(3)};
    }

    ${theme.breakpoints.down("sm")} {
      padding: ${theme.spacing(2, 3)};
    }
  `,
);
