import { Partner, PartnersDomainSetup } from "shared/logic/types/Partners/Partner";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import partnersApi from "shared/logic/api/partnersApi";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

interface EditPartnersSetup {
  newSetupData: PartnersDomainSetup;
  partnerId: string;
}

interface EditPartnersSetupReturnType {
  newPartnerData: Partner;
}

export const editPartnerSetup = createAsyncThunk(
  storePrefixes.partnerUser.setupSlice.editPartnerSetup,
  async (
    { newSetupData, partnerId }: EditPartnersSetup,
    { dispatch },
  ): Promise<EditPartnersSetupReturnType> => {
    try {
      const newPartnerData = await partnersApi.patchPartner(partnerId, newSetupData);
      return {
        newPartnerData: {
          ...newPartnerData.data,
          id: partnerId,
        },
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
