import { styled, Box, css } from "@mui/material";
import { StyledWhiteBox } from "views/partnerViews/Dashboard/Dashboard.styled";

export const ScrollableWhiteBox = styled(StyledWhiteBox)`
  overflow-y: scroll;
`;

export const StyledHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const StyledMissingItems = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    & > * + * {
      margin-top: ${theme.spacing(1)};
    }
  `,
);
