import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "shared/logic/types/User/User";
import { PolicyEntity, UserPolicy } from "shared/logic/types/User/UserPolicy";
import { UserRole } from "shared/components/auth/AuthContext";
import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import Brand from "shared/logic/types/Brands/Brand";
import { Partner } from "shared/logic/types/Partners/Partner";
import brandsApi from "shared/logic/api/brandsApi";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";
import partnersApi from "shared/logic/api/partnersApi";
import { getUserForAuth0 } from "shared/logic/store/features/user/utils/getUserForAuth0";
import { UserStatus } from "shared/logic/types/User/UserStatus";
import { getUserPolicies } from "shared/logic/store/features/user/utils/getUserPolicies";
import { Error500 } from "shared/logic/store/features/user/types";

interface ApiCallForUserInfoParams {
  id?: string;
  email?: string;
}

const getUserInfo = (id: string, email: string | undefined) => getUserForAuth0(id, email!);

export const apiCallForUserInfo = createAsyncThunk(
  "userSlice/apiCallForUserInfo",
  async ({ id, email }: ApiCallForUserInfoParams) => {
    if (!id) {
      return {
        status: UserStatus.NOT_AUTHENTICATED,
      };
    }

    let user: User;

    // TODO MOVE IT TO UTIL
    try {
      const userFromThirdPartyProvider = await getUserInfo(id, email);
      if (!userFromThirdPartyProvider) {
        throw Error("Failed to fetch user from third party provider!");
      }
      user = userFromThirdPartyProvider;
    } catch (e) {
      if (e === Error500) {
        return { status: UserStatus.AUTHENTICATED_INTERNAL_ERROR };
      }
      return {
        status: UserStatus.AUTHENTICATED_REGISTRATION_NEEDED,
      };
    }

    let policies: PolicyEntity[];
    try {
      policies = await getUserPolicies(user.id);
    } catch {
      return {
        status: UserStatus.AUTHENTICATED_NO_POLICIES,
      };
    }

    // TODO REFINE WHOLE BLOCK & MOVE IT TO UTIL
    const brandPolicies: UserPolicy[] = [];
    const partnerPolicies: UserPolicy[] = [];
    let brandsObject: IdAndEntityPair<Brand>;
    let partnersObject: IdAndEntityPair<Partner>;

    try {
      // DIVIDE POLICIES AMONG BRANDS & PARTNERS
      policies.forEach((policy) =>
        policy.entityType === UserRole.BRAND
          ? brandPolicies.push(policy)
          : partnerPolicies.push(policy),
      );
      // FETCH CORRESPONDING BRANDS
      if (brandPolicies.length > 0) {
        const brandsResponse = await brandsApi.getBrandsByQuery({
          include: brandPolicies.map((b) => b.entityId),
        });
        brandsObject = parseArrayToIdsEntityPair(brandsResponse.data.brands);
      }
      // FETCH CORRESPONDING PARTNERS
      if (partnerPolicies.length > 0) {
        const partnersResponse = await partnersApi.getPartnersByIds({
          include: partnerPolicies.map((p) => p.entityId),
        });
        partnersObject = parseArrayToIdsEntityPair(partnersResponse.data.partners);
      }
    } catch (e) {
      return { status: UserStatus.AUTHENTICATED_INTERNAL_ERROR };
    }

    const policiesAggregated = policies.map((p) => ({
      ...p,
      brand: brandsObject && brandsObject[p.entityId],
      partner: partnersObject && partnersObject[p.entityId],
    }));

    return {
      user,
      policies: policiesAggregated,
      status: UserStatus.AUTHENTICATED,
    };
  },
);
