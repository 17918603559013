import { styled, css, Box, Button, Theme } from "@mui/material";
import gap from "shared/components/Styled/Gap";
import { ChevronRight } from "@mui/icons-material";

export const OrganizationSetup = styled(Box)`
  ${gap.top(2)};
`;

export const IntegrationSetup = styled(Box)`
  ${gap.top(2)};
`;

export const IntegrationDetails = styled(Box)`
  display: flex;
  ${gap.left(2)};
`;

const GrayTile = ({ theme }: { theme: Theme }) => css`
  border-radius: ${theme.shape.borderRadius}px;
  background: ${theme.palette.background.onSurface};
  padding: ${theme.spacing(2)};
  width: 100%;
  display: flex;
`;

export const GrayBox = styled(Box)(GrayTile);
export const GrayBoxButton = styled(Button)(GrayTile);

export const Websites = styled(GrayBox)`
  flex-direction: column;
  justify-content: center;
  ${gap.top(0.5)};
`;

export const StyledChevronRight = () => <ChevronRight color="tertiary" />;
