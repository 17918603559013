import { styled, css } from "@mui/material";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";
import { FC } from "react";
import { ProductPartProps } from "shared/components/ProductCards";
import { Product } from "shared/logic/types/Products/Product";

const VariantCount = styled((props: TextBaseProps) => <TextBase variant="caption" {...props} />)(
  ({ theme }) => css`
    white-space: nowrap;
    margin: ${theme.spacing(0.5, 0)};
  `,
);

export const CardDetails: FC<ProductPartProps<Product>> = ({ product }) => (
  <VariantCount>
    {product.productVariants.length} Variant{product.productVariants.length > 1 && "s"}
  </VariantCount>
);
