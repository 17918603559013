import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";
import {
  StyledCheckIcon,
  StyledWarnIcon,
  StyledRejectIcon,
} from "shared/components/ItemStatus/ItemStatus.styled";

const useItemStatus = () => {
  const statusIcon = (status: ApprovalStatus) => {
    switch (status) {
      case ApprovalStatus.APPROVED:
        return <StyledCheckIcon />;
      case ApprovalStatus.PENDING:
        return <StyledWarnIcon />;
      case ApprovalStatus.REJECTED:
        return <StyledRejectIcon />;
      default:
        return <></>;
    }
  };
  return {
    statusIcon,
  };
};

export default useItemStatus;
