import React from "react";
import { AuthenticatedRouting } from "views/Routing/AuthenticatedRouting";
import { NotAuthenticatedRouting } from "views/Routing/NotAuthenticatedRouting";
import { UserSetupStatus, UserStatus } from "shared/logic/types/User/UserStatus";
import { useSelector } from "react-redux";
import {
  getUserSetupStatus,
  getUserStatus,
} from "shared/logic/store/features/resourceSlices/user/selectors";

export const Routing = React.memo(() => {
  const userStatus = useSelector(getUserStatus);
  const userSetupStatus = useSelector(getUserSetupStatus);

  const isFirstLoginRequired =
    userSetupStatus === UserSetupStatus.PARTNER_FIRST_LOGIN ||
    userSetupStatus === UserSetupStatus.BRAND_FIRST_LOGIN;

  const isSetUpNotCompleted =
    userSetupStatus === UserSetupStatus.PARTNER_SETUP ||
    userSetupStatus === UserSetupStatus.BRAND_SETUP;

  if (userStatus === UserStatus.AUTHENTICATED && !isFirstLoginRequired) {
    return <AuthenticatedRouting isSetUpNotCompleted={isSetUpNotCompleted} />;
  }

  return <NotAuthenticatedRouting userStatus={userStatus} userSetupStatus={userSetupStatus} />;
});
