import { useDispatch } from "react-redux";
import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import { revokeAccess } from "shared/logic/store/features/viewsSlices/brandUser/assign/revokeAccess";

interface UseProductRevocationDialogProps {
  partneredProduct: PartneredProduct;
}

export const useProductRevocationDialog = ({
  partneredProduct,
}: UseProductRevocationDialogProps) => {
  const dispatch = useDispatch();
  const onConfirmClick = async () => {
    dispatch(
      revokeAccess({
        partneredProductId: partneredProduct.id,
      }),
    );
  };
  return {
    dispatch,
    onConfirmClick,
  };
};
