import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { storeAPI } from "views/partnerViews/storeAPI";

const usePartnerships = () => {
  const dispatch = useDispatch();
  const policy = useSelector(getUserPolicy);
  const partnerships = useSelector(storeAPI.partnershipsView.getPartnershipsAggregated);
  const isPartnershipsLoading = useSelector(storeAPI.partnershipsView.getIsPartnershipsLoading);
  const isEmpty = partnerships && partnerships.length === 0;

  useEffect(() => {
    dispatch(storeAPI.partnershipsView.fetchResources({ partnerId: policy?.entityId }));
  }, [policy?.entityId]);

  return {
    partnerships,
    isEmpty,
    isLoading: isPartnershipsLoading,
  };
};

export default usePartnerships;
