import { styled, Box, css, Button, Divider } from "@mui/material";

export const FormNavigationWrapper = styled(Box)``;

export const InputsWrapper = styled(Box)(
  ({ theme }) => css`
    flex-grow: 1;
    padding-top: ${theme.spacing(1)};
    overflow: auto;
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const ButtonSpace = styled(Box)(
  ({ theme }) => css`
    width: ${theme.spacing(6)};
  `,
);

export const UserInputSection = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const UserInputWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    & > * + * {
      margin-top: ${theme.spacing(2)};
    }
  `,
);

export const AddAnotherUserButton = styled(Button)(
  ({ theme }) => css`
    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledForm = styled("form")(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-height: 0;

    & > * + * {
      margin-top: ${theme.spacing(2)};
    }
  `,
);

export const NameInputWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    & > * + * {
      margin-left: ${theme.spacing(2)};
    }
  `,
);

export const StyledDivider = styled(Divider)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(3)};
  `,
);
