import { ViewModule, ViewList } from "@mui/icons-material";
import { FC } from "react";
import { useLayoutVariantButtons } from "shared/components/TableComponents/FiltersContainer/LayoutVariantButtons/useLayoutVariantButtons";
import { GridViewButton, ListViewButton } from "./LayoutVariantButtons.styled";

export enum DisplayVariant {
  GRID = "grid",
  LIST = "list",
}

export interface LayoutVariantButtonsProps {
  displayVariant: DisplayVariant;
  setDisplayVariant: (variant: DisplayVariant) => void;
}

export const LayoutVariantButtons: FC<LayoutVariantButtonsProps> = ({
  displayVariant,
  setDisplayVariant,
}) => {
  const { isGridViewDisabled } = useLayoutVariantButtons();
  return (
    <>
      <GridViewButton
        $active={displayVariant === DisplayVariant.GRID}
        onClick={() => setDisplayVariant(DisplayVariant.GRID)}
        disabled={isGridViewDisabled}
      >
        <ViewModule />
      </GridViewButton>
      <ListViewButton
        $active={displayVariant === DisplayVariant.LIST}
        onClick={() => setDisplayVariant(DisplayVariant.LIST)}
      >
        <ViewList />
      </ListViewButton>
    </>
  );
};
