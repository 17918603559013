import { FC } from "react";
import { Close } from "@mui/icons-material";
import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import {
  ClearAllBtn,
  ClearAllLabel,
  CloseButton,
  Navigation,
  StyledContent,
  StyledContentDrawer,
  StyledDrawer,
  Title,
  TitleWrapper,
} from "./FilterDrawer.styled";

export interface FilterDrawerWrapperProps {
  isOpen: boolean;
  toggleOpen: () => void;
  title: TextContentPath;
}

export const FilterDrawerWrapper: FC<FilterDrawerWrapperProps> = ({
  isOpen,
  toggleOpen,
  title,
  children,
}) => (
  <StyledDrawer anchor="right" onClose={toggleOpen} open={isOpen}>
    <StyledContent>
      <TitleWrapper>
        <Title content={title} />
        <Navigation>
          <ClearAllBtn>
            <ClearAllLabel content="filterDrawer.clearAll" />
          </ClearAllBtn>
          <CloseButton onClick={toggleOpen}>
            <Close />
          </CloseButton>
        </Navigation>
      </TitleWrapper>
      <StyledContentDrawer>{children}</StyledContentDrawer>
    </StyledContent>
  </StyledDrawer>
);
