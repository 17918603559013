import { useForm } from "react-hook-form";
import { BottomNavigationProps } from "shared/components/Drawer/BottomNavigation";
import { EditDrawerProps } from "shared/components/SettingsComponents/Organization/EditOrganizationDrawer/index";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import { useDispatch } from "react-redux";

export interface EditOrganizationForm {
  name: EditDrawerProps["name"];
  commissionPercent: EditDrawerProps["commissionPercent"];
  description: EditDrawerProps["description"];
  imageFile?: File;
}

interface UseEditOrganizationDrawerProps {
  name: EditDrawerProps["name"];
  commissionPercent: EditDrawerProps["commissionPercent"];
  description: EditDrawerProps["description"];
  submitThunk: EditDrawerProps["submitThunk"];
}

export const useEditOrganizationDrawer = ({
  name,
  commissionPercent,
  description,
  submitThunk,
}: UseEditOrganizationDrawerProps) => {
  const dispatch = useDispatch();
  const { register, setValue, handleSubmit } = useForm<EditOrganizationForm>({
    defaultValues: {
      name,
      commissionPercent,
      description,
    },
  });
  const formId = "edit-organization-form";
  const bottomNavigationProps: BottomNavigationProps = {
    bottomLabel: "settings.organization.edit.bottomLabel",
    submitLabel: "settings.organization.edit.submit",
    submitFormId: formId,
  };
  const fileUploadHandler = (file: File) => {
    setValue("imageFile", file);
  };
  const onSubmit = (data: EditOrganizationForm) => {
    ConsoleLog(["Edit Organization Form", data]);
    dispatch(submitThunk(data));
  };

  return {
    onSubmit,
    fileUploadHandler,
    bottomNavigationProps,
    register,
    handleSubmit,
    formId,
  };
};
