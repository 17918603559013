import { TextBase } from "shared/components/base/TextBase/TextBase";
import { ChatBubble } from "@mui/icons-material";
import { StyledCopyBtn } from "shared/components/ViewHeadline/ViewHeadline.styled";
import { FC } from "react";
import { DrawerWrapper } from "shared/components/Drawer";
import { useRequestNewPartnerDrawer } from "./useRequestNewPartnerDrawer";
import {
  NavigationContent,
  PartnerDomain,
  PartnerEmail,
  PartnerNameInput,
  StyledBox,
  StyledForm,
  StyledSendButton,
} from "./RequestNewPartnerDrawer.styled";

export const RequestNewPartnerDrawer: FC = () => {
  const { isOpenDrawer, toggleOpenDrawer, handleSubmit, onSubmit, register, isFormValid } =
    useRequestNewPartnerDrawer();
  return (
    <>
      <StyledCopyBtn variant="contained" color="tertiary" onClick={toggleOpenDrawer}>
        <TextBase content="discoverPartners.brandUserView.requestPartner" variant="subtitle1" />
        <ChatBubble />
      </StyledCopyBtn>
      <DrawerWrapper
        toggleOpen={toggleOpenDrawer}
        isOpen={isOpenDrawer}
        title="discoverPartners.brandUserView.requestPartner"
      >
        <StyledBox>
          <StyledForm id="submit-form" onSubmit={handleSubmit(onSubmit)}>
            <TextBase variant="h3" content="discoverPartners.brandUserView.partnerDetail" />
            <TextBase variant="body1" content="discoverPartners.brandUserView.fillIn" />
            <PartnerNameInput
              {...register("name", { required: true })}
              placeholder="discoverPartners.requestNewPartner.name"
              fullWidth
              variant="outlined"
              color="tertiary"
            />
            <PartnerDomain
              {...register("domain", { required: true })}
              placeholder="discoverPartners.requestNewPartner.domain"
              fullWidth
              variant="outlined"
              color="tertiary"
            />
            <TextBase variant="body1" content="discoverPartners.brandUserView.partnerEmail" />
            <PartnerEmail
              {...register("email")}
              placeholder="discoverPartners.requestNewPartner.email"
              fullWidth
              variant="outlined"
              color="tertiary"
            />
          </StyledForm>
          <NavigationContent>
            <TextBase
              variant="tableLabel"
              color="text.disabled"
              content="discoverPartners.brandUserView.sendRequest"
            />
            <StyledSendButton
              form="submit-form"
              type="submit"
              variant="contained"
              color="tertiary"
              disabled={!isFormValid}
            >
              <TextBase
                variant="subtitle1"
                content="discoverPartners.brandUserView.requestSubmit"
              />
            </StyledSendButton>
          </NavigationContent>
        </StyledBox>
      </DrawerWrapper>
    </>
  );
};
