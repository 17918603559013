import { styled, Box } from "@mui/material";
import { StyledWhiteBox } from "views/partnerViews/Dashboard/Dashboard.styled";

export const StyledWrapper = styled(StyledWhiteBox)`
  justify-content: space-between;
`;

export const StyledCountRow = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
