export enum LogType {
  WARN = "warn",
  ERROR = "error",
  MESSAGE = "message",
}

interface LogOptions {
  displayOnProd?: boolean;
  type?: LogType;
}

const defaultOptions: LogOptions = {
  displayOnProd: false,
  type: LogType.MESSAGE,
};

export const ConsoleLog = (message: unknown, options = defaultOptions): void => {
  if (!options.displayOnProd && process.env.NODE_ENV === "production") {
    return;
  }
  switch (options.type) {
    case LogType.MESSAGE:
      // eslint-disable-next-line no-console
      console.log(message);
      break;
    case LogType.WARN:
      // eslint-disable-next-line no-console
      console.warn(message);
      break;
    case LogType.ERROR:
      // eslint-disable-next-line no-console
      console.error(message);
      break;
    default:
      // eslint-disable-next-line no-console
      console.log(message);
      break;
  }
};
