import { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

const DEFAULT_CACHE_TIME = 50000; // milliseconds

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: DEFAULT_CACHE_TIME,
      staleTime: DEFAULT_CACHE_TIME,
    },
  },
});

const ReactQueryProvider: FC = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

export default ReactQueryProvider;
