import { useDispatch } from "react-redux";
import {
  setDialog,
  ChosenDialogEnum,
  closeDialog,
} from "shared/logic/store/features/resourceSlices/dialog/dialogSlice";
import { useForm } from "react-hook-form";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";

interface RejectFormData {
  rejectionMessage: string;
}

const useRejectItemDialog = (product?: ProductAggregated) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm<RejectFormData>();
  const closeDialogAction = () => dispatch(closeDialog());
  const onSubmit = ({ rejectionMessage }: RejectFormData) => {
    dispatch(
      setDialog({
        chosenDialog: ChosenDialogEnum.REJECTION_ACCEPTANCE,
        open: true,
        title: "dialog.rejectProduct",
        dialogProps: {
          product,
          rejectionMessage,
        },
      }),
    );
  };
  return {
    onSubmit,
    closeDialogAction,
    register,
    handleSubmit,
  };
};

export default useRejectItemDialog;
