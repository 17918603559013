import { createSlice } from "@reduxjs/toolkit";
import { fetchResourcesForDiscoverNewPartnersView } from "shared/logic/store/features/viewsSlices/brandUser/discoverNewPartners/fetchResourcesForDiscoverNewPartnersView";
import { requestPartnershipWithPartner } from "shared/logic/store/features/viewsSlices/brandUser/discoverNewPartners/requestPartnership";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

interface DiscoverNewPartnersViewState {
  isDiscoverNewPartnersLoading: boolean;
  isDataInvalid: boolean;
  isDataFetched: boolean;
  isRequestPartnershipSuccessful: boolean;
  partnerIds: string[];
}

export const initialState: DiscoverNewPartnersViewState = {
  isDiscoverNewPartnersLoading: false,
  isDataInvalid: false,
  isDataFetched: false,
  partnerIds: [],
  isRequestPartnershipSuccessful: false,
};

const discoverNewPartnersViewSliceForBrandUser = createSlice({
  name: storePrefixes.brandUser.discoverSlice.this,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourcesForDiscoverNewPartnersView.pending, (state) => {
        state.isDiscoverNewPartnersLoading = true;
      })
      .addCase(fetchResourcesForDiscoverNewPartnersView.fulfilled, (state, action) => {
        state.isDiscoverNewPartnersLoading = false;
        state.isDataFetched = true;
        state.partnerIds = action.payload.partnerIds ?? [];
      })
      .addCase(requestPartnershipWithPartner.pending, (state) => {
        state.isDiscoverNewPartnersLoading = true;
      })
      .addCase(requestPartnershipWithPartner.fulfilled, (state) => {
        state.isRequestPartnershipSuccessful = true;
        state.isDiscoverNewPartnersLoading = false;
      });
  },
});

export default discoverNewPartnersViewSliceForBrandUser.reducer;
