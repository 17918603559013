import { useHistory, useLocation } from "react-router-dom";
import {
  AddCircle,
  Home,
  Inventory,
  PersonAdd,
  Public,
  Settings,
  SupervisedUserCircle,
} from "@mui/icons-material";
import { NavigationItemProps } from "views/NavigationSidebar/NavigationItem";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import { useAuth } from "shared/logic/hooks/auth/useAuth";
import { InternalLogout, UserRole } from "shared/components/auth/AuthContext";
import { PolicyRole } from "shared/logic/types/User/UserPolicy";
import { useSelector } from "react-redux";
import {
  getIsCurrentUserSetUpCompletely,
  getUserPolicy,
} from "shared/logic/store/features/resourceSlices/user/selectors";
import { getPendingProductsCount } from "shared/logic/store/features/resourceSlices/partneredProducts/selectors";
import { useUserRole } from "shared/logic/hooks/store/useUserRole";

export interface SidebarItems extends NavigationItemProps {
  shouldPutDividerAfter?: boolean;
  title?: TextContentPath;
  role?: PolicyRole;
}

export const useNavigationSidebar = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { logout: authLogout } = useAuth();

  const userPolicy = useSelector(getUserPolicy);
  const { isPartnerUser } = useUserRole();
  const pendingProductsCount = useSelector(getPendingProductsCount);
  const isCurrentUserSetUpCompletely = useSelector(getIsCurrentUserSetUpCompletely);

  const userRole = userPolicy?.entityType;
  const policyRole = userPolicy?.role;
  const navigationListItems: SidebarItems[] = [
    {
      MaterialIcon: Home,
      localizedText: "navigation.home" as TextContentPath,
      isSelected: pathname === RouteEnum.DASHBOARD,
      onClick: () => history.push(RouteEnum.DASHBOARD),
    },
    {
      MaterialIcon: SupervisedUserCircle,
      localizedText: "navigation.partnerships" as TextContentPath,
      isSelected: pathname === RouteEnum.PARTNERSHIPS,
      onClick: () => history.push(RouteEnum.PARTNERSHIPS),
    },
    {
      MaterialIcon: Public,
      localizedText: (userRole === UserRole.BRAND
        ? "navigation.discoverPartners"
        : "navigation.discoverBrands") as TextContentPath,
      isSelected: pathname === RouteEnum.DISCOVER,
      onClick: () => history.push(RouteEnum.DISCOVER),
    },
    {
      MaterialIcon: Inventory,
      localizedText: "navigation.products" as TextContentPath,
      isSelected: pathname === RouteEnum.PRODUCTS,
      badgeContent: isPartnerUser ? pendingProductsCount : null,
      onClick: () => history.push(RouteEnum.PRODUCTS),
      shouldPutDividerAfter: true,
    },
    {
      MaterialIcon: Settings,
      localizedText: "navigation.setup" as TextContentPath,
      isSelected: pathname === RouteEnum.SETUP,
      onClick: () => history.push(RouteEnum.SETUP),
      badgeContent: isCurrentUserSetUpCompletely ? undefined : "!",
    },
    {
      MaterialIcon: PersonAdd,
      localizedText: "navigation.inviteToPlatform" as TextContentPath,
      isSelected: pathname === RouteEnum.INVITE_TO_PLATFORM,
      onClick: () => history.push(RouteEnum.INVITE_TO_PLATFORM),
      shouldPutDividerAfter: true,
    },
    {
      title: "navigation.administrator",
      MaterialIcon: AddCircle,
      localizedText: "navigation.addAccount",
      isSelected: pathname === RouteEnum.ADD_ACCOUNT,
      onClick: () => history.push(RouteEnum.ADD_ACCOUNT),
      role: PolicyRole.FERMAT_ADMINISTRATOR,
    },
  ];

  const logout = () => {
    (authLogout as InternalLogout)();
  };

  const roleGuard = (pathRole?: PolicyRole) => {
    if (!pathRole) {
      return true;
    }
    return pathRole === policyRole;
  };

  return {
    navigationListItems,
    logout,
    roleGuard,
  };
};
