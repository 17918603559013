import { styled, Box, css } from "@mui/material";
import gap from "shared/components/Styled/Gap";

export interface StyledCustomAlertProps {
  severity?: "primary" | "secondary" | "success" | "error" | "warning";
  sideBorder?: "left" | "right";
}

export const StyledCustomAlert = styled(Box)<StyledCustomAlertProps>(
  ({ theme, severity = "success", sideBorder }) => css`
    border: 1px solid ${theme.palette[severity].main};
    background-color: ${theme.palette[severity].light};
    border-radius: ${theme.shape.borderRadius}px;
    padding: ${sideBorder ? theme.spacing(2, 3) : theme.spacing(2)};
    align-items: center;
    display: flex;
    overflow: hidden;
    position: relative;
    ${gap.left(1)};
    &::after {
      ${sideBorder && "content: ''"};
      width: ${theme.spacing(1)};
      height: 100%;
      background: ${theme.palette[severity].main};
      display: block;
      position: absolute;
      ${sideBorder === "left" && "left: 0"};
      ${sideBorder === "right" && "right: 0"};
      top: 0;
    }
  `,
);
