import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { ListItemProps } from "shared/components/DashboardList/ListContent/ListItem";
import {
  BrandUserPartnershipAggregated,
  BrandUserProductAggregated,
} from "shared/logic/store/features/viewsSlices/brandUser/dashboard/selectors";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";
import { PartnershipAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/partnerships/selectors";

export enum ItemTypeEnum {
  PRODUCT = "PRODUCT",
  PARTNERSHIP = "PARTNERSHIP",
  BRAND_USER_LATEST_PARTNERSHIPS = "BRAND_USER_LATEST_PARTNERSHIPS",
  BRAND_USER_MOST_SHARED_PRODUCTS = "BRAND_USER_MOST_SHARED_PRODUCTS",
}

export type ItemInterfaces =
  | ProductAggregated
  | PartnershipAggregated
  | BrandUserPartnershipAggregated
  | BrandUserProductAggregated;

const useDashboardList = (itemType: ItemTypeEnum) => {
  const history = useHistory();
  const onSeeAllClick = useCallback(() => {
    switch (itemType) {
      case ItemTypeEnum.PARTNERSHIP:
      case ItemTypeEnum.BRAND_USER_LATEST_PARTNERSHIPS:
        history.push(RouteEnum.PARTNERSHIPS);
        break;
      case ItemTypeEnum.PRODUCT:
      case ItemTypeEnum.BRAND_USER_MOST_SHARED_PRODUCTS:
        history.push(RouteEnum.PRODUCTS);
        break;
      default:
        history.push(RouteEnum.DASHBOARD);
        break;
    }
  }, [history, itemType]);

  const parsePropsForMostSharedProduct = (product: BrandUserProductAggregated): ListItemProps => ({
    image: product.primaryImageUrl,
    title: product.name,
    commissionPercent: undefined,
    count: product.productVariants.length,
    brand: undefined,
    counterLabel: "Variant",
  });

  const parsePropsForLatestPartnership = (
    partnership: BrandUserPartnershipAggregated,
  ): ListItemProps => ({
    image: undefined,
    title: partnership.partner.name,
    commissionPercent: partnership.commissionPercent,
    count: undefined,
    brand: undefined,
    counterLabel: "Product",
  });

  const parsePropsForProduct = (product: ProductAggregated): ListItemProps => ({
    image: product.primaryImageUrl,
    title: product.name,
    commissionPercent: product.commissionPercent ?? product.partnership.commissionPercent,
    count: product.productVariants.length,
    brand: product.brand.name,
    counterLabel: "Variant",
  });

  const parsePropsForPartnership = (partnership: PartnershipAggregated): ListItemProps => ({
    image: partnership.brand.logoUrl,
    title: partnership.brand.name,
    commissionPercent: partnership.commissionPercent,
    count: undefined,
    brand: partnership.brand.name,
    counterLabel: "Product",
  });

  const parseProps = useCallback(
    (item: ItemInterfaces) => {
      switch (itemType) {
        case ItemTypeEnum.PARTNERSHIP:
          return parsePropsForPartnership(item as PartnershipAggregated);
        case ItemTypeEnum.PRODUCT:
          return parsePropsForProduct(item as ProductAggregated);
        case ItemTypeEnum.BRAND_USER_MOST_SHARED_PRODUCTS:
          return parsePropsForMostSharedProduct(item as BrandUserProductAggregated);
        case ItemTypeEnum.BRAND_USER_LATEST_PARTNERSHIPS:
          return parsePropsForLatestPartnership(item as BrandUserPartnershipAggregated);
        default:
          return parsePropsForPartnership(item as PartnershipAggregated);
      }
    },
    [itemType],
  );
  return {
    parseProps,
    onSeeAllClick,
  };
};

export default useDashboardList;
