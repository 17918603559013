import { RootState } from "shared/logic/store/rootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { getPartners } from "shared/logic/store/features/resourceSlices/partners/selectors";
import { Partner } from "shared/logic/types/Partners/Partner";

const getDiscoverSlice = (state: RootState) => state.brand.discoverNewPartnersViewSlice;

const getDiscoverPartnersIds = createSelector(
  [getDiscoverSlice],
  ({ partnerIds }): string[] => partnerIds,
);

const getDiscoverNewPartners = createSelector(
  [getDiscoverPartnersIds, getPartners],
  (partnerIds, partners) => {
    const results: Partner[] = [];

    partnerIds.forEach((id) => {
      if (partners[id]) {
        results.push(partners[id]);
      } else {
        // SHOULD NOT HAPPEN
        // TODO LOG ERROR TO DATADOG
      }
    });

    return results;
  },
);

const getAreBrandsLoading = createSelector(
  [getDiscoverSlice],
  ({ isDiscoverNewPartnersLoading }) => isDiscoverNewPartnersLoading,
);

export default {
  getDiscoverSlice,
  getDiscoverPartnersIds,
  getDiscoverNewPartners,
  getAreBrandsLoading,
};
