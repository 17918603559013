import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "shared/logic/store/rootReducer";

export const getPartnersSlice = (state: RootState) => state.partnersSlice;

export const getPartners = createSelector([getPartnersSlice], ({ partners }) => partners);

export const getIsFirstPartnerLoginLoading = createSelector(
  [getPartnersSlice],
  ({ isFirstPartnerLoginLoading }) => isFirstPartnerLoginLoading,
);
