import { ResponseDTO, ProductStatusResponseDTO } from "shared/logic/types/ResponseDTO";
import axiosInstance from "shared/logic/api/axiosInstance";
import PartneredProductsQuery from "shared/logic/types/PartneredProducts/PartneredProductsQuery";
import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import { Product } from "shared/logic/types/Products/Product";
import serializeArrayQueryParam from "shared/logic/utils/serializeArrayQueryParam";

function getPartneredProducts(
  productsQuery: PartneredProductsQuery,
): Promise<ResponseDTO<PartneredProduct[]>> {
  return axiosInstance.get("/partnered-products", {
    params: {
      ...productsQuery,
      "brand-id": productsQuery.brandId,
      "partner-id": productsQuery.partnerId,
      "approval-status": productsQuery.approvalStatus,
    },
  });
}

const getProductsByBrandId = (brandId: string): Promise<ResponseDTO<Product[]>> =>
  axiosInstance.get("/products", {
    params: {
      "brand-id": brandId,
    },
  });

interface RejectItemBody {
  message: string;
}

const rejectProduct = (
  productId: string,
  body: RejectItemBody,
): Promise<ProductStatusResponseDTO> =>
  axiosInstance.post(`/partnered-products/${productId}/reject`, body);

const approveProduct = (productId: string): Promise<ProductStatusResponseDTO> =>
  axiosInstance.post(`/partnered-products/${productId}/approve`);

interface ProductsDetailedInfoParams {
  include?: string[];
  "brand-id"?: string;
  limit?: number;
}

const getProductsDetailedInfo = (
  params: ProductsDetailedInfoParams,
): Promise<ResponseDTO<Product[]>> =>
  axiosInstance.get(`/products`, {
    params: { ...params, include: serializeArrayQueryParam(params.include) },
  });

const getProductDetailedInfo = (productId: string) => axiosInstance.get(`/products/${productId}`);

interface MakeProductAvailableToPartnerParams {
  productId: string;
  partnershipId: string;
}

const makeProductAvailableToPartner = ({
  productId,
  partnershipId,
}: MakeProductAvailableToPartnerParams) =>
  axiosInstance.post(`/partnered-products`, {
    productId,
    partnershipId,
  });

const revokePartnerProductAccess = (partneredProductId: string) =>
  axiosInstance.delete(`/partnered-products/${partneredProductId}`);

export default {
  getPartneredProducts,
  getProductDetailedInfo,
  rejectProduct,
  approveProduct,
  getProductsDetailedInfo,
  makeProductAvailableToPartner,
  revokePartnerProductAccess,
  getProductsByBrandId,
};
