import companiesApi from "shared/logic/api/companiesApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import userApi from "shared/logic/api/userApi";
import { PolicyRole } from "shared/logic/types/User/UserPolicy";
import { UserRole } from "shared/components/auth/AuthContext";
import { NewBrand, NewUser } from "shared/logic/store/features/user/signUp/types";
import { createCompanyTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createCompanyTransaction";
import { dispatchSnackbarGenericError } from "shared/logic/store/features/user/signUp/utils/dispatchSnackbarGenericError";
import { createUserTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createUserTransaction";
import { createPolicyTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createPolicyTransaction";
import { createBrandTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createBrandTransaction";
import brandsApi, { UpdateBrandLogoProps } from "shared/logic/api/brandsApi";

interface SignUpBrandArgs {
  newUser: NewUser;
  newBrand: NewBrand;
  logo?: UpdateBrandLogoProps["logo"];
}

export const signUpBrand = createAsyncThunk(
  "userSlice/signUpBrand",
  async ({ newUser, newBrand, logo }: SignUpBrandArgs, { dispatch }) => {
    function showError(): void {
      dispatchSnackbarGenericError(dispatch);
    }

    const company = await createCompanyTransaction({ name: newBrand.name, onError: showError });

    const user = await createUserTransaction({
      data: {
        ...newUser,
        companyId: company.id,
      },
      onError: showError,
      async rollback(): Promise<void> {
        await companiesApi.deleteCompany(company.id);
      },
    });

    const brand = await createBrandTransaction({
      data: { ...newBrand, companyId: company.id },
      logo,
      onError: showError,
      async rollback(): Promise<void> {
        await userApi.deleteUser(user.id);
        await companiesApi.deleteCompany(company.id);
      },
    });

    const { permissioned_entities: policies } = await createPolicyTransaction({
      data: {
        user_id: user.id,
        policy_id: user.id,
        permissioned_entities: [
          {
            role: PolicyRole.ADMINISTRATOR,
            entityType: UserRole.BRAND,
            entityId: brand.id,
          },
        ],
      },
      onError: showError,
      async rollback(): Promise<void> {
        await brandsApi.deleteBrand(brand.id);
        await userApi.deleteUser(user.id);
        await companiesApi.deleteCompany(company.id);
      },
    });

    const policiesAggregated = policies.map((p) => ({
      ...p,
      brand,
    }));

    return { company, user, brand, policies, policiesAggregated };
  },
);
