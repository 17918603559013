import { useEffect } from "react";
import { useSelector } from "react-redux";
import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { storeAPI } from "views/brandViews/storeAPI";

const useExistingIntegrations = () => {
  const existingIntegrations = useSelector(storeAPI.setupView.getExistingIntegrations);
  const isFetched = useSelector(storeAPI.setupView.getIsExistingIntegrationsFetched);
  const isLoading = useSelector(storeAPI.setupView.getIsSetupLoading);
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (isFetched && !existingIntegrations.length) {
      setSearchParams({});
    }
  }, [isFetched, existingIntegrations]);

  return {
    existingIntegrations,
    isLoading,
  };
};

export default useExistingIntegrations;
