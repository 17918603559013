import React, { useEffect, useMemo, useState } from "react";
import { isNil, omitBy } from "lodash-es";
import { UserRole } from "shared/components/auth/AuthContext";
import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { SelfSignUpFirstStep } from "views/SelfSignup/SelfSignUpFirstStep";
import { useSelector } from "react-redux";
import { getSignUpRequest } from "shared/logic/store/features/resourceSlices/user/selectors";
import { SelfSignUpSecondStep } from "./SelfSignUpSecondStep";

export enum UserLoginStepEnum {
  USER_POLICY = "user-policy",
  USER_INFORMATION = "user-information",
}

export enum UserAccountFormEnum {
  USER_TYPE = "userType",
  USER_NAME = "userName",
  COMMISSION_RATE = "commissionRate",
  USER_LOGO = "userLogo",
  USER_DESCRIPTION = "userDescription",
  TERMS_CONDITIONS = "termsConditions",
}

export interface UserAccountForm {
  [UserAccountFormEnum.USER_TYPE]?: UserRole;
  [UserAccountFormEnum.USER_NAME]?: string;
  [UserAccountFormEnum.COMMISSION_RATE]?: number;
  [UserAccountFormEnum.USER_DESCRIPTION]?: string;
  [UserAccountFormEnum.TERMS_CONDITIONS]?: boolean;
  [UserAccountFormEnum.USER_LOGO]?: File;
}

export type UserDetailsUpdateParams = UserAccountForm;

export const useSelfSignUp = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [formData, setFormData] = useState<UserAccountForm>({
    [UserAccountFormEnum.USER_TYPE]: undefined,
    [UserAccountFormEnum.USER_NAME]: undefined,
    [UserAccountFormEnum.COMMISSION_RATE]: undefined,
    [UserAccountFormEnum.USER_DESCRIPTION]: undefined,
    [UserAccountFormEnum.TERMS_CONDITIONS]: undefined,
    [UserAccountFormEnum.USER_LOGO]: undefined,
  });

  const { inProgress: isSignUpLoading } = useSelector(getSignUpRequest);

  const accountDetailsUpdate = ({
    userType,
    commissionRate,
    userName,
    userLogo,
    userDescription,
    termsConditions,
  }: UserDetailsUpdateParams) => {
    const newData = omitBy(
      {
        userType,
        commissionRate,
        userName,
        userLogo,
        userDescription,
        termsConditions,
      },
      isNil,
    );
    setFormData((prev) => ({ ...prev, ...newData }));
  };

  const stepObject = useMemo(() => {
    switch (searchParams.step) {
      case UserLoginStepEnum.USER_POLICY:
        return {
          title: "selfSignUp.title",
          number: 1,
          component: <SelfSignUpFirstStep userDetailsUpdate={accountDetailsUpdate} />,
        };
      case UserLoginStepEnum.USER_INFORMATION:
        return {
          title: "selfSignUp.secondTitle",
          number: 2,
          component: (
            <SelfSignUpSecondStep
              userDetailsUpdate={accountDetailsUpdate}
              savedUserDetails={formData}
            />
          ),
        };
      default:
        return {
          title: undefined,
          number: undefined,
          component: <FullScreenLoader />,
        };
    }
  }, [searchParams.step]);
  useEffect(() => {
    if (!searchParams.step) {
      setSearchParams({ step: UserLoginStepEnum.USER_POLICY });
    }
  }, [searchParams.step]);

  const profileName = formData[UserAccountFormEnum.USER_NAME];

  return {
    stepObject,
    profileName,
    isSignUpLoading,
  };
};
