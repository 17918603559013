import { CSSProperties } from "@mui/material/styles/createTypography";

export const materialFontToString = (materialFont: CSSProperties) =>
  `${materialFont.fontWeight} ${materialFont.fontSize}px ${materialFont.fontFamily}`;

export const getTextWidth = (text: string, font: string): number | null => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context || !font) {
    return null;
  }

  context.font = font;
  return context.measureText(text).width;
};
