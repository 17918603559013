import { useSelector } from "react-redux";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";

export const useSettings = () => {
  const currentPolicy = useSelector(getUserPolicy);
  const brandInfo = currentPolicy?.brand;
  const shopifyName = "Shopify Store Name"; // TODO -- link shopify name
  const isSetupCompleted = true; // TODO -- link during integration

  return {
    brandInfo,
    shopifyName,
    isSetupCompleted,
  };
};
