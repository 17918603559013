import { styled, Box, css, InputBase } from "@mui/material";
import { motion } from "framer-motion";
import { Cached } from "@mui/icons-material";

export const SearchWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing(1)};
    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const AnimatedSearchIcon = styled(Cached)`
  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(-360deg);
    }
  }
  animation: rotate 1s infinite linear;
`;

const StyledInput = styled(InputBase)(
  ({ theme }) => css`
    font-size: ${theme.typography.subtitle1.fontSize}px;
    font-weight: ${theme.typography.subtitle1.fontWeight};
    line-height: ${theme.typography.subtitle1.lineHeight};
  `,
);

export const AnimatedInput = motion(StyledInput);
