import useExistingIntegrations from "views/brandViews/Setup/ExistingIntegrations/useExistingIntegrations";
import {
  NameAndLogoWrapper,
  StyledIntegrationBox,
  StyledIntegrationsWrapper,
  StyledLogo,
  StyledNameBox,
} from "views/brandViews/Setup/ExistingIntegrations/ExsistingIntegrations.styled";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { FullScreenLoader } from "shared/components/FullScreenLoader";

export const ExistingIntegrations = () => {
  const { existingIntegrations, isLoading } = useExistingIntegrations();
  if (isLoading) {
    return <FullScreenLoader />;
  }
  return (
    <StyledIntegrationsWrapper>
      <TextBase
        variant="body1"
        color="text.secondary"
        content="setup.existingIntegrations.viewBanner"
      />
      {existingIntegrations.map((integration) => (
        <StyledIntegrationBox key={integration.id}>
          <NameAndLogoWrapper>
            <StyledLogo variant="rounded" src={integration.brand.logoUrl} />
            <StyledNameBox>{integration.brand.name}</StyledNameBox>
          </NameAndLogoWrapper>
        </StyledIntegrationBox>
      ))}
    </StyledIntegrationsWrapper>
  );
};
