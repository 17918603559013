import { TableCell, Typography } from "@mui/material";
import { FC } from "react";
import { ImageCell, Image, StyledItemRow } from "shared/components/TableComponents/Styles";
import { ItemStatus } from "shared/components/ItemStatus";
import { GetProductTag } from "views/partnerViews/Products/ProductsItem/GetProductTag";
import { StyledProductWrapper } from "views/partnerViews/Products/ProductsItem/ProductsItem.styled";
import { AcceptOptions } from "views/partnerViews/Products/ProductsItem/AcceptOptions";
import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";

interface ProductsItemProps {
  product: ProductAggregated;
}

export const ProductsItem: FC<ProductsItemProps> = ({ product }) => (
  <StyledItemRow>
    <ImageCell>
      <Image src={product.primaryImageUrl} variant="rounded" />
    </ImageCell>
    <TableCell padding="none">
      <StyledProductWrapper>
        <Typography variant="subtitle1">{product.name}</Typography>
        <Typography variant="caption" color="text.disabled">
          {product.productVariants.length} Variant{product.productVariants.length > 1 && "s"}
        </Typography>
      </StyledProductWrapper>
    </TableCell>
    <TableCell>
      <Typography variant="body1" color="text.disabled">
        {product.brand?.name}
      </Typography>
    </TableCell>
    <TableCell align="right">
      <Typography variant="body1">
        {product.commissionPercent ?? product.partnership.commissionPercent}%
      </Typography>
    </TableCell>
    <TableCell align="right">
      <ItemStatus status={product.partnerApprovalStatus as ApprovalStatus} />
    </TableCell>
    <TableCell align="right" padding="none">
      {product.partnerApprovalStatus === ApprovalStatus.APPROVED ? (
        <GetProductTag product={product} />
      ) : (
        <AcceptOptions product={product} />
      )}
    </TableCell>
  </StyledItemRow>
);
