enum UserSliceType {
  BRAND_USER = "brandUser",
  PARTNER_USER = "partnerUser",
  ADMIN_USER = "adminUser",
  SHARED = "shared",
}

enum ViewSlicePrefix {
  DASHBOARD = "dashboardViewSlice",
  PARTNERSHIPS = "partnershipsViewSlice",
  DISCOVER = "discoverViewSlice",
  PRODUCTS = "productsViewSlice",
  SETUP = "setupViewSlice",
  ASSIGN = "assignViewSlice",
  INVITE = "inviteViewSlice",
  SETTINGS = "settingsViewSlice",
}

enum ResourceSlicePrefix {
  BRANDS = "brandsSlice",
  DIALOG = "dialogSlice",
  PARTNERED_PRODUCT = "partneredProductSlice",
  PARTNERS = "partnersSlice",
  PRODUCTS = "productsSlice",
  SNACKBAR = "snackbarSlice",
  USER = "userSlice",
  PARTNERSHIPS = "partnershipsSlice",
  ECOMMERCE_ACCOUNTS = "ecommerceAccounts",
}

const storePrefixes = {
  resourceSlices: {
    brands: ResourceSlicePrefix.BRANDS,
    ecommerceAccounts: ResourceSlicePrefix.ECOMMERCE_ACCOUNTS,
    dialog: ResourceSlicePrefix.DIALOG,
    partneredProduct: ResourceSlicePrefix.PARTNERED_PRODUCT,
    partners: ResourceSlicePrefix.PARTNERS,
    products: ResourceSlicePrefix.PRODUCTS,
    snackbar: ResourceSlicePrefix.SNACKBAR,
    user: ResourceSlicePrefix.USER,
    partnerships: ResourceSlicePrefix.PARTNERSHIPS,
  },

  brandUser: {
    this: UserSliceType.BRAND_USER,
    firstLoginSetup: `${UserSliceType.BRAND_USER}/firstLoginSetup`,
    assignSlice: {
      this: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.ASSIGN}`,
      fetch: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.ASSIGN}/fetch`,
      makeProductAvailable: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.ASSIGN}/makeProductAvailable`,
      revokeAccess: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.ASSIGN}/revokeAccess`,
    },
    dashboardSlice: {
      this: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.DASHBOARD}`,
      fetch: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.DASHBOARD}/fetch`,
    },
    discoverSlice: {
      this: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.DISCOVER}`,
      fetch: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.DISCOVER}/fetch`,
      requestPartnership: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.DISCOVER}/requestPartnership`,
    },
    partnershipsSlice: {
      this: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.PARTNERSHIPS}`,
      fetch: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.PARTNERSHIPS}/fetch`,
    },
    productsSlice: {
      this: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.PRODUCTS}`,
      fetch: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.PRODUCTS}/fetch`,
    },
    setupSlice: {
      this: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.SETUP}`,
      fetch: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.SETUP}/fetch`,
      oAuthBrandIntegration: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.SETUP}/oAuthBrandIntegration`,
      saveBrandIntegration: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.SETUP}/saveBrandIntegration`,
      oauthBillingPermissions: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.SETUP}/oauthBillingPermissions`,
    },
    settingsSlice: {
      this: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.SETTINGS}`,
      updateOrganizationDetails: `${UserSliceType.BRAND_USER}/${ViewSlicePrefix.SETTINGS}/updateOrganizationDetails`,
    },
  },

  partnerUser: {
    this: UserSliceType.PARTNER_USER,
    firstLoginSetup: `${UserSliceType.PARTNER_USER}/firstLoginSetup`,
    dashboardSlice: {
      this: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.DASHBOARD}`,
      fetch: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.DASHBOARD}/fetch`,
    },
    discoverSlice: {
      this: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.DISCOVER}`,
      fetch: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.DISCOVER}/fetch`,
      fetchProducts: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.DISCOVER}/fetchProducts`,
      requestPartnership: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.DISCOVER}/requestPartnership`,
    },
    partnershipsSlice: {
      this: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.PARTNERSHIPS}`,
      fetch: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.PARTNERSHIPS}/fetch`,
    },
    productsSlice: {
      this: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.PRODUCTS}`,
      fetch: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.PRODUCTS}/fetch`,
    },
    setupSlice: {
      this: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.SETUP}`,
      fetch: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.SETUP}/fetch`,
      editPartnerSetup: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.SETUP}/editSetup`,
    },
    settingsSlice: {
      this: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.SETTINGS}`,
      updateOrganizationDetails: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.SETTINGS}/updateOrganizationDetails`,
      editPartnerDomainSettings: `${UserSliceType.PARTNER_USER}/${ViewSlicePrefix.SETTINGS}/editPartnerDomainSettings`,
    },
  },

  adminUser: {
    this: UserSliceType.ADMIN_USER,
    createNewUsers: `${UserSliceType.ADMIN_USER}/createNewUsers`,
  },

  shared: {
    this: UserSliceType.SHARED,
    inviteToPlatformSlice: {
      this: `${UserSliceType.SHARED}/${ViewSlicePrefix.INVITE}`,
      inviteToPlatform: `${UserSliceType.SHARED}/${ViewSlicePrefix.INVITE}/inviteToPlatform`,
    },
  },
};

export { storePrefixes };
