import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";

export default function aggregateEntitiesIntoArrayByAKey<EntityType>(
  array: EntityType[],
  keyForAggregation: keyof EntityType,
): IdAndEntityPair<EntityType[]> {
  const idEntityPair: { [key: string]: EntityType[] } = {};
  array.forEach((el) => {
    const aggregationKey = el[keyForAggregation] as unknown as string;
    if (idEntityPair[aggregationKey]) {
      idEntityPair[aggregationKey].push(el);
    } else {
      idEntityPair[aggregationKey] = [el];
    }
  });
  return idEntityPair;
}
