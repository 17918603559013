import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { saveIntegration } from "shared/logic/store/features/viewsSlices/brandUser/setup/saveIntegration";
import { setBillingPermissions } from "shared/logic/store/features/viewsSlices/brandUser/setup/setBillingPermissions";
import { oAuthBrandIntegration } from "shared/logic/store/features/viewsSlices/brandUser/setup/oAuthBrandIntegration";
import { fetchExistingIntegrations } from "./fetchExistingIntegrations";

interface ExistingIntegrationsViewState {
  isSetupLoading: boolean;
  isDataInvalid: boolean;
  isDataFetched: boolean;
}

export const initialState: ExistingIntegrationsViewState = {
  isSetupLoading: false,
  isDataInvalid: false,
  isDataFetched: false,
};

const setupViewSlice = createSlice({
  name: "existingIntegrationsViewSliceForBrandUser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExistingIntegrations.pending, (state) => {
        state.isDataFetched = false;
      })
      .addCase(fetchExistingIntegrations.fulfilled, (state) => {
        state.isDataFetched = true;
      })
      .addCase(fetchExistingIntegrations.rejected, (state) => {
        state.isDataFetched = true;
      })
      .addMatcher(
        isAnyOf(
          fetchExistingIntegrations.pending,
          saveIntegration.pending,
          setBillingPermissions.pending,
          oAuthBrandIntegration.pending,
        ),
        (state) => {
          state.isSetupLoading = true;
        },
      )
      .addMatcher(
        isAnyOf(
          fetchExistingIntegrations.rejected,
          saveIntegration.rejected,
          setBillingPermissions.rejected,
          oAuthBrandIntegration.rejected,
          fetchExistingIntegrations.fulfilled,
          saveIntegration.fulfilled,
          setBillingPermissions.fulfilled,
          oAuthBrandIntegration.fulfilled,
        ),
        (state) => {
          state.isSetupLoading = false;
        },
      );
  },
});

export default setupViewSlice.reducer;
