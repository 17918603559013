import { Argument, PlainObject, Relation } from "shared/logic/types/Filters";
import { toLowerCase } from "shared/logic/store/filters/matchArgument/toLowerCase";

type Obj = PlainObject;

export interface MatchArgumentArgs {
  value: any;
  argument: Argument<Obj, any>;
}

type MatchArgumentReturn = boolean;

export function matchArgument({ value, argument }: MatchArgumentArgs): MatchArgumentReturn {
  switch (argument.relation) {
    case Relation.INCLUDES:
      return toLowerCase(value).includes(toLowerCase(argument.value));
    case Relation.LESS_THAN:
      return value < argument.value;
    case Relation.LESS_THAN_OR_EQUAL:
      return value <= argument.value;
    case Relation.MORE_THAN:
      return value > argument.value;
    case Relation.MORE_THAN_OR_EQUAL:
      return value >= argument.value;
    case Relation.EQUALS:
      return toLowerCase(value) === toLowerCase(argument.value);
    default:
      throw Error("INVALID RELATION");
  }
}
