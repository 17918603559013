import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import PartnershipsQuery from "shared/logic/types/Partnerships/PartnershipsQuery";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Product } from "shared/logic/types/Products/Product";
import { Partner } from "shared/logic/types/Partners/Partner";
import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import Partnership from "shared/logic/types/Partnerships/Partnership";
import { getBaseDataForBrandUser } from "shared/logic/store/features/viewsSlices/brandUser/getBaseDataForBrandUser";
import { getProductsByPartneredProducts } from "shared/logic/store/features/viewsSlices/utils/getProductsByPartneredProducts";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

interface FetchResourcesForDashboardViewReturnType {
  partnerships: IdAndEntityPair<Partnership>;
  partners: IdAndEntityPair<Partner>;
  products: IdAndEntityPair<Product>;
  partneredProducts: IdAndEntityPair<PartneredProduct>;
  partneredProductsAggregatedByPartnershipId?: IdAndEntityPair<PartneredProduct[]>;
}

interface FetchResourcesForDashboardViewPayload {
  partnershipsQuery: PartnershipsQuery;
}

export const fetchResourcesForDashboardView = createAsyncThunk(
  storePrefixes.brandUser.dashboardSlice.fetch,
  async (
    { partnershipsQuery }: FetchResourcesForDashboardViewPayload,
    { dispatch },
  ): Promise<FetchResourcesForDashboardViewReturnType> => {
    try {
      const {
        // PARTNERSHIPS
        partnershipsMap,
        // PARTNERS
        partnersMap,
        // PARTNERED PRODUCTS
        allPartneredProducts,
        filteredPartneredProductsMap,
      } = await getBaseDataForBrandUser({
        partnershipsQuery,
        partneredProductsQuery: { brandId: partnershipsQuery.brandId },
      });

      const { productsMap } = await getProductsByPartneredProducts(allPartneredProducts);

      return {
        partners: partnersMap,
        products: productsMap,
        partneredProducts: filteredPartneredProductsMap,
        partnerships: partnershipsMap,
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
