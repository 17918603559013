import { BrandInfo } from "views/partnerViews/Discover/BrandInfo";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Product } from "shared/logic/types/Products/Product";
import { ProductCards } from "shared/components/ProductCards";
import { useParams } from "react-router-dom";
import { CardDetails } from "views/partnerViews/Discover/ExploreProducts/CardDetails";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { storeAPI } from "views/partnerViews/storeAPI";

interface ExploreParams {
  id: string;
}

export const ExploreProducts = () => {
  const { id: brandId } = useParams<ExploreParams>();
  const brand = useSelector(storeAPI.discoverView.getCurrentlyOpenedBrand);
  const products = useSelector(storeAPI.discoverView.getBrandsInventory);
  const dispatch = useDispatch();
  const isLoading = useSelector(storeAPI.discoverView.getDiscoverBrandProductsIsLoading);
  const isEmpty = !isLoading && !products?.length;

  useEffect(() => {
    dispatch(storeAPI.discoverView.exploreProductsView.fetchResources({ brandId }));
  }, []);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <>
      {brand && (
        <BrandInfo
          imageUrl={brand.logoUrl}
          name={brand.name}
          brandId={brand.id}
          commissionPercent={brand.defaultPartnershipCommissionPercent}
          productsQuantity={products?.length}
        />
      )}
      {products && (
        <ProductCards<Product>
          products={products}
          isLoading={isLoading}
          isEmpty={isEmpty}
          DetailsComponent={CardDetails}
        />
      )}
    </>
  );
};
