import { FC } from "react";
import {
  FlexBox,
  SearchInputContainer,
} from "shared/components/TableComponents/FiltersContainer/FiltersContainer.styled";
import { useFiltersContainer } from "shared/components/TableComponents/FiltersContainer/useFiltersContainer";
import { TableTabs } from "shared/components/TableComponents/FiltersContainer/TableTabs";
import { SearchFilters } from "shared/components/TableComponents/FiltersContainer/SearchFilters";
import {
  DisplayVariant,
  LayoutVariantButtons,
} from "shared/components/TableComponents/FiltersContainer/LayoutVariantButtons";
import { FiltersComponentProps } from "shared/components/TableComponents/FiltersContainer/types";
import { FilterByCategory } from "./FilterByCategory";

export interface FiltersContainerProps {
  displayVariant?: DisplayVariant;
  setDisplayVariant?: (variant: DisplayVariant) => void;
  onSearchHandler?: (input: string) => void;
  FiltersComponent?: FC<FiltersComponentProps>; // TODO -- change to proper type during integration
}

export const FiltersContainer: FC<FiltersContainerProps> = ({
  displayVariant = DisplayVariant.LIST,
  setDisplayVariant,
  onSearchHandler,
  FiltersComponent,
}) => {
  const { shouldDisplayProductTabs } = useFiltersContainer();
  return (
    <SearchInputContainer>
      <FlexBox>{shouldDisplayProductTabs && <TableTabs />}</FlexBox>
      <FlexBox>
        {onSearchHandler && <SearchFilters onSearchHandler={onSearchHandler} />}
        {FiltersComponent && <FilterByCategory FiltersComponent={FiltersComponent} />}
        {displayVariant && setDisplayVariant && (
          <LayoutVariantButtons
            displayVariant={displayVariant}
            setDisplayVariant={setDisplayVariant}
          />
        )}
      </FlexBox>
    </SearchInputContainer>
  );
};
