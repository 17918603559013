import { UserRole } from "shared/components/auth/AuthContext";
import { Avatar } from "@mui/material";
import { UserAvatar } from "shared/components/UserAvatar";
import createInitials from "shared/logic/utils/createInitials";
import { FC } from "react";
import { UserPolicy } from "shared/logic/types/User/UserPolicy";

interface UserPolicyAvatarProps {
  policy: UserPolicy;
}

export const UserPolicyAvatar: FC<UserPolicyAvatarProps> = ({ policy }) => {
  const isPartnerUser = policy.entityType === UserRole.PARTNER;
  const isBrandUser = policy.entityType === UserRole.BRAND;
  if (isPartnerUser) {
    return policy.partner?.creatorPhotoUrl ? (
      <Avatar variant="rounded" src={policy.partner.creatorPhotoUrl} />
    ) : (
      <UserAvatar variant="rounded" initials={createInitials(policy?.partner?.name)} />
    );
  }
  if (isBrandUser) {
    return <Avatar variant="rounded" src={policy.brand?.logoUrl} />;
  }
  // Should not happen
  return <></>;
};

export default UserPolicyAvatar;
