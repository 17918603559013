import { styled, Box } from "@mui/material";
import { TabButton } from "shared/components/TableComponents/FiltersContainer/TableTabs/TableTabs.styled";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";
import gap from "shared/components/Styled/Gap";

export const TabWrapper = styled(Box)`
  display: flex;
  ${gap.left(1)};
`;

export const OrganizationTab = TabButton;
export const UserProfileTab = TabButton;
export const OrganizationLabel = (props: TextBaseProps) => (
  <TextBase variant="subtitle1" {...props} />
);
export const UserProfileLabel = (props: TextBaseProps) => (
  <TextBase variant="subtitle1" {...props} />
);
