import {
  styled,
  Box,
  LinkProps,
  Link,
  css,
  CheckboxProps,
  Checkbox as MuiCheckbox,
  InputAdornment,
  ButtonProps,
  Button,
} from "@mui/material";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";
import { forwardRef } from "react";
import { StyledAppLogo, LogoVariant, LogoSize } from "shared/components/Styled/StyledAppLogo";
import {
  InputBaseProps,
  LocalizedInput,
} from "shared/components/base/LocalizedInput/LocalizedInput";

export const NavigationWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const AgreeLabel = styled((props: TextBaseProps) => <TextBase {...props} variant="body1" />)`
  &::after {
    content: " ";
    white-space: pre;
  }
`;

export const StyledLink = styled((props: LinkProps) => <Link {...props} underline="none" />)(
  ({ theme }) => css`
    color: ${theme.palette.tertiary.main};
  `,
);

export const Checkbox = styled(
  forwardRef<HTMLButtonElement, CheckboxProps>((props, ref) => (
    <MuiCheckbox ref={ref} {...props} disableRipple color="tertiary" />
  )),
)(
  ({ theme }) => css`
    padding: ${theme.spacing(0, 1, 0, 0)};
  `,
);

export const CheckboxWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

export const CommissionRateDescription = styled((props: TextBaseProps) => (
  <TextBase {...props} variant="caption2" color="text.secondary" />
))(
  ({ theme }) => css`
    padding: ${theme.spacing(0.5, 2)};
    display: inline-block;
  `,
);

export const Form = styled("form")(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const TitleSection = styled(Box)(
  ({ theme }) => css`
    width: 100%;

    & > * + * {
      margin-top: ${theme.spacing(0.5)};
    }
  `,
);

export const Logo = styled(() => (
  <StyledAppLogo variant={LogoVariant.TERTIARY} size={LogoSize.DEFAULT} />
))`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Wrapper = styled(Box)`
  height: 100%;
  position: relative;
`;

export const Content = styled(Box)(
  ({ theme }) => css`
    max-width: 552px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);

export const CommissionRateLabel = (props: TextBaseProps) => (
  <TextBase {...props} variant="body1" />
);
export const Subtitle = (props: TextBaseProps) => (
  <TextBase {...props} variant="subtitle1" color="text.disabled" />
);
export const Title = (props: TextBaseProps) => (
  <TextBase {...props} variant="h3" color="text.secondary" />
);

export const CommissionRateInput = forwardRef<HTMLInputElement, InputBaseProps>((props, ref) => (
  <LocalizedInput
    ref={ref}
    {...props}
    fullWidth
    color="tertiary"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <TextBase variant="subtitle1">%</TextBase>
        </InputAdornment>
      ),
    }}
  />
));

export const DescriptionInput = forwardRef<HTMLInputElement, InputBaseProps>((props, ref) => (
  <LocalizedInput ref={ref} {...props} fullWidth color="tertiary" rows={5} multiline />
));

export const DescriptionLabel = (props: TextBaseProps) => <TextBase {...props} variant="body1" />;

export const ButtonLabel = (props: TextBaseProps) => <TextBase {...props} variant="subtitle1" />;

export const ContinueButton = (props: ButtonProps) => (
  <Button {...props} color="tertiary" variant="contained" />
);

export const NavigationInfo = (props: TextBaseProps) => (
  <TextBase {...props} variant="tableLabel" color="text.disabled" />
);

export const LinkLabel = (props: TextBaseProps) => <TextBase {...props} variant="body1" />;

export const CommissionInputWrapper = Box;
