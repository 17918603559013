import {
  BottomLabel,
  SubmitButton,
  SubmitLabel,
  BottomNavigationContainer,
} from "shared/components/Drawer/DrawerWrapper.styled";
import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import { FC } from "react";

export interface BottomNavigationProps {
  bottomLabel?: TextContentPath;
  submitLabel?: TextContentPath;
  submitAction?: () => void;
  disabled?: boolean;
  submitFormId?: string;
}

export const BottomNavigation: FC<BottomNavigationProps> = ({
  bottomLabel,
  submitLabel,
  submitAction,
  disabled,
  submitFormId,
}) => (
  <BottomNavigationContainer>
    {bottomLabel && <BottomLabel content={bottomLabel} />}
    {(submitAction || submitFormId) && submitLabel && (
      <SubmitButton
        onClick={submitAction}
        disabled={disabled}
        form={submitFormId}
        type={submitFormId ? "submit" : "button"}
      >
        <SubmitLabel content={submitLabel} />
      </SubmitButton>
    )}
  </BottomNavigationContainer>
);
