import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchResourcesForProductsView } from "shared/logic/store/features/viewsSlices/partnerUser/products/fetchResourcesForProductsView";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";
import { FiltersViewState } from "shared/logic/store/filters/types";
import { createSliceActions } from "shared/logic/store/filters/utils/createSliceActions";

interface ProductsViewState extends FiltersViewState<ProductAggregated> {
  productsViewSliceStatus: SliceStatus;
  isDataInvalid: boolean;
  isGetTagDrawerOpen: boolean;
  getTagProduct?: ProductAggregated;
  productsAggregatedIds: string[];
  partnerId?: string;
}

export const initialState: ProductsViewState = {
  productsViewSliceStatus: SliceStatus.IDLE,
  isDataInvalid: false,
  isGetTagDrawerOpen: false,
  getTagProduct: undefined,
  productsAggregatedIds: [],
};

const productsViewSliceForPartnerUser = createSlice({
  name: storePrefixes.partnerUser.productsSlice.fetch,
  initialState,
  reducers: {
    ...createSliceActions<ProductAggregated>(),
    toggleGetTagDialog: (state) => {
      state.isGetTagDrawerOpen = !state.isGetTagDrawerOpen;
    },
    setGetTagProduct: (state, action) => {
      state.getTagProduct = action.payload;
    },
    openGetTagDialog: (state, action: PayloadAction<ProductAggregated>) => {
      state.isGetTagDrawerOpen = true;
      state.getTagProduct = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourcesForProductsView.pending, (state) => {
        state.productsViewSliceStatus = SliceStatus.PENDING;
      })
      .addCase(fetchResourcesForProductsView.rejected, (state) => {
        state.productsViewSliceStatus = SliceStatus.REJECTED;
      })
      .addCase(fetchResourcesForProductsView.fulfilled, (state, action) => {
        const productsAggregatedIds = action.payload?.productsAggregatedIds;
        state.productsViewSliceStatus = SliceStatus.FULFILLED;
        state.partnerId = action.payload.partnerId;
        state.productsAggregatedIds = productsAggregatedIds || [];
      });
  },
});

export const { setGetTagProduct, toggleGetTagDialog, openGetTagDialog } =
  productsViewSliceForPartnerUser.actions;

export default productsViewSliceForPartnerUser.reducer;
