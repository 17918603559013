import { createAsyncThunk } from "@reduxjs/toolkit";
import PartneredProductsQuery from "shared/logic/types/PartneredProducts/PartneredProductsQuery";
import partnersApi from "shared/logic/api/partnersApi";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { getBaseDataForPartnerUser } from "shared/logic/store/features/viewsSlices/partnerUser/getBaseDataForPartnerUser";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

export const fetchResourcesForProductsView = createAsyncThunk(
  storePrefixes.partnerUser.productsSlice.fetch,
  async (productsQuery: PartneredProductsQuery, { dispatch }) => {
    try {
      if (!productsQuery.partnerId) {
        throw new Error("No partnerId provided");
      }

      // ############################################################
      // PARTNER
      const partnersPromise = partnersApi.getPartners();

      // ############################################################
      // BASE DATA
      const baseDataPromise = getBaseDataForPartnerUser({
        partnershipsQuery: {
          partnerId: productsQuery.partnerId,
        },
        partneredProductsQuery: productsQuery,
      });

      const [partnersResponse, baseData] = await Promise.all([partnersPromise, baseDataPromise]);

      const partner = partnersResponse.data.partners.find((p) => p.id === productsQuery.partnerId);
      const {
        partnershipsMap,
        brandsMap,
        filteredPartneredProductsMap,
        filteredProductsMap,
        filteredPartneredProducts,
      } = baseData;

      return {
        // RESOURCE SLICES DATA
        brands: brandsMap,
        partneredProducts: filteredPartneredProductsMap,
        partners: parseArrayToIdsEntityPair([partner!]),
        partnerships: partnershipsMap,
        products: filteredProductsMap,

        // VIEW SLICE DATA
        partnerId: partner?.id,
        productsAggregatedIds: filteredPartneredProducts.map((product) => product.id),
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
