import { FC } from "react";
import { WarningRounded } from "@mui/icons-material";
import {
  SpaceBetweenWrapper,
  ContentSection,
  NavigationContent,
} from "views/partnerViews/Setup/StepDrawer/StepDrawer.styled";
import { HelpSection } from "views/partnerViews/Setup/StepDrawer/HelpSection";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { Partner } from "shared/logic/types/Partners/Partner";
import {
  WarningAlert,
  DomainInfo,
  SaveButton,
  BackButton,
  AlertTitle,
  DomainName,
} from "./DomainEdited.styled";
import { useDomainEdited } from "./useDomainEdited";

interface DomainEditedProps {
  partner?: Partner;
}

export const DomainEdited: FC<DomainEditedProps> = ({ partner }) => {
  const { onSaveClick, onBackClick, domainToSave } = useDomainEdited(partner);
  return (
    <SpaceBetweenWrapper>
      <ContentSection>
        <TextBase variant="h3" content="setup.partner.allDone" />
        <DomainInfo>
          <TextBase variant="body1" content="setup.partner.newDomainTitle" />
          {/* TODO - use currently edited domain name */}
          <DomainName>{domainToSave}</DomainName>
        </DomainInfo>
        <WarningAlert severity="warning">
          <AlertTitle>
            <WarningRounded color="warning" />
            <TextBase variant="subtitle1" content="utility.warning" />
          </AlertTitle>
          <TextBase variant="body1" content="setup.partner.warningDescription" />
        </WarningAlert>
        <HelpSection />
      </ContentSection>
      <NavigationContent>
        <BackButton color="tertiary" variant="outlined" onClick={onBackClick}>
          <TextBase variant="subtitle1" content="navigation.back" color="text.secondary" />
        </BackButton>
        <SaveButton color="tertiary" variant="contained" onClick={onSaveClick}>
          <TextBase variant="subtitle1" content="setup.partner.saveDomain" />
        </SaveButton>
      </NavigationContent>
    </SpaceBetweenWrapper>
  );
};
