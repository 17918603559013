import { FC } from "react";
import { Menu } from "@mui/icons-material";
import { StyledAppLogo, LogoVariant } from "shared/components/Styled/StyledAppLogo";
import { StyledAppBar, StyledToolbar, StyledButton } from "./MobileTopbar.styled";

export interface MobileTopbarProps {
  onButtonClick: () => void;
}

export const MobileTopbar: FC<MobileTopbarProps> = ({ onButtonClick }) => (
  <StyledAppBar elevation={0}>
    <StyledToolbar>
      <StyledAppLogo variant={LogoVariant.TERTIARY} />
      <StyledButton onClick={onButtonClick} disableRipple>
        <Menu />
      </StyledButton>
    </StyledToolbar>
  </StyledAppBar>
);
