import { Box, Button, ButtonProps, css, RadioGroup, styled } from "@mui/material";
import { LocalizedFormControlLabel } from "shared/components/base/LocalizedFormControlLabel";
import { borderRadiusHelper } from "shared/logic/utils/theme/borderRadiusHelper";
import { TextBase, TextBaseProps } from "shared/components/base/TextBase/TextBase";
import React, { forwardRef } from "react";
import {
  InputBaseProps,
  LocalizedInput,
} from "shared/components/base/LocalizedInput/LocalizedInput";

export const Form = styled("form")(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export type StyledFormControlProps = {
  $active: boolean;
};

export const StyledFormControlLabel = styled(LocalizedFormControlLabel)<StyledFormControlProps>(
  ({ theme, $active }) => css`
    margin-left: 0;
    width: 100%;
    border-radius: ${borderRadiusHelper(theme.shape.borderRadius, 1.5)}px;
    position: relative;
    overflow: hidden;
    border: 1px solid ${$active ? theme.palette.tertiary.main : theme.palette.border};

    span {
      font-size: ${theme.typography.h5.fontSize}px;
      font-weight: ${theme.typography.h5.fontWeight};
    }

    &::after {
      display: ${$active ? "block" : "none"};
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: ${theme.palette.tertiary.main};
      opacity: 0.04;
    }
    ,
    & > * + * {
      padding: ${theme.spacing(1)};
    }
  `,
);

export const RolesWrapper = styled(Box)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(1)};
    }
  `,
);

export const StyledIcon = styled(Box)(
  ({ theme }) => css`
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${theme.palette.background.onSurface};
    border-radius: ${theme.shape.borderRadius}px;
  `,
);

export const RolesInputWrapper = styled(RadioGroup)(
  // eslint-disable-next-line sonarjs/no-identical-functions
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(2)};
    }
  `,
);

export const ButtonWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const SubTitle = (props: TextBaseProps) => (
  <TextBase {...props} color="text.secondary" variant="body1" />
);

export const SelectRole = (props: TextBaseProps) => (
  <TextBase {...props} variant="body1" color="text.secondary" />
);

export const ButtonText = (props: TextBaseProps) => <TextBase {...props} variant="subtitle1" />;

export const AccountNameInput = forwardRef<HTMLInputElement, InputBaseProps>((props, ref) => (
  <LocalizedInput ref={ref} {...props} fullWidth color="tertiary" />
));

export const StyledButton = (props: ButtonProps) => (
  <Button {...props} variant="contained" color="tertiary" />
);

export const Wrapper = styled(Box)``;
