import axiosInstance from "shared/logic/api/axiosInstance";
import { CreateCompanyDTO, Company } from "shared/logic/types/Companies/Company";

const postCompany = (newCompanyData: CreateCompanyDTO): Promise<{ data: Company }> =>
  axiosInstance.post("/companies", newCompanyData);

const deleteCompany = (companyId: string) => axiosInstance.delete(`/companies/${companyId}`);

export default {
  postCompany,
  deleteCompany,
};
