import { FC } from "react";
import { NavigationSidebar } from "views/NavigationSidebar";
import { StyledBox, StyledFlexbox, StyledContent } from "views/Layout/Layout.styled";
import { MobileTopbar } from "views/MobileTopbar";
import { Toolbar } from "@mui/material";
import { useLayout } from "views/Layout/useLayout";
import { BackButton } from "shared/components/BackButton";
import { OverflowColumnFlex } from "shared/components/OverflowColumnFlex";

export const Layout: FC = ({ children }) => {
  const {
    shouldDisplayNavigation,
    shouldDisplayBackBtn,
    shouldDisplaySidebar,
    isBackgroundColor,
    isMobileView,
    toggleSidebar,
    onBackButtonClick,
  } = useLayout();
  if (isMobileView) {
    return (
      <StyledBox>
        {shouldDisplayNavigation && (
          <Toolbar>
            <MobileTopbar onButtonClick={toggleSidebar} />
            <NavigationSidebar
              variant="temporary"
              open={shouldDisplaySidebar}
              onClose={toggleSidebar}
              toggleSidebar={toggleSidebar}
              shouldDisplayCloseButton
            />
          </Toolbar>
        )}
        <StyledContent $isBackgroundColor={isBackgroundColor}>{children}</StyledContent>
      </StyledBox>
    );
  }
  return (
    <StyledFlexbox>
      {shouldDisplayNavigation && <NavigationSidebar variant="permanent" />}
      <StyledContent $isBackgroundColor={isBackgroundColor}>
        {shouldDisplayBackBtn && <BackButton onClick={onBackButtonClick} />}
        <OverflowColumnFlex>{children}</OverflowColumnFlex>
      </StyledContent>
    </StyledFlexbox>
  );
};
