import { useDispatch } from "react-redux";
import { useMemo } from "react";
import {
  setDialog,
  ChosenDialogEnum,
} from "shared/logic/store/features/resourceSlices/dialog/dialogSlice";
import { Product } from "shared/logic/types/Products/Product";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { makeProductAvailable } from "shared/logic/store/features/viewsSlices/brandUser/assign/makeProductAvailable";
import { PartnerAggregated } from "shared/logic/store/features/viewsSlices/brandUser/assign/selectors";

interface UsePartnerItemProps {
  product: Product;
  partner: PartnerAggregated;
}

export const usePartnerItem = ({ product, partner }: UsePartnerItemProps) => {
  const dispatch = useDispatch();
  const partneredProduct = useMemo(
    () => partner.partneredProducts?.find((p) => p.productId === product.id),
    [partner.partneredProducts, product],
  ); // TODO - change aggregation structure to swap find with id-entity selection
  const commissionPercentToDisplay =
    partner.partnership?.commissionPercent ?? partner.defaultCommissionPercent;
  const onAvailableChange = async () => {
    if (!partneredProduct) {
      dispatch(
        makeProductAvailable({
          productId: product.id,
          partnershipId: partner.partnership.id,
        }),
      );
    } else {
      dispatch(
        setDialog({
          open: true,
          title: "dialog.revokeProduct",
          chosenDialog: ChosenDialogEnum.PRODUCT_REVOCATION,
          dialogProps: {
            product,
            partneredProduct,
          },
        }),
      );
    }
  };
  const onMessageClick = () => {
    if (!partneredProduct) {
      dispatch(
        setSnackbar({
          open: true,
          message: "snackbar.rejectedMessageError",
        }),
      );
      return;
    }
    dispatch(
      setDialog({
        open: true,
        title: "dialog.rejectedProduct",
        dialogProps: { product, partneredProduct },
        chosenDialog: ChosenDialogEnum.REJECTION_MESSAGE,
      }),
    );
  };
  return {
    onAvailableChange,
    isAvailable: !!partneredProduct,
    onMessageClick,
    productStatus: partneredProduct?.partnerApprovalStatus,
    commissionPercentToDisplay,
  };
};
