import { styled, Box, css, Button } from "@mui/material";

export const StyledWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const StyledTextWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * + * {
      margin-top: ${theme.spacing(1)};
    }
  `,
);

export const BackButton = styled(Button)``;
