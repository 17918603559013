import { styled, IconButton, css, Box } from "@mui/material";
import { Close, Check } from "@mui/icons-material";

export const StyledApproveButton = styled(IconButton)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${theme.palette.tertiary.main};
    color: ${theme.palette.common.white};
    height: 40px;
    width: 40px;

    :hover {
      background-color: ${theme.palette.tertiary.dark};
    }
  `,
);

export const StyledRejectButton = styled(IconButton)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    color: ${theme.palette.tertiary.main};
    height: 40px;
    width: 40px;
  `,
);

export const StyledWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledCloseIcon = styled(Close)`
  width: 20px;
  height: 20px;
`;

export const StyledApproveIcon = styled(Check)`
  width: 20px;
  height: 20px;
`;
