import { TextBase } from "shared/components/base/TextBase/TextBase";
import { useTableTabs } from "shared/components/TableComponents/FiltersContainer/TableTabs/useTableTabs";
import { StyledBadge, TabButton } from "./TableTabs.styled";

export const TableTabs = () => {
  const {
    onPendingClick,
    isPendingActive,
    isApprovedActive,
    onApprovedClick,
    pendingProductCounts,
  } = useTableTabs();
  return (
    <>
      <TabButton $active={isApprovedActive} onClick={onApprovedClick} color="tertiary">
        <TextBase variant="subtitle1" content="products.approved" />
      </TabButton>
      <TabButton $active={isPendingActive} color="tertiary" onClick={onPendingClick}>
        <TextBase variant="subtitle1" content="products.pending" />
        {pendingProductCounts > 0 && (
          <StyledBadge color="secondary" badgeContent={pendingProductCounts} />
        )}
      </TabButton>
    </>
  );
};
