import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { storeAPI } from "views/brandViews/storeAPI";

export const useAssignProduct = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const partnersAggregated = useSelector(storeAPI.assignView.getPartnersAggregated);
  const { isLoading, arePartnersEmpty } = useSelector(storeAPI.assignView.getAssignSliceState);
  const policy = useSelector(getUserPolicy);
  const productFromStore = useSelector(storeAPI.assignView.getProduct);
  const productIdFromQueryParams = useMemo(() => search.split("?")[1], [search]);
  useEffect(() => {
    if (productIdFromQueryParams) {
      dispatch(
        storeAPI.assignView.fetchResources({
          brandId: policy!.entityId,
          productId: productIdFromQueryParams,
        }),
      );
    }
  }, [productIdFromQueryParams, policy?.entityId]);

  return {
    product: productFromStore,
    partners: partnersAggregated,
    isLoading,
    isEmpty: arePartnersEmpty,
  };
};
