import { useState } from "react";

export enum CodeBoxState {
  CLOSE = "CLOSE",
  OPEN = "OPEN",
}

export const useCodeBox = () => {
  const codeBoxVariants: Record<CodeBoxState, { height: string }> = {
    [CodeBoxState.CLOSE]: {
      height: "160px",
    },
    [CodeBoxState.OPEN]: {
      height: "unset",
    },
  };
  const [codeBoxAnimation, setCodeBoxAnimation] = useState<CodeBoxState>(CodeBoxState.CLOSE);
  const isOpen = codeBoxAnimation === CodeBoxState.OPEN;

  const toggleCodeBoxState = () => {
    setCodeBoxAnimation((prev) =>
      prev === CodeBoxState.CLOSE ? CodeBoxState.OPEN : CodeBoxState.CLOSE,
    );
  };

  return {
    toggleCodeBoxState,
    codeBoxAnimation,
    codeBoxVariants,
    isOpen,
  };
};
