import { createAsyncThunk } from "@reduxjs/toolkit";
import inviteApi from "shared/logic/api/inviteApi";
import { InviteToPlatformProps } from "shared/logic/types/InviteToPlatform/InviteToPlatform";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { AxiosError } from "axios";
import HttpStatusCode from "shared/logic/_enums/HttpStatusCodes";

export const inviteToPlatform = createAsyncThunk(
  storePrefixes.shared.inviteToPlatformSlice.inviteToPlatform,
  async (payload: InviteToPlatformProps, { dispatch }) => {
    try {
      await inviteApi.postReferral(payload);
      dispatch(
        setSnackbar({ message: "snackbar.inviteToPlatformSuccess", icon: SnackbarIcon.DONE }),
      );
    } catch (e) {
      const error = e as AxiosError;
      ConsoleLog(error);
      if (error.response?.status === HttpStatusCode.CONFLICT) {
        dispatch(setSnackbar({ message: "snackbar.inviteToPlatformExists" }));
        throw e;
      }
      dispatch(setSnackbar({ message: "snackbar.genericError" }));
      throw e;
    }
  },
);
