import { createAsyncThunk } from "@reduxjs/toolkit";
import productsApi from "shared/logic/api/productsApi";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

interface BrandProductsQueryParams {
  brandId: string;
}

export const fetchResourcesForProductsView = createAsyncThunk(
  storePrefixes.brandUser.productsSlice.fetch,
  async (params: BrandProductsQueryParams, { dispatch }) => {
    try {
      const productsDetailsResponse = await productsApi.getProductsDetailedInfo({
        "brand-id": params.brandId,
      });
      const { products } = productsDetailsResponse.data;

      return {
        // RESOURCE SLICES DATA
        products: parseArrayToIdsEntityPair(products),

        // VIEW SLICE DATA
        productsIds: products.map((product) => product.id),
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
