import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { getProductsAggregatedArray } from "shared/logic/store/features/resourceSlices/partneredProducts/selectors";
import { storeAPI } from "views/partnerViews/storeAPI";

const useDashboardForPartnerUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const policy = useSelector(getUserPolicy);
  const isDashboardDataFetched = useSelector(storeAPI.dashboardView.getIsDashboardDataFetched);
  const partnerId = policy && policy.entityId;
  const productsAggregated = useSelector(getProductsAggregatedArray);
  const partnershipsAggregated = useSelector(storeAPI.dashboardView.getPartnershipsAggregated);

  useEffect(() => {
    dispatch(
      storeAPI.dashboardView.fetchResources({
        partnershipsQuery: { partnerId },
        productsQuery: { partnerId },
      }),
    );
  }, [partnerId]);

  return {
    productsAggregated,
    partnershipsAggregated,
    history,
    isDashboardDataFetched,
    policy,
  };
};

export default useDashboardForPartnerUser;
