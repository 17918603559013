import { RoutesConfig } from "views/Routing/NotAuthenticatedRouting/types";
import { UserSetupStatus } from "shared/logic/types/User/UserStatus";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import PartnerView from "views/partnerViews";
import BrandView from "views/brandViews";

export function userSetupStatusToRoutesConfig(
  userSetupStatus: UserSetupStatus,
): RoutesConfig | null {
  switch (userSetupStatus) {
    case UserSetupStatus.PARTNER_FIRST_LOGIN:
      return {
        mainRedirect: RouteEnum.FIRST_LOGIN,
        routes: [{ path: RouteEnum.FIRST_LOGIN, component: PartnerView.FirstLogin }],
      };
    case UserSetupStatus.BRAND_FIRST_LOGIN:
      return {
        mainRedirect: RouteEnum.FIRST_LOGIN,
        routes: [{ path: RouteEnum.FIRST_LOGIN, component: BrandView.FirstLogin }],
      };
    // FOLLOWING SHOULD NOT HAPPEN
    case UserSetupStatus.PARTNER_SETUP:
    case UserSetupStatus.BRAND_SETUP:
    case UserSetupStatus.NO_NEED:
    default:
      return null;
  }
}
