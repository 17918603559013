import { BooleanFunction } from "shared/logic/types/Filters";
import some from "lodash-es/some";
import every from "lodash-es/every";
import { Predicate } from "shared/logic/store/filters/types";

interface FilterArgs<TEntry> {
  booleanFunction: BooleanFunction;
  entries: TEntry[];
  predicate: Predicate<TEntry>;
}

export function filter<TEntry>({
  booleanFunction,
  entries,
  predicate,
}: FilterArgs<TEntry>): boolean {
  switch (booleanFunction) {
    case BooleanFunction.OR:
      return some(entries, predicate);
    case BooleanFunction.AND:
      return every(entries, predicate);
    default:
      throw Error("INVALID BOOLEAN FUNCTION");
  }
}
