import { styled, Box, css, Button } from "@mui/material";

export const StyledHeadline = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCopyBtn = styled(Button)(
  ({ theme }) => css`
    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);
