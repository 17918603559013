import { FC, Fragment } from "react";
import { List, DrawerProps } from "@mui/material";
import {
  StyledNavigation,
  NavigationContent,
  StyledDivider,
  StyledIconButton,
  StyledToolbar,
  UserAccountWrapper,
} from "views/NavigationSidebar/NavigationSidebar.styled";
import { NavigationItem } from "views/NavigationSidebar/NavigationItem";
import { UserAccountItem } from "views/NavigationSidebar/UserAccountItem";
import { useNavigationSidebar } from "views/NavigationSidebar/useNavigationSidebar";
import { Login, Close } from "@mui/icons-material";
import { StyledAppLogo, LogoVariant } from "shared/components/Styled/StyledAppLogo";

export interface NavigationSidebarProps extends DrawerProps {
  toggleSidebar?: () => void;
  shouldDisplayCloseButton?: boolean;
}

export const NavigationSidebar: FC<NavigationSidebarProps> = ({
  shouldDisplayCloseButton,
  toggleSidebar,
  ...materialProps
}) => {
  const { navigationListItems, logout, roleGuard } = useNavigationSidebar();
  return (
    <StyledNavigation anchor="left" {...materialProps}>
      <StyledToolbar>
        <StyledAppLogo variant={LogoVariant.WHITE} />
        {shouldDisplayCloseButton && (
          <StyledIconButton disableRipple onClick={toggleSidebar}>
            <Close />
          </StyledIconButton>
        )}
      </StyledToolbar>
      <NavigationContent>
        <UserAccountWrapper>
          <UserAccountItem />
          <StyledDivider />
          <List>
            {navigationListItems.map(
              ({
                MaterialIcon,
                localizedText,
                onClick,
                isSelected,
                shouldPutDividerAfter,
                badgeContent,
                title,
                role,
              }) =>
                roleGuard(role) && (
                  <Fragment key={localizedText as string}>
                    <NavigationItem
                      localizedText={localizedText}
                      MaterialIcon={MaterialIcon}
                      isSelected={isSelected}
                      onClick={onClick}
                      badgeContent={badgeContent}
                      title={title}
                    />
                    {shouldPutDividerAfter && <StyledDivider />}
                  </Fragment>
                ),
            )}
          </List>
        </UserAccountWrapper>
        <NavigationItem localizedText="navigation.signOut" MaterialIcon={Login} onClick={logout} />
      </NavigationContent>
    </StyledNavigation>
  );
};
