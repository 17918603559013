import { styled, Box, css, Button } from "@mui/material";

export const StyledContentWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${theme.spacing(2)};
    }
  `,
);

export const StyledBottomWrapper = styled(Box)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);

export const AcceptButton = styled(Button)``;
