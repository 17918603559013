import { createSlice } from "@reduxjs/toolkit";
import { fetchResourcesForDiscoverView } from "shared/logic/store/features/viewsSlices/partnerUser/discover/fetchResourcesForDiscoverView";
import { fetchProductsForDiscoveredBrand } from "shared/logic/store/features/viewsSlices/partnerUser/discover/fetchProductsForDiscoveredBrand";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { requestPartnershipWithBrand } from "shared/logic/store/features/viewsSlices/partnerUser/discover/requestPartnershipWithBrand";

interface DiscoverViewState {
  areProductsLoading: boolean;
  isBrandsLoading: boolean;
  brandIds: string[];
  currentlyOpenedBrandId: string | null;
}

export const initialState: DiscoverViewState = {
  areProductsLoading: true,
  isBrandsLoading: true,
  brandIds: [],
  currentlyOpenedBrandId: null,
};

const discoverViewSliceForPartnerUser = createSlice({
  name: storePrefixes.partnerUser.discoverSlice.this,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourcesForDiscoverView.pending, (state) => {
        state.isBrandsLoading = true;
      })
      .addCase(fetchResourcesForDiscoverView.fulfilled, (state, action) => {
        state.isBrandsLoading = false;
        state.brandIds = action.payload.brandIds ?? [];
      })
      .addCase(fetchProductsForDiscoveredBrand.pending, (state, action) => {
        state.areProductsLoading = true;
        state.currentlyOpenedBrandId = action.meta.arg.brandId;
      })
      .addCase(fetchProductsForDiscoveredBrand.fulfilled, (state) => {
        state.areProductsLoading = false;
      })
      .addCase(requestPartnershipWithBrand.pending, (state) => {
        state.isBrandsLoading = true;
      })
      .addCase(requestPartnershipWithBrand.fulfilled, (state) => {
        state.isBrandsLoading = false;
      });
  },
});

export default discoverViewSliceForPartnerUser.reducer;
