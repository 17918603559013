import { styled, Box, ButtonProps, Button, css, SvgIconProps, AvatarProps } from "@mui/material";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";
import { Share } from "@mui/icons-material";
import { Image } from "shared/components/TableComponents/Styles";

export const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const ShareButton = styled((props: ButtonProps) => <Button size="small" {...props} />)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const ShareText = styled((props: TextBaseProps) => (
  <TextBase variant="subtitle1" {...props} />
))`
  white-space: pre;
`;

export const ShareIcon = (props: SvgIconProps) => <Share color="tertiary" {...props} />;
export const UserAvatar = (props: AvatarProps) => <Image variant="rounded" {...props} />;
