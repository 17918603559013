import { styled, Box, AvatarProps, Avatar, css } from "@mui/material";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";

export const MixedText = styled(Box)`
  display: flex;
  align-items: center;
  & > * + *::before {
    content: " ";
    white-space: pre;
  }
`;

export const BrandLogo = styled((props: AvatarProps) => <Avatar variant="rounded" {...props} />)`
  height: 48px;
  width: 48px;
`;

export const PriceWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    & > * + *::before {
      content: "+";
      margin: ${theme.spacing(0, 0.5)};
    }
  `,
);

export const InfoWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    & > * + * {
      margin-left: ${theme.spacing(2)};
    }
  `,
);

export const DetailsWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    & > * + *::before {
      content: "·";
      margin: ${theme.spacing(0, 1)};
    }
  `,
);

export const StyledWrapper = styled(Box)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    display: flex;
    padding: ${theme.spacing(2)};
    background-color: ${theme.palette.background.onSurface};
    justify-content: space-between;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(2)};
    }
  `,
);

export const Description = styled((props: TextBaseProps) => (
  <TextBase color="text.secondary" {...props} />
))`
  white-space: nowrap;
`;

export const Name = (props: TextBaseProps) => <TextBase variant="subtitle1" {...props} />;
export const Quantity = (props: TextBaseProps) => <TextBase variant="subtitle1" {...props} />;

export const ProductsQuantity = styled(MixedText)``;
export const CommissionPercent = styled(MixedText)``;
export const FlatFee = styled(MixedText)``;
