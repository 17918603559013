import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  TFunctionArgs,
  tFunctionWithMarkdownCreator,
} from "shared/logic/utils/translations/tFunctionWithMarkdown";

export function useTranslationWithMarkdown() {
  const { t } = useTranslation();

  const tWithMarkdown = useMemo(() => tFunctionWithMarkdownCreator(t), []);

  const tFunct = useCallback((...tArgs: TFunctionArgs) => tWithMarkdown(...tArgs), [tWithMarkdown]);

  return { t: tFunct };
}
