import { Dispatch } from "@reduxjs/toolkit";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";

export function dispatchSnackbarGenericError(dispatch: Dispatch) {
  dispatch(
    setSnackbar({
      message: "snackbar.genericError",
      open: true,
    }),
  );
}
