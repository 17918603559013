import { useDispatch } from "react-redux";
import {
  ChosenDialogEnum,
  setDialog,
} from "shared/logic/store/features/resourceSlices/dialog/dialogSlice";
import { ProductItemProps } from "views/brandViews/AssignPartner/ProductItem/index";
import { storeAPI } from "views/brandViews/storeAPI";

type UseProductItemProps = ProductItemProps;

export const useProductItem = ({ product }: UseProductItemProps) => {
  const dispatch = useDispatch();
  const { partneredProduct } = product;
  const productStatus = partneredProduct?.partnerApprovalStatus;
  const isAvailable = !!productStatus;

  const onAvailableChange = async () => {
    if (!partneredProduct) {
      dispatch(
        storeAPI.assignView.makeProductAvailable({
          productId: product.id,
          partnershipId: product.partnership.id,
        }),
      );
      return;
    }
    dispatch(
      setDialog({
        open: true,
        title: "dialog.revokeProduct",
        chosenDialog: ChosenDialogEnum.PRODUCT_REVOCATION,
        dialogProps: {
          product,
          partneredProduct,
        },
      }),
    );
  };

  const onMessageClick = () => {
    dispatch(
      setDialog({
        open: true,
        title: "dialog.rejectedProduct",
        dialogProps: {
          product,
          partneredProduct: partneredProduct!,
        },
        chosenDialog: ChosenDialogEnum.REJECTION_MESSAGE,
      }),
    );
  };

  return {
    isAvailable,
    onAvailableChange,
    onMessageClick,
    productStatus,
  };
};
