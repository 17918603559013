import { TextBase, TextContentPath } from "shared/components/base/TextBase/TextBase";
import { Typography } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { StyledButtonPaddingCompensation } from "shared/components/Styled/StyledButtonPaddingCompensation";
import { FC } from "react";
import { StyledCountRow, StyledWrapper } from "./CountInfo.styled";

interface CountInfoProps {
  heading: TextContentPath;
  count: number;
  onBrowseClick: () => void;
  Icon: SvgIconComponent;
}

export const CountInfo: FC<CountInfoProps> = ({ heading, count, onBrowseClick, Icon }) => (
  <StyledWrapper>
    <TextBase variant="subtitle1" color="text.disabled" content={heading} />
    <StyledCountRow>
      <Typography variant="h2">{count}</Typography>
      <Icon color="tertiary" sx={{ height: 40, width: 40 }} />
    </StyledCountRow>
    <StyledButtonPaddingCompensation color="tertiary" onClick={onBrowseClick} size="small">
      <TextBase variant="caption" content="navigation.browse" />
    </StyledButtonPaddingCompensation>
  </StyledWrapper>
);
