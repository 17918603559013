import { useState, useRef } from "react";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";

interface UseImageUploadProps {
  onUpload: (file: File) => void;
  previousImageUrl?: string;
}

export const useImageUpload = ({ onUpload, previousImageUrl }: UseImageUploadProps) => {
  const dispatch = useDispatch();
  const imageRef = useRef<HTMLInputElement>(null);
  const [uploadedImage, setUploadedImage] = useState(previousImageUrl ?? "");

  const onUploadClick = () => {
    if (!imageRef.current) {
      return;
    }
    imageRef.current.click();
  };
  const onFileUpload = () => {
    if (!imageRef.current || !imageRef.current.files) {
      return;
    }
    const image = imageRef.current.files[0];
    if (image.size > 2 * 1e6) {
      dispatch(
        setSnackbar({
          open: true,
          message: "snackbar.fileLimitExceeded",
        }),
      );
      return;
    }
    setUploadedImage(URL.createObjectURL(imageRef.current.files[0]));
    onUpload(image);
  };

  return {
    uploadedImage,
    imageRef,
    onFileUpload,
    onUploadClick,
  };
};
