import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { useEffect } from "react";
import { NoIntegrations } from "views/brandViews/Setup/NoIntegrations";
import { IntegrationDetails } from "views/brandViews/Setup/IntegrationDetails";
import { BillingPermissions } from "views/brandViews/Setup/BillingPermissions";
import { FinaliseIntegration } from "views/brandViews/Setup/FinaliseIntegration";
import { StepWrapper } from "views/brandViews/Setup/StepWrapper";
import { ExistingIntegrations } from "views/brandViews/Setup/ExistingIntegrations";
import { useDispatch, useSelector } from "react-redux";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { storeAPI } from "views/brandViews/storeAPI";

export enum SetupStepEnum {
  INTEGRATION_DETAILS = "integration-details",
  BILLING_PERMISSIONS = "billing-permissions",
  FINALISE_INTEGRATION = "finalise-integration",
  COMPLETED = "completed",
}

export const useSetup = () => {
  const existingIntegrations = useSelector(storeAPI.setupView.getExistingIntegrations);
  const isLoading = useSelector(storeAPI.setupView.getIsSetupLoading);
  const isFetched = useSelector(storeAPI.setupView.getIsExistingIntegrationsFetched);
  const dispatch = useDispatch();
  const policy = useSelector(getUserPolicy);
  const [searchParams] = useSearchParams();
  const integrationsExist =
    isFetched && !!existingIntegrations.length && !!policy?.brand?.privacyPolicyUrl;
  const stepToRender = () => {
    if (isLoading) {
      return <FullScreenLoader />;
    }
    if (integrationsExist) {
      return <ExistingIntegrations />;
    }
    switch (searchParams.step) {
      case SetupStepEnum.INTEGRATION_DETAILS: {
        return (
          <StepWrapper step={1} title="setup.integrationDetails.title">
            <IntegrationDetails />
          </StepWrapper>
        );
      }
      case SetupStepEnum.BILLING_PERMISSIONS:
        return (
          <StepWrapper step={2} title="setup.billingPermissions.title">
            <BillingPermissions />
          </StepWrapper>
        );
      case SetupStepEnum.FINALISE_INTEGRATION:
        return (
          <StepWrapper step={3} title="setup.finaliseIntegration.title">
            <FinaliseIntegration />
          </StepWrapper>
        );
      default:
        return <NoIntegrations />;
    }
  };
  useEffect(() => {
    dispatch(storeAPI.setupView.fetchResources({ brandId: policy!.entityId }));
  }, [policy?.entityId]);
  return { stepToRender };
};
