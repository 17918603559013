import { createSlice } from "@reduxjs/toolkit";
import { fetchResourcesForAssignView } from "shared/logic/store/features/viewsSlices/brandUser/assign/fetchResourcesForAssignView";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";

interface AssignViewState {
  assignSliceStatus: SliceStatus;
  isDataInvalid: boolean;
  productId: string | null;
  partnerId: string | null;
  partnersIds: string[] | null;
}

export const initialState: AssignViewState = {
  assignSliceStatus: SliceStatus.IDLE,
  isDataInvalid: false,
  productId: null,
  partnerId: null,
  partnersIds: null,
};

const assignViewSliceForBrandUser = createSlice({
  name: storePrefixes.brandUser.assignSlice.this,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourcesForAssignView.pending, (state) => {
        state.assignSliceStatus = SliceStatus.PENDING;
      })
      .addCase(fetchResourcesForAssignView.fulfilled, (state, action) => {
        const { productId, partnerId, partnersIds } = action.payload;
        state.assignSliceStatus = SliceStatus.FULFILLED;
        state.productId = productId ?? null;
        state.partnerId = partnerId ?? null;
        state.partnersIds = partnersIds ?? null;
      });
  },
});

export default assignViewSliceForBrandUser.reducer;
