import { styled, css, Box, Button } from "@mui/material";

export const StyledInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledButton = styled(Button)(
  ({ theme }) => css`
    min-width: 0;
    :hover {
      background-color: transparent;
    }

    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const CommissionBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const CommissionDetail = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
