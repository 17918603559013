import { Typography } from "@mui/material";
import { FC } from "react";
import { Image } from "shared/components/TableComponents/Styles";
import { StyledDetailsWrapper, StyledRow, StyledInfoWrapper } from "./ListItem.styled";

export interface ListItemProps {
  image?: string;
  count?: number;
  commissionPercent?: number;
  title: string;
  brand?: string;
  counterLabel: string;
}

export const ListItem: FC<ListItemProps> = ({
  image,
  title,
  count,
  brand,
  commissionPercent,
  counterLabel,
}) => (
  <StyledRow>
    <Image src={image} variant="rounded" />
    <StyledInfoWrapper>
      <Typography variant="subtitle1">{title}</Typography>
      <StyledDetailsWrapper>
        {!!count && (
          <Typography variant="caption" color="text.disabled">
            {count} {counterLabel}
            {count > 1 && "s"}
          </Typography>
        )}
        {brand && (
          <Typography variant="caption" color="text.disabled">
            {brand}
          </Typography>
        )}
        {!!commissionPercent && (
          <Typography variant="caption" color="text.disabled">
            {commissionPercent}% Commission
          </Typography>
        )}
      </StyledDetailsWrapper>
    </StyledInfoWrapper>
  </StyledRow>
);
