import { saveIntegration } from "./saveIntegration";
import { setBillingPermissions } from "./setBillingPermissions";
import { oAuthBrandIntegration } from "./oAuthBrandIntegration";
import { fetchExistingIntegrations } from "./fetchExistingIntegrations";
import selectors from "./selectors";
import setupViewSlice from "./setupViewSlice";

export default {
  ...selectors,
  fetchResources: fetchExistingIntegrations,
  saveIntegration,
  setBillingPermissions,
  oAuthBrandIntegration,
  slice: setupViewSlice,
};
