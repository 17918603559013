import { useEffect, useState } from "react";
import { getCookie } from "shared/logic/utils/cookiesUtils";
import { useHistory } from "react-router-dom";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { storeAPI } from "views/brandViews/storeAPI";

export const useBillingPermissions = () => {
  const [isNonceCorrect, setNonceCorrect] = useState<boolean>();
  const [searchParams] = useSearchParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const policy = useSelector(getUserPolicy);
  const onShopifyButtonClick = () => {
    dispatch(
      storeAPI.setupView.setBillingPermissions({
        brandId: policy?.brand?.id,
        shopName: (searchParams.shop as string).split(".")[0],
        authorizationCode: searchParams.code as string,
      }),
    );
  };

  useEffect(() => {
    const savedNonce = getCookie("nonce");
    const receivedNonce = searchParams.state;
    const idsMatch = savedNonce === receivedNonce;
    setNonceCorrect(idsMatch);
    if (!idsMatch) {
      history.push(RouteEnum.SETUP);
      dispatch(setSnackbar({ open: true, message: "snackbar.genericError" }));
    }
  }, []);
  return { onShopifyButtonClick, isNonceCorrect };
};
