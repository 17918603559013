import { TitleBox } from "shared/components/SettingsComponents/TitleBox";
import { PolicyInfo } from "shared/components/PolicyInfo";
import { PresentationalUserRole } from "shared/logic/_enums/PresentationalUserRole.enum";
import { OrganizationSetup } from "shared/components/SettingsComponents/Organization/Organization.styled";
import Brand from "shared/logic/types/Brands/Brand";
import { FC } from "react";
import { MissingInfoAlert } from "shared/components/SettingsComponents/MissingInfoAlert";
import { useOrganization } from "views/brandViews/Settings/Organization/useOrganization";
import { EditOrganizationDrawer } from "shared/components/SettingsComponents/Organization/EditOrganizationDrawer";

interface OrganizationProps {
  brandInfo: Brand;
  isSetupCompleted: boolean;
}

export const Organization: FC<OrganizationProps> = ({ brandInfo, isSetupCompleted }) => {
  const { onOrganizationEditClick, toggleOrganizationDrawer, isOrganizationDrawerOpen } =
    useOrganization();
  return (
    <>
      <OrganizationSetup>
        <TitleBox
          title="settings.organization.organizationSetup"
          onEditClick={onOrganizationEditClick}
        />
        {!isSetupCompleted && (
          <MissingInfoAlert content="settings.organization.missingOrganizationInfo" />
        )}
        <PolicyInfo
          name={brandInfo.name}
          commissionPercent={brandInfo.defaultPartnershipCommissionPercent}
          description={brandInfo.description}
          policyType={PresentationalUserRole.BRAND}
          imageUrl={brandInfo.logoUrl}
        />
      </OrganizationSetup>
      <EditOrganizationDrawer
        toggleOpen={toggleOrganizationDrawer}
        isOpen={isOrganizationDrawerOpen}
        submitThunk={() => {}} // TODO -- change to thunk when logic is ready
        name={brandInfo.name}
        commissionPercent={brandInfo.defaultPartnershipCommissionPercent}
        description={brandInfo.description}
        imageUrl={brandInfo.logoUrl}
      />
    </>
  );
};
