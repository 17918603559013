import { ResponseDTO } from "shared/logic/types/ResponseDTO";
import axiosInstance from "shared/logic/api/axiosInstance";
import { EcommerceAccountQuery } from "shared/logic/types/EcommerceAccount/EcommerceAccountQuery";
import { EcommerceAccount } from "shared/logic/types/EcommerceAccount/EcommerceAccount";
import {
  NewAccountDTO,
  EcommerceAccountDTO,
  SetupBillingDTO,
  SetupBillingResponse,
} from "shared/logic/types/EcommerceAccount/EcommerceAccountDTO";

const getEcommerceAccounts = (
  ecommerceAccountQuery: EcommerceAccountQuery,
): Promise<ResponseDTO<EcommerceAccount[]>> =>
  axiosInstance.get(`/ecommerceAccounts`, { params: ecommerceAccountQuery });

const newAccount = ({
  brandId,
  shopName,
  authorizationCode,
}: NewAccountDTO): Promise<EcommerceAccountDTO> =>
  axiosInstance.post(`/ecommerceAccounts`, {
    brandId,
    ecommercePlatform: "SHOPIFY",
    ecommerceAccountConfiguration: {
      shopName,
    },
    authorizationCode,
  });

const setupBilling = ({
  ecommerceAccountId,
  urlToRedirect,
}: SetupBillingDTO): Promise<SetupBillingResponse> =>
  axiosInstance.post(`/ecommerceAccounts/${ecommerceAccountId}/setupBilling`, {
    returnUrl: urlToRedirect,
  });

export default {
  getEcommerceAccounts,
  newAccount,
  setupBilling,
};
