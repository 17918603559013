import { Product } from "shared/logic/types/Products/Product";
import { FC, ReactElement } from "react";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { MissingContent } from "shared/components/TableComponents/MissingContent";
import { Divider } from "@mui/material";
import {
  CardsWrapper,
  ProductCard,
  ImageWrapper,
  Image,
  ProductInfo,
  Name,
  StyledDetailsWrapper,
  InfoWrapper,
} from "./ProductCards.styled";

export interface ProductPartProps<T extends Product> {
  product: T;
}

export interface ProductCardsProps<T extends Product> {
  products: T[];
  isLoading: boolean;
  isEmpty: boolean;
  ImageOverlayComponent?: FC<ProductPartProps<T>>;
  DetailsComponent?: FC<ProductPartProps<T>>;
  FooterComponent?: FC<ProductPartProps<T>>;
}

export interface ProductCardsInterface {
  <T extends Product>(props: ProductCardsProps<T>): ReactElement<any, any> | null;
}

export const ProductCards: ProductCardsInterface = ({
  products,
  isEmpty,
  isLoading,
  ImageOverlayComponent,
  DetailsComponent,
  FooterComponent,
}) => {
  if (isLoading) {
    return <FullScreenLoader />;
  }
  if (isEmpty) {
    return (
      <MissingContent description="products.noProductsDescription" title="products.noProducts" />
    );
  }
  return (
    <CardsWrapper>
      {products.map((product) => (
        <ProductCard key={product.id}>
          <ImageWrapper>
            <Image src={product.primaryImageUrl} alt={product.name} />
            {ImageOverlayComponent && <ImageOverlayComponent product={product} />}
          </ImageWrapper>
          <InfoWrapper>
            <ProductInfo>
              <Name>{product.name}</Name>
              {DetailsComponent && (
                <StyledDetailsWrapper>
                  <DetailsComponent product={product} />
                </StyledDetailsWrapper>
              )}
            </ProductInfo>
            {FooterComponent && (
              <>
                <Divider />
                <FooterComponent product={product} />
              </>
            )}
          </InfoWrapper>
        </ProductCard>
      ))}
    </CardsWrapper>
  );
};
