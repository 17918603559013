import { createAsyncThunk } from "@reduxjs/toolkit";
import { ThunkConfigRootState } from "shared/logic/store/rootReducer";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import {
  getCurrentUserPolicyId,
  getUserId,
} from "shared/logic/store/features/resourceSlices/user/selectors";
import {
  RequestPartnershipDTO,
  RequestPartnershipType,
} from "shared/logic/types/Partnerships/Partnership";
import partnershipsApi from "shared/logic/api/partnershipsApi";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import { getBrands } from "shared/logic/store/features/resourceSlices/brands/selectors";

export type RequestPartnershipReturned = void;

export interface RequestPartnershipPayload {
  brandId: string;
}

export const requestPartnershipWithBrand = createAsyncThunk<
  RequestPartnershipReturned,
  RequestPartnershipPayload,
  ThunkConfigRootState
>(
  storePrefixes.partnerUser.discoverSlice.requestPartnership,
  async ({ brandId }, { dispatch, getState }) => {
    try {
      const userId = getUserId(getState());
      const partnerId = getCurrentUserPolicyId(getState());
      const brand = getBrands(getState())[brandId];

      if (!brand) {
        throw new Error("Missing brand entity in memory");
      }

      const {
        defaultPartnershipCommissionPercent,
        defaultPartnershipNonRecurringCharge,
        name: brandName,
      } = brand;

      if (!partnerId || !defaultPartnershipCommissionPercent) {
        throw new Error("Missing data");
      }

      const requestBody: RequestPartnershipDTO = {
        recipient_org_id: brandId,
        request_type: RequestPartnershipType.CREATOR_TO_BRAND,
        nonRecurringCharge: defaultPartnershipNonRecurringCharge ?? 0,
        commissionPercent: defaultPartnershipCommissionPercent,
        requesting_user_id: userId,
      };

      await partnershipsApi.requestPartnership(partnerId, requestBody);

      dispatch(
        setSnackbar({
          message: "snackbar.partnershipRequestSuccess",
          icon: SnackbarIcon.DONE,
          i18nInterpolationValues: { name: brandName },
        }),
      );
    } catch (e) {
      ConsoleLog(e);
      dispatch(
        setSnackbar({
          message: "snackbar.partnershipError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
