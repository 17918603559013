import { createAsyncThunk } from "@reduxjs/toolkit";
import productsApi from "shared/logic/api/productsApi";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

interface MakeProductAvailableParams {
  productId: string;
  partnershipId: string;
}

export const makeProductAvailable = createAsyncThunk(
  storePrefixes.brandUser.assignSlice.makeProductAvailable,
  async ({ productId, partnershipId }: MakeProductAvailableParams, { dispatch }) => {
    try {
      const partneredProductResponse = await productsApi.makeProductAvailableToPartner({
        productId,
        partnershipId,
      });
      const partneredProduct = partneredProductResponse.data;
      dispatch(
        setSnackbar({
          icon: SnackbarIcon.DONE,
          open: true,
          message: "snackbar.productAvailableSuccess",
        }),
      );

      return {
        partneredProducts: {
          // plural to keep partneredProducts reducer consistent
          [partneredProduct.id]: partneredProduct,
        },
      };
    } catch (e) {
      ConsoleLog(e);
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
    }
    return {};
  },
);
