import React from "react";
import { SignUpQuery } from "shared/logic/types/User/SignUpQuery";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";

export type InternalLogin = () => Promise<any>;
export type InternalLogout = () => void;
export type InternalRegister = (args: SignUpQuery) => Promise<void>;

export enum UserRole {
  BRAND = "BRAND",
  PARTNER = "PARTNER",
}

export interface AuthContextState {
  isAuthenticated: boolean;
  authUser?: Auth0ContextInterface["user"];
  getAccessTokenSilently: Auth0ContextInterface["getAccessTokenSilently"];
  error?: Error;
  isLoading: boolean;
  login?: InternalLogin;
  logout: InternalLogout;
  register?: InternalRegister;
}

export const AuthContext = React.createContext<AuthContextState>({
  isAuthenticated: false,
  isLoading: true,
  error: undefined,
  logout() {
    ConsoleLog("FALLBACK LOGOUT");
    // TODO LOG INTO DATADOG
  },
  getAccessTokenSilently() {
    ConsoleLog("FALLBACK GET_ACCESS_TOKEN_SILENTLY");
    // TODO LOG INTO DATADOG
    throw new Error("NOT IMPLEMENTED - GET_ACCESS_TOKEN_SILENTLY");
  },
});
