import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { useDispatch, useSelector } from "react-redux";
import { useUserRole } from "shared/logic/hooks/store/useUserRole";
import { useEffect } from "react";
import { storeAPI } from "views/brandViews/storeAPI";

export const useAssignPartner = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { policyId } = useUserRole();
  const partner = useSelector(storeAPI.assignView.getPartner);
  const productsAggregated = useSelector(storeAPI.assignView.getProductsAggregated);
  const { areProductsEmpty, isLoading } = useSelector(storeAPI.assignView.getAssignSliceState);
  const partnerId = searchParams.id as string;

  useEffect(() => {
    dispatch(storeAPI.assignView.fetchResources({ brandId: policyId ?? "", partnerId }));
  }, []);

  return {
    partner,
    productsAggregated,
    areProductsEmpty,
    isLoading,
  };
};
