import { styled, Box, Button, css, ButtonProps } from "@mui/material";
import { CodeOffRounded } from "@mui/icons-material";
import { TextBase, TextBaseProps } from "shared/components/base/TextBase/TextBase";

export const StyledWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const StyledTagButton = styled((props: ButtonProps) => <Button size="small" {...props} />)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledTagIcon = styled(CodeOffRounded)(
  ({ theme }) => css`
    color: ${theme.palette.tertiary.main};
  `,
);

export const GetTagLabel = (props: TextBaseProps) => <TextBase variant="subtitle1" {...props} />;
