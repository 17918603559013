import { SearchOutlined } from "@mui/icons-material";
import { FC } from "react";
import {
  useSearchFilters,
  SearchAnimation,
} from "shared/components/TableComponents/FiltersContainer/SearchFilters/useSearchFilters";
import { SearchWrapper, AnimatedInput, AnimatedSearchIcon } from "./SearchFilters.styled";

export interface SearchFiltersProps {
  onSearchHandler: (input: string) => void;
}

export const SearchFilters: FC<SearchFiltersProps> = ({ onSearchHandler }) => {
  const {
    searchAnimationVariants,
    searchState,
    isSearching,
    searchPlaceholder,
    openAnimation,
    closeAnimation,
    onInputChange,
  } = useSearchFilters({ onSearchHandler });

  return (
    <>
      <SearchWrapper>
        {isSearching ? (
          <AnimatedSearchIcon color="tertiary" />
        ) : (
          <SearchOutlined color="tertiary" />
        )}
        <AnimatedInput
          layout
          transition={{ duration: 0.25, ease: ["easeOut"] }}
          variants={searchAnimationVariants}
          initial={SearchAnimation.BLUR}
          animate={searchState}
          onFocus={openAnimation}
          onBlur={closeAnimation}
          onChange={onInputChange}
          placeholder={searchPlaceholder}
        />
      </SearchWrapper>
    </>
  );
};
