import { FC } from "react";
import { useAuth } from "shared/logic/hooks/auth/useAuth";
import { ErrorPage } from "shared/components/ErrorPage";

export const ForbiddenPage: FC = () => {
  const { logout } = useAuth();
  return (
    <ErrorPage
      errorNumber={403}
      errorTitle="error.403.title"
      errorDescription="error.403.description"
      errorButtonLabel="navigation.signOut"
      errorButtonAction={logout}
    />
  );
};
