import { createSlice } from "@reduxjs/toolkit";
import { fetchResourcesForSetupView } from "shared/logic/store/features/viewsSlices/partnerUser/setup/fetchResourcesForSetupView";
import { editPartnerSetup } from "shared/logic/store/features/viewsSlices/partnerUser/setup/editPartnerSetup";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";

interface ExistingSetupsViewState {
  isDataInvalid: boolean;
  setupViewSliceEditThunkStatus: SliceStatus;
  setupViewSliceStatus: SliceStatus;
  partnerId: string | null;
}

export const initialState: ExistingSetupsViewState = {
  isDataInvalid: false,
  setupViewSliceEditThunkStatus: SliceStatus.IDLE,
  setupViewSliceStatus: SliceStatus.IDLE,
  partnerId: null,
};

const setupViewSliceForPartnerUser = createSlice({
  name: storePrefixes.partnerUser.setupSlice.this,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(editPartnerSetup.pending, (state) => {
        state.setupViewSliceEditThunkStatus = SliceStatus.PENDING;
      })
      .addCase(editPartnerSetup.fulfilled, (state) => {
        state.setupViewSliceEditThunkStatus = SliceStatus.FULFILLED;
      })
      .addCase(fetchResourcesForSetupView.pending, (state) => {
        state.setupViewSliceStatus = SliceStatus.PENDING;
      })
      .addCase(fetchResourcesForSetupView.fulfilled, (state, action) => {
        state.setupViewSliceStatus = SliceStatus.FULFILLED;
        state.partnerId = action.payload.partnerId;
      });
  },
});

export default setupViewSliceForPartnerUser.reducer;
