import { OverflowColumnFlex } from "shared/components/OverflowColumnFlex";
import { styled, css } from "@mui/material";

export type StyledViewSpaceProps = {
  multiplier: number;
};

export const StyledViewSpace = styled(OverflowColumnFlex)<StyledViewSpaceProps>(
  ({ theme, multiplier }) => css`
    & > * + * {
      margin-top: ${theme.spacing(multiplier)};
    }
  `,
);
