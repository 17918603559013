import { RootState } from "shared/logic/store/rootReducer";
import { createSelector } from "@reduxjs/toolkit";

export const getProductsSlice = (state: RootState) => state.productsSlice;

export const getProducts = createSelector([getProductsSlice], ({ products }) => products);

export const getProductIdsForBrands = createSelector(
  [getProductsSlice],
  ({ productIdsForBrands }) => productIdsForBrands,
);
