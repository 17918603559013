import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import { TooltipProps, Tooltip } from "@mui/material";
import { memo, forwardRef, FC } from "react";
import { useTranslationWithMarkdown } from "shared/logic/hooks/translations/useTranslationWithMarkdown";

export interface LocalizedTooltipProps extends Omit<TooltipProps, "title"> {
  title?: TextContentPath;
}

const LocalizedTooltipComponent: FC<LocalizedTooltipProps> = forwardRef(
  ({ title, children, ...materialProps }, ref) => {
    const { t } = useTranslationWithMarkdown();
    return (
      <Tooltip title={title ? t(title) : ""} ref={ref} {...materialProps}>
        {children}
      </Tooltip>
    );
  },
);

export const LocalizedTooltip = memo(LocalizedTooltipComponent);
