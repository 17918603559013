import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";
import { styled, Box, ButtonProps, Button, css } from "@mui/material";

export const CARD_SIZE = 268;

export const CommissionInfo = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

export const CopyButton = styled((props: ButtonProps) => (
  <Button size="icon" variant="contained" color="tertiary" {...props} />
))(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
  `,
);

export const Brand = styled((props: TextBaseProps) => <TextBase variant="caption" {...props} />)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const VariantCount = styled((props: TextBaseProps) => (
  <TextBase variant="caption" {...props} />
))`
  white-space: nowrap;
`;

export const CommissionRate = (props: TextBaseProps) => (
  <TextBase variant="subtitle1" color="tertiary.main" {...props} />
);

export const CommissionDescription = (props: TextBaseProps) => (
  <TextBase variant="subtitle1" {...props} />
);
