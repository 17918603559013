import { FC, Fragment } from "react";
import usePartnerships from "views/partnerViews/Partnerships/usePartnerships";
import { FiltersContainer } from "shared/components/TableComponents/FiltersContainer";
import { TableWrapper } from "shared/components/TableComponents/TableWrapper";
import { TableBody } from "@mui/material";
import { PartnershipsListHeader } from "views/partnerViews/Partnerships/PartnershipsListHeader";
import { PartnershipItem } from "views/partnerViews/Partnerships/PartnershipItem";
import { ViewHeadline } from "shared/components/ViewHeadline";
import { SpaceBetweenWrapper } from "views/partnerViews/Partnerships/Partnerships.styled";
import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";

export const Partnerships: FC = () => {
  const { partnerships, isEmpty, isLoading } = usePartnerships();
  return (
    <StyledViewSpace multiplier={3}>
      <ViewHeadline localizedText="partnerships.title" />
      <StyledViewSpace multiplier={1}>
        <FiltersContainer />
        <SpaceBetweenWrapper>
          <TableWrapper
            missingContentTitle="partnerships.noPartnerships"
            missingContentDescription="partnerships.noPartnershipsDescription"
            isLoading={isLoading}
            isEmpty={isEmpty}
          >
            <PartnershipsListHeader />
            <TableBody>
              {!isLoading &&
                !isEmpty &&
                Object.values(partnerships).map((partnership) => (
                  <Fragment key={partnership.id}>
                    <PartnershipItem partnership={partnership} />
                  </Fragment>
                ))}
            </TableBody>
          </TableWrapper>
        </SpaceBetweenWrapper>
      </StyledViewSpace>
    </StyledViewSpace>
  );
};
