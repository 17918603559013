import { DrawerWrapper } from "shared/components/Drawer";
import { ProductInfo } from "shared/components/ProductInfo";
import { useTagDrawer } from "./useTagDrawer";
import {
  Content,
  ItemTitle,
  ChooseTagTitle,
  LinkShopTag,
  EmbeddedShopTag,
} from "./TagDrawer.styled";

export const TagDrawer = () => {
  const { isOpen, onTagClick, product, toggleOpen } = useTagDrawer();

  return (
    <DrawerWrapper isOpen={isOpen} toggleOpen={toggleOpen} title="products.itemTag">
      <Content>
        <ItemTitle content="products.getItemTag" />
        {!!product && (
          <ProductInfo
            name={product.name}
            imageUrl={product.primaryImageUrl}
            brandName={product.brand.name}
            commissionPercent={product.partnership.commissionPercent}
            variantCount={product.productVariants.length}
          />
        )}
        <ChooseTagTitle content="products.tagStyle.title" />
        <LinkShopTag onTagClick={onTagClick} title="products.tagStyle.link" />
        <EmbeddedShopTag onTagClick={onTagClick} title="products.tagStyle.embedded" />
      </Content>
    </DrawerWrapper>
  );
};
