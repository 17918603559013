import { Redirect, Route, Switch } from "react-router-dom";
import React from "react";
import { UserSetupStatus, UserStatus } from "shared/logic/types/User/UserStatus";
import { useNotAuthenticatedRouting } from "views/Routing/NotAuthenticatedRouting/useNotAuthenticatedRouting";

interface NotAuthenticatedRoutingProps {
  userStatus: UserStatus;
  userSetupStatus: UserSetupStatus;
}

export const NotAuthenticatedRouting: React.FC<NotAuthenticatedRoutingProps> = ({
  userStatus,
  userSetupStatus,
}) => {
  const config = useNotAuthenticatedRouting(userStatus, userSetupStatus);

  if (!config) {
    // TODO FALLBACK NEEDED
    return null;
  }

  return (
    <Switch>
      {config.routes.map((routeProps, index) => (
        <Route {...routeProps} key={routeProps.path?.toString() ?? index.toString()} />
      ))}

      <Redirect from="*" to={config.mainRedirect} />
    </Switch>
  );
};
