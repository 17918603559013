import { createAsyncThunk } from "@reduxjs/toolkit";
import productsApi from "shared/logic/api/productsApi";
import { batch } from "react-redux";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { closeDialog } from "shared/logic/store/features/resourceSlices/dialog/dialogSlice";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

interface RevokeAccessParams {
  partneredProductId: string;
}

export const revokeAccess = createAsyncThunk(
  storePrefixes.brandUser.assignSlice.revokeAccess,
  async ({ partneredProductId }: RevokeAccessParams, { dispatch }) => {
    try {
      await productsApi.revokePartnerProductAccess(partneredProductId);
      batch(() => {
        dispatch(
          setSnackbar({
            icon: SnackbarIcon.DONE,
            open: true,
            message: "snackbar.productRevocationSuccess",
          }),
        );
        dispatch(closeDialog());
      });

      return {
        partneredProductId,
      };
    } catch (e) {
      ConsoleLog(e);
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
