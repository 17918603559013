import { createSlice } from "@reduxjs/toolkit";
import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import {
  BrandUserPartnershipAggregated,
  BrandUserProductAggregated,
} from "shared/logic/store/features/viewsSlices/brandUser/dashboard/selectors";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import { fetchResourcesForDashboardView } from "./fetchResourcesForDashboardView";

interface DashboardViewState {
  dashboardSliceStatus: SliceStatus;
  isDataInvalid: boolean;
  partnershipsAggregated: BrandUserPartnershipAggregated[];
  partneredProductsWithDetails: IdAndEntityPair<BrandUserProductAggregated[]>;
  mostSharedPartneredProductsWithDetails: BrandUserProductAggregated[];
}

export const initialState: DashboardViewState = {
  dashboardSliceStatus: SliceStatus.IDLE,
  isDataInvalid: false,
  partnershipsAggregated: [],
  mostSharedPartneredProductsWithDetails: [],
  partneredProductsWithDetails: {},
};

const dashboardViewSliceForBrandUser = createSlice({
  name: storePrefixes.brandUser.dashboardSlice.this,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourcesForDashboardView.pending, (state) => {
        state.dashboardSliceStatus = SliceStatus.PENDING;
      })
      .addCase(fetchResourcesForDashboardView.fulfilled, (state) => {
        state.dashboardSliceStatus = SliceStatus.FULFILLED;
      })
      .addCase(fetchResourcesForDashboardView.rejected, (state) => {
        state.dashboardSliceStatus = SliceStatus.REJECTED;
      });
  },
});

export default dashboardViewSliceForBrandUser.reducer;
