import { styled, Box, css, Button } from "@mui/material";
import { TextBase } from "shared/components/base/TextBase/TextBase";

export const StyledForm = styled("form")(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const InputDescription = styled(TextBase)(
  ({ theme }) => css`
    display: inline-block;
    padding: ${theme.spacing(0.5, 2)};
  `,
);

export const ContinueButton = styled(Button)``;
export const DomainInput = styled(Box)``;
