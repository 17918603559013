import { Company } from "shared/logic/types/Companies/Company";
import companiesApi from "shared/logic/api/companiesApi";
import {
  performTransaction,
  PerformTransactionArgs,
} from "shared/logic/store/features/user/signUp/utils/performTransaction";

interface CreateCompanyTransactionArgs
  extends Pick<PerformTransactionArgs<Company>, "onError" | "rollback"> {
  name: string;
}

export async function createCompanyTransaction({
  name,
  onError,
  rollback,
}: CreateCompanyTransactionArgs): Promise<Company> {
  async function action(): Promise<Company> {
    const companyResponse = await companiesApi.postCompany({
      name,
    });
    return companyResponse.data;
  }

  return performTransaction({ action, onError, rollback });
}
