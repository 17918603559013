import { styled, Box, css, Button } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

export const StyledContentWrapper = styled(Box)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);

export const StyledListBox = styled(Box)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(1)};
    }
  `,
);

export const StyledOrderedList = styled("ol")(
  ({ theme }) => css`
    padding-left: ${theme.spacing(3)};
  `,
);

export const StyledButtonBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export const StyledInputBox = styled(Box)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const StyledCheckIcon = styled(CheckCircle)`
  height: 20px;
`;

export const ContinueButton = styled(Button)``;
