import { useAuth } from "shared/logic/hooks/auth/useAuth";
import { useForm } from "react-hook-form";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";

interface FormFields {
  fullName?: string;
  email?: string;
}

export const useUserSettings = () => {
  const { authUser } = useAuth();
  const { handleSubmit, register } = useForm<FormFields>({
    defaultValues: { fullName: authUser?.name, email: authUser?.email },
    /*
      TODO - we'd probably take those values from /users/:id
       API endpoint after the logic is ready
    */
  });
  const onSubmit = (formData: FormFields) => {
    ConsoleLog(["User Profile Form:", formData]);
  };
  return {
    handleSubmit,
    onSubmit,
    register,
  };
};
