import { FC } from "react";
import { TableCell, Typography } from "@mui/material";
import { ImageCell, Image, StyledItemRow } from "shared/components/TableComponents/Styles";
import { PartnershipAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/partnerships/selectors";

interface PartnershipItemProps {
  partnership: PartnershipAggregated;
}

export const PartnershipItem: FC<PartnershipItemProps> = ({ partnership }) => (
  <StyledItemRow>
    <ImageCell>
      <Image src={partnership.brand.logoUrl} variant="rounded" />
    </ImageCell>
    <TableCell padding="none">
      <Typography variant="subtitle1">{partnership.brand?.name}</Typography>
    </TableCell>
    <TableCell align="right">{partnership.partneredProducts?.length}</TableCell>
    <TableCell align="right">{partnership.commissionPercent}%</TableCell>
  </StyledItemRow>
);
