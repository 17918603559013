import { FC } from "react";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { ListItemText } from "@mui/material";
import { UserPolicy } from "shared/logic/types/User/UserPolicy";
import { UserRole } from "shared/components/auth/AuthContext";

interface UserPolicyItemTextProps {
  policy?: UserPolicy;
  primaryTextColor?: string;
  secondaryTextColor?: string;
}

const UserPolicyItemText: FC<UserPolicyItemTextProps> = ({
  policy,
  primaryTextColor = "text.disabled",
  secondaryTextColor,
}) => {
  const labelLocalizedPath = `navigation.accountSwitcher.${
    policy?.entityType === UserRole.BRAND ? "brandAccount" : "partnerAccount"
  }`;
  const policyName =
    policy?.entityType === UserRole.BRAND ? policy.brand?.name : policy?.partner?.name;

  return (
    <ListItemText
      primary={<TextBase color={primaryTextColor} variant="caption" content={labelLocalizedPath} />}
      secondary={
        <TextBase component="span" variant="subtitle1" color={secondaryTextColor}>
          {policyName}
        </TextBase>
      }
    />
  );
};

export default UserPolicyItemText;
