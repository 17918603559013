import { Box, Button, css, Drawer, styled } from "@mui/material";
import { LocalizedInput } from "shared/components/base/LocalizedInput/LocalizedInput";

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NameInputContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;

    & > * + * {
      margin-left: ${theme.spacing(2)};
    }
  `,
);

export const StyledForm = styled("form")(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const NavigationContent = styled(Box)(
  ({ theme }) => css`
    border-top: 1px solid ${theme.palette.border};
    height: 72px;
    padding-top: ${theme.spacing(3)};
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledDrawer = styled(Drawer)``;
export const StyledSendButton = styled(Button)``;
export const BrandNameInput = styled(LocalizedInput)``;
export const BrandWebsite = styled(LocalizedInput)``;
export const BrandEmail = styled(LocalizedInput)``;
