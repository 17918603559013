import { Redirect, useHistory, useLocation } from "react-router-dom";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import PartnerView from "views/partnerViews";
import BrandView from "views/brandViews";
import React, { ReactElement, useEffect, useCallback } from "react";
import { useUserRole } from "shared/logic/hooks/store/useUserRole";

type RoleRoutingParams = {
  partnerComponent: ReactElement;
  brandComponent: ReactElement;
};

export const useAuthenticatedRouting = (isSetUpNotCompleted: boolean) => {
  const history = useHistory();
  const location = useLocation();
  const { isPartnerUser, isBrandUser, isAdmin } = useUserRole();

  useEffect(() => {
    if (isSetUpNotCompleted) {
      history.push({ pathname: RouteEnum.SETUP, search: location.search ?? "" });
    }
  }, [isSetUpNotCompleted]);

  const roleRouting = useCallback(
    ({ partnerComponent, brandComponent }: RoleRoutingParams) => {
      if (isPartnerUser) return partnerComponent;
      if (isBrandUser) return brandComponent;
      // SHOULD NOT HAPPEN
      // TODO LOG INTO DATADOG
      // TODO THINK IT OVER
      return <Redirect to={RouteEnum.LOGOUT} />;
    },
    [isPartnerUser, isBrandUser],
  );

  const productsPathComponent = useCallback(
    () =>
      roleRouting({
        partnerComponent: <PartnerView.Products />,
        brandComponent: <BrandView.Products />,
      }),
    [roleRouting],
  );
  const setupPathComponent = useCallback(
    () =>
      roleRouting({
        partnerComponent: <PartnerView.Setup />,
        brandComponent: <BrandView.Setup />,
      }),
    [roleRouting],
  );
  const partnershipsPathComponent = useCallback(
    () =>
      roleRouting({
        partnerComponent: <PartnerView.Partnerships />,
        brandComponent: <BrandView.Partnerships />,
      }),
    [roleRouting],
  );
  const dashboardPathComponent = useCallback(
    () =>
      roleRouting({
        partnerComponent: <PartnerView.Dashboard />,
        brandComponent: <BrandView.Dashboard />,
      }),
    [roleRouting],
  );
  const discoverPathComponent = useCallback(
    () =>
      roleRouting({
        partnerComponent: <PartnerView.DiscoverBrands />,
        brandComponent: <BrandView.DiscoverPartner />,
      }),
    [roleRouting],
  );
  const settingsPathComponent = useCallback(
    () =>
      roleRouting({
        partnerComponent: <PartnerView.Settings />,
        brandComponent: <BrandView.Settings />,
      }),
    [roleRouting],
  );

  return {
    productsPathComponent,
    setupPathComponent,
    partnershipsPathComponent,
    dashboardPathComponent,
    discoverPathComponent,
    isBrandUser,
    isPartnerUser,
    isAdmin,
    settingsPathComponent,
  };
};
