import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { Partner } from "shared/logic/types/Partners/Partner";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import brandUser from "../../viewsSlices/brandUser";
import partnerUser from "../../viewsSlices/partnerUser";

interface PartnersState {
  partners: IdAndEntityPair<Partner>;
  isFirstPartnerLoginLoading: boolean;
}

export const initialState: PartnersState = {
  partners: {},
  isFirstPartnerLoginLoading: false,
};

const partnersSlice = createSlice({
  name: storePrefixes.resourceSlices.partners,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(partnerUser.firstLoginSetupView.firstLoginPartnerSetup.pending, (state) => {
        state.isFirstPartnerLoginLoading = true;
      })
      .addCase(
        partnerUser.firstLoginSetupView.firstLoginPartnerSetup.fulfilled,
        (state, action) => {
          const { partnerId, patchedPartner } = action.payload;
          state.isFirstPartnerLoginLoading = false;
          state.partners[partnerId] = {
            ...state.partners[partnerId],
            ...patchedPartner,
          };
        },
      )
      .addCase(partnerUser.firstLoginSetupView.firstLoginPartnerSetup.rejected, (state) => {
        state.isFirstPartnerLoginLoading = false;
      })
      .addMatcher(
        isAnyOf(
          partnerUser.setupView.editPartnerSetup.fulfilled,
          partnerUser.settingsView.editPartnerDomainSettings.fulfilled,
          partnerUser.settingsView.updatePartnerDetails.fulfilled,
        ),
        (state, action) => {
          const changedPartner = action.payload.newPartnerData;
          state.partners = {
            ...state.partners,
            [changedPartner.id]: {
              ...state.partners[changedPartner.id],
              ...changedPartner,
            },
          };
        },
      )
      .addMatcher(
        isAnyOf(
          brandUser.assignView.fetchResources.fulfilled,
          brandUser.dashboardView.fetchResources.fulfilled,
          brandUser.discoverNewPartnersView.fetchResources.fulfilled,
          brandUser.partnershipsView.fetchResources.fulfilled,
          partnerUser.productsView.fetchResources.fulfilled,
          partnerUser.dashboardView.fetchResources.fulfilled,
          partnerUser.setupView.fetchResources.fulfilled,
        ),
        (state, action) => {
          state.partners = {
            ...state.partners,
            ...action.payload.partners,
          };
        },
      );
  },
});

export default partnersSlice.reducer;
