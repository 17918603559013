import { createSlice } from "@reduxjs/toolkit";
import { fetchResourcesForDashboardView } from "shared/logic/store/features/viewsSlices/partnerUser/dashboard/fetchResourcesForDashboardView";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";

interface DashboardViewState {
  dashboardSliceStatus: SliceStatus;
  isDataInvalid: boolean;
  partneredProductsAggregatedIds: string[];
  partnershipsAggregatedIds: string[];
  partnerId?: string;
}

export const initialState: DashboardViewState = {
  dashboardSliceStatus: SliceStatus.IDLE,
  isDataInvalid: false,
  partneredProductsAggregatedIds: [],
  partnershipsAggregatedIds: [],
};

const dashboardViewSliceForPartnerUser = createSlice({
  name: storePrefixes.partnerUser.dashboardSlice.this,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourcesForDashboardView.pending, (state) => {
        state.dashboardSliceStatus = SliceStatus.PENDING;
      })
      .addCase(fetchResourcesForDashboardView.rejected, (state) => {
        state.dashboardSliceStatus = SliceStatus.REJECTED;
      })
      .addCase(fetchResourcesForDashboardView.fulfilled, (state, action) => {
        const partnershipsAggregatedIds = action.payload?.partnershipsAggregatedIds;
        const partneredProductsAggregatedIds = action.payload?.partneredProductsAggregatedIds;
        state.partnerId = action.payload?.partnerId;
        state.dashboardSliceStatus = SliceStatus.FULFILLED;
        state.partneredProductsAggregatedIds = partnershipsAggregatedIds ?? [];
        state.partnershipsAggregatedIds = partneredProductsAggregatedIds ?? [];
      });
  },
});

export default dashboardViewSliceForPartnerUser.reducer;
