import { FC, Fragment } from "react";
import { Divider } from "@mui/material";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { AddAccountForm } from "views/adminViews/AddAccount/useAddAccount";
import {
  ButtonsWrapper,
  ContinueButton,
  BackButton,
} from "views/adminViews/AddAccount/AddAccount.styled";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { useConfirmDetails } from "./useConfirmDetails";
import {
  StyledWrapper,
  AccountDetailsSection,
  AccountInfo,
  StyledDivider,
  CapitalizedText,
  StyledUsersList,
  UserListItem,
} from "./ConfirmDetails.styled";

export interface ConfirmDetailsProps {
  formData: AddAccountForm;
}

export const ConfirmDetails: FC<ConfirmDetailsProps> = ({ formData }) => {
  const { sendForm, accountTypeLabel, accountName, onBackClick, usersToAdd, inProgress } =
    useConfirmDetails({ formData });
  if (inProgress) {
    return <FullScreenLoader />;
  }
  return (
    <StyledWrapper>
      <TextBase
        color="text.secondary"
        variant="body1"
        content="addAccount.confirmDetails.description"
      />
      <AccountDetailsSection>
        <AccountInfo>
          <TextBase
            variant="caption"
            color="text.disabled"
            content="addAccount.confirmDetails.accountName"
          />
          <TextBase variant="subtitle1">{accountName}</TextBase>
        </AccountInfo>
        <StyledDivider orientation="vertical" variant="middle" />
        <AccountInfo>
          <TextBase
            variant="caption"
            color="text.disabled"
            content="addAccount.confirmDetails.accountType"
          />
          <CapitalizedText variant="subtitle1">{accountTypeLabel}</CapitalizedText>
        </AccountInfo>
      </AccountDetailsSection>
      <TextBase variant="subtitle1" content="addAccount.confirmDetails.usersList" />
      <StyledUsersList>
        {usersToAdd?.map((user, index) => (
          <Fragment key={user.email}>
            <UserListItem>
              <TextBase variant="body1" color="tertiary.main">
                {user.email}
              </TextBase>
            </UserListItem>
            {index !== usersToAdd.length - 1 && <Divider />}
          </Fragment>
        ))}
      </StyledUsersList>
      <ButtonsWrapper>
        <BackButton color="tertiary" variant="outlined" onClick={onBackClick}>
          <KeyboardArrowLeft color="tertiary" />
          <TextBase content="navigation.back" variant="subtitle1" color="text.primary" />
        </BackButton>
        <ContinueButton variant="contained" color="tertiary" onClick={sendForm}>
          <TextBase content="navigation.continue" variant="subtitle1" />
        </ContinueButton>
      </ButtonsWrapper>
    </StyledWrapper>
  );
};
