import { css, styled, ListItemButton, ListItemAvatar } from "@mui/material";

export const StyledListItemButton = styled(ListItemButton)(
  ({ theme }) => css`
    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledListAvatar = styled(ListItemAvatar)`
  min-width: 0;
`;
