import { FC, Fragment } from "react";
import { TableBody } from "@mui/material";
import { FiltersContainer } from "shared/components/TableComponents/FiltersContainer";
import { TableWrapper } from "shared/components/TableComponents/TableWrapper";
import { ViewHeadline } from "shared/components/ViewHeadline";
import { SpaceBetweenWrapper } from "views/partnerViews/Products/Products.styled";
import { ProductsTableHeader } from "views/partnerViews/Products/ProductsTableHeader";
import { ProductsItem } from "views/partnerViews/Products/ProductsItem";
import useProducts from "views/partnerViews/Products/useProducts";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";
import { ProductCards } from "shared/components/ProductCards";
import { DisplayVariant } from "shared/components/TableComponents/FiltersContainer/LayoutVariantButtons";
import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";
import { TagDrawer } from "views/partnerViews/Products/TagDrawer";
import { ImageOverlayButton, ProductCardDetails, ProductCardFooter } from "./ProductCards";

export const Products: FC = () => {
  const { products, isLoading, isEmpty, displayVariant, setDisplayVariant, onSearchHandler } =
    useProducts();

  return (
    <>
      <StyledViewSpace multiplier={3}>
        <ViewHeadline localizedText="products.title" />
        <StyledViewSpace multiplier={1}>
          <FiltersContainer
            displayVariant={displayVariant}
            setDisplayVariant={setDisplayVariant}
            onSearchHandler={onSearchHandler}
          />
          <SpaceBetweenWrapper>
            {displayVariant === DisplayVariant.LIST && (
              <TableWrapper
                isLoading={isLoading}
                isEmpty={isEmpty}
                missingContentDescription="products.noProductsDescription"
                missingContentTitle="products.noProducts"
              >
                <ProductsTableHeader />
                <TableBody>
                  {!isLoading &&
                    !isEmpty &&
                    Object.values(products!).map((product) => (
                      <Fragment key={product.id}>
                        <ProductsItem product={product} />
                      </Fragment>
                    ))}
                </TableBody>
              </TableWrapper>
            )}
            {displayVariant === DisplayVariant.GRID && (
              <ProductCards<ProductAggregated>
                products={products}
                isEmpty={isEmpty}
                isLoading={isLoading}
                DetailsComponent={ProductCardDetails}
                FooterComponent={ProductCardFooter}
                ImageOverlayComponent={ImageOverlayButton}
              />
            )}
          </SpaceBetweenWrapper>
        </StyledViewSpace>
      </StyledViewSpace>
      <TagDrawer />
    </>
  );
};
