import Partnership from "shared/logic/types/Partnerships/Partnership";
import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import { Partner } from "shared/logic/types/Partners/Partner";
import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import { BrandUserPartnershipAggregated } from "shared/logic/store/features/viewsSlices/brandUser/dashboard/selectors";

export const parsePartnershipToBrandUserPartnershipsAggregated = (
  partnershipsMap: IdAndEntityPair<Partnership>,
  partnersObject: IdAndEntityPair<Partner>,
  partneredProductsAggregatedByPartnershipId: IdAndEntityPair<PartneredProduct[]>,
): BrandUserPartnershipAggregated[] =>
  Object.values(partnershipsMap).map((partnership) => ({
    ...partnership,
    partner: partnersObject[partnership.partnerId],
    partneredProducts: partneredProductsAggregatedByPartnershipId[partnership.id],
  }));
