import { styled, Box } from "@mui/material";
import gap from "shared/components/Styled/Gap";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";
import { Bolt } from "@mui/icons-material";
import { GrayBoxButton } from "shared/components/SettingsComponents/Organization/Organization.styled";

export const GetSiteTagButton = styled(GrayBoxButton)`
  align-items: center;
  justify-content: space-between;
`;

export const GetSiteTag = styled(Box)`
  display: flex;
  ${gap.left(2)};
`;

export const GetSiteTagLabel = (props: TextBaseProps) => (
  <TextBase variant="subtitle1" {...props} />
);

export const StyledBolt = ({ disabled }: { disabled: boolean }) => (
  <Bolt color={disabled ? "disabled" : "tertiary"} />
);
