import { TextContentPath, TextBase } from "shared/components/base/TextBase/TextBase";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import {
  StyledTextWrapper,
  BackButton,
  StyledWrapper,
} from "shared/components/TableComponents/MissingContent/MissingContent.styled";

export interface MissingContentProps {
  title: TextContentPath;
  description: TextContentPath;
}

export const MissingContent: FC<MissingContentProps> = ({ title, description }) => {
  const history = useHistory();
  return (
    <StyledWrapper>
      <StyledTextWrapper>
        <TextBase variant="h4" content={title} />
        <TextBase variant="body1" content={description} color="text.disabled" align="center" />
      </StyledTextWrapper>
      <BackButton
        variant="contained"
        onClick={() => history.push(RouteEnum.DASHBOARD)}
        disableElevation
        color="tertiary"
      >
        <TextBase variant="subtitle1" content="navigation.backToHome" />
      </BackButton>
    </StyledWrapper>
  );
};
