import { PolicyEntity } from "shared/logic/types/User/UserPolicy";
import userApi from "shared/logic/api/userApi";
import isEmpty from "lodash-es/isEmpty";

export async function getUserPolicies(userId: string): Promise<PolicyEntity[]> {
  const policiesObj = await userApi.getUserPolicies(userId);
  const policies = policiesObj.data.permissioned_entities;

  if (isEmpty(policies)) {
    throw new Error("NO POLICIES");
  }

  return policies;
}
