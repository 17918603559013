import { FC } from "react";
import { TextBase, TextContentPath } from "shared/components/base/TextBase/TextBase";
import { StyledButtonPaddingCompensation } from "shared/components/Styled/StyledButtonPaddingCompensation";
import useDashboardList, { ItemTypeEnum } from "shared/components/DashboardList/useDashboardList";
import {
  ScrollableWhiteBox,
  StyledHeader,
  StyledMissingItems,
} from "shared/components/DashboardList/List.styled";
import { ListContent } from "shared/components/DashboardList/ListContent";
import {
  BrandUserPartnershipAggregated,
  BrandUserProductAggregated,
} from "shared/logic/store/features/viewsSlices/brandUser/dashboard/selectors";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";
import { PartnershipAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/partnerships/selectors";

type ItemPropType<T extends ItemTypeEnum> = T extends ItemTypeEnum.PRODUCT
  ? ProductAggregated[]
  : T extends ItemTypeEnum.PARTNERSHIP
  ? PartnershipAggregated[]
  : T extends ItemTypeEnum.BRAND_USER_LATEST_PARTNERSHIPS
  ? BrandUserPartnershipAggregated[]
  : T extends ItemTypeEnum.BRAND_USER_MOST_SHARED_PRODUCTS
  ? BrandUserProductAggregated[]
  : never;

export interface DashboardListProps {
  header: TextContentPath;
  items: ItemPropType<ItemTypeEnum> | null;
  itemType: ItemTypeEnum;
  missingItemsHeader: TextContentPath;
  missingItemsDescription: TextContentPath;
}

export const DashboardList: FC<DashboardListProps> = ({
  itemType,
  header,
  items,
  missingItemsHeader,
  missingItemsDescription,
}) => {
  const { parseProps, onSeeAllClick } = useDashboardList(itemType);
  return (
    <ScrollableWhiteBox>
      <StyledHeader>
        <TextBase variant="h4" content={header} />
        <StyledButtonPaddingCompensation color="tertiary" onClick={onSeeAllClick} size="small">
          <TextBase variant="subtitle1" content="navigation.seeAll" />
        </StyledButtonPaddingCompensation>
      </StyledHeader>
      {(!items || items.length === 0) && (
        <StyledMissingItems>
          <TextBase variant="h4" content={missingItemsHeader} />
          <TextBase variant="body1" color="text.disabled" content={missingItemsDescription} />
        </StyledMissingItems>
      )}
      {items && items.length > 0 && <ListContent items={items} parseProps={parseProps} />}
    </ScrollableWhiteBox>
  );
};
