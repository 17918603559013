import { styled, Box } from "@mui/material";

export const OverflowColumnFlex = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  /* we need to reset those values to stop flexbox children from exceeding theirs parent */
  min-height: 0;
  min-width: 0;
  /* ^^^ */
`;
