import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import { useDispatch } from "react-redux";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";

interface FormInputs {
  name: string;
  domain: string;
  email: string;
}

export const useRequestNewPartnerDrawer = () => {
  const dispatch = useDispatch();
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  const toggleOpenDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };

  const onSubmit: SubmitHandler<FormInputs> = (data: FormInputs) => {
    toggleOpenDrawer();
    dispatch(
      setSnackbar({
        open: true,
        message: "snackbar.requestNewPartnerSuccess",
        icon: SnackbarIcon.COPY,
      }),
    );
    ConsoleLog(["Submit form data", data]);
  };

  return {
    isOpenDrawer,
    toggleOpenDrawer,
    onSubmit,
    register,
    handleSubmit,
    setValue,
    isFormValid: isValid,
  };
};
