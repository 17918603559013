import axiosInstance from "shared/logic/api/axiosInstance";
import { ResponseDTO } from "shared/logic/types/ResponseDTO";
import { SignUpQuery } from "shared/logic/types/User/SignUpQuery";
import { User } from "shared/logic/types/User/User";
import { AxiosResponse } from "axios";
import { UserPoliciesDTO } from "shared/logic/types/User/UserPolicy";

const getUserInfo = async (userId: string): Promise<AxiosResponse<User, any>> =>
  axiosInstance.get(`/users/${userId}`);
const getUserPolicies = async (userId: string): Promise<AxiosResponse<UserPoliciesDTO>> =>
  axiosInstance.get(`/users/${userId}/policy`);
const getUserInfoByAuth0Id = async (auth0Id: string): Promise<ResponseDTO<User[]>> =>
  axiosInstance.get(`/users?auth0-user-id=${encodeURIComponent(auth0Id)}`);
// ^ auth0 have id in format containing pipe sign "|", so we need to encode it

const getUserByEmail = (email: string): Promise<ResponseDTO<User[]>> =>
  axiosInstance.get(`/users`, {
    params: {
      "email-address": email,
    },
  });

const putUserPolicy = (newUserPolicies: UserPoliciesDTO): Promise<AxiosResponse<UserPoliciesDTO>> =>
  axiosInstance.put(`/users/${newUserPolicies.user_id}/policy`, newUserPolicies);

const deleteUser = (userId: string): Promise<void> => axiosInstance.delete(`/users/${userId}`);

const patchUser = (userId: string, dataToUpdate: Partial<Omit<User, "id">>) =>
  axiosInstance.patch(`/users/${userId}`, dataToUpdate);

const postUser = async (registerData: Partial<SignUpQuery>): Promise<{ data: User }> =>
  axiosInstance.post("/users", {
    testUser: process.env.REACT_APP_ENVIRONMENT_NAME !== "PRODUCTION",
    ...registerData,
  });

export default {
  postUser,
  getUserPolicies,
  getUserInfo,
  putUserPolicy,
  deleteUser,
  getUserInfoByAuth0Id,
  getUserByEmail,
  patchUser,
};

// When creating an api functions export signatures
export type TypeOfPostUser = typeof postUser;
