import React from "react";
import { styled, css, Box, ButtonProps } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { borderRadiusHelper } from "shared/logic/utils/theme/borderRadiusHelper";
import { StyledButtonPaddingCompensation } from "shared/components/Styled/StyledButtonPaddingCompensation";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";

export const Button = styled((props: ButtonProps) => (
  <StyledButtonPaddingCompensation size="small" {...props} />
))(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    & > * + *:not(.MuiTouchRipple-root) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const BackIconWrapper = styled(Box)(
  ({ theme }) => css`
    border-radius: ${borderRadiusHelper(theme.shape.borderRadius, 0.5)}px;
    background-color: ${theme.palette.tertiary.main};
    height: 20px;
    width: 20px;
    color: ${theme.palette.common.white};
  `,
);

export const BackIcon = styled(ChevronLeft)`
  height: inherit;
  width: inherit;
`;

export const BackText = (props: TextBaseProps) => <TextBase variant="caption" {...props} />;
