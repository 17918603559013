import { FC } from "react";
import {
  SpaceBetweenWrapper,
  StepDetails,
  Content,
  NavigationContent,
  ContentSection,
} from "views/partnerViews/Setup/StepDrawer/StepDrawer.styled";
import { HelpSection } from "views/partnerViews/Setup/StepDrawer/HelpSection";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { Partner } from "shared/logic/types/Partners/Partner";
import { CodeBox } from "shared/components/CodeBox";
import { BackButton, SaveButton, StyledList } from "./SiteTag.styled";
import { useSiteTag } from "./useSiteTag";

interface SiteTagProps {
  partner?: Partner;
}

export const SiteTag: FC<SiteTagProps> = ({ partner }) => {
  const { onBackClick, onSaveClick, isReadOnly } = useSiteTag(partner);
  return (
    <SpaceBetweenWrapper>
      <ContentSection>
        <StepDetails>
          {!isReadOnly && (
            <TextBase
              variant="caption"
              color="text.disabled"
              content="utility.step"
              tParams={{ current: "2", max: "2" }}
            />
          )}
          <TextBase variant="h3" content="setup.partner.siteTag" />
        </StepDetails>
        <Content>
          <TextBase variant="body1" content="setup.partner.codeTitle" />
          <CodeBox code={partner?.initializationTag ?? ""} />
          <TextBase variant="body1" content="setup.partner.tagListTitle" />
          <StyledList>
            <li>
              <TextBase variant="body1" content="setup.partner.tagListFirstStep" />
            </li>
            <li>
              <TextBase variant="body1" content="setup.partner.tagListSecondStep" />
            </li>
          </StyledList>
        </Content>
        <HelpSection />
      </ContentSection>
      {!isReadOnly && (
        <NavigationContent>
          <BackButton color="tertiary" variant="outlined" onClick={onBackClick}>
            <TextBase
              variant="subtitle1"
              content="setup.partner.backToStepOne"
              color="text.secondary"
            />
          </BackButton>
          <SaveButton color="tertiary" variant="contained" onClick={onSaveClick}>
            <TextBase variant="subtitle1" content="setup.partner.saveDomain" />
          </SaveButton>
        </NavigationContent>
      )}
    </SpaceBetweenWrapper>
  );
};
