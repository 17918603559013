import { Tuple } from "shared/logic/store/filters/matchFilters/types";
import { Predicate } from "shared/logic/store/filters/types";
import {
  matchFilterProperty,
  MatchFilterPropertyArgs,
} from "shared/logic/store/filters/matchFilterProperty";
import { PlainObject } from "shared/logic/types/Filters";

type Obj = PlainObject;

type CreatePredicateArgs<O extends Obj> = Pick<
  MatchFilterPropertyArgs<O>,
  "object" | "matchFilters"
>;

export function createPredicate<O extends Obj>({
  object,
  matchFilters,
}: CreatePredicateArgs<O>): Predicate<Tuple<O>> {
  return function predicate([property, filterProperty]) {
    try {
      return matchFilterProperty({
        object,
        matchFilters,
        property,
        filterProperty,
      });
    } catch {
      // DO NOT MATCH - STH WENT WRONG
      // Its better to omit the given filterProperty from filtering than to throw an error to the parent in this case
      return false;
    }
  };
}
