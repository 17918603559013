import { AddCircle } from "@mui/icons-material";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { useNoIntegrations } from "views/brandViews/Setup/NoIntegrations/useNoIntegrations";
import {
  StyledAddIntegrationButton,
  StyledTextWrapper,
  StyledContentWrapper,
} from "./NoIntegrations.styled";

export const NoIntegrations = () => {
  const { onAddIntegrationClick } = useNoIntegrations();
  return (
    <StyledContentWrapper>
      <StyledTextWrapper>
        <TextBase
          variant="h4"
          color="text.secondary"
          content="setup.initial.title"
          align="center"
        />
        <TextBase
          variant="body1"
          color="text.disabled"
          align="center"
          content="setup.initial.description"
        />
      </StyledTextWrapper>
      <StyledAddIntegrationButton
        variant="contained"
        color="tertiary"
        onClick={onAddIntegrationClick}
      >
        <TextBase variant="subtitle1" content="setup.addIntegration" />
        <AddCircle />
      </StyledAddIntegrationButton>
    </StyledContentWrapper>
  );
};
