import { ErrorPage } from "shared/components/ErrorPage";
import { useServerErrorPage } from "views/ServerErrorPage/useServerErrorPage";

export const ServerErrorPage = () => {
  const { onRefresh } = useServerErrorPage();
  return (
    <ErrorPage
      errorNumber={500}
      errorTitle="error.500.title"
      errorDescription="error.500.description"
      errorButtonLabel="navigation.refresh"
      errorButtonAction={onRefresh}
    />
  );
};
