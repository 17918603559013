import { IconButtonProps, IconButton, styled, css } from "@mui/material";

interface CustomIconProps {
  $active: boolean;
}

const IconButtonStyleProps = (props: IconButtonProps) => (
  <IconButton color="primary" size="small" {...props} />
);

export const StyledIconButton = styled(IconButtonStyleProps)<CustomIconProps>(
  ({ theme, $active }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    color: ${$active && theme.palette.tertiary.main};
    background: ${$active && theme.palette.tertiary.dim};
  `,
);

export const GridViewButton = StyledIconButton;
export const ListViewButton = StyledIconButton;
