import { VisitWebsite } from "shared/components/VisitWebsite";
import { FC } from "react";
import {
  Wrapper,
  Avatar,
  PolicyDetails,
  InfoWrapper,
  Details,
  Name,
  Commission,
  Description,
  Role,
  MissingCommission,
} from "shared/components/PolicyInfo/PolicyInfo.styled";
import { PresentationalUserRole } from "shared/logic/_enums/PresentationalUserRole.enum";

export interface PolicyInfoProps {
  name: string;
  commissionPercent?: number;
  description?: string;
  imageUrl?: string;
  website?: string;
  policyType?: PresentationalUserRole | null;
}

export const PolicyInfo: FC<PolicyInfoProps> = ({
  name,
  commissionPercent,
  description,
  imageUrl,
  website,
  policyType,
}) => (
  <Wrapper>
    <PolicyDetails>
      <Avatar src={imageUrl} />
      <InfoWrapper>
        <Details>
          <Name>{name}</Name>
          {policyType && <Role>{policyType}</Role>}
          {commissionPercent ? (
            <Commission
              content="partnerships.brandUserView.commissionValue"
              tParams={{ value: commissionPercent }}
            />
          ) : (
            <MissingCommission content="settings.organization.missingCommission" />
          )}
        </Details>
        {description ? (
          <Description>{description}</Description>
        ) : (
          <Description content="settings.organization.missingDescription" />
        )}
      </InfoWrapper>
    </PolicyDetails>
    {website && <VisitWebsite website={website} label="utility.visitWebsite" />}
  </Wrapper>
);
