import { Product } from "shared/logic/types/Products/Product";
import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import productsApi from "shared/logic/api/productsApi";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";
import _ from "lodash-es";

export interface GetProductsByPartneredProductsReturn {
  products: Product[];
  productsMap: Record<string, Product>;
}

export async function getProductsByPartneredProducts(
  partneredProducts: PartneredProduct[],
): Promise<GetProductsByPartneredProductsReturn> {
  if (_.isEmpty(partneredProducts)) {
    return {
      products: [],
      productsMap: {},
    };
  }

  const productsIds = partneredProducts.map((partneredProduct) => partneredProduct.productId);
  const productsDetailsResponse = await productsApi.getProductsDetailedInfo({
    include: productsIds,
  });
  const { products } = productsDetailsResponse.data;
  const productsMap = parseArrayToIdsEntityPair(products);

  return {
    products,
    productsMap,
  };
}
