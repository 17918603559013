import { FC } from "react";
import { FiltersContainer } from "shared/components/TableComponents/FiltersContainer";
import { TableWrapper } from "shared/components/TableComponents/TableWrapper";
import { TableBody } from "@mui/material";
import { ViewHeadline } from "shared/components/ViewHeadline";
import usePartnershipsForBrandUser from "views/brandViews/Partnerships/usePartnershipsForBrandUser";
import { SpaceBetweenWrapper } from "views/brandViews/Partnerships/Partnerships.styled";
import { PartnershipsListHeader } from "views/brandViews/Partnerships/PartnershipsListHeader";
import { PartnershipItem } from "views/brandViews/Partnerships/PartnershipItem";
import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";

export const PartnershipsForBrandUser: FC = () => {
  const { partnerships, isEmpty, isLoading } = usePartnershipsForBrandUser();
  return (
    <StyledViewSpace multiplier={3}>
      <ViewHeadline localizedText="partnerships.brandUserView.title" />
      <StyledViewSpace multiplier={1}>
        <FiltersContainer />
        <SpaceBetweenWrapper>
          <TableWrapper
            missingContentTitle="partnerships.brandUserView.noPartnerships"
            missingContentDescription="partnerships.brandUserView.noPartnershipsDescription"
            isLoading={isLoading}
            isEmpty={isEmpty}
          >
            <PartnershipsListHeader />
            <TableBody>
              {!isLoading &&
                !isEmpty &&
                partnerships.map((partnership) => (
                  <PartnershipItem key={partnership.id} partnership={partnership} />
                ))}
            </TableBody>
          </TableWrapper>
        </SpaceBetweenWrapper>
      </StyledViewSpace>
    </StyledViewSpace>
  );
};
