import { styled, Box, css } from "@mui/material";
import { borderRadiusHelper } from "shared/logic/utils/theme/borderRadiusHelper";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";

export const TagStyle = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)};
    outline: 1px solid ${theme.palette.border};
    box-shadow: 0 0 ${theme.spacing(1)} rgba(0, 0, 0, 0.08);
    border-radius: ${borderRadiusHelper(theme.shape.borderRadius, 1.5)}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: content-box;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      background: ${theme.palette.tertiary.dim};
      outline: 2px solid ${theme.palette.tertiary.main};
    }
  `,
);

export const TagDescription = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(3)};
    }
  `,
);

export const Title = (props: TextBaseProps) => <TextBase variant="subtitle1" {...props} />;
