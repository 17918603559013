import { createAsyncThunk } from "@reduxjs/toolkit";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import partnersApi from "shared/logic/api/partnersApi";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";

interface UpdatePartnerDetailsParams {
  newOrganizationData: {
    logo?: File;
    name: string;
    description: string;
    defaultCommissionPercent: number;
  };
  partnerId: string;
}

export const updatePartnerDetails = createAsyncThunk(
  storePrefixes.partnerUser.settingsSlice.updateOrganizationDetails,
  async (
    {
      partnerId,
      newOrganizationData: { name, defaultCommissionPercent, logo, description },
    }: UpdatePartnerDetailsParams,
    { dispatch },
  ) => {
    try {
      let patchedPartnerResponse = await partnersApi.patchPartner(partnerId, {
        name,
        defaultCommissionPercent,
        description,
      });
      if (logo) {
        patchedPartnerResponse = await partnersApi.updatePartnerLogo({ logo, partnerId });
      }
      const patchedPartner = patchedPartnerResponse.data;

      dispatch(
        setSnackbar({
          open: true,
          message: "snackbar.userUpdateSuccess",
          icon: SnackbarIcon.DONE,
        }),
      );
      return { newPartnerData: patchedPartner };
    } catch (e) {
      ConsoleLog(e);
      dispatch(setSnackbar({ open: true, message: "snackbar.genericError" }));
      throw e;
    }
  },
);
