import { FC } from "react";
import { Box } from "@mui/material";
import {
  useCopyToClipboard,
  UseCopyToClipboardProps,
} from "shared/logic/hooks/generalPurpose/useCopyToClipboard";

type CopyToClipboardButtonProps = UseCopyToClipboardProps;

const CopyToClipboardButton: FC<CopyToClipboardButtonProps> = ({
  children,
  textToCopy,
  itemName,
}) => {
  const copyToClipboard = useCopyToClipboard();
  const onButtonClick = () => {
    copyToClipboard({ textToCopy, itemName });
  };
  return <Box onClick={onButtonClick}>{children}</Box>;
};

export default CopyToClipboardButton;
