import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { useState, useEffect } from "react";
import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import { DomainDetails } from "views/partnerViews/Setup/StepDrawer/DomainDetails";
import { SiteTag } from "views/partnerViews/Setup/StepDrawer/SiteTag";
import { DomainEdited } from "views/partnerViews/Setup/StepDrawer/DomainEdited";
import { SetupStep } from "views/partnerViews/Setup/StepDrawer/index";
import { Partner } from "shared/logic/types/Partners/Partner";

interface UseStepDrawerProps {
  closeDrawer: () => void;
  partner?: Partner;
}

export const useStepDrawer = ({ closeDrawer, partner }: UseStepDrawerProps) => {
  const [searchParams] = useSearchParams();
  const [title, setTitle] = useState<TextContentPath>();
  const [content, setContent] = useState<JSX.Element>();
  const onClose = () => {
    closeDrawer();
  };
  useEffect(() => {
    switch (searchParams.step) {
      case SetupStep.DOMAIN_DETAILS:
        setTitle(searchParams.edit ? "setup.partner.editDomain" : "setup.partner.addDomain");
        setContent(<DomainDetails />);
        return;
      case SetupStep.SITE_TAG:
        setTitle(
          searchParams.readOnly ? "setup.partner.setupInstructions" : "setup.partner.addDomain",
        );
        setContent(<SiteTag partner={partner} />);
        return;
      case SetupStep.DOMAIN_EDITED:
        setTitle("setup.partner.editDomain");
        setContent(<DomainEdited partner={partner} />);
        return;
      default: {
        closeDrawer();
        setContent(undefined);
      }
    }
  }, [searchParams.step, partner]);

  return {
    title,
    onClose,
    content,
  };
};
