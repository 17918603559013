import { PlainObject, PrimitiveFilter } from "shared/logic/types/Filters";
import isArray from "lodash-es/isArray";
import { filter } from "shared/logic/store/filters/utils/filter";
import { createPredicate } from "shared/logic/store/filters/matchPrimitiveFilter/createPredicate";

type Obj = PlainObject;

export interface MatchPrimitiveFilterArgs {
  value: any;
  primitiveFilter: PrimitiveFilter<Obj, any>;
}

type MatchPrimitiveFilterReturn = boolean;

export function matchPrimitiveFilter({
  value,
  primitiveFilter,
}: MatchPrimitiveFilterArgs): MatchPrimitiveFilterReturn {
  // INVALID FILTER
  if (!isArray(primitiveFilter.arguments)) {
    throw new Error("ARGUMENTS (PrimitiveFilter) SHOULD BE AN ARRAY");
  }

  return filter({
    booleanFunction: primitiveFilter.function,
    entries: primitiveFilter.arguments,
    predicate: createPredicate({ value }),
  });
}
