import { FC, Fragment } from "react";
import { TableBody } from "@mui/material";
import { ViewHeadline } from "shared/components/ViewHeadline";
import { TableWrapper } from "shared/components/TableComponents/TableWrapper";
import { FiltersContainer } from "shared/components/TableComponents/FiltersContainer";
import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";
import { DiscoverPartnerListHeader } from "./DiscoverPartnerTableHeader";
import { DiscoverPartnerItem } from "./DiscoverPartnerItem";
import { SpaceBetweenWrapper } from "../Partnerships/Partnerships.styled";
import { RequestNewPartnerDrawer } from "./RequestNewPartnerDrawer";
import { useDiscoverPartner } from "./useDiscoverPartner";

export const DiscoverPartner: FC = () => {
  const { partners, onSearchHandler, isLoading } = useDiscoverPartner();
  return (
    <StyledViewSpace multiplier={3}>
      <ViewHeadline
        localizedText="discoverPartners.title"
        buttonToDisplay={<RequestNewPartnerDrawer />}
      />
      <StyledViewSpace multiplier={1}>
        <FiltersContainer onSearchHandler={onSearchHandler} />
        <SpaceBetweenWrapper>
          <TableWrapper isLoading={isLoading}>
            <DiscoverPartnerListHeader />
            <TableBody>
              {partners.map((partner) => (
                <Fragment key={partner.id}>
                  {partner && <DiscoverPartnerItem partner={partner} />}
                </Fragment>
              ))}
            </TableBody>
          </TableWrapper>
        </SpaceBetweenWrapper>
      </StyledViewSpace>
    </StyledViewSpace>
  );
};
