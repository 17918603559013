import { styled, Box, css } from "@mui/material";
import { OverflowColumnFlex } from "shared/components/OverflowColumnFlex";

export const StyledWrapper = styled(Box)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    display: flex;
    padding: ${theme.spacing(2)};
    background-color: ${theme.palette.background.onSurface};

    & > * + * {
      margin-left: ${theme.spacing(2)};
    }
  `,
);

export const StyledInfoWrapper = styled(OverflowColumnFlex)`
  justify-content: center;
`;

export const StyledDetailsWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    color: ${theme.palette.text.disabled};
    align-items: center;

    & > * + *::before {
      content: "·";
      margin: ${theme.spacing(0, 1)};
    }
  `,
);
