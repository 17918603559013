import { useForm } from "react-hook-form";
import { UserAccountFormEnum, UserDetailsUpdateParams } from "views/SelfSignup/useSelfSignUp";
import { UserRole } from "shared/components/auth/AuthContext";
import { useDispatch } from "react-redux";
import { useAuth } from "shared/logic/hooks/auth/useAuth";
import { signUpPartner } from "shared/logic/store/features/user/signUp/partner";
import { signUpBrand } from "shared/logic/store/features/user/signUp/brand";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { FormDetailsProps } from "./index";

export type UseFormDetailsProps = FormDetailsProps;

export const useSelfSignUpSecondStep = ({
  userDetailsUpdate,
  savedUserDetails,
}: UseFormDetailsProps) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { isValid },
  } = useForm({
    defaultValues: savedUserDetails,
    mode: "onChange",
  });

  const { authUser } = useAuth();
  const creatorTermsAndConditionsUrl = process.env.REACT_APP_CREATOR_TERMS_AND_CONDITIONS_URL;
  const isBrandUser = savedUserDetails[UserAccountFormEnum.USER_TYPE] === UserRole.BRAND;
  const isPartnerUser = savedUserDetails[UserAccountFormEnum.USER_TYPE] === UserRole.PARTNER;

  const onSubmit = ({
    userLogo,
    userDescription,
    termsConditions,
    commissionRate,
  }: UserDetailsUpdateParams) => {
    userDetailsUpdate({ userLogo, userDescription, termsConditions, commissionRate });

    const policyName = savedUserDetails[UserAccountFormEnum.USER_NAME];

    if (!userDescription || !commissionRate || !policyName || !authUser?.email) {
      dispatch(setSnackbar({ message: "snackbar.genericError" }));
      return;
    }

    if (isBrandUser) {
      dispatch(
        signUpBrand({
          newBrand: {
            name: policyName,
            description: userDescription,
            defaultPartnershipCommissionPercent: commissionRate,
          },
          logo: userLogo,
          newUser: { emailAddress: authUser?.email },
        }),
      );
      return;
    }
    if (isPartnerUser) {
      dispatch(
        signUpPartner({
          newPartner: {
            name: policyName,
            description: userDescription,
            defaultCommissionPercent: commissionRate,
          },
          newUser: { emailAddress: authUser?.email },
          logo: userLogo,
        }),
      );
      return;
    }
    dispatch(setSnackbar({ message: "snackbar.genericError" }));
  };

  const onImageUpload = (file: File) => {
    setValue(UserAccountFormEnum.USER_LOGO, file);
  };

  return {
    handleSubmit,
    register,
    isValidForm: isValid,
    onSubmit,
    isBrandUser,
    onImageUpload,
    creatorTermsAndConditionsUrl,
  };
};
