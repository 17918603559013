import { FiltersViewState } from "shared/logic/store/filters/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { Filters, PlainObject } from "shared/logic/types/Filters";

type Obj = PlainObject;

export function createSliceActions<O extends Obj>() {
  return {
    setSearch(state: FiltersViewState<O>, action: PayloadAction<Filters<O>>) {
      state.search = action.payload;
    },
    resetSearch(state: FiltersViewState<O>) {
      state.search = undefined;
    },
    setFilters(state: FiltersViewState<O>, action: PayloadAction<Filters<O>>) {
      state.filters = action.payload;
    },
    resetFilters(state: FiltersViewState<O>) {
      state.filters = undefined;
    },
    resetSearchAndFilters(state: FiltersViewState<O>) {
      state.search = undefined;
      state.filters = undefined;
    },
  };
}
