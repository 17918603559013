import { RootState } from "shared/logic/store/rootReducer";
import { Product } from "shared/logic/types/Products/Product";
import { Partner } from "shared/logic/types/Partners/Partner";
import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import Partnership from "shared/logic/types/Partnerships/Partnership";
import { createSelector } from "@reduxjs/toolkit";
import { getPartners } from "shared/logic/store/features/resourceSlices/partners/selectors";
import { getPartnershipsAggregatedByPartnerId } from "shared/logic/store/features/resourceSlices/partnerships/selectors";
import { getPartneredProductsAggregatedByPartnershipId } from "shared/logic/store/features/resourceSlices/partneredProducts/selectors";
import { getProducts } from "shared/logic/store/features/resourceSlices/products/selectors";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";

export interface PartnerAggregated extends Partner {
  partneredProducts: PartneredProduct[];
  partnership: Partnership;
}

export interface AssignProductAggregated extends Product {
  partneredProduct: PartneredProduct | null;
  partnership: Partnership;
}

const getAssignProductSlice = (state: RootState) => state.brand.assignProductView;

const getAssignProductViewStatus = createSelector(
  [getAssignProductSlice],
  ({ assignSliceStatus }) => assignSliceStatus,
);

const getIsAssignProductViewLoading = createSelector(
  [getAssignProductViewStatus],
  (assignProductSliceStatus) => assignProductSliceStatus === SliceStatus.PENDING,
);

const getProductId = createSelector([getAssignProductSlice], ({ productId }) => productId);
const getPartnerId = createSelector([getAssignProductSlice], ({ partnerId }) => partnerId);
const getPartnersIds = createSelector([getAssignProductSlice], ({ partnersIds }) => partnersIds);

const getPartnersForAssignProductView = createSelector(
  [getPartnersIds, getPartners],
  (partnersIds, partners) => partnersIds?.map((partnerId) => partners[partnerId]),
);

const getPartnershipForAssignView = createSelector(
  [getPartnershipsAggregatedByPartnerId, getPartnerId],
  (partnershipsAggregatedByPartnerId, partnerId) =>
    partnerId
      ? partnershipsAggregatedByPartnerId[partnerId][0] // it should always be just one since request contains brandId and partnerId filtering
      : null,
);

const getPartnersAggregated = createSelector(
  [
    getPartnersForAssignProductView,
    getPartnershipsAggregatedByPartnerId,
    getPartneredProductsAggregatedByPartnershipId,
  ],
  (
    partners,
    partnershipsAggregatedByPartnerId,
    partneredProductsAggregatedByPartnershipId,
  ): PartnerAggregated[] => {
    if (!partners || !partneredProductsAggregatedByPartnershipId) {
      return [];
    }

    return partners.map((partner) => {
      const partnership = partnershipsAggregatedByPartnerId[partner.id];
      const partnershipForCurrentBrand = partnership?.[0]; // partnerships are fetched with brand-id filter, so it will always be one partnership
      return {
        ...partner,
        partnership: partnershipForCurrentBrand,
        partneredProducts:
          partneredProductsAggregatedByPartnershipId[partnershipForCurrentBrand?.id ?? ""],
      };
    });
  },
);

const getProductsAggregated = createSelector(
  [
    getProducts,
    getPartnershipForAssignView,
    getPartneredProductsAggregatedByPartnershipId,
    getPartnerId,
  ],
  (
    products,
    partnership,
    partneredProductsAggregatedByPartnershipId,
    partnerId,
  ): AssignProductAggregated[] => {
    if (!partneredProductsAggregatedByPartnershipId || !partnerId || !partnership) {
      return [];
    }

    const partneredProducts = partneredProductsAggregatedByPartnershipId[partnership.id] ?? null;

    return Object.values(products).map((product) => {
      const partneredProduct =
        partneredProducts?.find((item) => item.productId === product.id) ?? null;
      return {
        ...product,
        partnership,
        partneredProduct,
      };
    });
  },
);

const getAssignSliceState = createSelector(
  [getAssignProductViewStatus, getProductsAggregated, getPartnersAggregated],
  (assignSliceStatus, productsAggregated, partnersAggregated) => ({
    areProductsEmpty: assignSliceStatus === SliceStatus.FULFILLED && !productsAggregated.length,
    arePartnersEmpty: assignSliceStatus === SliceStatus.FULFILLED && !partnersAggregated.length,
    isLoading: assignSliceStatus === SliceStatus.PENDING,
  }),
);

const getPartner = createSelector([getPartners, getPartnerId], (partners, partnerId) =>
  partnerId ? partners[partnerId] : null,
);

const getProduct = createSelector(
  [getProducts, getProductId],
  (products, productId): Product | null => (productId ? products[productId] : null),
);

export default {
  getAssignProductSlice,
  getAssignProductViewStatus,
  getIsAssignProductViewLoading,
  getProductId,
  getPartnerId,
  getPartnersIds,
  getPartnersForAssignProductView,
  getPartnershipForAssignView,
  getPartnersAggregated,
  getProductsAggregated,
  getAssignSliceState,
  getPartner,
  getProduct,
};
