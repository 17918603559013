import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { useFilter } from "shared/logic/hooks/generalPurpose/useFilter";
import { storeAPI } from "views/brandViews/storeAPI";

const useProducts = () => {
  const dispatch = useDispatch();
  const unfilteredProducts = useSelector(storeAPI.productsView.getProductsForProductsView);
  const { onSearchHandler, items: products } = useFilter({
    unfilteredItems: unfilteredProducts,
    propertiesToFilterBy: ["name", "description"],
  });
  const policy = useSelector(getUserPolicy);
  const isProductsLoading = useSelector(storeAPI.productsView.getIsProductsLoading);
  const isEmpty = products && !isProductsLoading && products.length === 0;

  useEffect(() => {
    dispatch(storeAPI.productsView.fetchResources({ brandId: policy!.entityId }));
  }, [policy?.entityId]);

  return {
    products,
    isLoading: isProductsLoading,
    isEmpty,
    onSearchHandler,
  };
};

export default useProducts;
