import { styled, Box, css, Button, Avatar } from "@mui/material";

export const StyledImageUpload = styled(Box)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.border};
    display: flex;
    justify-content: space-between;
    border-radius: ${theme.shape.borderRadius}px;
    padding: ${theme.spacing(2)};
  `,
);

export const StyledImageInfo = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(2)};
    }
  `,
);

export const StyledUploadButton = styled(Button)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: ${theme.palette.tertiary.main};

    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledInputBox = styled(Box)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const StyledAvatar = styled(Avatar)(
  ({ theme }) => css`
    background: ${theme.palette.background.onSurface};
  `,
);
