import { Partner } from "shared/logic/types/Partners/Partner";
import { useDomainActions } from "views/partnerViews/Setup/StepDrawer/useDomainActions";

export const useSiteTag = (partner?: Partner) => {
  const { searchParams, onSaveClick, onBackClick } = useDomainActions({ partner });
  const isReadOnly = !!searchParams.readOnly;

  return {
    onBackClick,
    onSaveClick,
    isReadOnly,
  };
};
