export default function parseArrayToIdsEntityPair<EntityType>(
  array: EntityType[],
  keyForAggregation: keyof EntityType = "id" as keyof EntityType,
) {
  if (!Array.isArray(array)) {
    return {};
  }
  const idEntityPair: { [key: string | number]: EntityType } = {};
  array.forEach((el) => {
    if (el[keyForAggregation] !== undefined) {
      idEntityPair[el[keyForAggregation] as unknown as string | number] = el; // keyForAggregation should always point to string or number value
    } else {
      // TODO: logger
    }
  });
  return idEntityPair;
}
