import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";
import { FiltersContainer } from "shared/components/TableComponents/FiltersContainer";
import { SpaceBetweenWrapper } from "views/brandViews/AssignProduct/AssignProduct.styled";
import { TableWrapper } from "shared/components/TableComponents/TableWrapper";
import { TableBody } from "@mui/material";
import { PolicyInfo } from "shared/components/PolicyInfo";
import { AssignPartnerTableHeader } from "views/brandViews/AssignPartner/AssignPartnerTableHeader";
import { ProductItem } from "views/brandViews/AssignPartner/ProductItem";
import { Fragment } from "react";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { useAssignPartner } from "views/brandViews/AssignPartner/useAssignPartner";

export const AssignPartner = () => {
  const { partner, productsAggregated, areProductsEmpty, isLoading } = useAssignPartner();

  if (isLoading || !partner) {
    return <FullScreenLoader />;
  }

  return (
    <StyledViewSpace multiplier={3}>
      <PolicyInfo
        name={partner.name}
        commissionPercent={productsAggregated[0].partnership.commissionPercent}
        description={partner.description}
        website={partner.website}
      />
      <StyledViewSpace multiplier={1}>
        <FiltersContainer />
        <SpaceBetweenWrapper>
          <TableWrapper
            isLoading={isLoading}
            isEmpty={areProductsEmpty}
            missingContentDescription="products.noProductsDescription"
            missingContentTitle="partners.noProducts"
          >
            <AssignPartnerTableHeader />
            <TableBody>
              {Object.values(productsAggregated).map((product) => (
                <Fragment key={product.id}>
                  <ProductItem product={product} />
                </Fragment>
              ))}
            </TableBody>
          </TableWrapper>
        </SpaceBetweenWrapper>
      </StyledViewSpace>
    </StyledViewSpace>
  );
};
