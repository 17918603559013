import { RootState } from "shared/logic/store/rootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { getPartnerships } from "shared/logic/store/features/resourceSlices/partnerships/selectors";
import { getPartners } from "shared/logic/store/features/resourceSlices/partners/selectors";
import { getPartneredProductsAggregatedByPartnershipId } from "shared/logic/store/features/resourceSlices/partneredProducts/selectors";
import { parsePartnershipToBrandUserPartnershipsAggregated } from "shared/logic/store/features/viewsSlices/brandUser/partnerships/utils/parsePartnershipToBrandUserPartnershipsAggregated";

const getPartnershipsViewSlice = (state: RootState) => state.brand.partnershipsViewSlice;

const getPartnershipsViewSliceStatus = createSelector(
  [getPartnershipsViewSlice],
  ({ partnershipsSliceStatus }) => partnershipsSliceStatus,
);

const getPartnershipsAggregated = createSelector(
  [getPartnerships, getPartners, getPartneredProductsAggregatedByPartnershipId],
  (partnershipsMap, partnersMap, partneredProductsAggregatedByPartnershipId) =>
    parsePartnershipToBrandUserPartnershipsAggregated(
      partnershipsMap,
      partnersMap,
      partneredProductsAggregatedByPartnershipId,
    ),
);

export default {
  getPartnershipsViewSlice,
  getPartnershipsViewSliceStatus,
  getPartnershipsAggregated,
};
