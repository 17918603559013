import { createAsyncThunk } from "@reduxjs/toolkit";
import ecommerceAccountsApi from "shared/logic/api/ecommerceAccountsApi";
import brandsApi from "shared/logic/api/brandsApi";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import Brand from "shared/logic/types/Brands/Brand";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { EcommerceAccount } from "shared/logic/types/EcommerceAccount/EcommerceAccount";

export interface FetchExistingIntegrationsForTheBrandReturnType {
  brands: IdAndEntityPair<Brand>;
  ecommerceAccounts: IdAndEntityPair<EcommerceAccount>;
}

export interface FetchExistingIntegrationsForTheBrandPayload {
  brandId: string;
}

export const fetchExistingIntegrations = createAsyncThunk(
  storePrefixes.brandUser.setupSlice.fetch,
  async (
    { brandId }: FetchExistingIntegrationsForTheBrandPayload,
    { dispatch },
  ): Promise<FetchExistingIntegrationsForTheBrandReturnType> => {
    try {
      const ecommerceAccountsResponse = await ecommerceAccountsApi.getEcommerceAccounts({
        "brand-id": brandId,
      });
      const { ecommerceAccounts } = ecommerceAccountsResponse.data;
      const brandsResponse = await brandsApi.getBrandsByQuery({
        include: ecommerceAccounts.map((e) => e.brandId),
      });
      const brandsMap = parseArrayToIdsEntityPair(brandsResponse.data.brands);

      return {
        // RESOURCE SLICES DATA
        brands: brandsMap,
        ecommerceAccounts: parseArrayToIdsEntityPair(ecommerceAccounts),
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          open: true,
          message: "snackbar.genericError",
        }),
      );
      throw e;
    }
  },
);
