import React, { FC } from "react";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { useSelfSignUp } from "./useSelfSignUp";
import { StyledLogo, StyledWrapper, StepTitleSection, Wrapper } from "./SelfSignUp.styled";

export const SelfSignUp: FC = () => {
  const { stepObject, profileName, isSignUpLoading } = useSelfSignUp();
  if (isSignUpLoading) {
    return <FullScreenLoader />;
  }
  return (
    <StyledWrapper>
      <StyledLogo />
      <Wrapper>
        <StepTitleSection>
          <TextBase variant="caption" color="text.disabled">
            Step {stepObject.number} / 2
          </TextBase>
          <TextBase variant="h3" content={stepObject.title} tParams={{ name: profileName }} />
        </StepTitleSection>
        {stepObject.component}
      </Wrapper>
    </StyledWrapper>
  );
};
