import { styled, AppBar, css, IconButton, Toolbar } from "@mui/material";

export const StyledAppBar = styled(AppBar)(
  ({ theme }) => css`
    background-color: ${theme.palette.background.paper};
    border-bottom: 1px solid ${theme.palette.border};
  `,
);

export const StyledButton = styled(IconButton)(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.border};
    border-radius: ${theme.shape.borderRadius}px;
    padding: ${theme.spacing(0.5)};
    color: ${theme.palette.text.primary};
  `,
);

export const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
`;
