import { Box, css, IconButton, styled, ButtonProps, Button } from "@mui/material";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";

export const StyledDrawerContent = styled(Box)(
  ({ theme }) => css`
    max-width: 720px;
    padding: ${theme.spacing(4, 8, 3, 8)};
    display: flex;
    flex-direction: column;
    flex: 1;

    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);

export const TitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledCloseButton = styled(IconButton)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    border: 1px solid ${theme.palette.border};
  `,
);

export const StyledDrawerStep = styled(Box)`
  display: flex;
  flex: 1;
`;

export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const BottomNavigationContainer = styled(Box)(
  ({ theme }) => css`
    border-top: 1px solid ${theme.palette.border};
    height: 72px;
    padding-top: ${theme.spacing(3)};
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const Title = (props: TextBaseProps) => (
  <TextBase variant="subtitle1" color="text.disabled" {...props} />
);

export const BottomLabel = (props: TextBaseProps) => (
  <TextBase variant="tableLabel" color="text.disabled" {...props} />
);

export const SubmitButton = (props: ButtonProps) => (
  <Button variant="contained" color="tertiary" {...props} />
);
export const SubmitLabel = (props: TextBaseProps) => <TextBase variant="subtitle1" {...props} />;
