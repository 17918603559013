import Partnership from "shared/logic/types/Partnerships/Partnership";
import PartnershipsQuery from "shared/logic/types/Partnerships/PartnershipsQuery";
import partnershipsApi from "shared/logic/api/partnershipsApi";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";

export interface GetPartnershipsReturn {
  partnerships: Partnership[];
  partnershipsMap: Record<string, Partnership>;
}

export async function getPartnerships(
  partnershipQuery: PartnershipsQuery,
): Promise<GetPartnershipsReturn> {
  const partnershipsResponse = await partnershipsApi.getPartnerships(partnershipQuery);
  const { partnerships } = partnershipsResponse.data;
  const partnershipsMap = parseArrayToIdsEntityPair(partnerships);

  return {
    partnerships,
    partnershipsMap,
  };
}
