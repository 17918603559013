import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { useEffect } from "react";

export enum SettingsTabs {
  ORGANIZATION = "organization",
  USER_PROFILE = "user-profile",
}

export const useSettingsWrapper = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isOrganizationTab = searchParams.details === SettingsTabs.ORGANIZATION;
  const isUserProfileTab = searchParams.details === SettingsTabs.USER_PROFILE;
  const tabToDisplay = searchParams.details as SettingsTabs;

  const setTab = (input: SettingsTabs) => {
    setSearchParams({ details: input });
  };

  useEffect(() => {
    if (!searchParams.details) {
      setTab(SettingsTabs.ORGANIZATION);
    }
  }, [searchParams]);

  return {
    tabToDisplay,
    isOrganizationTab,
    isUserProfileTab,
    setTab,
  };
};
