import { FC } from "react";
import { ChevronRight } from "@mui/icons-material";
import { TableCell, Typography } from "@mui/material";
import { Image, ImageCell, StyledItemRow } from "shared/components/TableComponents/Styles";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { PartnershipInteractions } from "shared/components/PartnershipInteractions";
import { VisitWebsite } from "shared/components/VisitWebsite";
import {
  CommissionBox,
  StyledButton,
  StyledInfoWrapper,
  CommissionDetail,
} from "./DiscoverBrandsItem.styled";
import { useDiscoverBrandItem } from "./useDiscoverBrandItem";
import { Quantity } from "../BrandInfo/BrandInfo.styled";

export interface BrandInfoProps {
  imageUrl: string;
  name: string;
  website: string;
  brandId: string;
  commissionPercent?: number;
  flatFeeValue?: number;
}

export const DiscoverBrandsItem: FC<BrandInfoProps> = ({
  imageUrl,
  name,
  website,
  brandId,
  commissionPercent,
  flatFeeValue,
}) => {
  const { formattedFeeValue, onExploreClick } = useDiscoverBrandItem({ flatFeeValue, brandId });
  return (
    <StyledItemRow>
      <ImageCell>
        <Image variant="rounded" src={imageUrl} />
      </ImageCell>
      <TableCell padding="none">
        <StyledInfoWrapper>
          <Typography variant="subtitle1">{name}</Typography>
        </StyledInfoWrapper>
      </TableCell>
      <TableCell align="right" padding="none">
        {website && <VisitWebsite website={website} label="utility.visitWebsite" />}
      </TableCell>
      <TableCell align="center">
        <StyledButton onClick={onExploreClick}>
          <TextBase variant="subtitle1" content="discoverBrands.brandItem.explore" />
          <ChevronRight color="tertiary" />
        </StyledButton>
      </TableCell>
      <TableCell align="right">
        <CommissionBox>
          {!!commissionPercent && (
            <CommissionDetail>
              <TextBase
                variant="caption"
                color="text.disabled"
                content="discoverBrands.header.transaction"
              />
              <Quantity>{commissionPercent}%</Quantity>
            </CommissionDetail>
          )}
          {!!flatFeeValue && (
            <CommissionDetail>
              <TextBase
                variant="caption"
                color="text.disabled"
                content="discoverBrands.header.flatFee"
              />
              <Quantity>{formattedFeeValue}</Quantity>
            </CommissionDetail>
          )}
        </CommissionBox>
      </TableCell>
      <TableCell align="center">
        <PartnershipInteractions entityIdToPartnerWith={brandId} />
      </TableCell>
    </StyledItemRow>
  );
};
