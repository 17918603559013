import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { TextBase, TextContentPath } from "shared/components/base/TextBase/TextBase";
import { StyledContentWrapper } from "./StepWrapper.styled";

export interface StepWrapperProps {
  step: number;
  title: TextContentPath;
}

export const StepWrapper: FC<StepWrapperProps> = ({ step, title, children }) => (
  <StyledContentWrapper>
    <Box>
      <Typography color="text.disabled" variant="caption">
        Step {step}/3
      </Typography>
      <TextBase variant="h3" content={title} />
    </Box>
    <Box>{children}</Box>
  </StyledContentWrapper>
);
