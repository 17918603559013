import { TableCell } from "@mui/material";
import { StyledItemRow } from "shared/components/TableComponents/Styles";
import { FC } from "react";
import { Settings } from "@mui/icons-material";
import {
  IconButtonsCell,
  StyledEditIcon,
  StyledIconButtonGray,
} from "views/partnerViews/Setup/IntegrationItem/IntegrationItem.styled";
import { StyledIconButton } from "shared/components/Styled/StyledIconButton";
import { Partner } from "shared/logic/types/Partners/Partner";
import { useIntegrationItem } from "views/partnerViews/Setup/IntegrationItem/useIntegrationItem";
import { StyledTooltip } from "shared/components/Styled/StyledTooltip";

export interface IntegrationItemProps {
  partner: Partner;
  toggleDrawer: () => void;
}

export const IntegrationItem: FC<IntegrationItemProps> = ({ partner, toggleDrawer }) => {
  const { onEditClick, onSettingsClick } = useIntegrationItem({ toggleDrawer });
  return (
    <StyledItemRow>
      <TableCell>{partner.website}</TableCell>
      <IconButtonsCell align="right">
        <StyledTooltip title="tooltip.editDomain">
          <StyledIconButtonGray onClick={onEditClick}>
            <StyledEditIcon color="tertiary" />
          </StyledIconButtonGray>
        </StyledTooltip>
        <StyledTooltip title="tooltip.setupInstructions">
          <StyledIconButton onClick={onSettingsClick}>
            <Settings />
          </StyledIconButton>
        </StyledTooltip>
      </IconButtonsCell>
    </StyledItemRow>
  );
};
