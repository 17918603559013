import { styled, Box, css, Badge } from "@mui/material";

export const FlexBox = styled(Box)(
  ({ theme }) => css`
    display: flex;
    height: 100%;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledBadge = styled(Badge)(
  ({ theme }) => css`
    width: 20px;

    .MuiBadge-badge:not(.MuiBadge-invisible) {
      color: ${theme.palette.text.primary};
      transform: scale(1) translate(0, -50%); // move badge left to the anchor
    }
  `,
);

export const SearchInputContainer = styled(Box)(
  ({ theme }) => css`
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: content-box;

    & > * + * {
      margin-left: ${theme.spacing(2)};
    }
  `,
);
