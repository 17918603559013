import { ViewHeadline } from "shared/components/ViewHeadline";
import { FiltersContainer } from "shared/components/TableComponents/FiltersContainer";
import { TableWrapper } from "shared/components/TableComponents/TableWrapper";
import { TableBody } from "@mui/material";
import { Fragment } from "react";
import { ProductsTableHeader } from "views/brandViews/Products/ProductsTableHeader";
import { ProductsItem } from "views/brandViews/Products/ProductsItem";
import useProducts from "views/brandViews/Products/useProducts";
import { SpaceBetweenWrapper } from "views/brandViews/Products/Products.styled";
import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";

export const Products = () => {
  const { products, isLoading, isEmpty, onSearchHandler } = useProducts();
  return (
    <StyledViewSpace multiplier={3}>
      <ViewHeadline localizedText="products.title" />
      <StyledViewSpace multiplier={1}>
        <FiltersContainer onSearchHandler={onSearchHandler} />
        <SpaceBetweenWrapper>
          <TableWrapper
            isLoading={isLoading}
            isEmpty={isEmpty}
            missingContentDescription="products.noProductsDescription"
            missingContentTitle="products.noProducts"
          >
            <ProductsTableHeader />
            <TableBody>
              {!isLoading &&
                !isEmpty &&
                Object.values(products!).map((product) => (
                  <Fragment key={product.id}>
                    {product && <ProductsItem product={product} />}
                  </Fragment>
                ))}
            </TableBody>
          </TableWrapper>
        </SpaceBetweenWrapper>
      </StyledViewSpace>
    </StyledViewSpace>
  );
};
