import { StyledItemRow, ImageCell, Image } from "shared/components/TableComponents/Styles";
import { TableCell, Typography } from "@mui/material";
import { FC } from "react";
import { Share } from "@mui/icons-material";
import { useProductsItem } from "views/brandViews/Products/ProductsItem/useProductsItem";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import {
  StyledInfoWrapper,
  StyledButtonWrapper,
  StyledShareButton,
} from "views/brandViews/Products/ProductsItem/ProductsItem.styled";
import { Product } from "shared/logic/types/Products/Product";

interface BrandProductsItemProps {
  product: Product;
}

export const ProductsItem: FC<BrandProductsItemProps> = ({ product }) => {
  const { onShareClick } = useProductsItem(product);
  return (
    <StyledItemRow>
      <ImageCell>
        <Image src={product.primaryImageUrl} variant="rounded" />
      </ImageCell>
      <TableCell padding="none">
        <StyledInfoWrapper>
          <Typography variant="subtitle1">{product.name}</Typography>
          <Typography variant="caption" color="text.disabled">
            {product.productVariants.length} Variant{product.productVariants.length > 1 && "s"}
          </Typography>
        </StyledInfoWrapper>
      </TableCell>
      <TableCell align="right" padding="none">
        <StyledButtonWrapper>
          <StyledShareButton onClick={onShareClick} size="small">
            <TextBase variant="subtitle1" content="products.shareProduct" />
            <Share color="tertiary" />
          </StyledShareButton>
        </StyledButtonWrapper>
      </TableCell>
    </StyledItemRow>
  );
};
