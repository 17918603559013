import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { storeAPI } from "views/brandViews/storeAPI";

const useDashboardForBrandUser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const policy = useSelector(getUserPolicy);
  const isDashboardDataFetched = useSelector(storeAPI.dashboardView.getIsDashboardDataFetched);
  const partnershipsAggregated = useSelector(
    storeAPI.dashboardView.getBrandUserPartnershipsAggregated,
  );
  const partneredProductsWithDetails = useSelector(
    storeAPI.dashboardView.getBrandUserProductsEnhanced,
  );
  const mostSharedPartneredProductsWithDetails = useSelector(
    storeAPI.dashboardView.getMostSharedPartneredProductsEnhanced,
  );

  useEffect(() => {
    dispatch(
      storeAPI.dashboardView.fetchResources({
        partnershipsQuery: { brandId: policy?.entityId },
      }),
    );
  }, [policy?.entityId]);

  return {
    partnershipsAggregated,
    partneredProductsWithDetails,
    history,
    isDashboardDataFetched,
    mostSharedPartneredProductsWithDetails,
    policy,
  };
};

export default useDashboardForBrandUser;
