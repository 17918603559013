import { PartnersQuery } from "shared/logic/types/Partners/PartnersQuery";
import { ResponseDTO } from "shared/logic/types/ResponseDTO";
import { CreateNewPartnerDTO, Partner } from "shared/logic/types/Partners/Partner";
import axiosInstance from "shared/logic/api/axiosInstance";
import serializeArrayQueryParam from "shared/logic/utils/serializeArrayQueryParam";
import { ENVIRONMENT, getEnvironment } from "shared/logic/utils/getEnvironment";
import { AccountType } from "shared/logic/types/AccountType";

const getPartnersByIds = (partnersQuery: PartnersQuery): Promise<ResponseDTO<Partner[]>> =>
  axiosInstance.get("/partners", {
    params: {
      include: serializeArrayQueryParam(partnersQuery.include),
    },
  });

const getPartners = (): Promise<ResponseDTO<Partner[]>> => axiosInstance.get("/partners");

const getPartnerById = (id: string): Promise<{ data: Partner }> =>
  axiosInstance.get(`/partners/${id}`);

const getStorefrontInitTag = (id: string): Promise<{ data: Pick<Partner, "initializationTag"> }> =>
  axiosInstance.get(`/partners/${id}/storefront-initialization-tag`);

const patchPartner = (
  partnerId: string,
  newPartnerData: Partial<Partner>,
): Promise<{ data: Partner }> => axiosInstance.patch(`/partners/${partnerId}`, newPartnerData);

export interface UpdatePartnerLogoProps {
  logo: File; // image/png
  partnerId: string;
}

const updatePartnerLogo = ({
  logo,
  partnerId,
}: UpdatePartnerLogoProps): Promise<{ data: Partner }> =>
  axiosInstance.patch(`/partners/${partnerId}/photo`, logo, {
    headers: { "Content-Type": logo.type },
  });

const postPartner = (
  newPartnerData: Partial<CreateNewPartnerDTO>, // TODO: remove Partial when creating partner implemented fully
): Promise<{ data: Partner }> =>
  axiosInstance.post(`/partners`, {
    contactEmail: "",
    description: "",
    website: "",
    accountType:
      getEnvironment() === ENVIRONMENT.PRODUCTION ? AccountType.PRODUCTION : AccountType.TEST,
    ...newPartnerData,
  });

const deletePartner = (partnerId: string) => axiosInstance.delete(`/partners/${partnerId}`);

export default {
  getPartnersByIds,
  getPartnerById,
  patchPartner,
  getPartners,
  getStorefrontInitTag,
  postPartner,
  deletePartner,
  updatePartnerLogo,
};
