import { Partner } from "shared/logic/types/Partners/Partner";
import { createAsyncThunk } from "@reduxjs/toolkit";
import partnersApi from "shared/logic/api/partnersApi";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";

interface FetchResourcesForSetupViewPayload {
  partnerId: string;
}

interface FetchResourcesForSetupViewReturnType {
  partners?: IdAndEntityPair<Partner>;
  partnerId: string;
}

export const fetchResourcesForSetupView = createAsyncThunk(
  storePrefixes.partnerUser.setupSlice.fetch,
  async (
    { partnerId }: FetchResourcesForSetupViewPayload,
    { dispatch },
  ): Promise<FetchResourcesForSetupViewReturnType> => {
    try {
      const partnerPromise = partnersApi.getPartnerById(partnerId);
      const partnerStorefrontPromise = partnersApi.getStorefrontInitTag(partnerId);
      const [partnerResponse, partnerStorefront] = await Promise.all([
        partnerPromise,
        partnerStorefrontPromise,
      ]);
      const partnerObject = {
        ...partnerStorefront.data,
        ...partnerResponse.data,
      };

      return {
        // RESOURCE SLICE DATA
        partners: { [partnerObject.id]: partnerObject },
        // VIEW SLICE DATA
        partnerId: partnerObject.id,
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
