import { useSelector, useDispatch } from "react-redux";
import {
  ChosenDialogEnum,
  closeDialog,
} from "shared/logic/store/features/resourceSlices/dialog/dialogSlice";
import {
  RejectItemDialog,
  RejectItemDialogProps,
} from "shared/components/Dialogs/RejectItemDialog";
import {
  RejectionAcceptDialog,
  RejectionAcceptDialogProps,
} from "shared/components/Dialogs/RejectionAcceptDialog";
import {
  ProductRevocationDialogProps,
  ProductRevocationDialog,
} from "shared/components/Dialogs/ProductRevocationDialog";
import {
  RejectionMessageDialogProps,
  RejectionMessageDialog,
} from "shared/components/Dialogs/RejectionMessageDialog";
import { RequestCommissionDialog } from "shared/components/Dialogs/RequestCommissionDialog";
import { getDialog } from "shared/logic/store/features/resourceSlices/dialog/selectors";

const useDialogWrapper = () => {
  const dispatch = useDispatch();
  const { chosenDialog, open, title, dialogProps } = useSelector(getDialog);

  const Content = () => {
    switch (chosenDialog) {
      case ChosenDialogEnum.ITEM_REJECTION: {
        const { product } = dialogProps as RejectItemDialogProps;
        return <RejectItemDialog product={product} />;
      }
      case ChosenDialogEnum.REJECTION_ACCEPTANCE: {
        const { product, rejectionMessage } = dialogProps as RejectionAcceptDialogProps;
        return <RejectionAcceptDialog product={product} rejectionMessage={rejectionMessage} />;
      }
      case ChosenDialogEnum.PRODUCT_REVOCATION: {
        const { product, partneredProduct } = dialogProps as ProductRevocationDialogProps;
        return <ProductRevocationDialog product={product} partneredProduct={partneredProduct} />;
      }
      case ChosenDialogEnum.REJECTION_MESSAGE: {
        const { product, partneredProduct } = dialogProps as RejectionMessageDialogProps;
        return <RejectionMessageDialog product={product} partneredProduct={partneredProduct} />;
      }
      case ChosenDialogEnum.REQUEST_COMMISSION: {
        return <RequestCommissionDialog />;
      }
      default:
        return <></>;
    }
  };

  const onDialogClose = () => dispatch(closeDialog());

  return {
    Content,
    open,
    title,
    onDialogClose,
  };
};

export default useDialogWrapper;
