import companiesApi from "shared/logic/api/companiesApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import userApi from "shared/logic/api/userApi";
import { PolicyRole } from "shared/logic/types/User/UserPolicy";
import { UserRole } from "shared/components/auth/AuthContext";
import { NewPartner, NewUser } from "shared/logic/store/features/user/signUp/types";
import { createCompanyTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createCompanyTransaction";
import { createPartnerTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createPartnerTransaction";
import { dispatchSnackbarGenericError } from "shared/logic/store/features/user/signUp/utils/dispatchSnackbarGenericError";
import { createUserTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createUserTransaction";
import partnersApi, { UpdatePartnerLogoProps } from "shared/logic/api/partnersApi";
import { createPolicyTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createPolicyTransaction";

interface SignUpPartnerArgs {
  newUser: NewUser;
  newPartner: NewPartner;
  logo?: UpdatePartnerLogoProps["logo"];
}

export const signUpPartner = createAsyncThunk(
  "userSlice/signUpPartner",
  async ({ newUser, newPartner, logo }: SignUpPartnerArgs, { dispatch }) => {
    function showError(): void {
      dispatchSnackbarGenericError(dispatch);
    }

    const company = await createCompanyTransaction({ name: newPartner.name, onError: showError });

    const user = await createUserTransaction({
      data: {
        ...newUser,
        companyId: company.id,
      },
      onError: showError,
      async rollback(): Promise<void> {
        await companiesApi.deleteCompany(company.id);
      },
    });

    const partner = await createPartnerTransaction({
      data: { ...newPartner, companyId: company.id },
      logo,
      onError: showError,
      async rollback(): Promise<void> {
        await userApi.deleteUser(user.id);
        await companiesApi.deleteCompany(company.id);
      },
    });

    const { permissioned_entities: policies } = await createPolicyTransaction({
      data: {
        user_id: user.id,
        policy_id: user.id,
        permissioned_entities: [
          {
            role: PolicyRole.ADMINISTRATOR,
            entityType: UserRole.PARTNER,
            entityId: partner.id,
          },
        ],
      },
      onError: showError,
      async rollback(): Promise<void> {
        await partnersApi.deletePartner(partner.id);
        await userApi.deleteUser(user.id);
        await companiesApi.deleteCompany(company.id);
      },
    });

    const policiesAggregated = policies.map((p) => ({
      ...p,
      partner,
    }));

    return { company, user, partner, policies, policiesAggregated };
  },
);
