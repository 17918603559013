import { FC, useState, MouseEvent, TouchEvent } from "react";
import {
  StyledBox,
  ArrowDropUpStyled,
  ArrowDropDownStyled,
  StyledList,
  UserAccountInfoWrapper,
  StyledListWrapper,
  SwitchAccountBanner,
} from "views/NavigationSidebar/UserAccountItem/UserAccountItem.styled";
import { IconButton, Divider, ClickAwayListener } from "@mui/material";
import { useUserInfo } from "shared/logic/hooks/store/useUserInfo";
import { useSelector } from "react-redux";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import UserPolicyItem from "views/NavigationSidebar/UserAccountItem/UserPolicyItem";
import UserPolicyAvatar from "shared/components/UserPolicyAvatar";
import UserPolicyItemText from "shared/components/UserPolicyItemText";

export const UserAccountItem: FC = () => {
  const { policies } = useUserInfo();
  const currentUserPolicy = useSelector(getUserPolicy);
  const [isAccountSwitcherOpen, setIsAccountSwitcherOpen] = useState(false);

  const onDropdownClick = (e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsAccountSwitcherOpen(!isAccountSwitcherOpen);
  };

  return (
    <>
      <UserAccountInfoWrapper>
        <StyledBox>
          {currentUserPolicy && <UserPolicyAvatar policy={currentUserPolicy} />}
          <UserPolicyItemText
            policy={currentUserPolicy}
            secondaryTextColor="secondary.contrastText"
            primaryTextColor="primary.disabled"
          />
        </StyledBox>
        <IconButton onClick={onDropdownClick} aria-label="Switch account" size="small">
          {isAccountSwitcherOpen ? (
            <ArrowDropUpStyled color="tertiary" />
          ) : (
            <ArrowDropDownStyled color="tertiary" />
          )}
        </IconButton>
      </UserAccountInfoWrapper>
      {isAccountSwitcherOpen && (
        <ClickAwayListener onClickAway={() => setIsAccountSwitcherOpen(false)}>
          <StyledListWrapper>
            <SwitchAccountBanner
              color="text.disabled"
              content="navigation.accountSwitcher.switchAccount"
              variant="tableLabel"
            />
            <Divider />
            <StyledList aria-labelledby="Switch Account">
              {Object.values(policies).map((policy) => (
                <UserPolicyItem
                  key={policy.entityId}
                  onSwitcherClose={() => setIsAccountSwitcherOpen(false)}
                  policy={policy}
                />
              ))}
            </StyledList>
          </StyledListWrapper>
        </ClickAwayListener>
      )}
    </>
  );
};
