import { StyledItemRow, ImageCell, Image } from "shared/components/TableComponents/Styles";
import { TableCell, Typography, IconButton, Badge } from "@mui/material";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import IOSSwitch from "shared/components/Styled/StyledSwitch";
import { ItemStatus } from "shared/components/ItemStatus";
import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";
import { StyledTooltip } from "shared/components/Styled/StyledTooltip";
import { Message } from "@mui/icons-material";
import { AssignProductAggregated } from "shared/logic/store/features/viewsSlices/brandUser/assign/selectors";
import { useProductItem } from "./useProductItem";
import { StyledInfoWrapper, StatusCellWrapper, SwitchCellWrapper } from "./ProductItem.styled";

export interface ProductItemProps {
  product: AssignProductAggregated;
}

export const ProductItem = ({ product }: ProductItemProps) => {
  const { onAvailableChange, isAvailable, onMessageClick, productStatus } = useProductItem({
    product,
  });

  return (
    <StyledItemRow>
      <ImageCell>
        <Image variant="rounded" src={product.primaryImageUrl} />
      </ImageCell>
      <TableCell padding="none">
        <StyledInfoWrapper>
          <Typography variant="subtitle1">{product.name}</Typography>
          <Typography variant="caption" color="text.disabled">
            {product.productVariants.length} Variant{product.productVariants.length > 1 && "s"}
          </Typography>
        </StyledInfoWrapper>
      </TableCell>
      <TableCell align="right" padding="none">
        {productStatus && (
          <StatusCellWrapper>
            <ItemStatus status={productStatus as ApprovalStatus} />
            {productStatus === ApprovalStatus.REJECTED && (
              <StyledTooltip title="tooltip.seeMessage">
                <IconButton disableRipple onClick={onMessageClick}>
                  <Badge color="secondary" variant="dot" invisible={false}>
                    <Message color="tertiary" />
                  </Badge>
                </IconButton>
              </StyledTooltip>
            )}
          </StatusCellWrapper>
        )}
      </TableCell>
      <TableCell align="right">
        <SwitchCellWrapper>
          <TextBase variant="tableLabel" content="products.productAvailable" />
          <IOSSwitch checked={isAvailable} onChange={onAvailableChange} />
        </SwitchCellWrapper>
      </TableCell>
    </StyledItemRow>
  );
};
