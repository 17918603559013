import { styled, Box, css, BoxProps, Theme } from "@mui/material";
import { CheckCircle, WarningRounded, ErrorSharp } from "@mui/icons-material";
import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";
import { ItemStatusSize } from "shared/components/ItemStatus/index";
import { borderRadiusHelper } from "shared/logic/utils/theme/borderRadiusHelper";

interface StatusBoxProps extends BoxProps {
  status: ApprovalStatus;
  $size?: ItemStatusSize;
}

const bgColor = (theme: Theme, status: ApprovalStatus) => {
  switch (status) {
    case ApprovalStatus.APPROVED:
      return theme.palette.success.light;
    case ApprovalStatus.PENDING:
      return theme.palette.warning.light;
    case ApprovalStatus.REJECTED:
      return theme.palette.error.light;
    default:
      return theme.palette.secondary.light;
  }
};

export const StyledStatus = styled(Box)<StatusBoxProps>(
  ({ theme, status, $size }) => css`
    border-radius: ${borderRadiusHelper(
      theme.shape.borderRadius,
      $size === ItemStatusSize.SMALL ? 0.5 : 1,
    )}px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${$size === ItemStatusSize.SMALL ? "unset" : "112px"};
    padding: ${$size === ItemStatusSize.SMALL ? theme.spacing(0.75, 1) : theme.spacing(1)};
    background-color: ${bgColor(theme, status)};

    & > * + * {
      margin-left: ${theme.spacing(0.5)};
    }
  `,
);

export const StyledCheckIcon = styled(CheckCircle)(
  ({ theme }) => css`
    height: 20px;
    width: 20px;
    color: ${theme.palette.success.main};
  `,
);

export const StyledWarnIcon = styled(WarningRounded)(
  ({ theme }) => css`
    height: 20px;
    width: 20px;
    color: ${theme.palette.warning.main};
  `,
);

export const StyledRejectIcon = styled(ErrorSharp)(
  ({ theme }) => css`
    height: 20px;
    width: 20px;
    color: ${theme.palette.error.main};
  `,
);

export const AlignmentWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;
