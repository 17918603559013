import { styled, Box, css, Button } from "@mui/material";

export const StyledContentWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: ${theme.spacing(5)};
    }
  `,
);

export const SpaceBetweenWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ConfirmButton = styled(Button)``;
