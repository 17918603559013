import { styled, css, Link, Box } from "@mui/material";

export const StyledLink = styled(Link)(
  ({ theme }) => css`
    text-decoration: none;
    display: flex;
    align-items: center;

    color: ${theme.palette.tertiary.main};

    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledLinkWrapper = styled(Box)`
  display: flex;
`;

export const StyledDescription = styled(Box)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
`;
