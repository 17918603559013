import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import Partnership from "shared/logic/types/Partnerships/Partnership";
import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import brandUser from "../../viewsSlices/brandUser";
import partnerUser from "../../viewsSlices/partnerUser";

interface PartnershipsState {
  partnerships: IdAndEntityPair<Partnership>;
}

export const initialState: PartnershipsState = {
  partnerships: {},
};

const partnershipsSlice = createSlice({
  name: storePrefixes.resourceSlices.partnerships,
  initialState,
  reducers: {
    // TODO ADD RESET ACTION
  },
  extraReducers: (builder) => {
    builder
      // UPDATE PARTNERSHIPS - TODO DECIDE IF FOR SOME CASES IT CAN BE AN ASSIGNMENT INSTEAD
      .addMatcher(
        isAnyOf(
          partnerUser.discoverView.fetchResources.fulfilled,
          partnerUser.productsView.fetchResources.fulfilled,
          partnerUser.dashboardView.fetchResources.fulfilled,
          partnerUser.partnershipsView.fetchResources.fulfilled,
          brandUser.assignView.fetchResources.fulfilled,
          brandUser.dashboardView.fetchResources.fulfilled,
          brandUser.discoverNewPartnersView.fetchResources.fulfilled,
          brandUser.partnershipsView.fetchResources.fulfilled,
        ),
        (state, action) => {
          state.partnerships = {
            ...state.partnerships,
            ...action.payload.partnerships,
          };
        },
      );
  },
});

export default partnershipsSlice.reducer;
