import { TextBase } from "shared/components/base/TextBase/TextBase";
import { SendRounded } from "@mui/icons-material";
import { usePartnershipInteractions } from "./usePartnershipInteractions";
import { StyledButtonWrapper, StyledRequestButton } from "./PartnershipInteractions.styled";

export interface PartnershipInteractionsProps {
  entityIdToPartnerWith: string;
}

export const PartnershipInteractions = ({
  entityIdToPartnerWith,
}: PartnershipInteractionsProps) => {
  const { onPartnershipRequest } = usePartnershipInteractions({
    entityIdToPartnerWith,
  });
  return (
    <StyledButtonWrapper>
      <StyledRequestButton onClick={onPartnershipRequest} size="small">
        <TextBase variant="subtitle1" content="partnerships.requestPartnership" />
        <SendRounded color="tertiary" />
      </StyledRequestButton>
    </StyledButtonWrapper>
  );
};
