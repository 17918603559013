import React, { useEffect } from "react";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { useAuth } from "shared/logic/hooks/auth/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { apiCallForUserInfo } from "shared/logic/store/features/user/apiCallForUserInfo";
import { UserStatus } from "shared/logic/types/User/UserStatus";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import {
  getUserInfoRequest,
  getUserStatus,
} from "shared/logic/store/features/resourceSlices/user/selectors";

export const UserProvider: React.FunctionComponent = React.memo(({ children }) => {
  const dispatch = useDispatch();

  // ############################################################
  // DATA
  const { authUser, logout } = useAuth();
  const { error: apiError, status: apiStatus } = useSelector(getUserInfoRequest);
  const userStatus = useSelector(getUserStatus);

  const userId = authUser?.sub;
  const userEmail = authUser?.email;

  // ############################################################
  // EFFECT
  useEffect(() => {
    dispatch(apiCallForUserInfo({ id: userId, email: userEmail }));
  }, [userId]);

  // ############################################################
  // RENDER
  if (apiError || apiStatus === SliceStatus.REJECTED) {
    // TODO LOG INTO DATADOG
    // TODO DISPATCH SNACKBAR
    logout();
    // TODO FALLBACK NEEDED - SNACKBAR WILL DISAPPEAR SOON AFTER REDIRECT
    return null;
  }

  if (userStatus === UserStatus.NOT_SPECIFIED) {
    return <FullScreenLoader />;
  }

  return <>{children}</>;
});
