export const EmbeddedShop = () => (
  <svg
    width="136"
    height="102"
    viewBox="0 0 136 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="136" height="102" rx="8" fill="white" />
    <rect x="12" y="33" width="36" height="36" rx="4" fill="black" fillOpacity="0.12" />
    <rect
      x="56"
      y="34"
      width="35.0968"
      height="3.09677"
      rx="1.54839"
      fill="black"
      fillOpacity="0.38"
    />
    <rect
      x="56"
      y="39.0968"
      width="48"
      height="3.09677"
      rx="1.54839"
      fill="black"
      fillOpacity="0.12"
    />
    <rect
      x="56"
      y="47.9139"
      width="54.8571"
      height="2.43764"
      rx="1.21882"
      fill="black"
      fillOpacity="0.12"
    />
    <rect
      x="56"
      y="52.3516"
      width="15.8476"
      height="2.43764"
      rx="1.21882"
      fill="black"
      fillOpacity="0.12"
    />
    <rect
      x="74.2856"
      y="52.3516"
      width="25.6"
      height="2.43764"
      rx="1.21882"
      fill="black"
      fillOpacity="0.12"
    />
    <rect x="56" y="62.7893" width="40" height="6.61" rx="2" fill="#5A4FDB" />
    <path
      d="M8 1H128V-1H8V1ZM135 8V94H137V8H135ZM128 101H8V103H128V101ZM1 94V8H-1V94H1ZM8 101C4.13401 101 1 97.866 1 94H-1C-1 98.9706 3.02944 103 8 103V101ZM135 94C135 97.866 131.866 101 128 101V103C132.971 103 137 98.9706 137 94H135ZM128 1C131.866 1 135 4.13401 135 8H137C137 3.02944 132.971 -1 128 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z"
      fill="black"
      fillOpacity="0.12"
    />
  </svg>
);
