import PartnershipsQuery from "shared/logic/types/Partnerships/PartnershipsQuery";
import PartneredProductsQuery from "shared/logic/types/PartneredProducts/PartneredProductsQuery";
import { createAsyncThunk } from "@reduxjs/toolkit";
import partnersApi from "shared/logic/api/partnersApi";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { getBaseDataForPartnerUser } from "shared/logic/store/features/viewsSlices/partnerUser/getBaseDataForPartnerUser";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

interface FetchResourcesForDashboardViewPayload {
  partnershipsQuery: PartnershipsQuery;
  productsQuery: PartneredProductsQuery;
}

export const fetchResourcesForDashboardView = createAsyncThunk(
  storePrefixes.partnerUser.dashboardSlice.fetch,
  async (
    { partnershipsQuery, productsQuery }: FetchResourcesForDashboardViewPayload,
    { dispatch },
  ) => {
    try {
      // ############################################################
      // PARTNER
      const partnerResponse = await partnersApi.getPartnerById(partnershipsQuery.partnerId!);

      // ############################################################
      // BASE DATA
      const { partnershipsMap, brandsMap, filteredPartneredProductsMap, filteredProductsMap } =
        await getBaseDataForPartnerUser({
          partnershipsQuery: {
            partnerId: productsQuery.partnerId,
          },
          partneredProductsQuery: productsQuery,
        });

      return {
        // RESOURCE SLICES DATA
        partneredProducts: filteredPartneredProductsMap,
        partners: parseArrayToIdsEntityPair([partnerResponse.data]),
        brands: brandsMap,
        partnerships: partnershipsMap,
        products: filteredProductsMap,
        // VIEW SLICE DATA
        partnerId: partnerResponse.data.id,
        partneredProductsAggregatedIds: Object.keys(filteredPartneredProductsMap),
        partnershipsAggregatedIds: Object.keys(partnershipsMap),
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
    }
    return {
      partnershipsAggregated: [],
      allProductsAggregated: [],
      productsAggregated: [],
    };
  },
);
