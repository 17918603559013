import queryString from "query-string";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export type UseSearchParams = [queryString.ParsedQuery<string>, (params: any) => void];

export default function useSearchParams(): UseSearchParams {
  const { search, pathname } = useLocation();

  const history = useHistory();
  const setParams = useCallback(
    (params: Record<string, string>) => {
      history.push({
        pathname,
        search: queryString.stringify(params),
      });
    },
    [pathname, history],
  );

  const currentParams = useMemo(() => queryString.parse(search), [search]);
  return [currentParams, setParams];
}
