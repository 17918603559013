import { useForm } from "react-hook-form";
import { AccountDetailsProps } from "views/adminViews/AddAccount/AccountDetails/index";
import {
  AccountDetailsUpdateParams,
  AddAccountFormEnum,
  AddAccountStepEnum,
} from "views/adminViews/AddAccount/useAddAccount";
import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";

export type UseAccountDetailsProps = AccountDetailsProps;

export const useAccountDetails = ({
  accountDetailsUpdate,
  savedAccountType,
  savedAccountName,
  savedAccountCommission,
}: UseAccountDetailsProps) => {
  const [, setSearchParams] = useSearchParams();
  const {
    handleSubmit,
    register,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      [AddAccountFormEnum.ACCOUNT_NAME]: savedAccountName ?? "",
      [AddAccountFormEnum.ACCOUNT_TYPE]: savedAccountType ?? "",
      [AddAccountFormEnum.COMMISSION_RATE]: savedAccountCommission ?? "",
    },
    mode: "onChange",
  });
  const onSubmit = ({ accountType, accountName, commissionRate }: AccountDetailsUpdateParams) => {
    accountDetailsUpdate({ accountName, accountType, commissionRate });
    setSearchParams({ step: AddAccountStepEnum.ADD_USERS });
  };
  return {
    onSubmit,
    handleSubmit,
    register,
    control,
    isValid,
  };
};
