export const Shopify = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3495_1337)">
      <path
        d="M26.5684 6.242C26.5449 6.1005 26.4294 5.992 26.2864 5.9785C26.1739 5.96 23.7014 5.7905 23.7014 5.7905L21.8164 3.9055C21.6464 3.7175 21.2704 3.7725 21.1284 3.8115C21.1099 3.8115 20.7524 3.924 20.1674 4.114C19.5989 2.469 18.5924 0.961 16.8194 0.961H16.6679C16.1599 0.3025 15.5379 0 15.0129 0C10.8849 0.0185 8.91792 5.1665 8.29592 7.797L5.41092 8.682C4.52592 8.964 4.49342 8.9845 4.37442 9.8285L1.94092 28.5875L20.1749 32L30.0564 29.87C30.0564 29.8515 26.5864 6.4215 26.5684 6.2425V6.242ZM19.1564 4.43C18.6979 4.5815 18.1749 4.7325 17.6114 4.9025V4.5625C17.6281 3.71408 17.501 2.86896 17.2354 2.063C18.1594 2.205 18.7804 3.2415 19.1569 4.4295L19.1564 4.43ZM16.1224 2.281C16.4239 3.17 16.5639 4.106 16.5354 5.0445V5.2145L13.3479 6.196C13.9719 3.866 15.1229 2.715 16.1229 2.281H16.1224ZM14.8974 1.1345C15.086 1.13851 15.2693 1.19742 15.4249 1.304C14.0949 1.923 12.6889 3.487 12.0859 6.6175L9.56342 7.3905C10.2749 5.015 11.9349 1.13 14.8959 1.13L14.8974 1.1345Z"
        fill="#95BF47"
      />
      <path
        d="M26.2865 5.97847C26.174 5.95997 23.7015 5.79047 23.7015 5.79047L21.8165 3.90547C21.7465 3.83447 21.6515 3.79397 21.553 3.79297L20.177 32L30.0585 29.87L26.5685 6.26097C26.5572 6.19025 26.5238 6.12492 26.4732 6.07428C26.4226 6.02364 26.3572 5.99029 26.2865 5.97897V5.97847Z"
        fill="#5E8E3E"
      />
      <path
        d="M16.8199 11.4452L15.5929 15.0662C14.8514 14.7072 14.0409 14.5142 13.2169 14.4997C11.2929 14.4997 11.1989 15.7057 11.1989 16.0087C11.1989 17.6577 15.5169 18.2907 15.5169 22.1752C15.5169 25.2302 13.5744 27.1902 10.9669 27.1902C7.8369 27.1902 6.2334 25.2477 6.2334 25.2477L7.0659 22.4727C7.0659 22.4727 8.7059 23.8877 10.1024 23.8877C10.2693 23.8941 10.4358 23.8665 10.5918 23.8067C10.7478 23.7468 10.89 23.656 11.0098 23.5396C11.1297 23.4232 11.2247 23.2837 11.2891 23.1296C11.3534 22.9754 11.3859 22.8098 11.3844 22.6427C11.3844 20.4727 7.8394 20.3792 7.8394 16.8337C7.8394 13.8527 9.9884 10.9487 14.3064 10.9487C16.0034 10.9737 16.8154 11.4437 16.8154 11.4437L16.8199 11.4452Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3495_1337">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
