import { combineReducers } from "@reduxjs/toolkit";
import snackbar from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import dialog from "shared/logic/store/features/resourceSlices/dialog/dialogSlice";
import productsSlice from "shared/logic/store/features/resourceSlices/products/productsSlice";
import partnershipsSlice from "shared/logic/store/features/resourceSlices/partnerships/partnershipsSlice";
import brandsSlice from "shared/logic/store/features/resourceSlices/brands/brandsSlice";
import ecommerceAccountsSlice from "shared/logic/store/features/resourceSlices/ecommerceAccounts/ecommerceAccountsSlice";
import partneredProductsSlice from "shared/logic/store/features/resourceSlices/partneredProducts/partneredProductsSlice";
import productsViewSliceForPartnerUser from "shared/logic/store/features/viewsSlices/partnerUser/products/productsViewSlice";
import discoverViewSliceForPartnerUser from "shared/logic/store/features/viewsSlices/partnerUser/discover/discoverViewSlice";
import partnershipViewSliceForPartnerUser from "shared/logic/store/features/viewsSlices/partnerUser/partnerships/partnershipsViewSlice";
import productsViewSliceForBrandUser from "shared/logic/store/features/viewsSlices/brandUser/products/productsViewSlice";
import assignProductViewSliceForBrandUser from "shared/logic/store/features/viewsSlices/brandUser/assign/assignProductSlice";
import userSlice, { login } from "shared/logic/store/features/resourceSlices/user/userSlice";
import dashboardViewSliceForPartnerUser from "shared/logic/store/features/viewsSlices/partnerUser/dashboard/dashboardSlice";
import dashboardViewSliceForBrandUser from "shared/logic/store/features/viewsSlices/brandUser/dashboard/dashboardSlice";
import setupViewSliceForBrandUser from "shared/logic/store/features/viewsSlices/brandUser/setup/setupViewSlice";
import partnershipsViewSliceForBrandUser from "shared/logic/store/features/viewsSlices/brandUser/partnerships/partnershipsViewSlice";
import discoverNewPartnersViewSlice from "shared/logic/store/features/viewsSlices/brandUser/discoverNewPartners/discoverNewPartnersSlice";
import setupViewForPartnerUserSlice from "shared/logic/store/features/viewsSlices/partnerUser/setup/setupViewSlice";
import settingsViewSliceForPartnerUser from "shared/logic/store/features/viewsSlices/partnerUser/settings/settingsViewSlice";
import settingsViewSliceForBrandUser from "shared/logic/store/features/viewsSlices/brandUser/settings/settingsViewSlice";
import partnersSlice from "shared/logic/store/features/resourceSlices/partners/partnersSlice";
import inviteViewSlice from "shared/logic/store/features/viewsSlices/shared/inviteToPlatform/inviteViewSlice";

const appReducer = combineReducers({
  snackbar,
  dialog,
  productsSlice,
  partnershipsSlice,
  brandsSlice,
  ecommerceAccountsSlice,
  brand: combineReducers({
    productsViewSlice: productsViewSliceForBrandUser,
    assignProductView: assignProductViewSliceForBrandUser,
    dashboardViewSlice: dashboardViewSliceForBrandUser,
    setupViewSlice: setupViewSliceForBrandUser,
    partnershipsViewSlice: partnershipsViewSliceForBrandUser,
    discoverNewPartnersViewSlice,
    settingsViewSlice: settingsViewSliceForBrandUser,
  }),
  partner: combineReducers({
    partnershipViewSlice: partnershipViewSliceForPartnerUser,
    productsViewSlice: productsViewSliceForPartnerUser,
    discoverViewSlice: discoverViewSliceForPartnerUser,
    dashboardViewSlice: dashboardViewSliceForPartnerUser,
    setupViewSlice: setupViewForPartnerUserSlice,
    settingsViewSlice: settingsViewSliceForPartnerUser,
  }),
  shared: combineReducers({
    inviteViewSlice,
  }),
  partnersSlice,
  partneredProductsSlice,
  userSlice,
});

export type RootState = ReturnType<typeof appReducer>;
export type ThunkConfigRootState = { state: RootState };

const rootReducer: typeof appReducer = (state, action) => {
  switch (action.type) {
    case "userSlice/changeUserPolicy":
      // if this action is executed => clear all the slices (not including userSlice)
      return appReducer({ userSlice: state!.userSlice } as RootState, action);
    case login.type:
      // if this action is executed => clear all the slices
      return appReducer(undefined, action);
    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
