import { Partnerships } from "views/partnerViews/Partnerships";
import { Products } from "views/partnerViews/Products";
import { Setup } from "views/partnerViews/Setup";
import { Dashboard } from "views/partnerViews/Dashboard";
import { DiscoverBrands } from "./Discover";
import { SelfSignUp } from "../SelfSignup";
import { FirstLogin } from "./FirstLogin";
import { Settings } from "./Settings";

export default {
  Partnerships,
  Products,
  Setup,
  Dashboard,
  SelfSignUp,
  FirstLogin,
  DiscoverBrands,
  Settings,
};
