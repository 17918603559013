import { ButtonProps, Button, styled, Box, css } from "@mui/material";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";
import { StyledAppLogo } from "shared/components/Styled/StyledAppLogo";

export const DescriptionSection = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * + * {
      margin-top: ${theme.spacing(4)};
    }
  `,
);

export const Content = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    max-width: 580px;
    margin: 0 auto;
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const Logo = styled(StyledAppLogo)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Wrapper = styled(Box)`
  height: 100%;
  position: relative;
`;

export const Description = styled((props: TextBaseProps) => (
  <TextBase {...props} variant="body1" color="text.secondary" />
))`
  text-align: center;
`;

export const ErrorNumber = styled((props: TextBaseProps) => (
  <TextBase {...props} color="tertiary.main" />
))`
  font-size: 256px;
  font-weight: 600;
  line-height: 100%;
`;

export const SignOutButton = (props: ButtonProps) => (
  <Button {...props} variant="contained" color="tertiary" />
);

export const ButtonText = (props: TextBaseProps) => <TextBase {...props} variant="subtitle1" />;

export const ErrorTitle = (props: TextBaseProps) => (
  <TextBase {...props} variant="h4" color="text.secondary" />
);
