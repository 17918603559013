import React, { FC } from "react";
import { useAuthApiProvider } from "shared/components/auth/AuthApiProvider/useAuthApiProvider";
import { AuthContext } from "../AuthContext";

export const AuthApiProvider: FC = ({ children }) => {
  const {
    isAuthenticated,
    user,
    isLoading,
    error,
    internalLogin,
    internalLogout,
    getAccessTokenSilently,
  } = useAuthApiProvider();

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authUser: user,
        error,
        isLoading,
        login: internalLogin,
        logout: internalLogout,
        getAccessTokenSilently,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
