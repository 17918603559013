import { TableHead, TableCell } from "@mui/material";
import { StyledHeaderRow } from "shared/components/TableComponents/Styles";
import { TextBase } from "shared/components/base/TextBase/TextBase";

export const ProductsTableHeader = () => (
  <TableHead>
    <StyledHeaderRow>
      <TableCell colSpan={2}>
        <TextBase variant="tableLabel" content="products.product" />
      </TableCell>
      <TableCell align="right" />
    </StyledHeaderRow>
  </TableHead>
);
