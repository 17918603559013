import { useSettings } from "views/partnerViews/Settings/useSettings";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { SettingsWrapper } from "shared/components/SettingsComponents/SettingsWrapper";
import { Organization } from "views/partnerViews/Settings/Organization";
import { Integration } from "views/partnerViews/Settings/Integration";

export const Settings = () => {
  const { partnerInfo, isSetupCompleted } = useSettings();
  if (!partnerInfo) {
    return <FullScreenLoader />;
  }
  return (
    <SettingsWrapper>
      <Organization partnerInfo={partnerInfo} isSetupCompleted={isSetupCompleted} />
      <Integration partnerInfo={partnerInfo} isSetupCompleted={isSetupCompleted} />
    </SettingsWrapper>
  );
};
