import { RootState } from "shared/logic/store/rootReducer";
import Brand from "shared/logic/types/Brands/Brand";
import { EcommerceAccount } from "shared/logic/types/EcommerceAccount/EcommerceAccount";
import { createSelector } from "@reduxjs/toolkit";
import { getEcommerceAccounts } from "shared/logic/store/features/resourceSlices/ecommerceAccounts/selectors";
import { getBrands } from "shared/logic/store/features/resourceSlices/brands/selectors";

export interface ExistingIntegration extends EcommerceAccount {
  brand: Brand;
}

const getSetupSlice = (state: RootState) => state.brand.setupViewSlice;

const getExistingIntegrations = createSelector(
  [getEcommerceAccounts, getBrands],
  (ecommerceAccountsMap, brandsMap) =>
    Object.values(ecommerceAccountsMap).map((account) => ({
      ...account,
      brand: brandsMap[account.brandId],
    })),
);

const getIsSetupLoading = createSelector([getSetupSlice], ({ isSetupLoading }) => isSetupLoading);

const getIsExistingIntegrationsFetched = createSelector(
  [getSetupSlice],
  ({ isDataFetched }) => isDataFetched,
);

export default {
  getSetupSlice,
  getExistingIntegrations,
  getIsSetupLoading,
  getIsExistingIntegrationsFetched,
};
