import { createSlice } from "@reduxjs/toolkit";
import { fetchResourcesForPartnershipsView } from "shared/logic/store/features/viewsSlices/partnerUser/partnerships/fetchResourcesForPartnershipsView";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";

interface PartnershipViewState {
  partnershipsViewSliceStatus: SliceStatus;
  isDataInvalid: boolean;
}

export const initialState: PartnershipViewState = {
  partnershipsViewSliceStatus: SliceStatus.IDLE,
  isDataInvalid: false,
};

const partnershipViewSliceForPartnerUser = createSlice({
  name: storePrefixes.partnerUser.this,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourcesForPartnershipsView.pending, (state) => {
        state.partnershipsViewSliceStatus = SliceStatus.PENDING;
      })
      .addCase(fetchResourcesForPartnershipsView.rejected, (state) => {
        state.partnershipsViewSliceStatus = SliceStatus.REJECTED;
      })
      .addCase(fetchResourcesForPartnershipsView.fulfilled, (state) => {
        state.partnershipsViewSliceStatus = SliceStatus.FULFILLED;
      });
  },
});

export default partnershipViewSliceForPartnerUser.reducer;
