import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import { storeAPI } from "views/brandViews/storeAPI";

const usePartnershipsForBrandUser = () => {
  const dispatch = useDispatch();
  const policy = useSelector(getUserPolicy);
  const partnershipsAggregated = useSelector(storeAPI.partnershipsView.getPartnershipsAggregated);
  const partnershipsViewSliceStatus = useSelector(
    storeAPI.partnershipsView.getPartnershipsViewSliceStatus,
  );
  const isEmpty = partnershipsAggregated && partnershipsAggregated.length === 0;

  useEffect(() => {
    dispatch(
      storeAPI.partnershipsView.fetchResources({
        partnershipsQuery: { brandId: policy?.entityId },
      }),
    );
  }, [policy?.entityId]);

  return {
    partnerships: partnershipsAggregated,
    isLoading: partnershipsViewSliceStatus === SliceStatus.PENDING,
    isEmpty,
  };
};

export default usePartnershipsForBrandUser;
