import {
  AuthTokenDependencies,
  OnAccessingTokenError,
  TokenStatus,
} from "shared/components/auth/AuthGuard/hooks/useToken/types";
import { useEffect, useState } from "react";
import { getCanTokenBeEstablished } from "shared/components/auth/AuthGuard/hooks/useToken/utils/getCanTokenBeEstablished";
import axiosInstance from "shared/logic/api/axiosInstance";
import { isTokenLoading } from "shared/components/auth/AuthGuard/hooks/useToken/utils/isTokenLoading";
import { createAuthInterceptorOnFulfilled } from "shared/components/auth/AuthGuard/hooks/useToken/utils/createAuthInterceptorOnFulfilled";

interface UseTokenArgs extends AuthTokenDependencies {
  onError: OnAccessingTokenError;
}

interface UseTokenReturn {
  isLoading: boolean;
}

export function useToken({
  authUser,
  getAccessTokenSilently,
  isAuthenticated,
  onError,
}: UseTokenArgs): UseTokenReturn {
  const [status, setStatus] = useState<TokenStatus>(TokenStatus.NOT_USED);

  // ############################################################
  // EFFECT
  useEffect(() => {
    setStatus(TokenStatus.APPLYING);

    if (!getCanTokenBeEstablished({ authUser, getAccessTokenSilently, isAuthenticated })) {
      // SHOULD NOT HAPPEN
      if (isAuthenticated) {
        // TODO log in to the datadog that the token cannot be established even though user is authenticated
      }

      setStatus(TokenStatus.NOT_USED);
      return undefined;
    }

    const authInterceptor = axiosInstance.interceptors.request.use(
      createAuthInterceptorOnFulfilled({ getAccessTokenSilently, onError }),
    );

    setStatus(TokenStatus.APPLIED);

    return function cleanUp() {
      axiosInstance.interceptors.request.eject(authInterceptor);
      setStatus(TokenStatus.NOT_USED);
    };
  }, [isAuthenticated, getAccessTokenSilently, authUser]);

  // ############################################################
  // RETURN
  const canTokenBeEstablished = getCanTokenBeEstablished({
    authUser,
    getAccessTokenSilently,
    isAuthenticated,
  });

  return {
    isLoading: isTokenLoading({ status, canTokenBeEstablished }),
  };
}
