import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { useEffect, useMemo, useState } from "react";
import { AccountDetails } from "views/adminViews/AddAccount/AccountDetails";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { UserRole } from "shared/components/auth/AuthContext";
import { AddUsers } from "views/adminViews/AddAccount/AddUsers";
import { ConfirmDetails } from "views/adminViews/AddAccount/ConfirmDetails";

export enum AddAccountStepEnum {
  ACCOUNT_DETAILS = "account-details",
  ADD_USERS = "add-users",
  CONFIRM_DETAILS = "confirm-details",
}

export enum AddAccountFormEnum {
  ACCOUNT_NAME = "accountName",
  ACCOUNT_TYPE = "accountType",
  USERS_TO_ADD = "usersToAdd",
  COMMISSION_RATE = "commissionRate",
}

export interface NewUserInterface {
  email: string;
  firstName: string;
  lastName: string;
}

export interface AddAccountForm {
  [AddAccountFormEnum.ACCOUNT_NAME]: string | undefined;
  [AddAccountFormEnum.ACCOUNT_TYPE]: UserRole | undefined;
  [AddAccountFormEnum.USERS_TO_ADD]: NewUserInterface[] | undefined;
  [AddAccountFormEnum.COMMISSION_RATE]: number | undefined;
}

export type AccountDetailsUpdateParams = Pick<
  AddAccountForm,
  | AddAccountFormEnum.ACCOUNT_NAME
  | AddAccountFormEnum.ACCOUNT_TYPE
  | AddAccountFormEnum.COMMISSION_RATE
>;

export type UsersListUpdateParams = Pick<AddAccountForm, AddAccountFormEnum.USERS_TO_ADD>;

export const useAddAccount = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [formData, setFormData] = useState<AddAccountForm>({
    [AddAccountFormEnum.ACCOUNT_NAME]: undefined,
    [AddAccountFormEnum.ACCOUNT_TYPE]: undefined,
    [AddAccountFormEnum.USERS_TO_ADD]: undefined,
    [AddAccountFormEnum.COMMISSION_RATE]: undefined,
  });
  const accountDetailsUpdate = ({
    accountName,
    accountType,
    commissionRate,
  }: AccountDetailsUpdateParams) => {
    setFormData((prev) => ({ ...prev, accountName, accountType, commissionRate }));
  };
  const usersListUpdate = ({ usersToAdd }: UsersListUpdateParams) => {
    setFormData((prev) => ({ ...prev, usersToAdd }));
  };
  const stepObject = useMemo(() => {
    switch (searchParams.step) {
      case AddAccountStepEnum.ACCOUNT_DETAILS:
        return {
          title: "addAccount.accountDetails.title",
          number: 1,
          component: (
            <AccountDetails
              accountDetailsUpdate={accountDetailsUpdate}
              savedAccountName={formData[AddAccountFormEnum.ACCOUNT_NAME]}
              savedAccountType={formData[AddAccountFormEnum.ACCOUNT_TYPE]}
              savedAccountCommission={formData[AddAccountFormEnum.COMMISSION_RATE]}
            />
          ),
        };
      case AddAccountStepEnum.ADD_USERS:
        return {
          title: "addAccount.addUsers.title",
          number: 2,
          component: (
            <AddUsers
              usersListUpdate={usersListUpdate}
              savedUsersList={formData[AddAccountFormEnum.USERS_TO_ADD]}
            />
          ),
        };
      case AddAccountStepEnum.CONFIRM_DETAILS:
        return {
          title: "addAccount.confirmDetails.title",
          number: 3,
          component: <ConfirmDetails formData={formData} />,
        };
      default:
        return {
          title: undefined,
          number: undefined,
          component: <FullScreenLoader />,
        };
    }
  }, [searchParams.step]);
  useEffect(() => {
    if (!searchParams.step) {
      setSearchParams({ step: AddAccountStepEnum.ACCOUNT_DETAILS });
    }
  }, [searchParams.step]);
  return {
    stepObject,
  };
};
