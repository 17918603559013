import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import Brand from "shared/logic/types/Brands/Brand";
import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import Partnership from "shared/logic/types/Partnerships/Partnership";
import { PartnershipAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/partnerships/selectors";

export const aggregatePartnershipsHOF =
  (
    brands: IdAndEntityPair<Brand>,
    partneredProductsAggregatedByPartnershipId: IdAndEntityPair<PartneredProduct[]>,
  ) =>
  (partnership: Partnership): PartnershipAggregated => ({
    ...partnership,
    brand: brands[partnership.brandId],
    partneredProducts: partneredProductsAggregatedByPartnershipId[partnership.id],
  });
