import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import { FC } from "react";
import { StyledChevronRight } from "shared/components/SettingsComponents/Organization/Organization.styled";
import {
  TitleWrapper,
  Title,
  EditButton,
  Label,
} from "shared/components/SettingsComponents/TitleBox/TitleBox.styled";

interface TitleBoxProps {
  title: TextContentPath;
  onEditClick: () => void;
}

export const TitleBox: FC<TitleBoxProps> = ({ title, onEditClick }) => (
  <TitleWrapper>
    <Title content={title} />
    <EditButton onClick={onEditClick}>
      <Label content="utility.edit" />
      <StyledChevronRight />
    </EditButton>
  </TitleWrapper>
);
