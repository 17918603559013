import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "shared/logic/types/User/UserInfo";
import { UserPolicy } from "shared/logic/types/User/UserPolicy";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";
import { LocalStorageKeys } from "shared/logic/hooks/generalPurpose/useLocalStorage";
import adminUser from "shared/logic/store/features/viewsSlices/adminUser";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import { apiCallForUserInfo } from "shared/logic/store/features/user/apiCallForUserInfo";
import { UserStatus } from "shared/logic/types/User/UserStatus";
import { Request } from "shared/logic/types/store/Request";
import { signUpPartner } from "shared/logic/store/features/user/signUp/partner";
import { signUpBrand } from "shared/logic/store/features/user/signUp/brand";
import brandUser from "../../viewsSlices/brandUser";
import partnerUser from "../../viewsSlices/partnerUser";

interface UserState {
  userInfo: Partial<UserInfo>;
  currentPolicyId?: string;
  userInfoRequest: Request;
  createNewUsersRequest: Request;
  signUpRequest: Request;
}

export const initialState: UserState = {
  userInfo: {
    user: undefined,
    policies: undefined,
    status: UserStatus.NOT_SPECIFIED,
  },
  userInfoRequest: {
    error: undefined,
    status: SliceStatus.IDLE,
  },
  createNewUsersRequest: {
    error: undefined,
    status: SliceStatus.IDLE,
  },
  signUpRequest: {
    error: undefined,
    status: SliceStatus.IDLE,
  },
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    changeUserPolicy(state, action: PayloadAction<{ policyId: string }>) {
      state.currentPolicyId = action.payload.policyId;
    },
    // AN EMPTY FUNCTION FOR RESETTING THE STORE ON DISPATCH
    login() {},
  },
  extraReducers: (builder) => {
    // ############################################################
    // apiCallForUserInfo
    builder.addCase(apiCallForUserInfo.fulfilled, (state, action) => {
      state.userInfoRequest.error = undefined;
      state.userInfoRequest.status = SliceStatus.FULFILLED;

      const { user, policies, status } = action.payload;

      state.userInfo.user = user;
      state.userInfo.status = status;

      if (!policies) {
        return;
      }

      const localStoragePolicyID = localStorage.getItem(LocalStorageKeys.CURRENT_POLICY_ID);
      state.currentPolicyId =
        localStoragePolicyID && policies.find((policy) => policy.entityId === localStoragePolicyID)
          ? localStoragePolicyID
          : policies[0]?.entityId;
      state.userInfo.policies = parseArrayToIdsEntityPair<UserPolicy>(policies, "entityId");
    });
    builder.addCase(apiCallForUserInfo.pending, (state) => {
      state.userInfo.user = undefined;
      state.userInfo.policies = undefined;
      state.userInfo.status = UserStatus.NOT_SPECIFIED;
      state.userInfoRequest.error = undefined;
      state.userInfoRequest.status = SliceStatus.PENDING;
    });
    builder.addCase(apiCallForUserInfo.rejected, (state, action) => {
      state.userInfo.user = undefined;
      state.userInfo.policies = undefined;
      // TODO proper error handling
      if (action.payload instanceof Error) {
        state.userInfoRequest.error = action.payload;
      } else {
        state.userInfoRequest.error = new Error(action.error.message);
      }
      state.userInfoRequest.status = SliceStatus.REJECTED;
    });
    // ############################################################
    // createNewUsers
    builder.addCase(adminUser.addAccountView.createNewUsers.pending, (state) => {
      state.createNewUsersRequest.error = undefined;
      state.createNewUsersRequest.status = SliceStatus.PENDING;
    });
    builder.addCase(adminUser.addAccountView.createNewUsers.rejected, (state, action) => {
      state.createNewUsersRequest.error = new Error(action.error.message);
      state.createNewUsersRequest.status = SliceStatus.REJECTED;
    });
    builder.addCase(adminUser.addAccountView.createNewUsers.fulfilled, (state) => {
      state.createNewUsersRequest.error = undefined;
      state.createNewUsersRequest.status = SliceStatus.FULFILLED;
    });
    // ############################################################
    // editPartnerSetup
    builder.addCase(partnerUser.setupView.editPartnerSetup.fulfilled, (state, action) => {
      const { currentPolicyId } = state;

      if (!currentPolicyId) {
        return;
      }

      const currentPolicy = state.userInfo.policies?.[currentPolicyId];

      if (!currentPolicy || !currentPolicy.partner) {
        return;
      }

      currentPolicy.partner.website = action.payload.newPartnerData.website;
    });
    // ############################################################
    // saveIntegration
    builder.addCase(brandUser.setupView.saveIntegration.fulfilled, (state, action) => {
      const { updatedBrand } = action.payload;
      const newPrivacyPolicyUrl = updatedBrand?.privacyPolicyUrl;
      const { currentPolicyId } = state;

      if (!currentPolicyId || !newPrivacyPolicyUrl) {
        return;
      }

      const currentPolicy = state.userInfo.policies?.[currentPolicyId];

      if (!currentPolicy || !currentPolicy.brand) {
        return;
      }

      currentPolicy.brand.privacyPolicyUrl = newPrivacyPolicyUrl;
    });
    // ############################################################
    // firstBrandLogin
    builder.addCase(brandUser.firstLoginSetupView.firstLoginSetup.fulfilled, (state, action) => {
      const { patchedBrand, brandId } = action.payload;
      const currentPolicy = state.userInfo.policies?.[brandId];
      if (!currentPolicy?.brand) {
        return;
      }
      currentPolicy.brand = {
        ...currentPolicy.brand,
        ...patchedBrand,
      };
    });
    // ############################################################
    // firstPartnerLogin
    builder.addCase(
      partnerUser.firstLoginSetupView.firstLoginPartnerSetup.fulfilled,
      (state, action) => {
        const { patchedPartner, partnerId } = action.payload;
        const currentPolicy = state.userInfo.policies?.[partnerId];
        if (!currentPolicy?.partner) {
          return;
        }
        currentPolicy.partner = {
          ...currentPolicy.partner,
          ...patchedPartner,
        };
      },
    );
    // ############################################################
    // signUpPartner
    // signUpBrand
    builder.addMatcher(isAnyOf(signUpPartner.fulfilled, signUpBrand.fulfilled), (state, action) => {
      state.signUpRequest.error = undefined;
      state.signUpRequest.status = SliceStatus.FULFILLED;

      const { user, policiesAggregated } = action.payload;

      state.userInfo.user = user;
      state.userInfo.status = UserStatus.AUTHENTICATED;
      state.currentPolicyId = policiesAggregated[0]?.entityId;
      state.userInfo.policies = parseArrayToIdsEntityPair<UserPolicy>(
        policiesAggregated,
        "entityId",
      );
    });
    builder.addMatcher(isAnyOf(signUpPartner.pending, signUpBrand.pending), (state) => {
      state.signUpRequest.error = undefined;
      state.signUpRequest.status = SliceStatus.PENDING;
    });
    builder.addMatcher(isAnyOf(signUpPartner.rejected, signUpBrand.rejected), (state, action) => {
      state.signUpRequest.error =
        action.payload instanceof Error ? action.payload : new Error(action.error.message);
      state.signUpRequest.status = SliceStatus.REJECTED;
    });
  },
});

export const { changeUserPolicy, login } = userSlice.actions;

export default userSlice.reducer;
