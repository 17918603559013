import { Box, styled, css, Button, Typography } from "@mui/material";
import { StyledCustomAlert } from "shared/components/Styled/StyledCustomAlert";

export const WarningAlert = styled(StyledCustomAlert)(
  ({ theme }) => css`
    flex-direction: column;
    align-items: flex-start;
    & > * + * {
      margin-top: ${theme.spacing(1)};
    }
  `,
);

export const DomainInfo = styled(Box)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(1)};
    }
  `,
);

export const AlertTitle = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const DomainName = styled(Typography)`
  font-weight: 700;
`;

export const BackButton = styled(Button)``;
export const SaveButton = styled(Button)``;
