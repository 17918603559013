import { createAsyncThunk } from "@reduxjs/toolkit";
import brandsApi from "shared/logic/api/brandsApi";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import Brand from "shared/logic/types/Brands/Brand";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";

export interface FirstLoginBrandSetupParams {
  brandId?: string;
  description: string;
  logo?: File;
  termsAndConditionsAgreement: boolean;
}

export const firstLoginBrandSetup = createAsyncThunk(
  "firstLoginBrandSetup",
  async (
    { brandId, description, logo, termsAndConditionsAgreement }: FirstLoginBrandSetupParams,
    { dispatch },
  ) => {
    try {
      if (!brandId || !description || !termsAndConditionsAgreement) {
        throw new Error("Something went wrong");
      }
      let patchedBrand: Brand;
      if (logo) {
        const patchBrand = brandsApi.patchBrand(brandId, { description });
        const updateBrandLogo = brandsApi.updateBrandLogo({ brandId, logo });
        const [, brandWithLogoResponse] = await Promise.all([patchBrand, updateBrandLogo]);
        patchedBrand = brandWithLogoResponse.data;
      } else {
        const brandResponse = await brandsApi.patchBrand(brandId, { description });
        patchedBrand = brandResponse.data;
      }
      dispatch(
        setSnackbar({
          open: true,
          message: "snackbar.userUpdateSuccess",
          icon: SnackbarIcon.DONE,
        }),
      );
      return { patchedBrand, brandId };
    } catch (e) {
      ConsoleLog(e);
      dispatch(setSnackbar({ open: true, message: "snackbar.genericError" }));
      throw e;
    }
  },
);
