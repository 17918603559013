import { createAsyncThunk } from "@reduxjs/toolkit";
import brandsApi from "shared/logic/api/brandsApi";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import Brand from "shared/logic/types/Brands/Brand";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

export interface SaveIntegrationParams {
  brandId: string;
  privacyPolicyUrl?: string;
}

export const saveIntegration = createAsyncThunk(
  storePrefixes.brandUser.setupSlice.saveBrandIntegration,
  async ({ brandId, privacyPolicyUrl }: SaveIntegrationParams, { dispatch }) => {
    try {
      const brandResponse = await brandsApi.patchBrand(brandId, { privacyPolicyUrl });
      const updatedBrand: Brand = brandResponse.data;

      dispatch(
        setSnackbar({
          icon: SnackbarIcon.DONE,
          open: true,
          message: "snackbar.shopifyIntegrationSuccess",
        }),
      );

      return {
        updatedBrand,
        brandId,
      };
    } catch (e) {
      ConsoleLog(e);
      dispatch(
        setSnackbar({
          message: "snackbar.shopifyIntegrationError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
