const createInitials = (fullName?: string) => {
  if (!fullName || typeof fullName !== "string") {
    return "";
  }
  const fullNameSplit = fullName.split(" ");
  return fullNameSplit.length > 1
    ? `${fullNameSplit[0].slice(0, 1)}${fullNameSplit[1].slice(0, 1)}`
    : fullNameSplit[0].slice(0, 2);
};
export default createInitials;
