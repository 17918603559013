import { Box, css, styled } from "@mui/material";
import React from "react";
import { LogoSize, LogoVariant, StyledAppLogo } from "shared/components/Styled/StyledAppLogo";

export const StyledLogo = styled(() => (
  <StyledAppLogo variant={LogoVariant.TERTIARY} size={LogoSize.DEFAULT} />
))`
  position: absolute;
  top: 0;
  left: 0;
`;

export const StyledWrapper = styled(Box)`
  height: 100%;
  position: relative;
`;

export const Wrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    max-width: 552px;
    margin: 0 auto;
    justify-content: center;

    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const StepTitleSection = styled(Box)``;
