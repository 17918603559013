import { FC } from "react";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { StyledBox, SubmitBtn, TitleWrapper } from "views/LoginPage/LoginPage.styled";
import { useLoginPage } from "views/LoginPage/useLoginPage";
import { LogoSize, LogoVariant, StyledAppLogo } from "shared/components/Styled/StyledAppLogo";

export const LoginPage: FC = () => {
  const { onLogin } = useLoginPage();

  return (
    <StyledBox>
      <StyledAppLogo variant={LogoVariant.TERTIARY} size={LogoSize.BIG} />
      <TitleWrapper>
        <TextBase content="login.titleLogin" variant="h2" />
        <TextBase variant="body1" color="text.disabled" content="login.titleDescription" />
      </TitleWrapper>
      <SubmitBtn onClick={onLogin} color="tertiary" variant="contained" fullWidth>
        <TextBase content="login.titleLogin" variant="subtitle1" />
      </SubmitBtn>
    </StyledBox>
  );
};
