import assignView from "./assign";
import dashboardView from "./dashboard";
import discoverNewPartnersView from "./discoverNewPartners";
import partnershipsView from "./partnerships";
import productsView from "./products";
import setupView from "./setup";
import firstLoginSetupView from "./firstLoginSetup";
import settingsView from "./settings";

export default {
  assignView,
  dashboardView,
  discoverNewPartnersView,
  partnershipsView,
  productsView,
  setupView,
  firstLoginSetupView,
  settingsView,
};
