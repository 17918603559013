import selectors from "./selectors";
import ViewSlice from "./settingsViewSlice";
import { editPartnerDomainSettings } from "./editPartnerDomainSettings";
import { updatePartnerDetails } from "./updatePartnerDetails";

export default {
  ...selectors,
  // fetchResources: fetchResourcesFor,
  slice: ViewSlice,
  editPartnerDomainSettings,
  updatePartnerDetails,
};
