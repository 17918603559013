import React, { FC } from "react";
import { ImageUpload } from "shared/components/ImageUpload";
import { UserAccountForm, UserAccountFormEnum } from "views/SelfSignup/useSelfSignUp";
import { useSelfSignUpSecondStep } from "views/SelfSignup/SelfSignUpSecondStep/useSelfSignUpSecondStep";
import { percentValueRegex } from "shared/logic/utils/percentValueRegex";
import {
  AgreeLabel,
  ButtonLabel,
  Checkbox,
  CheckboxWrapper,
  CommissionInputWrapper,
  CommissionRateDescription,
  CommissionRateInput,
  CommissionRateLabel,
  Content,
  ContinueButton,
  DescriptionInput,
  DescriptionLabel,
  FirstTitle,
  Form,
  LinkLabel,
  NavigationInfo,
  NavigationWrapper,
  SecondTitle,
  StyledLink,
  TitleSection,
  Wrapper,
} from "./SelfSignUpSecondStep.styled";

export interface FormDetailsProps {
  savedUserDetails: UserAccountForm;
  userDetailsUpdate: ({
    commissionRate,
    userLogo,
    userDescription,
    termsConditions,
  }: UserAccountForm) => void;
}

export const SelfSignUpSecondStep: FC<FormDetailsProps> = ({
  savedUserDetails,
  userDetailsUpdate,
}) => {
  const {
    handleSubmit,
    register,
    onSubmit,
    isBrandUser,
    onImageUpload,
    isValidForm,
    creatorTermsAndConditionsUrl,
  } = useSelfSignUpSecondStep({
    userDetailsUpdate,
    savedUserDetails,
  });
  return (
    <Wrapper>
      <Content>
        <TitleSection>
          <FirstTitle content="selfSignUp.secondStepPartner.subtitle" />
          &nbsp;
          <SecondTitle
            content={
              isBrandUser
                ? "selfSignUp.secondStepBrand.accountTypeBrand"
                : "selfSignUp.secondStepPartner.accountTypeCreator"
            }
          />
        </TitleSection>
        <Form onSubmit={handleSubmit(onSubmit)} id="brand-first-login">
          <ImageUpload
            uploadLabel={
              isBrandUser
                ? "selfSignUp.secondStepBrand.uploadLogo"
                : "selfSignUp.secondStepPartner.uploadAvatar"
            }
            customLabel={
              isBrandUser
                ? "selfSignUp.secondStepBrand.logoPreview"
                : "selfSignUp.secondStepPartner.avatarPreview"
            }
            onUpload={onImageUpload}
          />
          <CommissionRateLabel
            content={
              isBrandUser
                ? "selfSignUp.secondStepBrand.commissionRate"
                : "selfSignUp.secondStepPartner.commissionRate"
            }
          />
          <CommissionInputWrapper>
            <CommissionRateInput
              type="number"
              placeholder="firstLogin.partner.commissionRatePlaceholder"
              label="selfSignUp.secondStepBrand.commissionRateLabel"
              {...register(UserAccountFormEnum.COMMISSION_RATE, {
                required: true,
                pattern: { value: percentValueRegex, message: "Incorrect value" },
              })}
            />
            <CommissionRateDescription
              content={
                isBrandUser
                  ? "selfSignUp.secondStepBrand.commissionRateInfo"
                  : "selfSignUp.secondStepPartner.commissionRateInfo"
              }
            />
          </CommissionInputWrapper>
          <DescriptionLabel content="firstLogin.descriptionLabel" />
          <DescriptionInput
            placeholder="firstLogin.descriptionPlaceholder"
            {...register(UserAccountFormEnum.USER_DESCRIPTION, { required: true })}
          />
          <CheckboxWrapper>
            <Checkbox
              required
              {...register(UserAccountFormEnum.TERMS_CONDITIONS, { required: true })}
            />
            <AgreeLabel content="selfSignUp.secondStepPartner.agreements" />
            <StyledLink href={creatorTermsAndConditionsUrl}>
              <LinkLabel content="selfSignUp.secondStepPartner.terms" />
            </StyledLink>
          </CheckboxWrapper>
        </Form>
        <NavigationWrapper>
          <NavigationInfo content="selfSignUp.secondStepPartner.acceptPlatform" />
          <ContinueButton type="submit" form="brand-first-login" disabled={!isValidForm}>
            <ButtonLabel content="navigation.continue" />
          </ContinueButton>
        </NavigationWrapper>
      </Content>
    </Wrapper>
  );
};
