import { FC } from "react";
import { Close } from "@mui/icons-material";
import { Drawer } from "@mui/material";
import { BottomNavigationProps, BottomNavigation } from "shared/components/Drawer/BottomNavigation";
import {
  StyledCloseButton,
  StyledDrawerContent,
  StyledDrawerStep,
  TitleWrapper,
  Content,
  Title,
} from "./DrawerWrapper.styled";
import { TextContentPath } from "../base/TextBase/TextBase";

export interface DrawerWrapperProps {
  isOpen: boolean;
  toggleOpen: () => void;
  title: TextContentPath;
  bottomNavigationProps?: BottomNavigationProps;
}

export const DrawerWrapper: FC<DrawerWrapperProps> = ({
  children,
  toggleOpen,
  isOpen,
  title,
  bottomNavigationProps,
}) => (
  <Drawer anchor="right" onClose={toggleOpen} open={isOpen}>
    <StyledDrawerContent>
      <TitleWrapper>
        <Title content={title} />
        <StyledCloseButton onClick={toggleOpen}>
          <Close />
        </StyledCloseButton>
      </TitleWrapper>
      <Content>
        <StyledDrawerStep>{children}</StyledDrawerStep>
        {bottomNavigationProps && <BottomNavigation {...bottomNavigationProps} />}
      </Content>
    </StyledDrawerContent>
  </Drawer>
);
