import { FC } from "react";
import { ViewHeadline } from "shared/components/ViewHeadline";
import { Inventory, SupervisedUserCircle } from "@mui/icons-material";
import { RouteEnum } from "shared/logic/_enums/route.enum";

import useDashboardForPartnerUser from "views/partnerViews/Dashboard/useDashboardForPartnerUser";
import { CountInfo } from "shared/components/CountInfo";
import { DashboardList } from "shared/components/DashboardList";
import { ItemTypeEnum } from "shared/components/DashboardList/useDashboardList";
import {
  StyledDivider,
  StyledGridWrapper,
  StyledViewWrapper,
} from "views/partnerViews/Dashboard/Dashboard.styled";
import { FullScreenLoader } from "shared/components/FullScreenLoader";

export const Dashboard: FC = () => {
  const { productsAggregated, partnershipsAggregated, history, policy, isDashboardDataFetched } =
    useDashboardForPartnerUser();

  if (!isDashboardDataFetched || !policy?.partner) {
    return <FullScreenLoader />;
  }

  return (
    <StyledViewWrapper>
      <ViewHeadline text={`Welcome ${policy.partner.name}!`} />
      {/* TODO: change this ^ banner text */}
      <StyledGridWrapper>
        <CountInfo
          Icon={SupervisedUserCircle}
          count={partnershipsAggregated.length}
          heading="dashboard.activePartnerships"
          onBrowseClick={() => history.push(RouteEnum.PARTNERSHIPS)}
        />
        <CountInfo
          Icon={Inventory}
          count={productsAggregated ? productsAggregated.length : 0}
          heading="dashboard.promotedProducts"
          onBrowseClick={() => history.push(RouteEnum.PRODUCTS)}
        />
        <StyledDivider />
        <DashboardList
          itemType={ItemTypeEnum.PARTNERSHIP}
          header="dashboard.latestPartnerships"
          items={partnershipsAggregated}
          missingItemsDescription="dashboard.noPartnershipsDescription"
          missingItemsHeader="dashboard.noPartnerships"
        />
        <DashboardList
          itemType={ItemTypeEnum.PRODUCT}
          header="dashboard.latestProducts"
          items={productsAggregated}
          missingItemsDescription="dashboard.noProductsDescription"
          missingItemsHeader="dashboard.noProducts"
        />
      </StyledGridWrapper>
    </StyledViewWrapper>
  );
};
