import React, { FC } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { AuthApiProvider } from "shared/components/auth/AuthApiProvider";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { createOnRedirectCallback } from "shared/components/auth/utils/createOnRedirectCallback";

const DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN!;
const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID!;
const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE!;

export const AuthProvider: FC = ({ children }) => {
  const history = useHistory();

  return (
    <Auth0Provider
      domain={DOMAIN}
      clientId={CLIENT_ID}
      audience={AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={createOnRedirectCallback(history)}
      skipRedirectCallback={window.location.pathname === RouteEnum.SETUP}
      useRefreshTokens
    >
      <AuthApiProvider>{children}</AuthApiProvider>
    </Auth0Provider>
  );
};
