import { RouteEnum } from "shared/logic/_enums/route.enum";
import { UserSetupStatus, UserStatus } from "shared/logic/types/User/UserStatus";
import { LoginPage } from "views/LoginPage";
import { RoutesConfig } from "views/Routing/NotAuthenticatedRouting/types";
import { userSetupStatusToRoutesConfig } from "views/Routing/NotAuthenticatedRouting/utils/userSetupStatusToRoutesConfig";
import { SelfSignUp } from "views/SelfSignup";
import { ForbiddenPage } from "views/ForbiddenPage";
import { ServerErrorPage } from "views/ServerErrorPage";

export const useNotAuthenticatedRouting = (
  userStatus: UserStatus,
  userSetupStatus: UserSetupStatus,
): RoutesConfig | null => {
  switch (userStatus) {
    case UserStatus.AUTHENTICATED_REGISTRATION_NEEDED:
      return {
        mainRedirect: RouteEnum.REGISTER,
        routes: [{ path: RouteEnum.REGISTER, component: SelfSignUp }],
      };
    case UserStatus.AUTHENTICATED_NO_POLICIES:
      return {
        mainRedirect: RouteEnum.FORBIDDEN,
        routes: [{ path: RouteEnum.FORBIDDEN, component: ForbiddenPage }],
      };
    case UserStatus.AUTHENTICATED_INTERNAL_ERROR:
      return {
        mainRedirect: RouteEnum.INTERNAL_ERROR,
        routes: [{ path: RouteEnum.INTERNAL_ERROR, component: ServerErrorPage }],
      };
    case UserStatus.NOT_AUTHENTICATED:
      return {
        mainRedirect: RouteEnum.LOGIN,
        routes: [{ path: RouteEnum.LOGIN, component: LoginPage }],
      };
    case UserStatus.AUTHENTICATED:
      return userSetupStatusToRoutesConfig(userSetupStatus);
    // FOLLOWING SHOULD NOT HAPPEN
    case UserStatus.NOT_SPECIFIED:
    default:
      return null;
  }
};
