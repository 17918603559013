import { ContactUsButton } from "views/partnerViews/Setup/StepDrawer/SiteTag/SiteTag.styled";
import { Mail } from "@mui/icons-material";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { useHelpSection } from "views/partnerViews/Setup/StepDrawer/HelpSection/useHelpSection";
import { Wrapper } from "./HelpSection.styled";

export const HelpSection = () => {
  const { onContactButtonClick } = useHelpSection();
  return (
    <Wrapper>
      <TextBase variant="subtitle1" content="setup.partner.helpTitle" />
      <ContactUsButton color="tertiary" variant="contained" onClick={onContactButtonClick}>
        <Mail />
        <TextBase variant="subtitle1" content="utility.contactUs" />
      </ContactUsButton>
    </Wrapper>
  );
};
