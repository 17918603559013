import { FC } from "react";
import { ChatBubble } from "@mui/icons-material";
import { StyledCopyBtn } from "shared/components/ViewHeadline/ViewHeadline.styled";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { DrawerWrapper } from "shared/components/Drawer";
import { useRequestNewBrandDrawer } from "./useRequestNewBrandDrawer";
import {
  StyledForm,
  BrandEmail,
  BrandNameInput,
  BrandWebsite,
  StyledBox,
  NavigationContent,
  StyledSendButton,
} from "./RequestNewBrandDrawer.styled";

export const RequestNewBrandDrawer: FC = () => {
  const { toggleOpenDrawer, isOpenDrawer, handleSubmit, onSubmit, register, isFormValid } =
    useRequestNewBrandDrawer();
  return (
    <>
      <StyledCopyBtn variant="contained" color="tertiary" onClick={toggleOpenDrawer}>
        <TextBase content="discoverBrands.requestButton" variant="subtitle1" />
        <ChatBubble />
      </StyledCopyBtn>
      <DrawerWrapper
        toggleOpen={toggleOpenDrawer}
        isOpen={isOpenDrawer}
        title="discoverBrands.drawer.title"
      >
        <StyledBox>
          <StyledForm id="submit-form" onSubmit={handleSubmit(onSubmit)}>
            <TextBase variant="h3" content="discoverBrands.drawer.mainTitle" />
            <TextBase variant="body1" content="discoverBrands.drawer.fillInfo" />
            <BrandNameInput
              {...register("name", { required: true })}
              placeholder="discoverBrands.drawer.brandName"
              fullWidth
              variant="outlined"
              color="tertiary"
            />
            <BrandWebsite
              {...register("website", { required: true })}
              placeholder="discoverBrands.drawer.website"
              fullWidth
              variant="outlined"
              color="tertiary"
            />
            <TextBase variant="body1" content="discoverBrands.drawer.fillEmail" />
            <BrandEmail
              {...register("email")}
              placeholder="discoverBrands.drawer.email"
              fullWidth
              variant="outlined"
              color="tertiary"
            />
          </StyledForm>
          <NavigationContent>
            <TextBase
              variant="tableLabel"
              color="text.disabled"
              content="discoverPartners.brandUserView.sendRequest"
            />
            <StyledSendButton
              form="submit-form"
              type="submit"
              variant="contained"
              color="tertiary"
              disabled={!isFormValid}
            >
              <TextBase
                variant="subtitle1"
                content="discoverPartners.brandUserView.requestSubmit"
              />
            </StyledSendButton>
          </NavigationContent>
        </StyledBox>
      </DrawerWrapper>
    </>
  );
};
