import {
  performTransaction,
  PerformTransactionArgs,
} from "shared/logic/store/features/user/signUp/utils/performTransaction";
import brandsApi, { UpdateBrandLogoProps } from "shared/logic/api/brandsApi";
import Brand, { CreateBrandDTO } from "shared/logic/types/Brands/Brand";

interface CreateBrandTransactionArgs
  extends Pick<PerformTransactionArgs<Brand>, "onError" | "rollback">,
    Partial<Pick<UpdateBrandLogoProps, "logo">> {
  data: Partial<CreateBrandDTO>;
}

export async function createBrandTransaction({
  data,
  logo,
  onError,
  rollback,
}: CreateBrandTransactionArgs): Promise<Brand> {
  async function action(): Promise<Brand> {
    // CREATE BRAND
    const newBrandResponse = await brandsApi.postNewBrand(data);
    if (!logo) {
      return newBrandResponse.data;
    }
    // UPDATE THAT BRAND WITH LOGO
    const newBrandWithLogoResponse = await brandsApi.updateBrandLogo({
      brandId: newBrandResponse.data.id,
      logo,
    });

    return newBrandWithLogoResponse.data;
  }

  return performTransaction({ action, onError, rollback });
}
