import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

export enum SnackbarIcon {
  COPY = "copy",
  INFO = "info",
  DONE = "done",
  CODE = "code",
}

interface SnackbarState {
  open?: boolean;
  message?: TextContentPath;
  i18nInterpolationValues?: {
    [key: string]: string;
  };
  icon?: SnackbarIcon;
}

export const initialState: SnackbarState = {
  open: false,
  message: undefined,
  i18nInterpolationValues: {},
  icon: undefined,
};

interface SetSnackbarPayload extends SnackbarState {}

const snackbarSlice = createSlice({
  name: storePrefixes.resourceSlices.snackbar,
  initialState,
  reducers: {
    setSnackbar(state, action: PayloadAction<SetSnackbarPayload>) {
      const { open, icon, message, i18nInterpolationValues } = action.payload;
      state.open = open ?? true;
      state.icon = icon;
      state.message = message as string;
      state.i18nInterpolationValues = i18nInterpolationValues;
    },
    closeSnackbar(state) {
      state.open = false;
    },
  },
});

export const { setSnackbar, closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
