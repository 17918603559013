import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import partnersApi from "shared/logic/api/partnersApi";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

export interface FirstLoginPartnerSetupParams {
  partnerId?: string;
  description: string;
  commissionRate: number;
  termsAndConditionsAgreement: boolean;
}

export const firstLoginPartnerSetup = createAsyncThunk(
  storePrefixes.partnerUser.firstLoginSetup,
  async (
    {
      partnerId,
      description,
      commissionRate,
      termsAndConditionsAgreement,
    }: FirstLoginPartnerSetupParams,
    { dispatch },
  ) => {
    try {
      if (!partnerId || !description || !commissionRate || !termsAndConditionsAgreement) {
        throw new Error("Something went wrong");
      }
      const patchedPartnerResponse = await partnersApi.patchPartner(partnerId, {
        description,
        defaultCommissionPercent: commissionRate,
      });
      const patchedPartner = patchedPartnerResponse.data;
      dispatch(
        setSnackbar({
          open: true,
          message: "snackbar.userUpdateSuccess",
          icon: SnackbarIcon.DONE,
        }),
      );
      return { patchedPartner, partnerId };
    } catch (e) {
      ConsoleLog(e);
      dispatch(setSnackbar({ open: true, message: "snackbar.genericError" }));
      throw e;
    }
  },
);
