import { RootState } from "shared/logic/store/rootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";

const getInviteViewSlice = (state: RootState) => state.shared.inviteViewSlice;

const getIsInviteToPlatformLoading = createSelector(
  [getInviteViewSlice],
  ({ sliceStatus }) => sliceStatus === SliceStatus.PENDING,
);

export default {
  getInviteViewSlice,
  getIsInviteToPlatformLoading,
};
