import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";
import { useSelector } from "react-redux";
import { getPendingProductsCount } from "shared/logic/store/features/resourceSlices/partneredProducts/selectors";

export const useTableTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isApprovedActive = searchParams?.status === ApprovalStatus.APPROVED;
  const isPendingActive = searchParams?.status === ApprovalStatus.PENDING;
  const onApprovedClick = () => {
    setSearchParams({ status: ApprovalStatus.APPROVED });
  };
  const onPendingClick = () => {
    setSearchParams({ status: ApprovalStatus.PENDING });
  };

  const pendingProductCounts = useSelector(getPendingProductsCount);

  return {
    isPendingActive,
    isApprovedActive,
    onPendingClick,
    onApprovedClick,
    pendingProductCounts,
  };
};
