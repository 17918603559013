import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import productsApi from "shared/logic/api/productsApi";
import { useDispatch, useSelector } from "react-redux";
import {
  ChosenDialogEnum,
  setDialog,
} from "shared/logic/store/features/resourceSlices/dialog/dialogSlice";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";
import { storeAPI } from "views/partnerViews/storeAPI";

const useAcceptOptions = (product: ProductAggregated) => {
  const dispatch = useDispatch();
  const currentPolicy = useSelector(getUserPolicy);
  const onApproveClick = async () => {
    try {
      await productsApi.approveProduct(product.id);
      // TODO - mutate existing data instead of refetching it
      dispatch(
        storeAPI.productsView.fetchResources({
          partnerId: currentPolicy?.entityId,
          approvalStatus: ApprovalStatus.PENDING,
        }),
      );
      dispatch(
        setSnackbar({
          icon: SnackbarIcon.DONE,
          open: true,
          message: "snackbar.productAccepted",
          i18nInterpolationValues: { itemName: product.name },
        }),
      );
    } catch (e) {
      ConsoleLog(e);
    }
  };

  const onRejectClick = () => {
    dispatch(
      setDialog({
        title: "dialog.acceptProductRejection",
        open: true,
        chosenDialog: ChosenDialogEnum.ITEM_REJECTION,
        dialogProps: {
          product,
        },
      }),
    );
  };

  return {
    onApproveClick,
    onRejectClick,
  };
};

export default useAcceptOptions;
