import { styled, Box, css } from "@mui/material";

export const Wrapper = styled(Box)(
  ({ theme }) => css`
    border-top: 1px solid ${theme.palette.border};
    padding-top: ${theme.spacing(2)};

    & > * + * {
      margin-top: ${theme.spacing(2)};
    }
  `,
);
