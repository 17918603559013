import { styled, css } from "@mui/material";
import { OverflowColumnFlex } from "shared/components/OverflowColumnFlex";

export const SpaceBetweenWrapper = styled(OverflowColumnFlex)(
  ({ theme }) => css`
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.border};
    width: 100%;
  `,
);
