import { Partner, PartnersDomainSetup } from "shared/logic/types/Partners/Partner";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import partnersApi from "shared/logic/api/partnersApi";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

interface EditPartnersDomainSettings {
  newDomainData: PartnersDomainSetup;
  partnerId: string;
}

interface EditPartnersDomainSettingsReturnType {
  newPartnerData: Partner;
}

export const editPartnerDomainSettings = createAsyncThunk(
  storePrefixes.partnerUser.settingsSlice.editPartnerDomainSettings,
  async (
    { newDomainData, partnerId }: EditPartnersDomainSettings,
    { dispatch },
  ): Promise<EditPartnersDomainSettingsReturnType> => {
    try {
      const newPartnerData = await partnersApi.patchPartner(partnerId, newDomainData);
      return {
        newPartnerData: {
          ...newPartnerData.data,
          id: partnerId,
        },
      };
    } catch (e) {
      dispatch(
        setSnackbar({
          message: "snackbar.genericError",
          open: true,
        }),
      );
      throw e;
    }
  },
);
