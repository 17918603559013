import {
  performTransaction,
  PerformTransactionArgs,
} from "shared/logic/store/features/user/signUp/utils/performTransaction";
import { User } from "shared/logic/types/User/User";
import { SignUpQuery } from "shared/logic/types/User/SignUpQuery";
import userApi from "shared/logic/api/userApi";

interface CreateUserTransactionArgs
  extends Pick<PerformTransactionArgs<User>, "onError" | "rollback"> {
  data: Partial<SignUpQuery>;
}

export async function createUserTransaction({
  data,
  onError,
  rollback,
}: CreateUserTransactionArgs): Promise<User> {
  async function action(): Promise<User> {
    const newUserResponse = await userApi.postUser(data);
    return newUserResponse.data;
  }

  return performTransaction({ action, onError, rollback });
}
