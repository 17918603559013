import { TextBase } from "shared/components/base/TextBase/TextBase";
import {
  StyledContentWrapper,
  StyledOrderedList,
  StyledButtonBox,
  ContinueButton,
  StyledListBox,
  StyledCheckIcon,
} from "views/brandViews/Setup/SharedStyles.styled";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import { Alert } from "shared/components/Alert";
import { useBillingPermissions } from "./useBillingPermissions";

export const BillingPermissions = () => {
  const { onShopifyButtonClick, isNonceCorrect } = useBillingPermissions();
  if (isNonceCorrect === undefined) {
    return <FullScreenLoader />;
  }
  return (
    <StyledContentWrapper>
      <Alert iconComponent={<StyledCheckIcon color="success" />}>
        <TextBase
          variant="subtitle1"
          color="text.secondary"
          content="setup.billingPermissions.successAlert"
        />
      </Alert>
      <StyledListBox>
        <TextBase
          content="setup.billingPermissions.listTitle"
          variant="subtitle1"
          color="text.secondary"
        />
        <StyledOrderedList>
          <li>
            <TextBase
              color="text.secondary"
              variant="body1"
              content="setup.billingPermissions.listSignInStep"
            />
          </li>
          <li>
            <TextBase
              color="text.secondary"
              variant="body1"
              content="setup.billingPermissions.listApproveStep"
            />
          </li>
        </StyledOrderedList>
      </StyledListBox>
      <StyledButtonBox>
        <ContinueButton variant="contained" color="tertiary" onClick={onShopifyButtonClick}>
          <TextBase variant="subtitle1" content="setup.billingPermissions.shopifyRedirect" />
        </ContinueButton>
      </StyledButtonBox>
    </StyledContentWrapper>
  );
};
