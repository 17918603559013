import Partnership, {
  CreatePartnershipDTO,
  RequestPartnershipDTO,
} from "shared/logic/types/Partnerships/Partnership";
import axiosInstance from "shared/logic/api/axiosInstance";
import PartnershipsQuery from "shared/logic/types/Partnerships/PartnershipsQuery";
import { ResponseDTO } from "shared/logic/types/ResponseDTO";
import { omitBy, isUndefined, isNil } from "lodash-es";

function getPartnerships(
  partnershipsQuery: PartnershipsQuery,
): Promise<ResponseDTO<Partnership[]>> {
  return axiosInstance.get("/partnerships", {
    params: omitBy(
      {
        "brand-id": partnershipsQuery.brandId,
        "partner-id": partnershipsQuery.partnerId,
      },
      isUndefined,
    ),
  });
}

function postPartnerships(
  createPartnershipDTO: CreatePartnershipDTO,
): Promise<{ data: Partnership }> {
  return axiosInstance.post("/partnerships", {
    brandId: createPartnershipDTO.brandId,
    partnerId: createPartnershipDTO.partnerId,
    commissionPercent: createPartnershipDTO.commissionPercent,
  });
}

const requestPartnership = (
  requesterId: string,
  requestPartnershipDTO: RequestPartnershipDTO,
): Promise<void> =>
  axiosInstance.post(
    `/orgs/${requesterId}/partnership-requests`,
    omitBy(requestPartnershipDTO, isNil),
  );

export default {
  getPartnerships,
  postPartnerships,
  requestPartnership,
};
