import { createAsyncThunk } from "@reduxjs/toolkit";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import brandsApi from "shared/logic/api/brandsApi";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";

interface UpdateBrandDetailsParams {
  newOrganizationData: {
    logo?: File;
    name: string;
    description: string;
    defaultPartnershipCommissionPercent: number;
  };
  brandId: string;
}

export const updateBrandDetails = createAsyncThunk(
  storePrefixes.brandUser.settingsSlice.updateOrganizationDetails,
  async (
    {
      brandId,
      newOrganizationData: { name, defaultPartnershipCommissionPercent, logo, description },
    }: UpdateBrandDetailsParams,
    { dispatch },
  ) => {
    try {
      let patchedBrandResponse = await brandsApi.patchBrand(brandId, {
        name,
        defaultPartnershipCommissionPercent,
        description,
      });
      if (logo) {
        patchedBrandResponse = await brandsApi.updateBrandLogo({ logo, brandId });
      }
      const updatedBrand = patchedBrandResponse.data;

      dispatch(
        setSnackbar({
          open: true,
          message: "snackbar.userUpdateSuccess",
          icon: SnackbarIcon.DONE,
        }),
      );
      return { updatedBrand, brandId };
    } catch (e) {
      ConsoleLog(e);
      dispatch(setSnackbar({ open: true, message: "snackbar.genericError" }));
      throw e;
    }
  },
);
