import { FC } from "react";
import { StyledStatus, AlignmentWrapper } from "shared/components/ItemStatus/ItemStatus.styled";
import { Typography } from "@mui/material";
import capitalizeString from "shared/logic/utils/capitalizeString";
import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";
import useItemStatus from "shared/components/ItemStatus/useItemStatus";

export enum ItemStatusSize {
  NORMAL = "normal",
  SMALL = "small",
}

interface ItemStatusProps {
  status: ApprovalStatus;
  size?: ItemStatusSize;
  hideIcon?: boolean;
}

export const ItemStatus: FC<ItemStatusProps> = ({
  status,
  size = ItemStatusSize.NORMAL,
  hideIcon = false,
}) => {
  const { statusIcon } = useItemStatus();
  return (
    <AlignmentWrapper>
      <StyledStatus status={status} $size={size}>
        {!hideIcon && statusIcon(status)}
        <Typography
          variant={size === ItemStatusSize.SMALL ? "caption" : "tableLabel"}
          color="text.secondary"
        >
          {capitalizeString(status)}
        </Typography>
      </StyledStatus>
    </AlignmentWrapper>
  );
};
