import { User } from "shared/logic/types/User/User";
import userApi from "shared/logic/api/userApi";
import axios from "axios";
import HttpStatusCode from "shared/logic/_enums/HttpStatusCodes";
import { Error500 } from "shared/logic/store/features/user/types";

/**
 * Flow of obtaining/creating user if auth0 in usage
 * 1.check if user exist in config-api
 * 2. if exist proceed obtaining policies
 * 3. if there is no user in config-api try fetching from /users by email
 *   3a. if exist update the user auth0 id in config-api
 *   3b. if does not exist throw error
 *
 * docs -> https://friendly-player-f3f.notion.site/From-Okta-to-Auth0-7b1d1cea33be40dab060758c65938844
 */
export const getUserForAuth0 = async (
  auth0UserId: string,
  email: string,
): Promise<User | undefined> => {
  try {
    const userResponse = await userApi.getUserInfoByAuth0Id(auth0UserId);
    const { users } = userResponse.data;
    return users.length ? users[0] : undefined;
  } catch (error) {
    // TEMPORARY SOLUTION UNTIL WE CREATE A CENTRALIZED ERROR HANDLING SYSTEM
    if (
      // UNKNOWN ERROR
      !axios.isAxiosError(error) ||
      // AXIOS BUT NO RESPONSE
      !error.response ||
      // AXIOS WITH RESPONSE BUT STATUS IS OTHER THAN NOT_FOUND
      error.response.status !== HttpStatusCode.NOT_FOUND
    ) {
      throw Error500;
    }

    const userResponse = await userApi.getUserByEmail(email);
    const { users } = userResponse.data;
    const userObj = users.length ? users[0] : undefined;

    // PATH USER WITH AUTH0 ID
    if (userObj) {
      await userApi.patchUser(userObj.id, { auth0UserId });
    }

    return userObj;
  }
};
