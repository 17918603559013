import { TextBase } from "shared/components/base/TextBase/TextBase";
import { LocalizedInput } from "shared/components/base/LocalizedInput/LocalizedInput";
import { Controller } from "react-hook-form";
import { RadioGroup, Radio } from "@mui/material";
import { FC } from "react";
import { useAccountDetails } from "views/adminViews/AddAccount/AccountDetails/useAccountDetails";
import {
  AccountDetailsUpdateParams,
  AddAccountFormEnum,
} from "views/adminViews/AddAccount/useAddAccount";
import { UserRole } from "shared/components/auth/AuthContext";
import { ButtonsWrapper, ContinueButton } from "views/adminViews/AddAccount/AddAccount.styled";
import { percentValueRegex } from "shared/logic/utils/percentValueRegex";
import { StyledForm, AccountTypeSection, StyledFormControlLabel } from "./AccountDetails.styled";

export interface AccountDetailsProps {
  accountDetailsUpdate: ({
    accountType,
    accountName,
    commissionRate,
  }: AccountDetailsUpdateParams) => void;
  savedAccountName?: string;
  savedAccountType?: UserRole;
  savedAccountCommission?: number;
}

export const AccountDetails: FC<AccountDetailsProps> = ({
  accountDetailsUpdate,
  savedAccountName,
  savedAccountType,
  savedAccountCommission,
}) => {
  const { handleSubmit, onSubmit, control, register, isValid } = useAccountDetails({
    accountDetailsUpdate,
    savedAccountName,
    savedAccountType,
    savedAccountCommission,
  });
  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)} id="account-details-form">
        <TextBase
          color="text.secondary"
          variant="body1"
          content="addAccount.accountDetails.description"
        />
        <LocalizedInput
          label="addAccount.accountDetails.accountName"
          fullWidth
          color="tertiary"
          required
          {...register(AddAccountFormEnum.ACCOUNT_NAME, { required: true })}
        />
        <TextBase
          color="text.secondary"
          variant="body1"
          content="addAccount.accountDetails.commissionRateDescription"
        />
        <LocalizedInput
          label="addAccount.accountDetails.commissionRate"
          placeholder="firstLogin.partner.commissionRatePlaceholder"
          fullWidth
          type="number"
          color="tertiary"
          required
          {...register(AddAccountFormEnum.COMMISSION_RATE, {
            required: true,
            pattern: { value: percentValueRegex, message: "Incorrect value" },
          })}
        />
        <AccountTypeSection>
          <TextBase
            variant="body1"
            color="text.secondary"
            content="addAccount.accountDetails.accountType"
          />
          <Controller
            rules={{ required: true }}
            control={control}
            name={AddAccountFormEnum.ACCOUNT_TYPE}
            render={({ field }) => (
              <RadioGroup {...field}>
                <StyledFormControlLabel
                  control={<Radio color="tertiary" />}
                  label="utility.brand.title"
                  value={UserRole.BRAND}
                />
                <StyledFormControlLabel
                  control={<Radio color="tertiary" />}
                  label="utility.partner.title"
                  value={UserRole.PARTNER}
                />
              </RadioGroup>
            )}
          />
        </AccountTypeSection>
      </StyledForm>
      <ButtonsWrapper>
        <ContinueButton
          variant="contained"
          color="tertiary"
          form="account-details-form"
          type="submit"
          disabled={!isValid}
        >
          <TextBase variant="subtitle1" content="navigation.continue" />
        </ContinueButton>
      </ButtonsWrapper>
    </>
  );
};
