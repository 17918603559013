import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";
import { ViewHeadline } from "shared/components/ViewHeadline";
import {
  useSettingsWrapper,
  SettingsTabs,
} from "shared/components/SettingsComponents/SettingsWrapper/useSettingsWrapper";
import {
  TabWrapper,
  OrganizationTab,
  UserProfileTab,
  OrganizationLabel,
  UserProfileLabel,
} from "shared/components/SettingsComponents/SettingsWrapper/SettingsWrapper.styled";
import { TabContent } from "shared/components/SettingsComponents/TabContent";
import { FC } from "react";

export const SettingsWrapper: FC = ({ children }) => {
  const { isUserProfileTab, tabToDisplay, isOrganizationTab, setTab } = useSettingsWrapper();
  return (
    <StyledViewSpace multiplier={5}>
      <ViewHeadline localizedText="settings.title" />
      <StyledViewSpace multiplier={4}>
        <TabWrapper>
          <OrganizationTab
            $active={isOrganizationTab}
            onClick={() => setTab(SettingsTabs.ORGANIZATION)}
          >
            <OrganizationLabel content="settings.organization.title" />
          </OrganizationTab>
          <UserProfileTab
            $active={isUserProfileTab}
            onClick={() => setTab(SettingsTabs.USER_PROFILE)}
          >
            <UserProfileLabel content="settings.userProfile.title" />
          </UserProfileTab>
        </TabWrapper>
        <TabContent tabToDisplay={tabToDisplay}>{children}</TabContent>
      </StyledViewSpace>
    </StyledViewSpace>
  );
};
