import { ChevronRight } from "@mui/icons-material";
import { TextContentPath } from "shared/components/base/TextBase/TextBase";
import { FC } from "react";
import { TagStyle, TagDescription, Title } from "./TagStyleButton.styled";

export interface TagStyleButtonProps {
  onTagClick: () => void;
  title: TextContentPath;
  IconComponent?: FC;
}

export const TagStyleButton: FC<TagStyleButtonProps> = ({ onTagClick, title, IconComponent }) => (
  <TagStyle onClick={onTagClick}>
    <TagDescription>
      {!!IconComponent && <IconComponent />}
      <Title content={title} />
    </TagDescription>
    <ChevronRight color="tertiary" />
  </TagStyle>
);
