import { styled, css, Button } from "@mui/material";

export const StyledList = styled("ol")(
  ({ theme }) => css`
    padding-left: ${theme.spacing(3)};

    code {
      color: ${theme.palette.tertiary.main};
    }
  `,
);

export const ContactUsButton = styled(Button)(
  ({ theme }) => css`
    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const BackButton = styled(Button)``;
export const SaveButton = styled(Button)``;
