import { FC } from "react";
import { ProductInfo } from "shared/components/ProductInfo";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import {
  StyledBottomWrapper,
  StyledWrapper,
  ConfirmButton,
} from "shared/components/Dialogs/RejectItemDialog/RejectItemDialog.styled";
import useRejectItemDialog from "shared/components/Dialogs/RejectItemDialog/useRejectItemDialog";
import { LocalizedInput } from "shared/components/base/LocalizedInput/LocalizedInput";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";

export interface RejectItemDialogProps {
  product?: ProductAggregated;
}

export const RejectItemDialog: FC<RejectItemDialogProps> = ({ product }) => {
  const { closeDialogAction, register, handleSubmit, onSubmit } = useRejectItemDialog(product);
  if (!product) {
    closeDialogAction();
    return <></>;
  }
  return (
    <StyledWrapper>
      <ProductInfo
        name={product.name}
        imageUrl={product.primaryImageUrl}
        brandName={product.brand.name}
        variantCount={product.productVariants.length}
        commissionPercent={product.commissionPercent ?? product.partnership.commissionPercent}
      />
      <form onSubmit={handleSubmit(onSubmit)} id="hook-form">
        <LocalizedInput
          fullWidth
          multiline
          rows={5}
          placeholder="products.rejectionInputPlaceholder"
          {...register("rejectionMessage")}
        />
      </form>
      <StyledBottomWrapper>
        <TextBase
          variant="tableLabel"
          color="text.disabled"
          content="products.rejectionDescription"
        />
        <ConfirmButton color="tertiary" variant="contained" form="hook-form" type="submit">
          <TextBase content="navigation.confirm" variant="subtitle1" />
        </ConfirmButton>
      </StyledBottomWrapper>
    </StyledWrapper>
  );
};
