import { styled, Box, css, Theme, List } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { TextBase } from "shared/components/base/TextBase/TextBase";

export const StyledBox = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.spacing(1, 1)};
    display: flex;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

const styleIcons = ({ theme }: { theme: Theme }) => css`
  color: ${theme!.palette.primary.contrastText};
`;

export const StyledList = styled(List)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    padding: 0;
    height: 100%;
    overflow: scroll;
  `,
);

export const SwitchAccountBanner = styled(TextBase)(
  ({ theme }) => css`
    padding: ${theme.spacing(1, 2)};
    display: block;
  `,
);

export const StyledListWrapper = styled(Box)(
  ({ theme }) => css`
    border-radius: ${theme.shape.borderRadius}px;
    background-color: ${theme.palette.background.default};
    position: absolute;
    z-index: 3;
    margin-top: ${theme.spacing(2)};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,
);

export const UserAccountInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ArrowDropDownStyled = styled(ArrowDropDown)(styleIcons);

export const ArrowDropUpStyled = styled(ArrowDropUp)(styleIcons);
