import { FC } from "react";
import {
  PriceWrapper,
  FlatFee,
  ProductsQuantity,
  BrandLogo,
  DetailsWrapper,
  Quantity,
  InfoWrapper,
  CommissionPercent,
  Name,
  StyledWrapper,
  Description,
} from "views/partnerViews/Discover/BrandInfo/BrandInfo.styled";
import { useBrandInfo } from "views/partnerViews/Discover/BrandInfo/useBrandInfo";
import { PartnershipInteractions } from "shared/components/PartnershipInteractions";

export interface BrandInfoProps {
  imageUrl: string;
  name: string;
  brandId: string;
  productsQuantity?: number;
  commissionPercent?: number;
  flatFeeValue?: number;
}

export const BrandInfo: FC<BrandInfoProps> = ({
  imageUrl,
  name,
  brandId,
  productsQuantity,
  commissionPercent,
  flatFeeValue,
}) => {
  const { formattedFeeValue } = useBrandInfo({ flatFeeValue });
  return (
    <StyledWrapper>
      <InfoWrapper>
        <BrandLogo src={imageUrl} />
        <DetailsWrapper>
          <Name>{name}</Name>
          {!!productsQuantity && (
            <ProductsQuantity>
              <Quantity>{productsQuantity}</Quantity>
              <Description content="discoverBrands.header.products" />
            </ProductsQuantity>
          )}
          <PriceWrapper>
            {!!commissionPercent && (
              <CommissionPercent>
                <Quantity>{commissionPercent}%</Quantity>
                <Description content="discoverBrands.header.transaction" />
              </CommissionPercent>
            )}
            {!!flatFeeValue && (
              <FlatFee>
                <Quantity>{formattedFeeValue}</Quantity>
                <Description content="discoverBrands.header.flatFee" />
              </FlatFee>
            )}
          </PriceWrapper>
        </DetailsWrapper>
      </InfoWrapper>
      <PartnershipInteractions entityIdToPartnerWith={brandId} />
    </StyledWrapper>
  );
};
