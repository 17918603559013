import { StyledTooltip } from "shared/components/Styled/StyledTooltip";
import { TextBase, TextContentPath } from "shared/components/base/TextBase/TextBase";
import { OpenInNew } from "@mui/icons-material";
import { FC } from "react";
import { StyledLinkWrapper, StyledLink } from "./VisitWebsite.styled";

interface VisitWebsiteBaseProps {
  website?: string;
  iconPosition?: "start" | "end";
}

interface VisitWebsiteTitle extends VisitWebsiteBaseProps {
  label?: TextContentPath;
  missingWebsiteLabel?: never;
}

interface VisitWebsitePlaceholder extends VisitWebsiteBaseProps {
  label?: never;
  missingWebsiteLabel?: TextContentPath;
}

type VisitWebsiteProps = VisitWebsiteBaseProps & (VisitWebsiteTitle | VisitWebsitePlaceholder);

export const VisitWebsite: FC<VisitWebsiteProps> = ({
  website,
  label,
  missingWebsiteLabel,
  iconPosition = "end",
}) => {
  if (!website) {
    return (
      <StyledLinkWrapper $iconPosition={iconPosition} $disabled>
        {iconPosition === "start" && <OpenInNew />}
        <TextBase variant="subtitle1" content={missingWebsiteLabel ?? label ?? "utility.link"} />
        {iconPosition === "end" && <OpenInNew />}
      </StyledLinkWrapper>
    );
  }
  return (
    <StyledLinkWrapper $iconPosition={iconPosition}>
      <StyledTooltip title={website}>
        <StyledLink href={`//${website}`} rel="noopener noreferrer" target="_blank">
          {iconPosition === "start" && <OpenInNew />}
          {label ? (
            <TextBase variant="subtitle1" content={label} />
          ) : (
            <TextBase variant="subtitle1">{website}</TextBase>
          )}
          {iconPosition === "end" && <OpenInNew />}
        </StyledLink>
      </StyledTooltip>
    </StyledLinkWrapper>
  );
};
