import { UserPolicy } from "shared/logic/types/User/UserPolicy";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeUserPolicy } from "shared/logic/store/features/resourceSlices/user/userSlice";
import UserPolicyItemText from "shared/components/UserPolicyItemText";
import UserPolicyAvatar from "shared/components/UserPolicyAvatar";
import {
  useLocalStorage,
  LocalStorageKeys,
} from "shared/logic/hooks/generalPurpose/useLocalStorage";
import { useHistory } from "react-router-dom";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { getCurrentUserPolicyId } from "shared/logic/store/features/resourceSlices/user/selectors";
import { StyledListItemButton, StyledListAvatar } from "./UserPolicyItem.styled";

interface UserPolicyItemProps {
  policy: UserPolicy;
  onSwitcherClose: () => void;
}

const UserPolicyItem: FC<UserPolicyItemProps> = ({ policy, onSwitcherClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentPolicyId = useSelector(getCurrentUserPolicyId);
  const { storeLocally } = useLocalStorage();

  const onPolicyClick = useCallback(() => {
    dispatch(changeUserPolicy({ policyId: policy.entityId }));
    storeLocally(LocalStorageKeys.CURRENT_POLICY_ID, policy.entityId);
    history.push(RouteEnum.DASHBOARD);
    onSwitcherClose();
  }, [policy.entityId, onSwitcherClose, dispatch]);

  if (currentPolicyId === policy.entityId) {
    return null;
  }
  return (
    <StyledListItemButton onClick={onPolicyClick}>
      <StyledListAvatar>
        <UserPolicyAvatar policy={policy} />
      </StyledListAvatar>
      <UserPolicyItemText policy={policy} primaryTextColor="text.disabled" />
    </StyledListItemButton>
  );
};

export default UserPolicyItem;
