import {
  getPartnerships,
  GetPartnershipsReturn,
} from "shared/logic/store/features/viewsSlices/utils/getPartnerships";
import PartnershipsQuery from "shared/logic/types/Partnerships/PartnershipsQuery";
import {
  getPartnersByPartnerships,
  GetPartnersByPartnershipsReturn,
} from "shared/logic/store/features/viewsSlices/utils/getPartnersByPartnerships";
import {
  getPartneredProducts,
  GetPartneredProductsReturn,
} from "shared/logic/store/features/viewsSlices/utils/getPartneredProducts";
import PartneredProductsQuery from "shared/logic/types/PartneredProducts/PartneredProductsQuery";

interface GetAndAggregateBaseDataReturn
  extends GetPartnershipsReturn,
    GetPartnersByPartnershipsReturn,
    GetPartneredProductsReturn {}

interface GetAndAggregateBaseDataArgs {
  partnershipsQuery: PartnershipsQuery;
  partneredProductsQuery: PartneredProductsQuery;
}

export async function getBaseDataForBrandUser({
  partnershipsQuery,
  partneredProductsQuery,
}: GetAndAggregateBaseDataArgs): Promise<GetAndAggregateBaseDataReturn> {
  // PARTNERSHIPS
  const { partnerships, partnershipsMap } = await getPartnerships(partnershipsQuery);
  // PARTNERS
  const partnersPromise = getPartnersByPartnerships(partnerships);
  // PARTNERED PRODUCTS
  const partneredProductsPromise = getPartneredProducts(partneredProductsQuery);

  const [partnersResponse, partneredProductsResponse] = await Promise.all([
    partnersPromise,
    partneredProductsPromise,
  ]);

  const { partners, partnersMap } = partnersResponse;
  const { allPartneredProducts, filteredPartneredProducts, filteredPartneredProductsMap } =
    partneredProductsResponse;

  return {
    // PARTNERSHIPS
    partnerships,
    partnershipsMap,
    // PARTNERS
    partners,
    partnersMap,
    // PARTNERED PRODUCTS
    allPartneredProducts,
    filteredPartneredProducts,
    filteredPartneredProductsMap,
  };
}
