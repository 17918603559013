import { styled, Button, Box, css } from "@mui/material";
import { OverflowColumnFlex } from "shared/components/OverflowColumnFlex";

export const StyledBottomWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledWrapper = styled(OverflowColumnFlex)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const ConfirmButton = styled(Button)``;
