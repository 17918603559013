import { fetchResourcesForDiscoverNewPartnersView } from "./fetchResourcesForDiscoverNewPartnersView";
import { requestPartnershipWithPartner } from "./requestPartnership";
import selectors from "./selectors";
import discoverNewPartnersViewSlice from "./discoverNewPartnersSlice";

export default {
  ...selectors,
  fetchResources: fetchResourcesForDiscoverNewPartnersView,
  requestPartnership: requestPartnershipWithPartner,
  slice: discoverNewPartnersViewSlice,
};
