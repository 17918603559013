import React, { FC } from "react";
import { Radio } from "@mui/material";
import { Controller } from "react-hook-form";
import { UserRole } from "shared/components/auth/AuthContext";
import { UserAccountFormEnum, UserDetailsUpdateParams } from "views/SelfSignup/useSelfSignUp";
import { useSelfSignUpFirstStep } from "views/SelfSignup/SelfSignUpFirstStep/useSelfSignUpFirstStep";
import {
  AccountNameInput,
  ButtonText,
  ButtonWrapper,
  Form,
  RolesInputWrapper,
  RolesWrapper,
  SelectRole,
  StyledButton,
  StyledFormControlLabel,
  SubTitle,
  Wrapper,
} from "./SelfSignUpFirstStep.styled";

export interface FormDetailsProps {
  userDetailsUpdate: ({ userType, userName }: UserDetailsUpdateParams) => void;
  savedUserName?: string;
  savedUserType?: UserRole;
}

export const SelfSignUpFirstStep: FC<FormDetailsProps> = ({
  userDetailsUpdate,
  savedUserName,
  savedUserType,
}) => {
  const { onSubmit, handleSubmit, register, control, isValidForm, BrandIcon, PartnerIcon } =
    useSelfSignUpFirstStep({
      userDetailsUpdate,
      savedUserName,
      savedUserType,
    });
  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SubTitle content="selfSignUp.firstStep.subTitle" />
        <AccountNameInput
          label="selfSignUp.firstStep.nameLabel"
          {...register(UserAccountFormEnum.USER_NAME, { required: true })}
        />
        <RolesWrapper>
          <SelectRole content="selfSignUp.firstStep.selectRole" />
          <Controller
            rules={{ required: true }}
            control={control}
            name={UserAccountFormEnum.USER_TYPE}
            render={({ field }) => (
              <RolesInputWrapper {...field}>
                <StyledFormControlLabel
                  $active={field.value === UserRole.BRAND}
                  control={<Radio icon={BrandIcon} checkedIcon={BrandIcon} />}
                  label="Brand"
                  value={UserRole.BRAND}
                />
                <StyledFormControlLabel
                  $active={field.value === UserRole.PARTNER}
                  control={<Radio icon={PartnerIcon} checkedIcon={PartnerIcon} />}
                  label="Content Creator"
                  value={UserRole.PARTNER}
                />
              </RolesInputWrapper>
            )}
          />
        </RolesWrapper>
        <ButtonWrapper>
          <StyledButton type="submit" disabled={!isValidForm}>
            <ButtonText content="selfSignUp.firstStep.continueBtn" />
          </StyledButton>
        </ButtonWrapper>
      </Form>
    </Wrapper>
  );
};
