import { styled, css, FormControl } from "@mui/material";
import { LocalizedFormControlLabel } from "shared/components/base/LocalizedFormControlLabel";

export const StyledFormControlLabel = styled(LocalizedFormControlLabel)(
  ({ theme }) => css`
    span {
      font-size: ${theme.typography.subtitle1.fontSize}px;
      font-weight: ${theme.typography.subtitle1.fontWeight};
      line-height: ${theme.typography.subtitle1.lineHeight};
    }
  `,
);

export const AccountTypeSection = styled(FormControl)(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(1)};
    }
  `,
);

export const StyledForm = styled("form")(
  ({ theme }) => css`
    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);
