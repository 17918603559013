import { DrawerWrapper } from "shared/components/Drawer";
import { ImageUpload } from "shared/components/ImageUpload";
import { FC } from "react";
import {
  EditOrganizationForm,
  useEditOrganizationDrawer,
} from "shared/components/SettingsComponents/Organization/EditOrganizationDrawer/useEditOrganizationDrawer";
import { percentValueRegex } from "shared/logic/utils/percentValueRegex";
import {
  DescriptionInput,
  AccountNameInput,
  CommissionInput,
  DetailsForm,
  Title,
  Content,
} from "./EditOrganizationDrawer.styled";

export interface EditDrawerProps {
  name?: string;
  commissionPercent?: number;
  description?: string;
  imageUrl?: string;
  isOpen: boolean;
  toggleOpen: () => void;
  submitThunk: (data: EditOrganizationForm) => void; // TODO - change to AsyncThunk<type> when logic is ready;
}

export const EditOrganizationDrawer: FC<EditDrawerProps> = ({
  isOpen,
  toggleOpen,
  commissionPercent,
  description,
  imageUrl,
  name,
  submitThunk,
}) => {
  const { fileUploadHandler, handleSubmit, onSubmit, register, bottomNavigationProps, formId } =
    useEditOrganizationDrawer({ name, submitThunk, commissionPercent, description });
  return (
    <DrawerWrapper
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      title="settings.organization.edit.topLabel"
      bottomNavigationProps={bottomNavigationProps}
    >
      <Content>
        <Title content="settings.organization.edit.title" />
        <DetailsForm id={formId} onSubmit={handleSubmit(onSubmit)}>
          <ImageUpload onUpload={fileUploadHandler} previousImageUrl={imageUrl} />
          <AccountNameInput
            label="settings.organization.edit.accountName"
            description="settings.organization.edit.accountNameDescription"
            {...register("name")}
          />
          <CommissionInput
            label="settings.organization.edit.commissionRate"
            description="settings.organization.edit.commissionRateDescription"
            type="number"
            startAdornment="%"
            {...register("commissionPercent", { pattern: percentValueRegex })}
          />
          <DescriptionInput
            label="settings.organization.edit.descriptionInputLabel"
            description="settings.organization.edit.descriptionInputDescription"
            rows={5}
            multiline
            {...register("description")}
          />
        </DetailsForm>
      </Content>
    </DrawerWrapper>
  );
};
