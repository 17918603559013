import { useCallback } from "react";

export enum LocalStorageKeys {
  REDIRECT_IN_PROGRESS = "REDIRECT_IN_PROGRESS",
  CURRENT_POLICY_ID = "CURRENT_POLICY_ID",
}

export function useLocalStorage() {
  const storeLocally = useCallback(
    (key: LocalStorageKeys, value: any) => localStorage.setItem(key, value),
    [],
  );

  const getFromLocalStore = useCallback((key: LocalStorageKeys) => localStorage.getItem(key), []);

  return {
    storeLocally,
    getFromLocalStore,
  };
}
