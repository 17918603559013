import { FC } from "react";
import { ImageUpload } from "shared/components/ImageUpload";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { LogoSize, LogoVariant, StyledAppLogo } from "shared/components/Styled/StyledAppLogo";
import { FullScreenLoader } from "shared/components/FullScreenLoader";
import {
  ButtonWrapper,
  StyledFormBox,
  ContinueButton,
  StyledCheckbox,
  StyledBox,
  TitleWrapper,
  StyledForm,
  StyledCheckboxContainer,
  StyledLink,
  DescriptionLabel,
  DescriptionInput,
} from "./FirstLogin.styled";
import { useFirstLogin } from "./useFirstLogin";

export const FirstLogin: FC = () => {
  const {
    handleSubmit,
    register,
    onSubmit,
    brandName,
    isFormValid,
    imageOnUpload,
    isLoading,
    brandTermsAndConditionsUrl,
  } = useFirstLogin();
  if (isLoading) {
    return <FullScreenLoader />;
  }
  return (
    <StyledBox>
      <StyledAppLogo variant={LogoVariant.TERTIARY} size={LogoSize.DEFAULT} />
      <StyledFormBox>
        <TitleWrapper>
          <TextBase content="firstLogin.title" tParams={{ name: brandName }} variant="h3" />
          <TextBase content="firstLogin.subTitle" variant="subtitle1" color="text.disabled" />
        </TitleWrapper>
        <StyledForm id="submit-form" onSubmit={handleSubmit(onSubmit)}>
          <ImageUpload onUpload={imageOnUpload} />
          <DescriptionLabel content="firstLogin.descriptionLabel" />
          <DescriptionInput
            placeholder="firstLogin.descriptionPlaceholder"
            {...register("description", { required: true })}
          />
          <StyledCheckboxContainer>
            <StyledCheckbox
              disableRipple
              color="tertiary"
              {...register("termsAndConditionsAgreement", { required: true })}
              required
            />
            <TextBase content="firstLogin.agreement" variant="body1" />
            &nbsp;
            <StyledLink href={brandTermsAndConditionsUrl} variant="body1">
              <TextBase content="firstLogin.terms" variant="body1" />
            </StyledLink>
          </StyledCheckboxContainer>
        </StyledForm>
        <ButtonWrapper>
          <TextBase content="firstLogin.accept" variant="tableLabel" color="text.disabled" />
          <ContinueButton
            form="submit-form"
            type="submit"
            variant="contained"
            color="tertiary"
            disabled={!isFormValid}
          >
            <TextBase content="navigation.continue" variant="subtitle1" />
          </ContinueButton>
        </ButtonWrapper>
      </StyledFormBox>
    </StyledBox>
  );
};
