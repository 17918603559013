import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { SetupStepEnum } from "views/brandViews/Setup/useSetup";

export const useNoIntegrations = () => {
  const [, setSearchParams] = useSearchParams();
  const onAddIntegrationClick = () => {
    setSearchParams({ step: SetupStepEnum.INTEGRATION_DETAILS });
  };
  return { onAddIntegrationClick };
};
