import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { useFilter } from "shared/logic/hooks/generalPurpose/useFilter";
import { storeAPI } from "views/brandViews/storeAPI";

export const useDiscoverPartner = () => {
  const unfilteredPartners = useSelector(storeAPI.discoverNewPartnersView.getDiscoverNewPartners);
  const areBrandsLoading = useSelector(storeAPI.discoverNewPartnersView.getAreBrandsLoading);
  const { onSearchHandler, items: partners } = useFilter({
    unfilteredItems: unfilteredPartners,
    propertiesToFilterBy: ["name", "description"],
  });
  const dispatch = useDispatch();
  const currentPolicy = useSelector(getUserPolicy);
  useEffect(() => {
    dispatch(storeAPI.discoverNewPartnersView.fetchResources({ brandId: currentPolicy?.entityId }));
  }, []);

  return {
    partners,
    onSearchHandler,
    isLoading: areBrandsLoading,
  };
};
