import { PartnershipInteractionsProps } from "shared/components/PartnershipInteractions/index";
import { useUserRole } from "shared/logic/hooks/store/useUserRole";
import { useDispatch } from "react-redux";
import { requestPartnershipWithPartner } from "shared/logic/store/features/viewsSlices/brandUser/discoverNewPartners/requestPartnership";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { requestPartnershipWithBrand } from "shared/logic/store/features/viewsSlices/partnerUser/discover/requestPartnershipWithBrand";

export type UsePartnershipInteractionsProps = PartnershipInteractionsProps;

export const usePartnershipInteractions = ({
  entityIdToPartnerWith,
}: UsePartnershipInteractionsProps) => {
  const { isPartnerUser, isBrandUser } = useUserRole();
  const dispatch = useDispatch();

  const onPartnershipRequest = () => {
    if (isBrandUser) {
      dispatch(requestPartnershipWithPartner({ partnerId: entityIdToPartnerWith }));
      return;
    }
    if (isPartnerUser) {
      dispatch(requestPartnershipWithBrand({ brandId: entityIdToPartnerWith }));
      return;
    }
    dispatch(setSnackbar({ message: "snackbar.genericError" }));
  };

  return {
    onPartnershipRequest,
  };
};
