import { createSlice } from "@reduxjs/toolkit";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";
import { updateBrandDetails } from "shared/logic/store/features/viewsSlices/brandUser/settings/updateBrandDetails";

interface SettingsViewState {
  settingsViewSliceEditThunkStatus: SliceStatus;
  settingsViewSliceStatus: SliceStatus;
}

export const initialState: SettingsViewState = {
  settingsViewSliceEditThunkStatus: SliceStatus.IDLE,
  settingsViewSliceStatus: SliceStatus.IDLE,
};

const settingsViewSlice = createSlice({
  name: storePrefixes.brandUser.settingsSlice.this,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateBrandDetails.pending, (state) => {
        state.settingsViewSliceEditThunkStatus = SliceStatus.PENDING;
      })
      .addCase(updateBrandDetails.fulfilled, (state) => {
        state.settingsViewSliceEditThunkStatus = SliceStatus.FULFILLED;
      });
  },
});

export default settingsViewSlice.reducer;
