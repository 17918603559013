import Partnership from "shared/logic/types/Partnerships/Partnership";
import parseArrayToIdsEntityPair from "shared/logic/utils/parseArrayToIdsEntityPair";
import { Partner } from "shared/logic/types/Partners/Partner";
import partnersApi from "shared/logic/api/partnersApi";

export interface GetPartnersByPartnershipsReturn {
  partners: Partner[];
  partnersMap: Record<string, Partner>;
}

export async function getPartnersByPartnerships(
  partnerships: Partnership[],
): Promise<GetPartnersByPartnershipsReturn> {
  const partnersId = partnerships.map((p) => p.partnerId);

  const partnersResponse = await partnersApi.getPartnersByIds({ include: partnersId });
  const { partners } = partnersResponse.data;
  const partnersMap = parseArrayToIdsEntityPair(partners);

  return {
    partners,
    partnersMap,
  };
}
