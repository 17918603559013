export enum BooleanFunction {
  AND = "AND",
  OR = "OR",
}

export enum Relation {
  EQUALS = "EQUALS",
  MORE_THAN = "MORE_THAN",
  MORE_THAN_OR_EQUAL = "MORE_THAN_OR_EQUAL",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
  INCLUDES = "INCLUDES",
}

export type PlainObject = Record<string, any>;
type Obj = PlainObject;

export interface Argument<O extends Obj, P extends keyof O> {
  relation: Relation;
  value: O[P];
}

export interface PrimitiveFilter<O extends Obj, P extends keyof O> {
  function: BooleanFunction;
  arguments: Argument<O, P>[];
}

export type FilterProperty<O extends Obj, P extends keyof O> =
  | Argument<O, P>
  | PrimitiveFilter<O, P>
  | (O[P] extends Obj ? Filters<O[P]> : never);

export type FilterProperties<O extends Obj> = {
  [P in keyof O]?: FilterProperty<O, P>;
};

export interface ComplexFilters<O extends Obj> {
  function: BooleanFunction;
  properties: FilterProperties<O>;
}

export type Filters<O extends Obj> = ComplexFilters<O>;
