import { FC } from "react";
import { ProductInfo } from "shared/components/ProductInfo";
import { useDispatch } from "react-redux";
import { closeDialog } from "shared/logic/store/features/resourceSlices/dialog/dialogSlice";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { Product } from "shared/logic/types/Products/Product";
import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import {
  AcceptButton,
  StyledBottomWrapper,
  StyledContentWrapper,
  StyledMessage,
} from "shared/components/Dialogs/RejectionMessageDialog/RejectionMessageDialog.styled";
import { revokeAccess } from "shared/logic/store/features/viewsSlices/brandUser/assign/revokeAccess";

export interface RejectionMessageDialogProps {
  product: Product;
  partneredProduct: PartneredProduct;
}

export const RejectionMessageDialog: FC<RejectionMessageDialogProps> = ({
  product,
  partneredProduct,
}) => {
  const dispatch = useDispatch();
  const onAcceptClick = async () => {
    dispatch(
      revokeAccess({
        partneredProductId: partneredProduct.id,
      }),
    );
  };
  if (!product) {
    dispatch(closeDialog());
    return <></>;
  }
  return (
    <StyledContentWrapper>
      <ProductInfo
        name={product.name}
        imageUrl={product.primaryImageUrl}
        variantCount={product.productVariants.length}
      />
      <StyledMessage>{partneredProduct.partnerApprovalMessage}</StyledMessage>
      <StyledBottomWrapper>
        <TextBase variant="tableLabel" color="text.disabled" content="products.acceptRevocation" />
        <AcceptButton variant="contained" color="tertiary" onClick={onAcceptClick}>
          <TextBase content="navigation.accept" variant="subtitle1" />
        </AcceptButton>
      </StyledBottomWrapper>
    </StyledContentWrapper>
  );
};
