import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { inviteToPlatform } from "shared/logic/store/features/viewsSlices/shared/inviteToPlatform/inviteToPlatform";
import { getUserId } from "shared/logic/store/features/resourceSlices/user/selectors";
import { UserRole } from "shared/components/auth/AuthContext";
import { sharedStoreAPI } from "views/sharedStoreAPI";

export interface FormInputs {
  roleType: UserRole | null;
  email: string;
}

export const useInviteToPlatform = () => {
  const {
    handleSubmit,
    register,
    control,
    formState: { isValid },
  } = useForm<FormInputs>({
    mode: "onChange",
    defaultValues: {
      roleType: null,
      email: undefined,
    },
  });
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const isLoading = useSelector(sharedStoreAPI.inviteToPlatformView.getIsInviteToPlatformLoading);

  const onSubmitForm: SubmitHandler<FormInputs> = async ({ email }: FormInputs) => {
    if (!userId) {
      return;
    }
    dispatch(inviteToPlatform({ recipientEmail: email, userId }));
  };

  return {
    handleSubmit,
    register,
    control,
    isValid,
    onSubmitForm,
    isLoading,
  };
};
