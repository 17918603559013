import { History } from "history";
import { AppState } from "@auth0/auth0-react";

export function createOnRedirectCallback(history: History) {
  return function onRedirectCallback(appState: AppState): void {
    if (appState && appState.returnTo) {
      history.push(appState.returnTo);
      return;
    }

    history.push(window.location.pathname);
  };
}
