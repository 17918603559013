import { ENVIRONMENT, getEnvironment } from "shared/logic/utils/getEnvironment";
import { Partner } from "shared/logic/types/Partners/Partner";
import { isProductionPartner } from "shared/logic/utils/partners/isProductionPartner";

export function filterPartnerIdsByEnv(
  partnerIds: string[],
  partnersMap: Record<string, Partner>,
): string[] {
  switch (getEnvironment()) {
    case ENVIRONMENT.PRODUCTION:
      return partnerIds.filter((id) => {
        const partner = partnersMap[id];
        return partner && isProductionPartner(partner);
      });
    case ENVIRONMENT.DEVELOPMENT:
    default:
      return partnerIds;
  }
}
