import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import useSearchParams from "shared/logic/hooks/generalPurpose/useSearchParams";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import { storeAPI } from "views/brandViews/storeAPI";

interface OnFormSubmitParams {
  privacyPolicyUrl: string;
}

export const useFinaliseIntegration = () => {
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();
  const policy = useSelector(getUserPolicy);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const onSubmit = async ({ privacyPolicyUrl }: OnFormSubmitParams) => {
    if (!privacyPolicyUrl || !policy?.entityId) {
      return;
    }
    dispatch(storeAPI.setupView.saveIntegration({ brandId: policy.entityId, privacyPolicyUrl }));
    setSearchParams({});
  };
  return {
    onSubmit,
    handleSubmit,
    register,
    errors,
  };
};
