import { Action, configureStore, getDefaultMiddleware, ThunkAction } from "@reduxjs/toolkit";
import rootReducer, { RootState } from "shared/logic/store/rootReducer";

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const index = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});

export type AppDispatch = typeof index.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default index;
