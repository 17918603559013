import i18next from "i18next";
import { TFunction } from "react-i18next";
import { marked } from "marked";
import { sanitize } from "dompurify";

export type TFunctionArgs = Parameters<TFunction<"translation">>;

export const tFunctionWithMarkdown = (...tArgs: TFunctionArgs) => {
  const tValue = i18next.t(...tArgs);
  try {
    return sanitize(marked.parseInline(tValue));
  } catch (err) {
    // TODO error handling
    return tValue;
  }
};

export const tFunctionWithMarkdownCreator =
  (t: TFunction) =>
  (...tArgs: TFunctionArgs) => {
    const tValue = t(...tArgs);
    try {
      return sanitize(marked.parseInline(tValue, { gfm: false }));
    } catch (err) {
      // TODO error handling
      return tValue;
    }
  };
