import { createAsyncThunk } from "@reduxjs/toolkit";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import { SetupStepEnum } from "views/brandViews/Setup/useSetup";
import { v4 as uuidv4 } from "uuid";
import { setCookie } from "shared/logic/utils/cookiesUtils";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";
import axios from "axios";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { storePrefixes } from "shared/logic/store/features/storePrefixes";

interface OauthIntegration {
  brandName: string;
}

export const oAuthBrandIntegration = createAsyncThunk(
  storePrefixes.brandUser.setupSlice.oAuthBrandIntegration,
  async ({ brandName }: OauthIntegration, { dispatch }) => {
    const apiKey = process.env.REACT_APP_SHOPIFY_API_KEY;
    const userPermissions = [
      "read_products",
      "read_product_listings",
      "write_customers",
      "read_inventory",
      "write_checkouts",
      "write_discounts",
    ];
    // TODO - use existing helper from utils
    const urlToRedirect = `${window.location.protocol}//${window.location.host}${RouteEnum.SETUP}?step=${SetupStepEnum.BILLING_PERMISSIONS}`;
    const nonce = uuidv4();
    const shopifyLink =
      `https://${brandName}.myshopify.com/admin/oauth/authorize` +
      `?client_id=${apiKey}` +
      `&scope=${userPermissions.join(",")}` +
      `&redirect_uri=${urlToRedirect}` +
      `&state=${nonce}`;
    setCookie("nonce", nonce);
    try {
      // await axios.get(shopifyLink);
      // TODO - figure out how to check if site exists despite CORS problem
      window.location.href = shopifyLink;
    } catch (e) {
      ConsoleLog(e);
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        dispatch(
          setSnackbar({
            open: true,
            message: "snackbar.shopifyAccountError",
          }),
        );
        return;
      }
      dispatch(
        setSnackbar({
          open: true,
          message: "snackbar.genericError",
        }),
      );
    }
  },
);
