import { ViewHeadline } from "shared/components/ViewHeadline";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";
import { useAddAccount } from "./useAddAccount";
import { StyledWrapper, StepTitleSection } from "./AddAccount.styled";

export const AddAccount = () => {
  const { stepObject } = useAddAccount();
  return (
    <StyledViewSpace multiplier={3}>
      <ViewHeadline text="Add Account" />
      <StyledWrapper>
        <StepTitleSection>
          <TextBase variant="caption" color="text.disabled">
            Step {stepObject.number} / 3
          </TextBase>
          <TextBase variant="h3" content={stepObject.title} />
        </StepTitleSection>
        {stepObject.component}
      </StyledWrapper>
    </StyledViewSpace>
  );
};
