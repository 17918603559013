import { FC } from "react";
import { TableCell, TableHead } from "@mui/material";
import { StyledHeaderRow } from "shared/components/TableComponents/Styles";
import { TextBase } from "shared/components/base/TextBase/TextBase";

export const DiscoverPartnerListHeader: FC = () => (
  <TableHead>
    <StyledHeaderRow>
      <TableCell colSpan={2}>
        <TextBase
          content="discoverPartners.brandUserView.name"
          variant="tableLabel"
          color="text.primary"
        />
      </TableCell>
      <TableCell align="left">
        <TextBase content="discoverPartners.brandUserView.website" variant="tableLabel" />
      </TableCell>
      <TableCell align="right">
        <TextBase content="discoverPartners.brandUserView.commission" variant="tableLabel" />
      </TableCell>
      <TableCell />
    </StyledHeaderRow>
  </TableHead>
);
