import { ViewHeadline } from "shared/components/ViewHeadline";
import { useSetup } from "views/brandViews/Setup/useSetup";
import { StyledViewSpace } from "shared/components/Styled/StyledViewSpace";

export const Setup = () => {
  const { stepToRender } = useSetup();
  return (
    <StyledViewSpace multiplier={3}>
      <ViewHeadline localizedText="setup.title" />
      {stepToRender()}
    </StyledViewSpace>
  );
};
