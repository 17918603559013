import { styled, ListItemButton, css, ListItemIcon, Box, Badge } from "@mui/material";
import { TextBase } from "shared/components/base/TextBase/TextBase";

export const StyledListButton = styled(ListItemButton)(
  ({ theme }) => css`
    color: ${theme.palette.secondary.contrastText};
    display: flex;
    width: 100%;
    padding: ${theme.spacing(1, 2)};
    min-height: 48px;
    height: 48px;
    flex-direction: row;
    align-items: center;

    .MuiTypography-caption {
      color: ${theme.palette.primary.disabled};
    }

    &:hover {
      background-color: unset;
      color: ${theme.palette.primary.contrastText};

      .MuiTypography-caption {
        color: ${theme.palette.secondary.contrastText};
      }
    }

    &.Mui-selected,
    &.Mui-selected:hover {
      color: ${theme.palette.primary.contrastText};
      background-color: ${theme.palette.primary.light};
      border-radius: ${theme.shape.borderRadius}px;

      .MuiTypography-caption {
        color: ${theme.palette.secondary.contrastText};
      }
    }
  `,
);

export const StyledListIcon = styled(ListItemIcon)`
  min-width: unset;
  color: inherit;
`;

export const FlexBox = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex: 1;
    align-items: center;

    & > * + * {
      margin-left: ${theme.spacing(1)};
    }
  `,
);

export const StyledBadge = styled(Badge)(
  ({ theme }) => css`
    .MuiBadge-badge:not(.MuiBadge-invisible) {
      color: ${theme.palette.text.primary};
      margin: ${theme.spacing(0, 1)};
    }
  `,
);

export const StyledTitle = styled(TextBase)(
  ({ theme }) => css`
    padding: ${theme.spacing(0, 2)};
  `,
);

export const BadgeWrapper = styled(Box)``;
