import { FC } from "react";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import {
  StyledWrapper,
  StyledCloseIcon,
  StyledRejectButton,
  StyledApproveButton,
  StyledApproveIcon,
} from "views/partnerViews/Products/ProductsItem/AcceptOptions/AcceptOptions.styled";
import useAcceptOptions from "views/partnerViews/Products/ProductsItem/AcceptOptions/useAcceptOptions";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";

interface AcceptOptionsProps {
  product: ProductAggregated;
}

export const AcceptOptions: FC<AcceptOptionsProps> = ({ product }) => {
  const { onApproveClick, onRejectClick } = useAcceptOptions(product);
  return (
    <StyledWrapper>
      <TextBase variant="tableLabel" content="products.acceptProduct" />
      <StyledRejectButton onClick={onRejectClick}>
        <StyledCloseIcon />
      </StyledRejectButton>
      <StyledApproveButton onClick={onApproveClick}>
        <StyledApproveIcon />
      </StyledApproveButton>
    </StyledWrapper>
  );
};
