import { RootState } from "shared/logic/store/rootReducer";
import PartneredProduct from "shared/logic/types/PartneredProducts/PartneredProduct";
import Partnership from "shared/logic/types/Partnerships/Partnership";
import { Product } from "shared/logic/types/Products/Product";
import { Partner } from "shared/logic/types/Partners/Partner";
import IdAndEntityPair from "shared/logic/types/IdAndEntityPair";
import { createSelector } from "@reduxjs/toolkit";
import { getPartners } from "shared/logic/store/features/resourceSlices/partners/selectors";
import { getPartnerships } from "shared/logic/store/features/resourceSlices/partnerships/selectors";
import {
  getPartneredProducts,
  getPartneredProductsAggregatedByPartnershipId,
} from "shared/logic/store/features/resourceSlices/partneredProducts/selectors";
import aggregateEntitiesIntoArrayByAKey from "shared/logic/utils/aggregators/aggregateEntitiesIntoArrayByAKey";
import { getProducts } from "shared/logic/store/features/resourceSlices/products/selectors";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";

export interface BrandUserProductAggregated extends PartneredProduct, Product {}

export interface BrandUserPartnershipAggregated extends Partnership {
  partner: Partner;
  partneredProducts: PartneredProduct[];
}

const parsePartnershipToBrandUserPartnershipsAggregated = (
  partnerships: IdAndEntityPair<Partnership>,
  partnersObject: IdAndEntityPair<Partner>,
  partneredProductsAggregatedByPartnershipId: IdAndEntityPair<PartneredProduct[]>,
): BrandUserPartnershipAggregated[] =>
  Object.values(partnerships).map((partnership) => ({
    ...partnership,
    partner: partnersObject[partnership.partnerId],
    partneredProducts: partneredProductsAggregatedByPartnershipId[partnership.id],
  }));

const getDashboardSlice = (state: RootState) => state.brand.dashboardViewSlice;

const getIsDashboardDataFetched = createSelector(
  [getDashboardSlice],
  ({ dashboardSliceStatus }) => dashboardSliceStatus === SliceStatus.FULFILLED,
);

const getBrandUserProductsEnhanced = createSelector(
  [getPartneredProducts, getProducts],
  (partneredProducts, products) =>
    Object.values(partneredProducts).map((partneredProduct) => ({
      ...products[partneredProduct.productId],
      ...partneredProduct,
    })),
);

const getBrandUserProductsAggregatedByProductId = createSelector(
  [getBrandUserProductsEnhanced],
  (productsAggregated) =>
    aggregateEntitiesIntoArrayByAKey<BrandUserProductAggregated>(productsAggregated, "productId"),
);

const getMostSharedPartneredProductsEnhanced = createSelector(
  [getBrandUserProductsAggregatedByProductId],
  (partneredProductsAggregatedByProductId) =>
    Object.values(partneredProductsAggregatedByProductId)
      .sort(
        (leftPartneredProducts, rightPartneredProducts) =>
          leftPartneredProducts.length - rightPartneredProducts.length,
      )
      .map((productsArray) => productsArray[0]) // take only one product data
      .slice(0, 5), // we want only 5 most shared products
);

const getBrandUserPartnershipsAggregated = createSelector(
  [getPartnerships, getPartners, getPartneredProductsAggregatedByPartnershipId],
  (partnerships, partners, partneredProductsEnhancedAggregatedByPartnershipId) =>
    parsePartnershipToBrandUserPartnershipsAggregated(
      partnerships,
      partners,
      partneredProductsEnhancedAggregatedByPartnershipId,
    ),
);

export default {
  getDashboardSlice,
  getIsDashboardDataFetched,
  getBrandUserProductsEnhanced,
  getBrandUserProductsAggregatedByProductId,
  getMostSharedPartneredProductsEnhanced,
  getBrandUserPartnershipsAggregated,
};
