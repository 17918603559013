import {
  StyledCustomAlertProps,
  StyledCustomAlert,
} from "shared/components/Styled/StyledCustomAlert";
import { ReactElement, FC } from "react";

export interface AlertProps extends StyledCustomAlertProps {
  iconComponent?: ReactElement;
}

export const Alert: FC<AlertProps> = ({ severity, iconComponent, sideBorder, children }) => (
  <StyledCustomAlert severity={severity} sideBorder={sideBorder}>
    {iconComponent}
    {children}
  </StyledCustomAlert>
);
