import { styled, Box, css } from "@mui/material";
import gap from "shared/components/Styled/Gap";
import { TextBaseProps, TextBase } from "shared/components/base/TextBase/TextBase";
import { GrayBox } from "shared/components/SettingsComponents/Organization/Organization.styled";
import { CheckCircle } from "@mui/icons-material";

export const ShopifyIntegrationWrapper = styled(GrayBox)<{ $disabled: boolean }>(
  ({ $disabled }) => css`
    align-items: center;
    justify-content: space-between;

    ${$disabled && "filter: grayscale(1)"};
  `,
);

export const ShopifyIntegration = styled(Box)`
  display: flex;
  align-items: center;
  ${gap.left(2)};
`;

export const ShopifyIntegrationLabel = (props: TextBaseProps) => (
  <TextBase variant="subtitle1" {...props} />
);

export const StyledCheckedIcon = styled(CheckCircle)(
  ({ theme }) => css`
    color: ${theme.palette.success.main};
  `,
);
