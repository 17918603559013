import { styled, css, Box, Button } from "@mui/material";
import { OverflowColumnFlex } from "shared/components/OverflowColumnFlex";

export const StyledContentWrapper = styled(OverflowColumnFlex)(
  ({ theme }) => css`
    align-items: center;
    justify-content: center;
    align-self: center;
    max-width: 678px;

    & > * + * {
      margin-top: ${theme.spacing(3)};
    }
  `,
);

export const StyledTextWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > * + * {
      margin-top: ${theme.spacing(1)};
    }
  `,
);

export const StyledAddIntegrationButton = styled(Button)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    & > * + *:not(span) {
      margin-left: ${theme.spacing(1)};
    }
  `,
);
