import { useDispatch, useSelector } from "react-redux";
import { useCopyToClipboard } from "shared/logic/hooks/generalPurpose/useCopyToClipboard";
import { SnackbarIcon } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { toggleGetTagDialog } from "shared/logic/store/features/viewsSlices/partnerUser/products/productsViewSlice";
import { storeAPI } from "views/partnerViews/storeAPI";

export const useTagDrawer = () => {
  const dispatch = useDispatch();
  const copyToClipboard = useCopyToClipboard();
  const isOpen = useSelector(storeAPI.productsView.getTagDrawerOpen);
  const product = useSelector(storeAPI.productsView.getGetTagProduct);
  const toggleOpen = () => {
    dispatch(toggleGetTagDialog());
  };
  const onTagClick = () => {
    if (!product) {
      return;
    }
    copyToClipboard({
      textToCopy: product.nativeStorefrontTag,
      itemName: product.name,
      icon: SnackbarIcon.CODE,
    });
    toggleOpen();
  };

  return {
    isOpen,
    onTagClick,
    toggleOpen,
    product,
  };
};
