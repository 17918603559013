import { Snackbar as MuiSnackbar } from "@mui/material";
import useSnackbar from "shared/components/Snackbar/useSnackbar";
import {
  StyledSnackbarContent,
  StyledMessageWrapper,
} from "shared/components/Snackbar/Snackbar.styled";
import { TextBase } from "shared/components/base/TextBase/TextBase";

const AUTO_HIDE_DURATION = 4000;

const Snackbar = () => {
  const { open, message, i18nInterpolationValues, IconElement, onClose } = useSnackbar();
  return (
    <MuiSnackbar
      onClose={onClose}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      open={open}
      autoHideDuration={AUTO_HIDE_DURATION}
    >
      <StyledSnackbarContent boxShadow={3}>
        <IconElement />
        <StyledMessageWrapper>
          <TextBase variant="body1" content={message} tParams={i18nInterpolationValues} />
        </StyledMessageWrapper>
      </StyledSnackbarContent>
    </MuiSnackbar>
  );
};

export default Snackbar;
