import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "shared/logic/store/rootReducer";
import aggregateEntitiesIntoArrayByAKey from "shared/logic/utils/aggregators/aggregateEntitiesIntoArrayByAKey";
import { getBrands } from "shared/logic/store/features/resourceSlices/brands/selectors";
import { getPartnerships } from "shared/logic/store/features/resourceSlices/partnerships/selectors";
import { aggregatePartneredProducts } from "shared/logic/store/features/viewsSlices/utils/aggregatePartneredProducts";
import { getProducts } from "shared/logic/store/features/resourceSlices/products/selectors";
import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";
import { isObjectEmpty } from "shared/logic/utils/isObjectEmpty";

export const getPartneredProductsSlice = (state: RootState) => state.partneredProductsSlice;

export const getPartneredProducts = createSelector(
  [getPartneredProductsSlice],
  ({ partneredProducts }) => partneredProducts,
);

export const getPartneredProductsAggregatedByPartnershipId = createSelector(
  [getPartneredProductsSlice],
  ({ partneredProducts }) =>
    aggregateEntitiesIntoArrayByAKey(Object.values(partneredProducts), "partnershipId"),
);

export const getProductsAggregated = createSelector(
  [getPartneredProducts, getBrands, getPartnerships, getProducts],
  (partneredProductsMap, brandsMap, partnershipsMap, productsMap) => {
    if (
      isObjectEmpty(brandsMap) ||
      isObjectEmpty(partneredProductsMap) ||
      isObjectEmpty(partnershipsMap) ||
      isObjectEmpty(productsMap)
    ) {
      return null;
    }
    return aggregatePartneredProducts({
      partneredProducts: Object.values(partneredProductsMap),
      brandsMap,
      partnershipsMap,
      productsMap,
    });
  },
);

export const getProductsAggregatedArray = createSelector(
  [getProductsAggregated],
  (partneredProductsEnhancedObject) =>
    partneredProductsEnhancedObject
      ? partneredProductsEnhancedObject.partneredProductsEnhancedArray
      : null,
);

export const getProductsAggregatedMap = createSelector(
  [getProductsAggregated],
  (partneredProductsEnhancedObject) =>
    partneredProductsEnhancedObject
      ? partneredProductsEnhancedObject.idAndPartneredProductEnhancedPair
      : null,
);

export const getPendingProductsCount = createSelector(
  [getProductsAggregatedArray],
  (partneredProductsEnhancedArray) =>
    partneredProductsEnhancedArray
      ? partneredProductsEnhancedArray.filter(
          (product) => product.partnerApprovalStatus === ApprovalStatus.PENDING,
        ).length
      : 0,
);
