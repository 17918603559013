import { RootState } from "shared/logic/store/rootReducer";
import { createSelector } from "@reduxjs/toolkit";
import { getPartners } from "shared/logic/store/features/resourceSlices/partners/selectors";
import { SliceStatus } from "shared/logic/types/store/SliceStatus";

const getDataForSetupViewForPartnerUser = (state: RootState) => state.partner.setupViewSlice;

const getSetupViewSliceStatus = createSelector(
  [getDataForSetupViewForPartnerUser],
  ({ setupViewSliceStatus }) => setupViewSliceStatus,
);

const getSetupViewSliceEditThunkStatus = createSelector(
  [getDataForSetupViewForPartnerUser],
  ({ setupViewSliceEditThunkStatus }) => setupViewSliceEditThunkStatus,
);

const getIsSetupViewDataFetching = createSelector(
  [getSetupViewSliceStatus],
  (setupViewSliceStatus) => setupViewSliceStatus === SliceStatus.PENDING,
);

const getPartnerId = createSelector(
  [getDataForSetupViewForPartnerUser],
  ({ partnerId }) => partnerId,
);

const getPartnerForSetupView = createSelector([getPartnerId, getPartners], (partnerId, partners) =>
  partnerId ? partners[partnerId] : undefined,
);

export default {
  getDataForSetupViewForPartnerUser,
  getSetupViewSliceStatus,
  getSetupViewSliceEditThunkStatus,
  getIsSetupViewDataFetching,
  getPartnerId,
  getPartnerForSetupView,
};
