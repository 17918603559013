import { styled, Switch, css } from "@mui/material";

const IOSSwitch = styled(Switch)(
  ({ theme }) => css`
    width: 42px;
    height: 24px;
    padding: 0;

    & .MuiSwitch-switchBase {
      padding: 0;
      margin: 2px;

      &.Mui-checked {
        transform: translateX(18px);
        color: ${theme.palette.common.white};

        & + .MuiSwitch-track {
          background-color: ${theme.palette.success.main};
          opacity: 1;
        }

        &.Mui-disabled + .MuiSwitch-track {
          opacity: 0.5;
        }
      }

      &.Mui-focusVisible .MuiSwitch-thumb {
        color: ${theme.palette.primary.main};
        border: 6px solid ${theme.palette.common.white};
      }

      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.7;
      }
    }

    & .MuiSwitch-thumb {
      box-shadow: unset;
    }

    & .MuiSwitch-track {
      border-radius: 100px;
      background-color: ${theme.palette.border};
      opacity: 1;
    }
  `,
);

export default IOSSwitch;
