import { FC } from "react";
import { Avatar, styled, Typography, AvatarProps, css } from "@mui/material";

export const StyledAvatar = styled(Avatar)(
  ({ theme }) => css`
    background: #f2c94c;
    border: 2px solid ${theme.palette.common.white};
    color: ${theme.palette.text.primary};
  `,
);

export interface UserAvatarProps extends AvatarProps {
  initials?: string;
}

export const UserAvatar: FC<UserAvatarProps> = ({ initials, ...materialProps }) => (
  <StyledAvatar {...materialProps}>
    <Typography variant="subtitle1">{initials}</Typography>
  </StyledAvatar>
);
