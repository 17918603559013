import { useSelector } from "react-redux";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";

export const useSettings = () => {
  const currentPolicy = useSelector(getUserPolicy);
  const partnerInfo = currentPolicy?.partner;
  const isSetupCompleted = false; // TODO -- link during integration

  return {
    partnerInfo,
    isSetupCompleted,
  };
};
