export enum UserStatus {
  NOT_SPECIFIED = "NOT_SPECIFIED",
  NOT_AUTHENTICATED = "NOT_AUTHENTICATED",
  AUTHENTICATED_REGISTRATION_NEEDED = "AUTHENTICATED_REGISTRATION_NEEDED",
  AUTHENTICATED_NO_POLICIES = "AUTHENTICATED_NO_POLICIES",
  AUTHENTICATED_INTERNAL_ERROR = "AUTHENTICATED_INTERNAL_ERROR",
  AUTHENTICATED = "AUTHENTICATED",
}

export enum UserSetupStatus {
  NO_NEED = "NO_NEED",
  PARTNER_FIRST_LOGIN = "PARTNER_FIRST_LOGIN",
  BRAND_FIRST_LOGIN = "BRAND_FIRST_LOGIN",
  PARTNER_SETUP = "PARTNER_SETUP",
  BRAND_SETUP = "BRAND_SETUP",
}
