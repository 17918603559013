import { useState, useEffect, useCallback } from "react";
import { compareStrings } from "shared/logic/utils/compareStrings";

export interface UseFilterProps<Type> {
  unfilteredItems: Array<Type>;
  propertiesToFilterBy: Array<keyof Type>;
}

export const useFilter = <Type,>({
  unfilteredItems,
  propertiesToFilterBy,
}: UseFilterProps<Type>) => {
  const [items, setItems] = useState<Array<Type>>(unfilteredItems);

  const onSearchHandler = useCallback(
    (input: string) => {
      const filteredItems = unfilteredItems.filter((item: Type) =>
        propertiesToFilterBy
          .map((prop) => compareStrings(item[prop] as unknown as string, input))
          .includes(true),
      );
      setItems(filteredItems);
    },
    [unfilteredItems, propertiesToFilterBy],
  );

  useEffect(() => {
    setItems(unfilteredItems);
  }, [unfilteredItems]);

  return {
    items,
    onSearchHandler,
  };
};
