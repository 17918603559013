import { makeProductAvailable } from "shared/logic/store/features/viewsSlices/brandUser/assign/makeProductAvailable";
import { revokeAccess } from "shared/logic/store/features/viewsSlices/brandUser/assign/revokeAccess";
import assignProductSlice from "shared/logic/store/features/viewsSlices/brandUser/assign/assignProductSlice";
import { fetchResourcesForAssignView } from "shared/logic/store/features/viewsSlices/brandUser/assign/fetchResourcesForAssignView";
import selectors from "./selectors";

export default {
  ...selectors,
  fetchResources: fetchResourcesForAssignView,
  makeProductAvailable,
  revokeAccess,
  slice: assignProductSlice,
};
