import { Drawer, DrawerProps } from "@mui/material";
import { FC } from "react";
import { Close } from "@mui/icons-material";
import { TextBase } from "shared/components/base/TextBase/TextBase";
import { useStepDrawer } from "views/partnerViews/Setup/StepDrawer/useStepDrawer";
import { Partner } from "shared/logic/types/Partners/Partner";
import {
  StyledDrawerContent,
  StyledCloseButton,
  StyledDrawerStep,
  TitleWrapper,
} from "./StepDrawer.styled";

export enum SetupStep {
  DOMAIN_DETAILS = "domain-details",
  DOMAIN_EDITED = "domain-edited",
  SITE_TAG = "site-tag",
}

interface StepDrawerProps extends DrawerProps {
  isOpen: boolean;
  toggleOpen: () => void;
  closeDrawer: () => void;
  partner?: Partner;
}

export const StepDrawer: FC<StepDrawerProps> = ({
  toggleOpen,
  partner,
  isOpen,
  closeDrawer,
  ...materialProps
}) => {
  const { onClose, title, content } = useStepDrawer({ closeDrawer, partner });
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} {...materialProps}>
      <StyledDrawerContent>
        <TitleWrapper>
          <TextBase variant="subtitle1" color="text.disabled" content={title} />
          <StyledCloseButton onClick={toggleOpen}>
            <Close />
          </StyledCloseButton>
        </TitleWrapper>
        <StyledDrawerStep>{content}</StyledDrawerStep>
      </StyledDrawerContent>
    </Drawer>
  );
};
