import { TextBase } from "shared/components/base/TextBase/TextBase";
import { AddCircle } from "@mui/icons-material";
import { FC } from "react";
import {
  StyledContentWrapper,
  StyledTextWrapper,
  StyledAddIntegrationButton,
} from "./NoIntegrations.styled";

interface NoIntegrationsProps {
  onClick: () => void;
}

export const NoIntegrations: FC<NoIntegrationsProps> = ({ onClick }) => (
  <StyledContentWrapper>
    <StyledTextWrapper>
      <TextBase variant="h4" color="text.secondary" content="setup.initial.title" align="center" />
      <TextBase
        variant="body1"
        color="text.disabled"
        align="center"
        content="setup.initial.description"
      />
    </StyledTextWrapper>
    <StyledAddIntegrationButton variant="contained" color="tertiary" onClick={onClick}>
      <TextBase variant="subtitle1" content="setup.addIntegration" />
      <AddCircle />
    </StyledAddIntegrationButton>
  </StyledContentWrapper>
);
