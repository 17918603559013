import { useCallback, useState } from "react";
import { InternalLogin, InternalLogout } from "shared/components/auth/AuthContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { login as loginStoreAction } from "shared/logic/store/features/resourceSlices/user/userSlice";

export function useAuthApiProvider() {
  const {
    loginWithPopup: login,
    isAuthenticated,
    user,
    logout,
    isLoading,
    error,
    getAccessTokenSilently,
  } = useAuth0();
  const dispatch = useDispatch();

  // ############################################################
  // ERROR
  const [internalError, setInternalError] = useState<Error>();

  // ############################################################
  // ACTIONS

  const handleAuthError = useCallback((err: unknown) => {
    if (err instanceof Error) {
      setInternalError(err);
      return;
    }
    setInternalError(new Error("UNKNOWN ERROR"));
  }, []);

  const internalLogin = useCallback<InternalLogin>(async () => {
    try {
      dispatch(loginStoreAction());
      await login();
    } catch (err) {
      handleAuthError(err);
    }
  }, []);

  const internalLogout = useCallback<InternalLogout>(() => {
    try {
      logout({ returnTo: window.location.origin });
    } catch (err) {
      handleAuthError(err);
    }
  }, []);

  return {
    isAuthenticated,
    user,
    error: error ?? internalError, // not used - for future use
    isLoading,
    internalLogin,
    internalLogout,
    getAccessTokenSilently,
  };
}
