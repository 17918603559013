import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useFilter } from "shared/logic/hooks/generalPurpose/useFilter";
import { storeAPI } from "views/partnerViews/storeAPI";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";

export const useDiscoverBrands = () => {
  const dispatch = useDispatch();
  const currentPolicy = useSelector(getUserPolicy);
  const partnerId = currentPolicy?.entityId;
  const unfilteredBrands = useSelector(storeAPI.discoverView.getDiscoverBrands);
  const { onSearchHandler, items: brands } = useFilter({
    unfilteredItems: unfilteredBrands,
    propertiesToFilterBy: ["name", "description"],
  });
  const isLoading = useSelector(storeAPI.discoverView.getDiscoverBrandIsLoading);
  const isEmpty = !isLoading && brands === null;

  useEffect(() => {
    if (!partnerId) {
      return;
    }
    dispatch(storeAPI.discoverView.fetchResources(partnerId));
  }, [partnerId]);

  return {
    isLoading,
    isEmpty,
    brands,
    onSearchHandler,
  };
};
