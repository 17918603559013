import {
  getPartnerships,
  GetPartnershipsReturn,
} from "shared/logic/store/features/viewsSlices/utils/getPartnerships";
import {
  getBrandsByPartnerships,
  GetBrandsByPartnershipsReturn,
} from "shared/logic/store/features/viewsSlices/utils/getBrandsByPartnerships";
import {
  getPartneredProducts,
  GetPartneredProductsReturn,
} from "shared/logic/store/features/viewsSlices/utils/getPartneredProducts";
import {
  getProductsByPartneredProducts,
  GetProductsByPartneredProductsReturn,
} from "shared/logic/store/features/viewsSlices/utils/getProductsByPartneredProducts";
import PartnershipsQuery from "shared/logic/types/Partnerships/PartnershipsQuery";
import PartneredProductsQuery from "shared/logic/types/PartneredProducts/PartneredProductsQuery";

interface GetAndAggregateBaseDataReturn
  extends GetPartnershipsReturn,
    GetBrandsByPartnershipsReturn,
    GetPartneredProductsReturn {
  // PRODUCTS
  allProducts: GetProductsByPartneredProductsReturn["products"];
  allProductsMap: GetProductsByPartneredProductsReturn["productsMap"];
  filteredProducts: GetProductsByPartneredProductsReturn["products"];
  filteredProductsMap: GetProductsByPartneredProductsReturn["productsMap"];
}

interface GetAndAggregateBaseDataArgs {
  partnershipsQuery: PartnershipsQuery;
  partneredProductsQuery: PartneredProductsQuery;
}

// TODO BETTER NAME NEEDED
export async function getBaseDataForPartnerUser({
  partnershipsQuery,
  partneredProductsQuery,
}: GetAndAggregateBaseDataArgs): Promise<GetAndAggregateBaseDataReturn> {
  // ############################################################
  // PARTNERSHIPS
  const { partnerships, partnershipsMap } = await getPartnerships(partnershipsQuery);

  // ############################################################
  // BRANDS
  const { brands, brandsMap } = await getBrandsByPartnerships(partnerships);

  // ############################################################
  // PARTNERED PRODUCTS
  const { allPartneredProducts, filteredPartneredProducts, filteredPartneredProductsMap } =
    await getPartneredProducts(partneredProductsQuery);

  // ############################################################
  // PRODUCTS
  const allProductsPromise = getProductsByPartneredProducts(allPartneredProducts);
  const filteredProductsPromise = getProductsByPartneredProducts(filteredPartneredProducts);

  const [allProductsResponse, filteredProductsResponse] = await Promise.all([
    allProductsPromise,
    filteredProductsPromise,
  ]);
  const { products: allProducts, productsMap: allProductsMap } = allProductsResponse;
  const { products: filteredProducts, productsMap: filteredProductsMap } = filteredProductsResponse;

  return {
    // PARTNERSHIPS
    partnerships,
    partnershipsMap,
    // BRANDS
    brands,
    brandsMap,
    // PARTNERED PRODUCTS
    allPartneredProducts,
    filteredPartneredProducts,
    filteredPartneredProductsMap,
    // PRODUCTS
    allProducts,
    allProductsMap,
    filteredProducts,
    filteredProductsMap,
  };
}
