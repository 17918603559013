import { Dispatch } from "@reduxjs/toolkit";
import { setSnackbar } from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { User } from "shared/logic/types/User/User";
import userApi from "shared/logic/api/userApi";
import { createUserTransaction } from "shared/logic/store/features/user/signUp/utils/actions/createUserTransaction";
import companiesApi from "shared/logic/api/companiesApi";
import { NewUserData } from "shared/logic/store/features/viewsSlices/adminUser/addAccount/createNewUser";

export const setSnackbarError = (dispatch: Dispatch) => {
  dispatch(
    setSnackbar({
      message: "snackbar.genericError",
      open: true,
    }),
  );
};

export const checkForExistingUser = async (email: string): Promise<User & { exists: boolean }> => {
  const userInfo = await userApi.getUserByEmail(email);
  if (!userInfo) {
    throw new Error("User response is empty.");
  }
  if (userInfo.data?.users?.length > 1) {
    throw new Error(`User with email ${email} has multiple instances in the database.`);
  }
  return { ...userInfo.data.users[0], exists: true };
};

type CreateOrReturnExistingUserProps = {
  user: NewUserData;
  onError: () => void;
  newCompanyId: string;
};

export const createOrReturnExistingUser = async ({
  user,
  newCompanyId,
  onError,
}: CreateOrReturnExistingUserProps) => {
  try {
    return await checkForExistingUser(user.emailAddress);
  } catch {
    return await createUserTransaction({
      data: {
        ...user,
        companyId: newCompanyId,
      },
      onError,
      rollback: async () => {
        await companiesApi.deleteCompany(newCompanyId);
      },
    });
  }
};
