import { useState } from "react";
import { useForm } from "react-hook-form";
import { ConsoleLog } from "shared/logic/utils/logging/console-log-helper";

export const useFilterByCategory = () => {
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({
    mode: "onChange",
  });

  const onSubmitFilters = (data: unknown) => {
    ConsoleLog([data, "submit"]);
  };

  const toggleOpenDrawer = () => {
    setOpenDrawer((prev) => !prev);
  };
  return {
    toggleOpenDrawer,
    isOpenDrawer,
    isFormValid: isValid,
    register,
    onSubmitFilters,
    handleSubmit,
  };
};
