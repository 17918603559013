import { useDispatch, useSelector } from "react-redux";
import {
  ChosenDialogEnum,
  closeDialog,
  setDialog,
} from "shared/logic/store/features/resourceSlices/dialog/dialogSlice";
import productsApi from "shared/logic/api/productsApi";
import {
  setSnackbar,
  SnackbarIcon,
} from "shared/logic/store/features/resourceSlices/snackbar/snackbarSlice";
import { ProductAggregated } from "shared/logic/store/features/viewsSlices/partnerUser/products/selectors";
import { getUserPolicy } from "shared/logic/store/features/resourceSlices/user/selectors";
import ApprovalStatus from "shared/logic/_enums/ApprovalStatus.enum";
import { storeAPI } from "views/partnerViews/storeAPI";

interface UseRejectionAcceptDialogProps {
  rejectionMessage?: string;
  product?: ProductAggregated;
}

const useRejectionAcceptDialog = ({ product, rejectionMessage }: UseRejectionAcceptDialogProps) => {
  const dispatch = useDispatch();
  const currentPolicy = useSelector(getUserPolicy);
  const closeDialogAction = () => dispatch(closeDialog());
  const onCancelClick = () => {
    if (!product) {
      return; // TODO -- snackbar?
    }
    dispatch(
      setDialog({
        title: "dialog.acceptProductRejection",
        open: true,
        chosenDialog: ChosenDialogEnum.ITEM_REJECTION,
        dialogProps: {
          product,
        },
      }),
    );
  };
  const onConfirmClick = async () => {
    if (!product) {
      return; // TODO -- snackbar?
    }
    await productsApi.rejectProduct(product.id, { message: rejectionMessage ?? "" }); // TODO - use proper partnered-product id
    // TODO - mutate existing data instead of refetching it
    dispatch(
      storeAPI.productsView.fetchResources({
        partnerId: currentPolicy?.entityId,
        approvalStatus: ApprovalStatus.PENDING,
      }),
    );
    dispatch(
      setSnackbar({
        icon: SnackbarIcon.DONE,
        open: true,
        message: "snackbar.productRejected",
        i18nInterpolationValues: { itemName: product.name },
      }),
    );
    dispatch(closeDialog());
    // TODO - snackbar?
  };

  return {
    onCancelClick,
    onConfirmClick,
    dispatch,
    closeDialogAction,
  };
};

export default useRejectionAcceptDialog;
