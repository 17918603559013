import dashboardView from "./dashboard";
import discoverView from "./discover";
import partnershipsView from "./partnerships";
import productsView from "./products";
import setupView from "./setup";
import firstLoginSetupView from "./firstLoginSetup";
import settingsView from "./settings";

export default {
  dashboardView,
  discoverView,
  partnershipsView,
  productsView,
  setupView,
  firstLoginSetupView,
  settingsView,
};
