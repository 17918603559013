import { FC } from "react";
import { TableCell, Typography } from "@mui/material";
import { ImageCell, StyledItemRow } from "shared/components/TableComponents/Styles";
import { BrandUserPartnershipAggregated } from "shared/logic/store/features/viewsSlices/brandUser/dashboard/selectors"; // TODO: this type should be placed somewhere else
import { VisitWebsite } from "shared/components/VisitWebsite";
import { useHistory } from "react-router-dom";
import { RouteEnum } from "shared/logic/_enums/route.enum";
import {
  UserAvatar,
  ButtonWrapper,
  ShareButton,
  ShareText,
  ShareIcon,
} from "./PartnershipItem.styled";

interface PartnershipItemProps {
  partnership: BrandUserPartnershipAggregated;
}

export const PartnershipItem: FC<PartnershipItemProps> = ({ partnership }) => {
  const history = useHistory();
  const onProductShare = () => {
    history.push({ pathname: RouteEnum.ASSIGN_PARTNER, search: `id=${partnership.partnerId}` });
  };
  return (
    <StyledItemRow>
      <ImageCell>
        <UserAvatar>{partnership.partner.name.slice(0, 2)}</UserAvatar>
      </ImageCell>
      <TableCell padding="none">
        <Typography variant="subtitle1">{partnership.partner.name}</Typography>
      </TableCell>
      <TableCell align="right">
        {partnership.partner.website && (
          <VisitWebsite website={partnership.partner.website} label="utility.visitWebsite" />
        )}
      </TableCell>
      <TableCell align="right">{partnership.partneredProducts?.length}</TableCell>
      <TableCell align="right">{partnership.commissionPercent}%</TableCell>
      <TableCell align="right">
        <ButtonWrapper>
          <ShareButton onClick={onProductShare}>
            <ShareText content="partnerships.brandUserView.shareProducts" />
            <ShareIcon />
          </ShareButton>
        </ButtonWrapper>
      </TableCell>
    </StyledItemRow>
  );
};
