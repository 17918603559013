import { defaultTheme } from "shared/logic/utils/theme/createFermatTheme";

/*
  Those helper functions work as a
  flexbox "gap" property polyfill
 */
const theme = defaultTheme;

const top = (multiplier: number) => `
  & > * + * {
    margin-top: ${theme.spacing(multiplier)};
  }
`;

const left = (multiplier: number) => `
  & > * + * {
    margin-left: ${theme.spacing(multiplier)};
  }
`;

/*
  No-span versions exist to avoid misplacing
  MUI button's ripple
 */

const topNoSpan = (multiplier: number) => `
  & > * + *:not(span) {
    margin-top: ${theme.spacing(multiplier)};
  }
`;

const leftNoSpan = (multiplier: number) => `
  & > * + *:not(span) {
    margin-left: ${theme.spacing(multiplier)};
  }
`;

export default { top, left, topNoSpan, leftNoSpan };
