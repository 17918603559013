import { FC } from "react";
import { TableCell, TableHead } from "@mui/material";
import { StyledHeaderRow } from "shared/components/TableComponents/Styles";
import { TextBase } from "shared/components/base/TextBase/TextBase";

export const DiscoverBrandsTableHeader: FC = () => (
  <TableHead>
    <StyledHeaderRow>
      <TableCell colSpan={2}>
        <TextBase
          content="discoverBrands.tableHeader.name"
          variant="tableLabel"
          color="text.primary"
        />
      </TableCell>
      <TableCell align="right">
        <TextBase content="discoverBrands.tableHeader.website" variant="tableLabel" />
      </TableCell>
      <TableCell align="center">
        <TextBase content="discoverBrands.tableHeader.availableProducts" variant="tableLabel" />
      </TableCell>
      <TableCell align="right">
        <TextBase content="discoverBrands.tableHeader.commission" variant="tableLabel" />
      </TableCell>
      <TableCell />
    </StyledHeaderRow>
  </TableHead>
);
